<template>
  <v-col>
    <v-row v-if="company.category.firstName === '제품'">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pt-0 pb-2">
        <v-btn-toggle v-model="tap" class="transparent" mandatory>
          <v-btn
            class="ma-0"
            active-class="rouge--text text--rouge v-btn--active"
            :value="0"
            text
            outlined
            style="width: 352px; height: 50px"
            >직접 배송</v-btn
          >
          <v-btn
            class="ma-0"
            active-class="rouge--text text--rouge v-btn--active"
            :value="1"
            text
            outlined
            style="width: 352px; height: 50px"
            >환급금</v-btn
          >
        </v-btn-toggle>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.smAndDown" class="pt-0 px-4">
        <v-btn-toggle v-model="tap" class="transparent" mandatory style="width: 100%">
          <v-btn
            class="ma-0"
            active-class="rouge--text text--rouge v-btn--active"
            :value="0"
            text
            outlined
            style="width: 50%; height: 50px"
            >직접 배송</v-btn
          >
          <v-btn
            class="ma-0"
            active-class="rouge--text text--rouge v-btn--active"
            :value="1"
            text
            outlined
            style="width: 50%; height: 50px"
            >구매 후 환급금</v-btn
          >
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-col class="content-page pt-0" :class="company.category.firstName === '제품' ? 'mt-2' : ''">
      <DirectDelivery
        v-if="tap === 0"
        :contracts="directDeliveryContracts"
        :adsPayments="adsPayments"
        :option="option"
        :tap="tap"
        @complete="getInitData(company)"></DirectDelivery>
      <DirectDelivery
        v-if="tap === 1"
        :contracts="purchaseSubsidyContracts"
        :adsPayments="adsPayments"
        :option="option"
        :tap="tap"
        @complete="getInitData(company)"></DirectDelivery>
    </v-col>
  </v-col>
</template>

<script>
import DirectDelivery from '@/components/company/contract/DirectDelivery';
import companyMixin from '@/components/shared/companyMixin';

export default {
  name: 'Contract',
  mixins: [companyMixin],
  components: {
    DirectDelivery,
  },
  data() {
    return {
      tap: 0,
      contracts: [],
      adsPayments: [],
      option: {},
    };
  },
  computed: {
    directDeliveryContracts() {
      return this.contracts.filter(contract => contract.deliveryMethod !== 'PR');
    },
    purchaseSubsidyContracts() {
      return this.contracts.filter(contract => contract.deliveryMethod === 'PR');
    },
  },
  watch: {
    company(value) {
      this.getInitData(value);
    },
  },
  methods: {
    getInitData(company) {
      this.axios
        .get('/company/option')
        .then(option => {
          this.option = option.data;
        })
        .catch(err => {
          console.log(err);
        });

      if (company) {
        this.axios
          .get(
            '/contract?company=' +
              company.id +
              '&orderBy=DATE(date0)&direction=desc&withInfluencer=' +
              (this.company.type === 'A')
          )
          .then(contracts => {
            this.contracts = contracts.data.contracts || [];
          })
          .catch(err => {
            console.log(err);
          });

        this.axios
          .get('/company3/payments?adId=' + company.id)
          .then(result => {
            this.adsPayments = result.data.data.adsPayments;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.getInitData(this.company);
  },
};
</script>
