<template>
  <v-col class="modcam mt-1">
    <v-col class="shades transparent pa-6">
      <v-row class="px-0">
        <v-col md="6">
          <div class="body-1 mt-6 mb-2" style="color: #484848">
            매장이 위치한 지역을 선택해주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-row>
            <v-col md="6">
              <v-select
                v-model="form.local.region"
                :items="Object.entries((option || {}).local || {}).map(([k, v]) => ({ text: k, value: k })) || []"
                item-text="text"
                item-value="value"
                label="지역"
                height="40px"
                :hide-details="true"
                outlined
                solo
                flat
                @change="checkDone"
                class="mr-2"></v-select>
            </v-col>
            <v-col md="6">
              <v-select
                v-model="form.local.code"
                :items="
                  Object.entries(((option || {}).local || {})[form.local.region] || {}).map(([k, v]) => ({
                    text: v,
                    value: k,
                  })) || []
                "
                item-text="text"
                item-value="value"
                label="상권"
                height="40px"
                :hide-details="true"
                outlined
                solo
                flat
                @change="checkDone"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shades white px-6">
      <v-row class="px-0">
        <v-col cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">
            매장 이름을 입력해주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-text-field
            v-model="form.serviceName"
            name="serviceName"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('serviceName') || !errors.has('serviceName')]"
            @input="checkDone"
            single-line
            outlined
            solo
            flat
            required></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12">
          <div class="body-1 mb-2" style="color: #484848">
            매장의 주소를 입력해주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-row>
            <v-col cols="8" class="py-1">
              <v-text-field
                v-model="form.address"
                name="address"
                type="text"
                v-validate="'required|min:2'"
                :rules="[errors.first('address') || !errors.has('address')]"
                placeholder="주소 검색 버튼을 클릭해주세요."
                @input="checkDone"
                readonly
                hide-details
                single-line
                outlined
                solo-inverted
                flat
                required></v-text-field>
            </v-col>
            <v-col cols="4" class="pl-4 py-1">
              <v-menu v-model="daumMap" absolute>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="rouge" class="ma-0" flat outlined style="width: 92px; height: 40px"
                    >주소 검색</v-btn
                  >
                </template>
                <vue-daum-postcode
                  @complete="
                    form.address = $event.address;
                    daumMap = false;
                  "
                  style="max-height: 500px; overflow: scroll"></vue-daum-postcode>
              </v-menu>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="form.addressDetail"
                name="addressDetail"
                type="text"
                :rules="[errors.first('addressDetail') || !errors.has('addressDetail')]"
                placeholder="상세 주소를 입력해주세요."
                @input="checkDone"
                single-line
                outlined
                solo
                flat></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="mt-1" cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">
            매장의 연락처를 알려주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-text-field
            v-model="form.contacts"
            name="contacts"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('contacts') || !errors.has('contacts')]"
            placeholder="예) 02-1234-1234"
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">매장의 영업시간을 알려주세요.</div>
          <v-text-field
            v-model="form.businessHours"
            name="businessHours"
            type="text"
            :rules="[errors.first('businessHours') || !errors.has('businessHours')]"
            hint="월-토 11:00 - 24:00, 주말 휴무"
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">인스타그램 공식 계정</div>
          <!--          <div class="body-1 mb-2">공식 계정이 있는 경우 인스타그램 아이디만 입력해주세요. (@제외)</div>-->
          <v-text-field
            v-model="form.instaOfficial"
            name="instaOfficial"
            type="text"
            :rules="[errors.first('instaOfficial') || !errors.has('instaOfficial')]"
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
export default {
  name: 'Page2',
  components: {
    VueDaumPostcode: () => import('@/components/shared/vue-daum-postcode'),
  },
  props: ['camData', 'option'],
  data: function () {
    return {
      form: {
        local: {
          code: (this.camData.local || {}).code || '',
          region: (this.camData.local || {}).region || '',
        },
        serviceName: this.camData.serviceName || '',
        address: this.camData.address || '',
        addressDetail: this.camData.addressDetail || '',
        contacts: this.camData.contacts || '',
        businessHours: this.camData.businessHours || '',
        instaOfficial: this.camData.instaOfficial || '',
      },
      daumMap: false,
    };
  },
  watch: {
    camData(value) {
      this.form = {
        local: {
          code: (value.local || {}).code || '',
          region: (value.local || {}).region || '',
        },
        serviceName: value.serviceName || '',
        address: value.address || '',
        addressDetail: this.camData.addressDetail || '',
        contacts: value.contacts || '',
        businessHours: value.businessHours || '',
        instaOfficial: value.instaOfficial || '',
      };
    },
    checkUTM(value) {
      if (value) {
        this.checkNT = false;
      }
    },
    checkNT(value) {
      if (value) {
        this.checkUTM = false;
      }
    },
  },
  methods: {
    checkDone() {
      // this.$validator.validateAll().then(success => {
      //   if (success) {
      // if(!this._.isEmpty(this.form.local.region) && !this._.isEmpty(this.form.local.code)) {
      // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
      this.$emit('updateEvent', this.form);
      // this.$store.commit('setDirty', true);
      // }
      // }
      // });
    },
  },
  created() {},
};
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/custom';

::v-deep .v-list-item {
  font-size: 14px;
}
</style>
