<template>
  <div>
    <v-row>
      <iframe
        type="text/html"
        width="100%"
        height="700"
        src="https://rank.supermembers.co.kr/rank/show"
        style="position: relative; top: -150px; z-index: 0"
        frameborder="0"></iframe>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'RankShow',
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
