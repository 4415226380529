var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-data-table',{staticStyle:{"backgroud-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.contracts,"show-select":"","items-per-page":5,"item-key":"id","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.level",fn:function(props){return [_c('v-row',{staticClass:"mt-2"},[(props.item.level === 'diamond')?_c('div',[_c('diamond-icon')],1):_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":props.item.level}},[_vm._v("mdi-hexagon")])],1),_c('a',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"},attrs:{"target":"_blank","href":`https://blog.naver.com/${props.item.outerId}`}},[_vm._v(_vm._s(props.item.username))]),_c('br'),_c('span',{staticClass:"ml-6",class:props.item.type === 'insta' ? 'pink--text text--darken-2' : 'green--text'},[_vm._v(_vm._s(props.item.type === 'insta' ? '인스타' : '네이버'))])])]}},{key:"item.bloggerInfo",fn:function(props){return [_c('v-row',{staticStyle:{"color":"#4770bd","padding-bottom":"4px","font-size":"13px"},on:{"click":function($event){return _vm.influencerDetailDlg(props.item?.Influencer)}}},[_vm._v(" "+_vm._s(props.item?.Influencer?.account)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"#4770BD"}},[_vm._v("mdi-text-box-search-outline")])],1),(props.item?.Influencer)?_c('v-row',_vm._l((Object.keys(props.item?.Influencer?.categories)
            .sort(function (a, b) {
              return props.item?.Influencer?.categories[b] - props.item?.Influencer?.categories[a];
            })
            .slice(0, 2)),function(item){return _c('v-chip',{key:item,staticClass:"ma-0 mr-1 mb-1",attrs:{"outlined":"","x-small":"","color":"#34CD89"}},[_vm._v(" "+_vm._s(item + ' ' + Math.round(props.item?.Influencer?.categories[item]) + '%')+" ")])}),1):_vm._e()]}},{key:"item.bloggerRank",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("numFormat")(props.item?.Influencer?.rank))+" ")]}},{key:"item.bloggerVisitor",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("numFormat")(props.item?.Influencer?.visitor))+" ")]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2",staticStyle:{"position":"absolute"}},[(
            _vm.company.type === 'A' &&
            _vm.$moment()
              .startOf('day')
              .diff(
                _vm.$moment(
                  _vm.curAdsPayment.adsStart || _vm.company.naver.payInfo_startPaidAt || _vm.company.naver.startDate
                ).startOf('day'),
                'days'
              ) <
              _vm.company.periodApply + 1
          )?_c('v-btn',{staticClass:"pa-1 caption",attrs:{"color":"grey","outlined":"","flat":"","tile":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$moment(_vm.curAdsPayment.adsStart || _vm.company.naver.payInfo_startPaidAt || _vm.company.naver.startDate) .startOf('day') .add(_vm.company.periodApply + 1, 'd') .format('YYYY.MM.DD'))+"부터 수락 가능 ")]):(
            _vm.company.type === 'A' &&
            _vm.$moment()
              .startOf('day')
              .diff(
                _vm.$moment(
                  _vm.curAdsPayment.adsStart || _vm.company.naver.payInfo_startPaidAt || _vm.company.naver.startDate
                ).startOf('day'),
                'days'
              ) <
              _vm.company.periodApply + _vm.company.periodAccept + 1 &&
            !_vm.accepted
          )?_c('v-btn',{attrs:{"color":"primary","outlined":!(_vm.selected.length > 0),"flat":"","tile":""},on:{"click":function($event){_vm.showAcceptDlg = true}}},[_vm._v(" "+_vm._s(_vm.selected.length > 0 ? _vm.selected.length + '/' + ((_vm.curAdsPayment.number || _vm.company.naver.number) - _vm.prevSelectedUsers.length) : '')+"수락하기 ")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('InfluencerDetail',{attrs:{"openInfluencer":_vm.openInfluencer},model:{value:(_vm.openInfluencerDetail),callback:function ($$v) {_vm.openInfluencerDetail=$$v},expression:"openInfluencerDetail"}})],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-dialog',{attrs:{"content-class":"white-two","max-width":"522px"},model:{value:(_vm.showAcceptDlg),callback:function ($$v) {_vm.showAcceptDlg=$$v},expression:"showAcceptDlg"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('div',[_c('v-icon',{attrs:{"color":"default"},on:{"click":function($event){_vm.showAcceptDlg = false}}},[_vm._v("close")]),_c('br'),_c('h2',{staticClass:"mt-2 font-weight-bold headline"},[_vm._v("수락 완료하시겠습니까?")])],1)]),_c('v-card-text',{staticClass:"font-size:14px;color:#484848;"},[_c('v-row',{staticClass:"mt-2"},[_vm._v(" 수락하면 캠페인이 시작되며, 추가로 인원을 선택하실 수 없습니다. ")])],1),_c('v-card-actions',{staticStyle:{"padding":"16px 0 0 0"}},[_c('v-spacer'),_c('v-btn',{staticClass:"rouge white-two--text",attrs:{"tile":"","width":"80"},on:{"click":function($event){return _vm.accept()}}},[_vm._v("수락")]),_c('v-btn',{attrs:{"color":"rouge","tile":"","outlined":"","flat":"","width":"80"},on:{"click":function($event){_vm.showAcceptDlg = false}}},[_vm._v("취소")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }