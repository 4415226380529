<template>
  <v-card
    flat
    class="py-4"
    style="background-color: #f2f2f2"
    :style="
      $vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'
    ">
    <v-row class="px-8 my-4" style="background-color: white">
      <v-col cols="12">
        <v-col cols="9" md="5">
          <div class="body-1 font-weight-bold mt-6 mb-2">담당자 성함</div>
          <v-text-field
            v-model="form.charger"
            name="charger"
            type="text"
            v-validate="'required'"
            :rules="[errors.first('charger') || !errors.has('charger')]"
            single-line
            outlined
            solo
            flat
            required></v-text-field>
        </v-col>
        <v-col cols="9" md="5">
          <div class="body-1 font-weight-bold mt-2 mb-2">담당자 연락처</div>
          <v-text-field
            v-model="form.contacts"
            name="contacts"
            type="text"
            v-validate="'required|min:10'"
            :rules="[errors.first('contacts') || !errors.has('contacts')]"
            @keyup="phoneFormat"
            maxlength="13"
            single-line
            outlined
            solo
            flat
            required
            hide-details></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-row v-for="(m, index) in form.contacts2 || []" :key="index">
            <v-col cols="9" md="5">
              <v-text-field
                v-model="form.contacts2[index]"
                name="contacts2"
                type="text"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
            </v-col>
            <v-btn icon depressed @click="removeArray('contacts2', index)" class="mt-1"
              ><v-icon>mdi-minus-circle</v-icon></v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="9" md="5">
              <v-text-field
                v-model="contacts2"
                name="contacts2"
                type="text"
                v-validate="'min:10'"
                :rules="[errors.first('contacts2') || !errors.has('contacts2')]"
                maxlength="13"
                @keyup.enter="addArray('contacts2')"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
            </v-col>
            <v-btn icon depressed @click="addArray('contacts2')" class="mt-1"><v-icon>mdi-plus-circle</v-icon></v-btn>
          </v-row>
        </v-col>
        <v-col cols="9" md="5">
          <div class="body-1 font-weight-bold mt-2 mb-2">마케팅 보고서 및 결제 정보 수령 이메일</div>
          <v-text-field
            v-model="form.email2"
            name="email"
            type="email"
            v-validate="'required|min:10'"
            :rules="[errors.first('email') || !errors.has('email')]"
            single-line
            outlined
            solo
            flat
            required
            hide-details></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-row v-for="(m, index) in form.email3 || []" :key="index">
            <v-col cols="9" md="5">
              <v-text-field
                v-model="form.email3[index]"
                name="email3"
                type="text"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
            </v-col>
            <v-btn icon depressed @click="removeArray('email3', index)" class="mt-1"
              ><v-icon>mdi-minus-circle</v-icon></v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="9" md="5">
              <v-text-field
                v-model="email3"
                name="email3"
                type="text"
                v-validate="'min:10'"
                :rules="[errors.first('email3') || !errors.has('email3')]"
                @keyup.enter="addArray('email3')"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
            </v-col>
            <v-btn icon depressed @click="addArray('email3')" class="mt-1"><v-icon>mdi-plus-circle</v-icon></v-btn>
          </v-row>
        </v-col>
      </v-col>
      <v-col>
        <v-col class="mt-4 mb-12 text-center pa-0 ml-0" cols="2">
          <v-btn color="primary" block @click="onSubmit">저장</v-btn>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="body-2" style="color: #c2c2c2">
      <v-spacer></v-spacer>
      {{ appVersion }}
    </v-row>
  </v-card>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';

export default {
  name: 'Editinfo',
  mixins: [userMixin, companyMixin],
  data() {
    return {
      form: {
        charger: '',
        contacts: '',
        email2: '',
        contacts2: [],
        email3: [],
      },
      contacts2: '',
      email3: '',
    };
  },
  computed: {
    appVersion() {
      return 'build ' + process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    onSubmit() {
      this.$validator
        .validateAll()
        .then(async success => {
          if (!success) {
            return null;
          }
          if (this.userIsAuthenticated) {
            await this.axios.put('/company/user?id=' + this.user.id, this.form);
            return this.$store.dispatch('getUserInfo', { email: this.user.email });
          } else {
            return null;
          }
        })
        .then(() => {});
    },
    phoneFormat(event) {
      if (!/\d/.test(event.key) && event.keyCode !== 8) return event.preventDefault();
    },
    addArray: function (type) {
      this.form[type] = this.form[type] || [];
      if (this[type].length > 0 && this.form[type].indexOf(this[type]) === -1) {
        this.form[type].push(this[type]);
        this[type] = '';
        this.checkDone();
      }
    },
    removeArray: function (type, idx) {
      console.log('remove' + idx);
      this.form[type].splice(idx, 1);
      this.checkDone();
    },
  },
  created() {
    if (!this._.isEmpty(this.user)) {
      this.form = this._.pick(this.user, ['charger', 'contacts', 'email2', 'email', 'contacts2', 'email3']);
      this.form.email2 = this.form.email2 || this.form.email;
    }
  },
};
</script>

<style scoped lang="scss">
/*@import '../../assets/css/headerfooter.css';*/
</style>
