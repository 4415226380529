<template>
  <v-col class="ml-1 pa-6 pb-12">
    <v-card
      v-if="company.type === 'A'"
      @click="showAdReportDetail = true"
      flat
      class="subheading my-4"
      style="background-color: white; cursor: pointer; border: 1px solid rgba(204, 204, 204, 1); border-radius: 8px">
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="#00C73C">
            <v-icon color="white">mdi-file-chart</v-icon>
          </v-list-item-avatar>
          <v-list-item-content style="min-width: 292px">
            <v-list-item-title>
              <naverIcon class="mt-1" />
              <span class="mb-2 ml-1" style="font-size: 16px">캠페인 전체 보고서</span>
              <span class="ml-1"><span style="font-size: 13px; color: #4770bd">상세보기 ></span></span>
            </v-list-item-title>
            <v-row v-if="$vuetify.breakpoint.smAndDown" class="mt-3">
              <v-col>
                <v-list-item-title style="font-size: 13px; color: #484848">조회수</v-list-item-title>
                <v-list-item-subtitle
                  class="body-1"
                  v-text="viewCount | numFormat"
                  style="color: #484848"></v-list-item-subtitle>
              </v-col>
              <v-col>
                <v-list-item-title style="font-size: 13px; color: #484848">링크 클릭수</v-list-item-title>
                <v-list-item-subtitle
                  class="body-1"
                  v-text="clickCount | numFormat"
                  style="color: #484848"></v-list-item-subtitle>
              </v-col>
              <v-col>
                <v-list-item-title style="font-size: 13px; color: #484848">CTR</v-list-item-title>
                <v-list-item-subtitle class="body-1" style="color: #484848">{{
                  (viewCount ? Math.round((clickCount * 10000.0) / viewCount) / 100 : 0) + '%'
                }}</v-list-item-subtitle>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.smAndDown" class="mt-3">
              <v-col>
                <v-list-item-title style="font-size: 13px; color: #484848">상위노출 키워드 수</v-list-item-title>
                <v-list-item-subtitle class="body-1" style="color: #484848"
                  >{{ keywordCount | numFormat }}개</v-list-item-subtitle
                >
              </v-col>
              <v-col>
                <v-list-item-title style="font-size: 13px; color: #484848">좋아요</v-list-item-title>
                <v-list-item-subtitle class="body-1" style="color: #484848"
                  >{{ likeCount | numFormat }}개</v-list-item-subtitle
                >
              </v-col>
              <v-col>
                <v-list-item-title style="font-size: 13px; color: #484848">댓글</v-list-item-title>
                <v-list-item-subtitle class="body-1" style="color: #484848"
                  >{{ commentCount | numFormat }}개</v-list-item-subtitle
                >
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$vuetify.breakpoint.mdAndUp">
          <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px; color: #484848">조회수</v-list-item-title>
            <v-list-item-subtitle
              class="body-1 mt-3"
              v-text="viewCount | numFormat"
              style="color: #484848"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px; color: #484848">클릭수</v-list-item-title>
            <v-list-item-subtitle
              class="body-1 mt-3"
              v-text="clickCount | numFormat"
              style="color: #484848"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px; color: #484848">CTR</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848">{{
              (viewCount ? Math.round((clickCount * 10000.0) / viewCount) / 100 : 0) + '%'
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px; color: #484848">상위노출 키워드 수</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
              >{{ keywordCount | numFormat }}개</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-content style="min-width: 110px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px; color: #484848">좋아요</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
              >{{ likeCount | numFormat }}개</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-content style="min-width: 110px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px; color: #484848">댓글</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
              >{{ commentCount | numFormat }}개</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <div v-if="company.category.firstName === '매장'">
      <v-row>
        <h2 class="font-weight-bold headline hidden-sm-and-down">네이버 플레이스 키워드 순위</h2>
        <v-spacer />
        <v-btn v-if="placeKeywords.length > 0" class="rouge white-two--text" @click="openAdsKeywordDialog()"
          >키워드 수정하기</v-btn
        >
      </v-row>
      <v-row class="mx-0">
        <v-col cols="12" md="4" class="pr-4" v-if="placeKeywords?.[0]">
          <v-col class="line py-2">
            <div class="body-1 font-weight-bold mt-6 mb-2">{{ placeKeywords?.[0]?.name }}</div>
            <span class="display-2 font-weight-light py-4"
              >{{ placeKeywords?.[0]?.ranks[$moment().tz('Asia/Seoul').format('YYYY-MM-DD')] || '- ' }}위</span
            >
          </v-col>
        </v-col>
        <v-col cols="12" md="4" class="pr-4" v-if="placeKeywords?.[1]">
          <v-col class="line py-2">
            <div class="body-1 font-weight-bold mt-6 mb-2">{{ placeKeywords?.[1]?.name }}</div>
            <span class="display-2 font-weight-light py-4"
              >{{ placeKeywords?.[1]?.ranks[$moment().tz('Asia/Seoul').format('YYYY-MM-DD')] || '- ' }}위</span
            >
          </v-col>
        </v-col>
        <v-col cols="12" md="4" class="pr-4" v-if="placeKeywords?.[2]">
          <v-col class="line py-2">
            <div class="body-1 font-weight-bold mt-6 mb-2">{{ placeKeywords?.[2]?.name }}</div>
            <span class="display-2 font-weight-light py-4"
              >{{ placeKeywords?.[2]?.ranks[$moment().tz('Asia/Seoul').format('YYYY-MM-DD')] || '- ' }}위</span
            >
          </v-col>
        </v-col>
      </v-row>
      <div class="body-1 font-weight-bold mt-6 mb-2">조회기간 설정</div>
      <div
        style="display: flex; margin-bottom: 24px"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'justify-content: space-between; align-items: center;'
            : 'flex-direction: column;'
        ">
        <v-btn-toggle mandatory>
          <v-btn class="ma-0" color="#BD1328" flat outlined style="width: 70px" @click="updateDate(0, 1, 'Place')">
            1주
          </v-btn>
          <v-btn class="ma-0" color="#BD1328" flat outlined style="width: 70px" @click="updateDate(1, 0, 'Place')">
            1개월
          </v-btn>
        </v-btn-toggle>
        <div style="display: flex; align-items: center" :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 8px;'">
          <DatePicker :date="startDatePlace" @onDateChangeHandler="v => onDateChangeHandler(v, 'start', 'Place')" />
          <span style="margin: 0px 8px">-</span>
          <DatePicker :date="endDatePlace" @onDateChangeHandler="v => onDateChangeHandler(v, 'end', 'Place')" />
        </div>
      </div>
      <v-row class="my-6">
        <v-col v-if="placeKeywords.length > 0">
          <KeywordChart
            :width="660"
            :height="280"
            :data="placeChartData"
            :labels="keywordChartLabelsPlace"></KeywordChart>
        </v-col>
        <v-row v-else>
          <v-row
            justify="center"
            align="center"
            class="py-12"
            :style="{
              backgroundImage: naverPlaceBackground,
              width: $vuetify.breakpoint.mdAndUp ? '647px' : '343px',
              height: $vuetify.breakpoint.mdAndUp ? '324px' : '314px',
            }">
            <v-col justify="center" cols="12">
              <div class="body-1 font-weight-bold text-center mt-6 mb-2" style="color: #484848">
                아직 등록된 키워드가 없습니다.
              </div>
              <div class="body-1 font-weight-light text-center" style="color: #808080">
                지금 키워드를 등록하고, 매장의 순위를 확인해보세요.
              </div>
            </v-col>
            <v-btn class="rouge white-two--text mb-6" @click="openAdsKeywordDialog()">키워드 추가하기</v-btn>
          </v-row>
        </v-row>
      </v-row>
    </div>
    <h2 class="font-weight-bold headline hidden-sm-and-down">집행된 광고의 결과를 확인해보세요.</h2>
    <v-row class="mx-0">
      <v-col cols="12" md="4" class="pr-4">
        <v-col class="line py-2">
          <div class="body-1 font-weight-bold mt-6 mb-2">10위 이상 노출 포스트 수</div>
          <span class="display-2 font-weight-light py-4">{{ topTenCount }}</span>
        </v-col>
      </v-col>
      <v-col cols="12" md="4" class="pr-4">
        <v-col class="line py-2">
          <div class="body-1 font-weight-bold mt-6 mb-2">10위 이상 노출 키워드 수</div>
          <span class="display-2 font-weight-light py-4">{{ topTenKeywordCount }}</span>
        </v-col>
      </v-col>
    </v-row>
    <v-col class="mx-0">
      <div class="body-1 font-weight-bold mt-6 mb-2">조회기간 설정</div>
      <div
        style="display: flex; margin-bottom: 24px"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'justify-content: space-between; align-items: center;'
            : 'flex-direction: column;'
        ">
        <v-btn-toggle mandatory>
          <v-btn class="ma-0" color="#BD1328" flat outlined style="width: 70px" @click="updateDate(0, 1, '')">
            1주
          </v-btn>
          <v-btn class="ma-0" color="#BD1328" flat outlined style="width: 70px" @click="updateDate(1, 0, '')">
            1개월
          </v-btn>
        </v-btn-toggle>
        <div style="display: flex; align-items: center" :style="$vuetify.breakpoint.mdAndUp ? '' : 'margin-top: 8px;'">
          <DatePicker :date="startDate" @onDateChangeHandler="v => onDateChangeHandler(v, 'start', '')" />
          <span style="margin: 0px 8px">-</span>
          <DatePicker :date="endDate" @onDateChangeHandler="v => onDateChangeHandler(v, 'end', '')" />
        </div>
      </div>
      <div
        style="
          color: #484848;
          font-size: 14px;
          font-weight: 700;
          padding: 8px 10px;
          background: #f5f5f5;
          text-align: center;
          margin-bottom: 12px;
        ">
        키워드 노출 현황
      </div>
      <KeywordChart :width="660" :height="280" :data="keywordChartData" :labels="keywordChartLabels"></KeywordChart>
      <v-data-table :headers="headers" :items="keywordsList" :items-per-page="10" mobile-breakpoint="0">
        <template v-slot:header="props">
          <tr class="text-left pa-0">
            <th
              v-for="header in props.headers"
              :key="header.text"
              class="text-left pa-0"
              :class="['column']"
              :style="'width:' + header.width + 'px'">
              <span class="header font-weight-bold shades--text text--black">{{ header.text }}</span>
            </th>
          </tr>
        </template>
        <template v-slot:item="props">
          <tr>
            <td class="pa-0">{{ props.item.name }}</td>
            <td class="pa-0">{{ parseInt(props.item.rank) }}위</td>
            <td class="pa-0">
              <v-btn
                v-if="!_.isEmpty(props.item.ranks[props.item.label])"
                color="rouge"
                outlined
                @click="showDetail(props.item)"
                class="pa-1"
                style="min-width: 40px"
                >내역 보기</v-btn
              >
            </td>
            <td class="pa-0">
              <v-btn
                v-if="props.item.link"
                color="rouge"
                :href="props.item.link"
                target="_blank"
                outlined
                class="pa-1"
                style="min-width: 40px"
                >노출보기</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="body-1 font-weight-bold mt-6 mb-2">이용 현황</div>
      <contract-chart :datas="contractChartData"></contract-chart>
      <!--<div class="body-1 font-weight-bold mt-6 mb-2">이용자 통계</div>-->
      <!--<contract-chart2 :datas="contractChartData2"></contract-chart2>-->
    </v-col>
    <v-row justify="center" class="mx-0">
      <v-dialog v-model="showDialog" content-class="white-two" width="340">
        <v-data-table
          :headers="[
            { text: '이름', value: 'username', width: 70 },
            { text: '검색결과 페이지', value: 'rank', width: 130 },
            { text: '노출 현황', value: 'link', width: 60 },
            { text: '게시물', value: 'link', width: 50 },
          ]"
          :items="(keyword.ranks || {})[keyword.label]"
          :items-per-page="10"
          mobile-breakpoint="0"
          style="backgroud-color: transparent">
          <template v-slot:header="props">
            <tr class="text-left pa-0">
              <th
                v-for="header in props.headers"
                :key="header.text"
                class="text-left px-4"
                :class="['column']"
                :style="'width:' + header.width + 'px'">
                <span class="header font-weight-bold shades--text text--black">{{ header.text }}</span>
              </th>
            </tr>
          </template>
          <template v-slot:item="props">
            <tr>
              <td class="px-4">{{ props.item.username }}</td>
              <td class="px-4">{{ parseInt(props.item.rank) }}위</td>
              <td class="px-4">
                <a :href="props.item.link" target="_blank"><v-icon>open_in_new</v-icon></a>
              </td>
              <td>
                <a :href="props.item.post" target="_blank"><v-icon>open_in_new</v-icon></a>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-dialog>
    </v-row>
    <v-row justify="center" class="mx-0">
      <v-dialog v-model="showAddKeywordDialog" fullscreen content-class="white-two">
        <v-row justify="center" class="pa-4">
          <v-col md="8" cols="12">
            <v-toolbar flat>
              <v-toolbar-title
                class="subheading"
                @click="
                  showAddKeywordDialog = false;
                  naverPlaceUrl = '';
                "
                >< &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;뒤로 가기</v-toolbar-title
              >
            </v-toolbar>
            <div style="border-bottom: 20px solid #fbfbfb" />
            <v-row justify="center">
              <v-col md="8" cols="12">
                <div class="subheading mt-6 mb-2">매장 순위를 확인할 키워드를 입력해주세요.</div>
                <div class="body-1 mb-6" style="color: #757575">
                  입력하신 키워드가 네이버 플레이스에 검색 시, 몇위에 노출되는지 모니터링할 수 있어요.
                </div>
                <div class="mb-6" v-if="naverPlaceUrl?.length === 0">
                  <div class="label mt-6 mb-2">네이버 지도에서 내 가게 정보 불러오기</div>
                  <v-btn class="mx-0" color="rouge" outlined tile flat @click="showNaverMapInfo = true">
                    불러오기
                  </v-btn>
                  <div style="font-size: 12px; color: #484848">
                    네이버 지도에 등록된 업체 정보를 불러올 수 있습니다.
                  </div>
                </div>
                <div v-else>
                  <div class="label mt-6 mb-2">네이버 지도에서 내 가게 정보 불러오기</div>
                  <v-row>
                    <v-text-field class="mb-2" dense v-model="naverPlaceUrl" outlined disabled></v-text-field>
                    <v-btn class="ml-4" color="rouge" outlined tile flat @click="showNaverMapInfo = true">
                      수정하기
                    </v-btn>
                  </v-row>
                </div>
                <v-col v-for="id in [0, 1, 2]" :key="id">
                  <div style="color: #484848">키워드 {{ id + 1 }}</div>
                  <v-text-field
                    class="mb-2"
                    dense
                    label="키워드를 입력해주세요."
                    v-model="placeKeywordsDialog[id].name"
                    outlined></v-text-field>
                </v-col>
                <v-btn class="rouge white-two--text mt-6" tile @click="saveAdsKeywordDialog()" style="width: 120px"
                  >저장</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showNaverMapInfo" content-class="white-two" persistent max-width="400px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showNaverMapInfo = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold subheading">네이버 지도에서 내 가게 정보 불러오기</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 0">
            <v-row>
              1. 네이버 지도에서 내 가게의 정보 페이지로 이동합니다.<br />
              2. 공유 버튼을 눌러 URL을 복사 후 아래에 붙여넣어주세요.
            </v-row>
            <v-row class="my-2">
              <a
                @click="
                  showNaverMapInfo = false;
                  showGuide = true;
                "
                style="text-decoration: underline"
                >URL을 찾기 어려우신가요?</a
              >
            </v-row>
            <v-row class="mt-6">
              <v-text-field
                v-model="naverPlaceUrl"
                name="naverPlaceUrl"
                type="text"
                :rules="[
                  v => (v || '').indexOf('naver.me/') > -1 || '유효한 URL이 아닙니다.',
                  v => (v || '').indexOf('http') > -1 || '유효한 URL이 아닙니다.',
                ]"
                outlined
                solo
                flat
                error-messages></v-text-field>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 1">
            <v-row class="mt-2" justify="center" align="center">
              <div>
                <v-progress-circular indeterminate :size="30" :width="5" color="red accent-3"></v-progress-circular>
              </div>
              <div>내 가게 정보를 가져오고 있습니다.</div>
              <div>잠시만 기다려주세요.</div>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 2">
            <v-row class="mt-2">
              <div>불러온 정보가 정확한지 꼭 확인해주세요.</div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="naverMapInfoStep === 2"
              class="rouge white-two--text"
              flat
              @click="
                showNaverMapInfo = false;
                getNaverMapClose();
              "
              >닫기</v-btn
            >
            <v-btn v-if="naverMapInfoStep === 0" color="rouge" outlined flat @click.native="showNaverMapInfo = false"
              >취소</v-btn
            >
            <v-btn v-if="naverMapInfoStep === 0" class="rouge white-two--text" flat @click="getNaverMapInfo()"
              >불러오기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" class="mx-0">
      <v-dialog v-model="showSaveCompletedDialog" content-class="white-two" width="422px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showSaveCompletedDialog = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold headline">네이버 플레이스 키워드 저장 완료</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <div>기간별로 키워드 순위를 확인할 수 있습니다.</div>
            </v-row>
            <v-row>
              <div>키워드 순위가 현황에 반영되기까지 최대 24시간이 소요될 수 있습니다.</div>
            </v-row>
            <v-btn class="mt-10" color="rouge white-two--text" @click="showSaveCompletedDialog = false" flat tile
              >닫기</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" class="mx-0">
      <v-dialog v-model="showModifyKeywordDialog" content-class="white-two" width="498px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showModifyKeywordDialog = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold headline">네이버 플레이스 키워드를 수정하시겠습니까?</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row class="mb-10">
              <div>키워드를 수정시, 예전 키워드의 기록은 삭제됩니다.</div>
            </v-row>
            <v-btn
              class="rouge white-two--text mr-4"
              flat
              tile
              @click="
                showModifyKeywordDialog = false;
                showAddKeywordDialog = false;
                saveNaverPlaceKeywords();
              "
              >저장</v-btn
            >
            <v-btn color="rouge" outlined flat tile @click.native="showModifyKeywordDialog = false">취소</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" class="mx-0">
      <v-dialog v-model="showAdReportDetail" fullscreen content-class="white-two">
        <v-row justify="center" class="pa-4">
          <v-col md="9" cols="12">
            <v-toolbar flat>
              <v-toolbar-title class="subheading" @click="showAdReportDetail = false"
                >< &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;뒤로 가기</v-toolbar-title
              >
            </v-toolbar>
            <div style="border-bottom: 20px solid #fbfbfb" />
            <v-row justify="center">
              <AdReportDetail :contracts="contracts" :stats="stats"></AdReportDetail>
            </v-row>
          </v-col>
        </v-row>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';

import KeywordChart from '@/components/company/report/KeywordChart';
import DatePicker from '@/components/company/report/DatePicker';
import ContractChart from './ContractChart';
import AdReportDetail from '@/components/company/report/AdReportDetail';

export default {
  name: 'Naver',
  mixins: [companyMixin],
  props: ['contracts'],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver_c.svg'),
    ContractChart,
    KeywordChart,
    DatePicker,
    AdReportDetail,
  },
  data() {
    return {
      headers: [
        { text: '노출 키워드', align: 'left', value: 'name', width: 200 },
        { text: '순위', value: 'rank', width: 130 },
        { text: '노출 내역', value: 'list', width: 130 },
        { text: '노출 현황', value: 'link', width: 130 },
      ],
      keywords: [],
      date: null,
      keywordChartData: [],
      keywordChartLabels: [],
      contractChartData2: [],
      showDialog: false,
      keyword: {},
      purchase: [],
      keywordsList: [],
      startDate: '',
      endDate: '',
      startDatePlace: '',
      endDatePlace: '',
      placeKeywordsDialog: [{}, {}, {}],
      placeKeywords: [],
      placeChartData: [],
      keywordChartLabelsPlace: [],
      showAddKeywordDialog: false,
      showNaverMapInfo: false,
      naverMapInfoStep: 0,
      naverPlaceUrl: '',
      showSaveCompletedDialog: false,
      showModifyKeywordDialog: false,
      stats: {},
      showAdReportDetail: false,
    };
  },
  computed: {
    topTenCount() {
      let count = 0;
      let date = this.$moment().tz('Asia/Seoul').format('YYYY-MM-DD');
      this.keywords?.forEach(function (keyword) {
        if (keyword.ranks[date]) {
          keyword.ranks[date]?.forEach(function (rank) {
            if (rank.rank <= 10) {
              count++;
            }
          });
        }
      });
      return count;
    },
    topTenKeywordCount() {
      let count = 0;
      let date = this.$moment().tz('Asia/Seoul').format('YYYY-MM-DD');
      this.keywords?.forEach(function (keyword) {
        if (keyword.ranks[date]) {
          if (
            keyword.ranks[date].some(function (rank) {
              return rank.rank <= 10;
            })
          ) {
            count++;
          }
        }
      });
      return count;
    },
    contractChartData() {
      let chartData = [];
      let contracts = this.contracts.filter(contract => {
        return contract.type !== 'insta';
      });
      let groupByMonth = this._.groupBy(contracts, b =>
        this.$moment(b.date0).tz('Asia/Seoul').startOf('month').format('YYYY/MM')
      );
      let dateStart = this.$moment().tz('Asia/Seoul');
      for (let i = 0; i < 5; i++) {
        let yearmonth = dateStart.format('YYYY/MM');
        let data = {};
        data.label = yearmonth.split('/')[1] + '월';
        let groupByLevel = this._.groupBy(groupByMonth[yearmonth], 'level');
        data.diamond = (groupByLevel.diamond || []).length;
        data.black = (groupByLevel.black || []).length;
        data.red = (groupByLevel.red || []).length;
        data.yellow = (groupByLevel.yellow || []).length;
        chartData.push(data);
        dateStart.subtract(1, 'month');
      }
      chartData.reverse();
      return chartData;
    },
    naverPlaceBackground() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return `url(${require('@/assets/img/report/place-keyword-desktop.png')})`;
      } else {
        return `url(${require('@/assets/img/report/place-keyword-mobile.png')})`;
      }
    },
    viewCount() {
      return this._.sumBy(this.stats.all || [], 'views');
    },
    clickCount() {
      return this._.sumBy(this.stats.contracts, contract => (contract.shortUrl || {}).views || 0);
    },
    keywordCount() {
      // return this._.sumBy(this.stats.contracts || [], function (contract) {
      //   return contract.keywords.filter((keyword) => {
      //     return (keyword.rank <= 20);
      //   }).length;
      // })
      let count = 0;
      let date = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      this.keywords?.forEach(function (keyword) {
        if (keyword.ranks[date]) {
          if (
            keyword.ranks[date].some(function (rank) {
              return rank.rank <= 10;
            })
          ) {
            count++;
          }
        }
      });
      return count;
    },
    likeCount() {
      return this._.sumBy(this.stats.all || [], 'likes');
    },
    commentCount() {
      return this._.sumBy(this.stats.all || [], 'comments');
    },
  },
  watch: {
    company(value) {
      this.getInitData(value);
    },
  },
  methods: {
    drawChart(date) {
      date = new Date(this.endDate);
      let endDate = date;
      let startDate = new Date(this.startDate);

      let labels = [];
      let dateMove = new Date(startDate);
      while (dateMove.getTime() <= endDate.getTime()) {
        let strDate = this.toIsoString(dateMove).split('T')[0];
        labels.push(strDate);
        dateMove.setDate(dateMove.getDate() + 1);
      }
      this.keywordChartLabels = labels;

      date = new Date(this.endDatePlace);
      let endDatePlace = date;
      let startDatePlace = new Date(this.startDatePlace);

      let labelsPlace = [];
      let dateMovePlace = new Date(startDatePlace);
      while (dateMovePlace.getTime() <= endDatePlace.getTime()) {
        let strDatePlace = this.$moment(dateMovePlace).tz('Asia/Seoul').format('YYYY-MM-DD');
        labelsPlace.push(strDatePlace);
        dateMovePlace.setDate(dateMovePlace.getDate() + 1);
      }
      this.keywordChartLabelsPlace = labelsPlace;

      let categoryRank = {
        AA: 1,
        BA: 2,
        AB: 3,
        CA: 4,
        DA: 5,
        AC: 6,
        BB: 7,
        BC: 8,
        AD: 9,
        CB: 10,
        BD: 11,
        CD: 12,
        CC: 13,
        DB: 14,
        DC: 15,
        DD: 16,
        AE: 17,
        BE: 18,
        CE: 19,
        DE: 20,
        EE: 21,
      };

      this.keywords.forEach(function (keyword) {
        keyword.categoryRank =
          categoryRank[
            keyword.info
              .map(function (info) {
                return info.level || 'A';
              })
              .join('')
          ];
      });
      this.keywords.sort(function (a, b) {
        if (a.categoryRank === b.categoryRank) {
          if (a.info[0].count === b.info[0].count) {
            return b.info[1].count - a.info[1].count;
          } else {
            return b.info[0].count - a.info[0].count;
          }
        } else {
          return a.categoryRank - b.categoryRank;
        }
      });

      let count = 0;
      let filterdKeywords = this.keywords.filter(keyword => {
        let rank = 50;
        let rankb = 50;
        let link = '';
        const targetIndex = labels.findLastIndex(date => !!keyword.ranks[date]);
        if (keyword.ranks[labels[targetIndex]]) {
          keyword.ranks[labels[targetIndex]].forEach(_rank => {
            if (_rank.rank < rank) {
              rank = _rank.rank;
              link =
                'https://search.naver.com/search.naver?date_from=&date_option=0&date_to=&dup_remove=1&nso=&post_blogurl=&post_blogurl_without=&&srchby=all&st=sim&where=post&query=' +
                keyword.name +
                '&start=' +
                (parseInt((rank - 1) / 10) * 10 + 1);
            }
            _rank.link =
              'https://search.naver.com/search.naver?date_from=&date_option=0&date_to=&dup_remove=1&nso=&post_blogurl=&post_blogurl_without=&&srchby=all&st=sim&where=post&query=' +
              keyword.name +
              '&start=' +
              (parseInt((_rank.rank - 1) / 10) * 10 + 1);
            let ct = this._.find(this.contracts, ['id', _rank.contractId]);
            _rank.post = (ct || {}).link
              ? 'https://blog.naver.com/' + String(ct.userEmail || '').split('@')[0] + '/' + ct.link
              : '';
          });
        }
        if (keyword.ranks[labels[0]]) {
          keyword.ranks[labels[0]].forEach(function (_rank) {
            if (_rank.rank < rankb) {
              rankb = _rank.rank;
            }
          });
        }
        keyword.rank = rank;
        keyword.rankb = rankb;
        keyword.link = link;

        // NOTE: label이 없어서 키워드 노출현황 테이블에 값이 출력 안되는 문제때문에 ranks에 있는 key으로 label 설정하도록 변경
        keyword.label = labels[labels.length - 1];
        const rankLabels = Object.keys(keyword.ranks).sort((a, b) => b - a);
        for (const rankLabel of rankLabels) {
          if (labels.includes(rankLabel)) {
            keyword.label = rankLabel;
            break;
          }
        }

        if (rank < 21) {
          count++;
        }
        return rank < 21 && count < 4;
      });

      let colors = ['rgba(221, 30, 48, .7)', 'rgba(64, 162, 203, .7)', 'rgba(235, 176, 53, .7)'];
      this.keywordChartData = filterdKeywords.map(function (keyword, idx) {
        return {
          label: keyword.name,
          data: labels.map(function (label) {
            let rank = 51;
            if (keyword.ranks[label]) {
              keyword.ranks[label].forEach(function (_rank) {
                if (_rank.rank < rank) {
                  rank = _rank.rank;
                }
              });
            }
            return rank > 50 ? undefined : rank;
          }),
          backgroundColor: colors[idx],
          hoverBackgroundColor: colors[idx],
          borderColor: colors[idx],
          borderWidth: 4,
          fill: false,
        };
      });

      this.placeChartData = this.placeKeywords.map(function (keyword, idx) {
        return {
          label: keyword.name,
          data: labelsPlace.map(function (label) {
            return keyword.ranks[label];
          }),
          backgroundColor: colors[idx],
          hoverBackgroundColor: colors[idx],
          borderColor: colors[idx],
          borderWidth: 4,
          fill: false,
        };
      });

      this.keywordsList = this.keywords.filter(keyword => {
        return (keyword.ranks[keyword.label] || []).length > 0;
      });
    },
    showDetail(keyword) {
      this.showDialog = true;
      this.keyword = keyword;
    },
    onDateChangeHandler(v, type, option) {
      if (type === 'start') {
        this['startDate' + option] = v;
      } else {
        this['endDate' + option] = v;
      }
      this.drawChart();
    },
    updateDate(mon, week, option) {
      this['endDate' + option] = this.$moment(this['endDate' + option])
        .tz('Asia/Seoul')
        .format('YYYY-MM-DD');
      if (mon > 0 || week > 0) {
        this['startDate' + option] = this.$moment(this['endDate' + option])
          .subtract(mon, 'months')
          .subtract(week, 'weeks')
          .format('YYYY-MM-DD');
      } else {
        this['startDate' + option] = this.$moment().subtract(6, 'months').format('YYYY-MM-DD');
      }
      this.drawChart();
    },
    openAdsKeywordDialog() {
      this.showAddKeywordDialog = true;
      this.naverPlaceUrl = this.company.naverPlaceUrl || '';
      this.placeKeywordsDialog = this._.cloneDeep(
        this._.concat(
          this.placeKeywords,
          this._.times(3 - this.placeKeywords.length, () => ({}))
        )
      );
    },
    saveAdsKeywordDialog() {
      if (this.placeKeywords?.length === 0) {
        this.showAddKeywordDialog = false;
        this.saveNaverPlaceKeywords();
      } else {
        this.showModifyKeywordDialog = true;
      }
    },
    async saveNaverPlaceKeywords() {
      if (this.company.storeId) {
        await Promise.all(
          this.placeKeywordsDialog.map(async (keyword, idx) => {
            if (keyword?.name !== this.placeKeywords[idx]?.name && !this._.isEmpty(this.placeKeywords[idx])) {
              await this.axios.delete(
                `/company3/place/keyword?adId=${this.company.id || this.company.rKey}&name=${
                  this.placeKeywords[idx]?.name
                }`
              );
            }
          })
        );
        this.placeKeywords = this._.filter(this.placeKeywordsDialog, item => {
          item.ranks = item.ranks || {};
          return !this._.isEmpty(item) && item.name;
        }).slice(0, 3);
        await this.axios.post('/company3/place/keyword', {
          adId: this.company.id || this.company.rKey,
          keywords: this.placeKeywords.map(keyword => keyword.name),
        });
        this.showSaveCompletedDialog = true;
      } else {
        alert('네이버 지도 정보를 먼저 등록해주세요.');
      }
    },
    async getNaverMapInfo() {
      let isValid =
        this.naverPlaceUrl.length > 0 &&
        this.naverPlaceUrl.indexOf('naver') > -1 &&
        this.naverPlaceUrl.indexOf('blog') === -1 &&
        this.naverPlaceUrl.indexOf('search') === -1;
      if (isValid) {
        let timerId = setInterval(async () => {
          if (this.company.id > 0) {
            clearInterval(timerId);
            let naverMapInfo = await this.axios.get(
              '/utils/naverme?naverUrl=' + this.naverPlaceUrl + '&id=' + this.company.id
            );
            if (naverMapInfo.status === 200 && naverMapInfo.data.storeId) {
              this.naverMapInfo = naverMapInfo.data;
              this.naverMapInfo.step = 2;
              this.naverMapInfoStep = 2;
              let data = {};
              Object.keys(this.naverMapInfo).map(key => {
                data[key] = this._.isEmpty(this.company[key]) ? this.naverMapInfo[key] : this.company[key];
              });
              data.naverPlaceUrl = this.naverPlaceUrl;
              data.storeId = this.naverMapInfo.storeId;
              await this.axios.put('/company3?id=' + (this.company.id || this.company.rKey), data);
              await this.$store.dispatch('getCompanyInfo', { email: this.company.email });
            } else {
              this.naverPlaceUrl = '';
              alert('불러오기 실패');
            }
          }
        }, 2000);
        setTimeout(() => {
          clearInterval(timerId);
          if (this.company.id === undefined) alert('불러오기 실패');
        }, 10000);
      } else {
        alert('유효한 URL이 아닙니다.');
      }
    },
    getNaverMapClose() {
      this.naverMapInfoStep = 0;
    },
    async getInitData(company) {
      let contracts = this.contracts.filter(contract => {
        return contract.type !== 'insta';
      });

      this.contractChartData2 = [];
      let groupByAge = this._.groupBy(contracts, b => {
        b.birth = b.birth || this.$moment().format('YYYY');
        let birth = this.$moment(b.birth, 'YYYY');
        let a = this.$moment().diff(birth, 'years');
        if (a < 40) {
          return Math.floor(a / 5) * 5;
        } else if (a < 50) {
          return Math.floor(a / 10) * 10;
        } else if (a >= 50) {
          return 50;
        }
      });
      [20, 25, 30, 35, 40, 50].forEach(age => {
        let data = {};
        if (age < 40) {
          data.label = age % 10 ? Math.floor(age / 10) * 10 + '대후반' : age + '대초반';
        } else if (age < 50) {
          data.label = age + '대';
        } else if (age >= 50) {
          data.label = age + '대이상';
        }
        let groupByGender = this._.groupBy(groupByAge[age], 'gender');
        data['남자'] = (groupByGender['남자'] || []).length;
        data['여자'] = (groupByGender['여자'] || []).length;
        this.contractChartData2.push(data);
      });

      // this.date = this.toIsoString(new Date()).split('T')[0];
      let keywords = await this.axios.get('/company3/keyword2?companyId=' + (company.id || company.rKey));
      this.keywords = keywords.data;
      if (this.company.category.firstName === '매장') {
        let placeKeywords = await this.axios.get('/company3/place/keyword?adId=' + (company.id || company.rKey));
        this.placeKeywords = placeKeywords.data;
      }

      let purchase = await this.axios.get(
        '/company3/purchase?companyId=' + (company.id || company.rKey) + '&type=naver'
      );
      this.purchase = purchase.data;

      this.drawChart();

      if (this.company.type === 'A') {
        this.stats = (await this.axios.get('/company3/report?id=' + this.company.id)).data;
        this.stats.contracts.map(async contract => {
          let today = this.$moment();
          let keywords = contract.keywordRankHistories.filter(history => {
            let historyDate = this.$moment(history.date).tz('Asia/Seoul');
            return today.format('YYYY-MM-DD') === historyDate.format('YYYY-MM-DD');
          });
          contract.keywords = this._.sortBy(keywords, ['rank']);
        });
      }
    },
  },
  async created() {
    this.endDate = this.endDatePlace = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
    this.startDate = this.startDatePlace = this.$moment().subtract(7, 'd').format('YYYY-MM-DD');
    if (this.company) {
      this.getInitData(this.company);
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .line {
  border-bottom: 1px solid #bd1328;
  border-bottom: 1px solid var(--rouge-color);
  width: 100%;
}

::v-deep .v-datatable.v-table {
  background-color: transparent;
  ::v-deep .v-datatable__actions {
    background-color: transparent;
  }
}
</style>
