import Vue from 'vue';
// import Vuetify from 'vuetify'
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import light from './vuetify/theme';
import '../assets/scss/main.scss';

import en from 'vuetify/es5/locale/en';
import ko from './i18n/vuetify/ko';

import { Ripple } from 'vuetify/lib/directives';

import '@mdi/font/css/materialdesignicons.css';

// Vue.use(Vuetify);
Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    // dark: true,
    // disable: true,
    themes: { light },
  },
  icons: {
    iconfont: 'md' || 'mdi' || 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    values: {
      plus: 'fas fa-plus-circle',
    },
  },
  options: {
    minifyTheme: function (css) {
      return process.env.NODE_ENV === 'production' ? css.replace(/[\s|\r\n|\r|\n]/g, '') : css;
    },
    customProperties: true,
  },
  lang: {
    locales: { ko, en },
    current: 'ko',
  },
});
