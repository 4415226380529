import Vue from 'vue';

export default {
  state: {
    campaigns: [],
    campaignIdx: 0,
    campaignTemp: {},
    campaignRegStep: 1,
  },
  mutations: {
    setCampaigns(state, payload) {
      state.campaigns = payload;
    },
    setCampaignIdx(state, payload) {
      state.campaignIdx = payload;
    },
    setCampaignTemp(state, payload) {
      state.campaignTemp = payload;
    },
    clearCampaignTemp(state) {
      state.campaignTemp = {};
    },
    setCampaignRegStep(state, payload) {
      state.campaignRegStep = payload;
    },
  },
  actions: {
    async getCampaignInfo({ commit, rootState }, payload) {
      commit('clearError');
      if (!((rootState.user || {}).id || payload.id)) {
        return null;
      }
      try {
        let data = await Vue.axios.get(
          '/campaign?companyId=' + ((rootState.user || {}).id || payload.id) + '&orderBy=id&direction=desc'
        );
        commit('clearCampaignTemp');
        let campaigns = data.data.campaigns.filter(campaign => {
          campaign.id *= 1;
          if (campaign.step !== 99) {
            // campaign.step
            commit('setCampaignTemp', campaign);
            return false;
          } else {
            return true;
          }
        });
        campaigns = Vue._.sortBy(campaigns, [
          function (com) {
            return ((com.naver || {}).available || 0.1) + ((com.insta || {}).available || 0.1);
          },
        ]);
        commit('setCampaigns', campaigns);
        if (rootState.campaignIdx >= campaigns.length || rootState.campaignIdx === undefined) {
          commit('setCampaignIdx', 0);
        }
        return payload;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    },
    async signCampaignUp({ commit, rootState, dispatch }, payload) {
      commit('clearError');
      try {
        await Vue.axios.post('/campaign', payload);
        dispatch('getCampaignInfo', payload);
        // commit('setcampaignIdx', rootState.campaigns.length - 1)

        // Vue.axios.post('/utils/sendmail', {
        //   from: '슈퍼멤버스 <cs@supermembers.co.kr>',
        //   to: (rootState.user || {}).email || payload.email,
        //   subject: '슈퍼멤버스에 오신 것을 환영합니다.',
        //   template: 'campaignWelcome',
        //   username: payload.name
        // });
        console.log((rootState.user || {}).email);
        return payload;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    },
  },
  getters: {
    campaign(state) {
      return state.campaigns[state.campaignIdx || 0];
    },
    campaigns(state) {
      return state.campaigns;
    },
    campaignIdx(state) {
      return state.campaignIdx;
    },
    campaignTemp(state) {
      return state.campaignTemp;
    },
    campaignRegStep(state) {
      return state.campaignRegStep;
    },
  },
};
