<template>
  <div class="whiteBg">
    <div class="container row3">
      <div class="text">
        <h2 class="font-weight-bold">가성비와 가심비를 챙긴<span class="mobile" /> 저렴한 비용</h2>
        <p class="description">업계 최저비용으로 좋은 광고 효과를 누릴 수 있습니다.</p>
      </div>
      <div style="display: flex" class="tap">
        <div
          style="
            width: 60px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            cursor: pointer;
            margin-right: 20px;
            padding-bottom: 4px;
          "
          :style="selectedTab === 0 ? 'color: #EA2A3A; box-shadow: 0 4px 2px -2px #EA2A3A;' : 'color: #212121;'"
          @click="onChangeTab(0)">
          매장
        </div>
        <div
          style="
            width: 60px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            cursor: pointer;
            padding-bottom: 4px;
          "
          :style="selectedTab === 1 ? 'color: #EA2A3A; box-shadow: 0 4px 2px -2px #EA2A3A;' : 'color: #212121;'"
          @click="onChangeTab(1)">
          제품
        </div>
      </div>
      <img
        :src="selectedTab === 0 ? storeImage : productImage"
        class="row-image mx-2 row-image-store"
        :class="selectedTab === 0 ? 'row-image-store' : 'row-image-product'" />
    </div>
  </div>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Price',
  mixins: [userMixin],
  data() {
    return {
      productImage:
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/storePrice.png')
          : require('@/assets/img/landing/ceo-landing/storePrice-mobile.png'),
      storeImage:
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/productPrice.png')
          : require('@/assets/img/landing/ceo-landing/productPrice-mobile.png'),
      selectedTab: 0,
    };
  },
  methods: {
    onChangeTab(index) {
      this.selectedTab = index;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.productImage =
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/storePrice.png')
          : require('@/assets/img/landing/ceo-landing/storePrice-mobile.png');

      this.storeImage =
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/productPrice.png')
          : require('@/assets/img/landing/ceo-landing/productPrice-mobile.png');
    });
  },
};
</script>

<style scoped lang="scss">
.row3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 72px 0;
  @media (max-width: 960px) {
    padding: 80px 0px 56px;
    overflow: hidden;
  }
}
.row3 .row-image {
  margin: 12px 0 0 0;
  @media (max-width: 960px) {
    max-width: 325px;
    margin: 16px auto;
    width: 100%;
    text-align: center;
  }
}

.row-image-store {
  /* width: 1046px; */
  /* width: 690px; */
  width: 335px;
}

.row-image-product {
  /* width: 690px; */
  width: 335px;
}

.text {
  .eyebrow {
    color: #757575;
    margin-bottom: 4px;
    margin-left: 1px;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 12px;
    color: #212121;
  }
  p {
    color: #484848;
    line-height: 1.8;
  }
  @media (max-width: 960px) {
    // padding-top: 0;
    // margin-bottom: auto;
    .eyebrow {
      margin-bottom: 6px;
      font-size: 13px;
    }
    h2 {
      margin-bottom: 12px;
    }
    p {
      color: #484848;
      line-height: 1.94;
    }
  }
}

.description {
  color: #484848;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;

  @media (max-width: 960px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.tap {
  margin-top: 48px;
  margin-bottom: 32px;

  @media (max-width: 960px) {
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

.rank-button {
  background: #ffffff;
  border: 2px solid #bd1328;
  border-radius: 16px;
  color: #bd1328;
  font-size: 14px;
}

.whiteBg {
  background-color: #fff;
}
</style>
