<template>
  <v-col>
    <v-card class="pa-6" flat>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading">사용 가능 금액</v-row>
          <v-row class="caption">즉시 이용가능한 잔액입니다.<br /></v-row>
          <v-row class="display-1 secondary--text text-center mt-10">
            <v-col> ₩{{ availPoints | numFormat }} </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="pa-6">
      <v-row class="subheading">잔액 충전하기</v-row>
      <v-row class="caption">현재 잔액 충전은 카드로만 가능합니다.</v-row>
      <v-divider class="my-4"></v-divider>
      <v-row class="body-2">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '7' : '12'">
          <div class="mb-2">충전금액</div>
          <v-chip v-for="(amount, idx) in [5, 10, 50, 100]" :key="idx" @click="price = price + amount * 10000">
            +{{ amount }}만원
          </v-chip>
          <v-text-field
            v-model.number="price"
            name="price"
            type="number"
            suffix="원"
            class="mt-2 mb-6"
            hint="충전하실 금액을 추가하거나 직접 입력해주세요."
            persistent-hint
            single-line
            outlined
            required
            >직접 입력</v-text-field
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="mb-2">결제 금액</div>
          <div class="secondary--text subheading">₩{{ Math.floor(price * 1.1) | numFormat }}</div>
          <div class="my-3" style="color: #757575">결제 금액은 부가세 10%가 포함된 금액입니다.</div>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="pay()" style="margin: 0">결제하기</v-btn>
    </v-card>
  </v-col>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import campaignMixin from '@/components/shared/campaignMixin';
import BootPay from 'bootpay-js';
import { EventBus } from '@/components/shared/event-bus';

export default {
  name: 'Payment',
  mixins: [userMixin, campaignMixin],
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      dialog: false,
      price: 0,
      points: [{ balance: 0 }],
    };
  },
  methods: {
    pay() {
      let price = this._.isNumber(this.price) ? this.price : Number(this.price.replace(/[^\d]/g, ''));
      let payPrice = Math.floor(this.price * 1.1);
      BootPay.request({
        price: payPrice, //실제 결제되는 가격
        application_id: '5f20860202f57e0033305324',
        name: '인플루언서 마케팅 상품', //결제창에서 보여질 이름
        pg: 'inicis',
        method: 'card',
        show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
        items: [
          {
            item_name: '인플루언서 마케팅 상품', //상품명
            qty: 1,
            unique: '0',
            price: payPrice,
            cat1: '마케팅',
            cat2: '마케팅',
            cat3: '마케팅',
          },
        ],
        user_info: {
          username: this.user.name,
          email: this.user.email,
          addr: '',
          phone: this.user.contacts.replace(/[^0-9]/g, ''),
        },
        order_id: this.user.id + '_members_' + new Date().getTime(),
        params: { date: new Date(), companyId: this.user.id },
        extra: {
          theme: 'purple',
        },
      })
        .error(data => {
          console.log('error', data);
          this.$store.commit('setMsg', data.msg);
          this.$store.commit('setShowSnackbar', true);
        })
        .cancel(data => {
          console.log('cancel', data);
        })
        .confirm(data => {
          console.log('confirm', data, this.user);
          if (data.params.companyId === this.user.id) {
            BootPay.transactionConfirm(data);
          } else {
            BootPay.removePaymentWindow();
          }
        })
        .close(data => {
          console.log('close', data);
        })
        .done(async data => {
          console.log('done', data);
          let charge = {
            companyId: data.params.companyId,
            card: data.card_name,
            amount: price, //data.price,
            status: data.status,
            method: data.method,
            order: data.order_id,
            receiptId: data.receipt_id,
            receiptUrl: data.receipt_url,
          };
          try {
            let res = await this.axios.post('/company/user/sumempoint', charge);
            if (res.status === 200) {
              this.points.unshift(res.data);
            } else {
              await this.axios.delete('/bootpay/cancel', { receiptId: charge.receiptId });
            }
          } catch (e) {
            await this.axios.delete('/bootpay/cancel', { receiptId: charge.receiptId });
            console.log(e);
          }
          if (this.user) {
            console.log('update:point', this.user);
            EventBus.$emit('update:point');
          }
        });
    },
  },
  mounted() {
    this.$nextTick(async () => {
      let points = await this.axios.get(`company/user/point?companyId=${this.user.id}&orderBy=id&direction=desc`);
      if (points.data.total > 0) {
        this.points = points.data.companyPoints;
      }
    });
  },
};
</script>
