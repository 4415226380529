import { render, staticRenderFns } from "./Editinfo.vue?vue&type=template&id=2a69540e&scoped=true"
import script from "./Editinfo.vue?vue&type=script&lang=js"
export * from "./Editinfo.vue?vue&type=script&lang=js"
import style0 from "./Editinfo.vue?vue&type=style&index=0&id=2a69540e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a69540e",
  null
  
)

export default component.exports