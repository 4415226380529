<template>
  <v-card
    flat
    :style="
      $vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'
    ">
    <v-tabs v-model="tab" grow>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <component
            :is="item.content"
            :propositions.sync="propositions"
            :idx="$route.params.initCampaign || 0"
            @updateProposition="updateProposition"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import campaignMixin from '@/components/shared/campaignMixin';

export default {
  name: 'PropositionYoutube',
  mixins: [userMixin, campaignMixin],
  data() {
    return {
      tab: 0,
      items: [
        { tab: '유튜버에게 제안하기', content: () => import('@/components/campaign/propositionyoutube/Propose') },
        { tab: '제안 결과', content: () => import('@/components/campaign/propositionyoutube/Result') },
      ],
      propositions: [],
    };
  },
  watch: {
    tab: {
      handler() {
        this.$router.push({ path: 'propositionyoutube', query: { tab: this.tab } });
      },
      deep: true,
    },
  },
  methods: {
    async updateProposition() {
      if (this.campaigns.length > 0) {
        let query = '';
        this.campaigns.map((campaign, idx) => {
          query += 'campaignId=' + campaign.id;
          if (idx !== this.campaigns.length - 1) {
            query += ' OR ';
          }
        });
        let propositions = await this.axios.get('/campaign/proposition?query=' + encodeURIComponent(query));
        this.propositions = propositions.data.propositions;
      }
    },
  },
  async created() {
    await this.updateProposition();
    this.tab = parseInt(this.$route.query.tab || '0');
  },
};
</script>

<style scoped lang="scss"></style>
