<template>
  <v-col class="regads mt-2">
    <v-col class="shades white pa-6">
      <v-row class="px-4">
        <v-col cols="12" md="12">
          <h3>서비스에 대한 정보를 입력해주세요.</h3>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shades white pa-6 mt-2">
      <v-row class="px-4">
        <v-col cols="12" md="10">
          <div class="label mb-2">서비스 이름을 입력해주세요.</div>
          <v-text-field
            v-model="form.serviceName"
            name="serviceName"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('serviceName') || !errors.has('serviceName')]"
            @input="checkDone"
            single-line
            outlined
            required></v-text-field>
        </v-col>
        <v-col class="mt-1" cols="12" md="10" v-if="this.camData.type === 'T'">
          <div class="label mb-2">서비스 이용과 관련하여 문의를 받으실 수 있는 연락처를 입력해주세요.</div>
          <v-text-field
            v-model="form.contacts"
            name="contacts"
            type="text"
            :rules="[errors.first('contacts') || !errors.has('contacts')]"
            placeholder="예) 02-1234-1234"
            @input="checkDone"
            single-line
            outlined></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mb-2">인스타그램 공식 계정</div>
          <v-text-field
            v-model="form.instaOfficial"
            name="instaOfficial"
            type="text"
            :rules="[errors.first('instaOfficial') || !errors.has('instaOfficial')]"
            hint="공식 계정이 있는 경우 인스타그램 아이디만 입력해주세요. (@제외)"
            persistent-hint
            @input="checkDone"
            outlined
            solo
            flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
export default {
  name: 'Page2',
  props: ['camData', 'option'],
  data: function () {
    return {
      form: {
        serviceName: this.camData.serviceName || '',
        contacts: this.camData.contacts || '',
        instaOfficial: this.camData.instaOfficial || '',
        step: 2,
      },
      checkUTM: false,
      checkNT: false,
      utm: {
        source: 'naver',
        medium: 'blog',
        campaign: this.camData.name,
      },
      nt: {
        source: 'naver',
        medium: 'blog',
        detail: this.camData.name,
        keyword: this.camData.name,
      },
    };
  },
  watch: {
    // 'camData.name': function (value) {
    //   this.form.name = value;
    // }
  },
  methods: {
    checkDone() {
      this.$validator.validateAll().then(success => {
        if (success) {
          this.form.step = 2;
          // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
          this.$emit('updateEvent', this.form);
        }
      });
    },
  },
  created() {
    // this.checkDone();
  },
};
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/custom';

::v-deep .v-list-item {
  font-size: 14px;
}
</style>
