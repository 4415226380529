<template>
  <v-card
    flat
    style="background-color: #f2f2f2"
    :style="
      $vuetify.breakpoint.mdOnly
        ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;'
        : 'width: 100vw; overflow-x:auto; white-space: nowrap;'
    ">
    <VideoInfo v-if="campaign.youtube" :campaign="campaign" :propositions="propositions"></VideoInfo>
    <PropositionDetails
      v-else
      :campaign="campaign"
      :propositions="propositions"
      @update:proposition="$emit('update:proposition')"></PropositionDetails>
    <DeliveryInfo v-if="!campaign.youtube" :campaign="campaign" :propositions="propositions"></DeliveryInfo>
  </v-card>
</template>

<script>
export default {
  name: 'Manage',
  props: ['campaign', 'propositions'],
  components: {
    PropositionDetails: () => import('./Manage/PropositionDetails'),
    VideoInfo: () => import('./Manage/VideoInfo'),
    DeliveryInfo: () => import('./Manage/DeliveryInfo'),
  },
};
</script>
