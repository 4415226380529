<template>
  <footer>
    <div class="footer-container">
      <div class="footer-logo">
        <img src="../../assets/img/mayacrew-logo.png" />
        <span class="font-weight-bold">(주) 마야크루</span>
        <span>company@supermembers.co.kr</span>
      </div>
      <div>
        <router-link class="font-weight-bold shades--text text--black" to="/terms/">이용약관</router-link>
        |
        <router-link class="font-weight-bold shades--text text--black" to="/privacy/">개인정보처리방침</router-link>
      </div>
    </div>
    <div class="footer-sub-container">
      <span class="hidden-md-and-up"><img src="../../assets/img/mayacrew-logo.png" width="160px" /></span>
      <span v-if="$vuetify.breakpoint.smAndDown" class="font-weight-bold">(주) 마야크루</span>
      <span v-if="$vuetify.breakpoint.smAndDown">company@supermembers.co.kr</span>
      <span>사업자등록번호 : 875-81-00475</span>
      <span class="desktop">|</span>
      <span>대표 : 오준호</span>
      <span class="desktop">|</span>
      <span>주소 : 서울특별시 마포구 월드컵북로 35, 5층</span>
      <span class="desktop">|</span>
      <span>고객센터 : 1800-7710</span>
      <span class="desktop">|</span>
      <span>통신판매업신고번호 : 2022-서울마포-1986</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SuperFooter',
  created() {
    // this.$vuetify.theme.primary = this.$vuetify.theme.default;
  },
};
</script>

<style scoped lang="scss">
footer {
  border-top: 1px solid #c2c2c2;
  border-top: 1px solid var(--pinkish-grey-color);
  padding: 20px 0;
  font-size: 14px;
  line-height: 30px;
}

.footer-sub-container,
.footer-container {
  width: 1000px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.footer-sub-container {
  align-items: flex-start;
}

.footer-logo {
  display: -webkit-flex;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-logo span,
.footer-container a {
  padding: 0 20px;
}

.footer-logo img {
  height: 30px;
  margin-bottom: 8px;
}

@media (max-width: 960px) {
  footer {
    padding: 25px;
  }
  .footer-logo {
    display: none;
  }
  .footer-container,
  .footer-sub-container {
    width: 100%;
    flex-direction: column;
  }
  .footer-container {
    margin-bottom: 20px;
  }
}
</style>
