import { render, staticRenderFns } from "./Charge.vue?vue&type=template&id=519a9a90&scoped=true"
import script from "./Charge.vue?vue&type=script&lang=js"
export * from "./Charge.vue?vue&type=script&lang=js"
import style0 from "./Charge.vue?vue&type=style&index=0&id=519a9a90&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519a9a90",
  null
  
)

export default component.exports