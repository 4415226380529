<template>
  <v-card
    flat
    style="width: 1120px"
    :style="
      $vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'
    ">
    <!--    <div style="background-color: white">-->
    <!--      <div class="pl-4 py-2" style="font-size: 13px; color: #484848">-->
    <!--        필터-->
    <!--      </div>-->
    <!--      <v-toolbar flat>-->
    <!--        <v-chip-group v-model="channels" multiple column-->
    <!--                      active-class="active white&#45;&#45;text rouge">-->
    <!--          <v-chip v-for="(option, index) in ['네이버 블로그']" :key="index"-->
    <!--                  color="#F5F5F5"-->
    <!--                  style="color:#484848;font-size:13px">-->
    <!--            {{ option }}-->
    <!--          </v-chip>-->
    <!--        </v-chip-group>-->
    <!--      </v-toolbar>-->
    <!--    </div>-->
    <div>
      <v-row v-for="(campaign, idx) in campaignsFiltered" :key="'list' + idx">
        <v-card
          flat
          class="mb-4"
          :to="'/campaign/detail/' + idx + '?tab=1'"
          v-if="idx >= (page - 1) * itemsPerPage && idx < itemsPerPage * page">
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="#F5F5F5">
                <v-icon color="#757575">mdi-file-chart</v-icon>
              </v-list-item-avatar>
              <v-list-item-content style="min-width: 292px">
                <v-list-item-title>
                  <youtubeIcon v-if="campaign.channel === 'youtube' || campaign['youtube']"></youtubeIcon>
                  <naverIcon v-else></naverIcon>
                  <span class="mb-2" style="font-size: 16px">{{ campaign.name }}</span>
                  <span class="ml-1" v-if="$vuetify.breakpoint.smAndDown"
                    ><span style="font-size: 13px; color: #4770bd">상세보기 ></span></span
                  >
                </v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848" v-if="$vuetify.breakpoint.mdAndUp">
                  {{ campaign.serviceName }}
                </v-list-item-subtitle>
                <div v-else>
                  <v-row class="mt-3">
                    <v-col>
                      <v-list-item-title style="font-size: 13px; color: #808080">집행예산</v-list-item-title>
                      <v-list-item-subtitle class="body-1" style="color: #484848"
                        >{{ campaign.campaignInfo.expense | numFormat }}원</v-list-item-subtitle
                      >
                    </v-col>
                    <v-col>
                      <v-list-item-title style="font-size: 13px; color: #808080">조회수/클릭수</v-list-item-title>
                      <v-list-item-subtitle class="body-1" style="color: #484848"
                        >{{ campaign.campaignInfo.viewCnt | numFormat }}회/{{
                          campaign.campaignInfo.clickCnt | numFormat
                        }}회</v-list-item-subtitle
                      >
                    </v-col>
                    <v-col>
                      <v-list-item-title style="font-size: 13px; color: #808080">상위노출 키워드 수</v-list-item-title>
                      <v-list-item-subtitle class="body-1" style="color: #484848"
                        >{{ campaign.campaignInfo.keywordCnt }}개</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <v-list-item-title style="font-size: 13px; color: #808080">CPV</v-list-item-title>
                      <v-list-item-subtitle class="body-1" style="color: #484848"
                        >{{ campaign.campaignInfo.cpv | numFormat }}원</v-list-item-subtitle
                      >
                    </v-col>
                    <v-col>
                      <v-list-item-title style="font-size: 13px; color: #808080">CPC</v-list-item-title>
                      <v-list-item-subtitle class="body-1" style="color: #484848"
                        >{{ campaign.campaignInfo.cpc | numFormat }}원</v-list-item-subtitle
                      >
                    </v-col>
                    <v-col>
                      <v-list-item-title style="font-size: 13px; color: #808080">CTR</v-list-item-title>
                      <v-list-item-subtitle
                        class="body-1"
                        v-text="campaign.campaignInfo.ctr + '%'"
                        style="color: #484848"></v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </div>
                <span
                  ><span v-if="$vuetify.breakpoint.mdAndUp" style="font-size: 13px; color: #4770bd"
                    >상세보기 ></span
                  ></span
                >
              </v-list-item-content>
              <v-list-item-content style="min-width: 110px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px; color: #484848">집행예산</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
                  >{{ campaign.campaignInfo.expense | numFormat }}원</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px; color: #484848">조회수/클릭수</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
                  >{{ campaign.campaignInfo.viewCnt | numFormat }}회/{{
                    campaign.campaignInfo.clickCnt | numFormat
                  }}회</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px; color: #484848">상위노출 키워드 수</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
                  >{{ campaign.campaignInfo.keywordCnt }}개</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px; color: #484848">CPV</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
                  >{{ campaign.campaignInfo.cpv | numFormat }}원</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px; color: #484848">CPC</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848"
                  >{{ campaign.campaignInfo.cpc | numFormat }}원</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-title style="font-size: 13px; color: #484848">CTR</v-list-item-title>
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848">{{
                  campaign.campaignInfo.ctr + '%'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-row>
      <div class="text-center mt-3" style="background-color: white">
        <v-pagination v-model="page" :length="(campaignsFiltered.length - 1) / itemsPerPage + 1"></v-pagination>
      </div>
    </div>
  </v-card>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import campaignMixin from '@/components/shared/campaignMixin';
import router from '@/router';

export default {
  name: 'CampaignList',
  mixins: [userMixin, campaignMixin],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver_c.svg'),
    youtubeIcon: () => import('@/assets/img/campaign/youtube_c.svg'),
  },
  data() {
    return {
      channels: [],
      page: 1,
      itemsPerPage: 10,
      headers: [
        { text: '캠페인 명', value: 'name' },
        { text: '확정 비용', value: 'price' },
        { text: '진행인원', value: 'count' },
        { text: '후기 등록 완료', value: 'done' },
        { text: '후기 등록 대기', value: 'wait' },
      ],
      display: [],
    };
  },
  computed: {
    campaignsFiltered() {
      if (this.$route.path === '/campaign/listyoutube') {
        return this.campaigns.filter(campaign => campaign.channel === 'youtube' || campaign['youtube']);
      } else {
        return this.campaigns.filter(campaign => (campaign.channel || 'naver') === 'naver' || campaign['naver']);
      }
    },
  },
  watch: {
    page() {
      this.setPage();
    },
  },
  methods: {
    setPage() {
      this.display = [];
      for (
        let i = (this.page - 1) * this.itemsPerPage;
        i < (this.page - 1) * this.itemsPerPage + this.itemsPerPage && this.campaigns[i];
        i++
      ) {
        this.display.push(this.campaigns[i]);
      }
    },
    handleRowClick(value) {
      router.push({ name: 'CampaignDetail', params: { index: value.index, campaign: this.campaigns[value.index] } });
    },
  },
  async created() {
    this.setPage();
  },
};
</script>
