<template>
  <div>
    <v-toolbar flat style="cursor: pointer" @click="returnToList">
      <v-icon>chevron_left</v-icon> <span class="ml-5" style="font-size: 16px">목록보기</span>
    </v-toolbar>
    <v-expansion-panels flat class="mt-4" :value="((form.name && form.campaignId > -1) || !propositionAuto) && 0">
      <v-expansion-panel style="background-color: white">
        <v-expansion-panel-header class="text-left">
          <div>
            <v-icon :color="form.name && form.campaignId > -1 ? '#15C072' : '#757575'">{{
              form.name && form.campaignId > -1 ? 'mdi-check' : 'mdi-dots-horizontal'
            }}</v-icon
            ><span class="ml-2" style="font-size: 16px; color: #484848">자동 제안 정보</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'" class="mt-3" style="font-size: 14px; color: #757575"
              >자동 제안명</v-col
            >
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '5' : '12'">
              <v-text-field
                v-model="form.name"
                name="name"
                type="text"
                v-validate="'required|min:2'"
                :rules="[errors.first('name') || !errors.has('name')]"
                single-line
                outlined
                solo
                flat
                required
                hide-details
                :disabled="disabled(propositionAuto)"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'" class="mt-3" style="font-size: 14px; color: #757575"
              >캠페인 선택</v-col
            >
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '5' : '12'">
              <v-select
                v-model="form.campaignId"
                :items="naverCampaigns"
                item-text="name"
                item-value="id"
                height="40px"
                hide-details
                outlined
                solo
                flat
                single-line
                :disabled="disabled(propositionAuto)">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-6">
              <v-btn
                width="120"
                class="rouge white-two--text"
                @click="saveName"
                :disabled="!form.name || form.campaignId < 0 || disabled(propositionAuto)"
                flat
                tile
                >저장</v-btn
              >
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels flat class="mt-4" :value="((form.budget > 0 && form.pointRate) || openFilterPanel) && 0">
      <v-expansion-panel style="background-color: white">
        <v-expansion-panel-header class="text-left">
          <div>
            <v-icon :color="form.budget > 0 && form.pointRate ? '#15C072' : '#757575'">{{
              form.budget > 0 && form.pointRate ? 'mdi-check' : 'mdi-dots-horizontal'
            }}</v-icon
            ><span class="ml-2" style="font-size: 16px; color: #484848">자동 제안 조건</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-6">
            <v-col cols="12" class="mt-3" style="font-size: 14px; color: #3a3a3a">금액 설정</v-col>
            <div class="line my-2 mb-4"></div>
            <v-col cols="12">
              <v-row>
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
                  class="mt-3"
                  style="font-size: 14px; color: #757575"
                  >집행 예산</v-col
                >
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '6'">
                  <v-text-field
                    v-model="form.budget"
                    name="budget"
                    type="text"
                    suffix="원"
                    v-validate="'required|min:2'"
                    :rules="[errors.first('budget') || !errors.has('budget')]"
                    single-line
                    outlined
                    solo
                    flat
                    required
                    hide-details
                    :disabled="disabled(propositionAuto)"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-4">
              <v-row>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'" style="font-size: 14px; color: #757575"
                  >예상 광고 단가 대비 <br v-if="$vuetify.breakpoint.mdAndUp" />제안 비용</v-col
                >
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '6'">
                  <v-select
                    v-model="form.pointRate"
                    :items="[
                      { text: '+100%', value: 200 },
                      { text: '+70%', value: 170 },
                      { text: '+50%', value: 150 },
                      { text: '+30%', value: 130 },
                      { text: '+20%', value: 120 },
                      { text: '+10%', value: 110 },
                      { text: '+0%', value: 100 },
                      { text: '-5%', value: 95 },
                      { text: '-10%', value: 90 },
                      { text: '-20%', value: 80 },
                      { text: '-30%', value: 70 },
                      { text: '-40%', value: 60 },
                      { text: '-50%', value: 50 },
                    ]"
                    item-text="text"
                    item-value="value"
                    height="40px"
                    hide-details
                    outlined
                    solo
                    flat
                    single-line
                    dense
                    :disabled="disabled(propositionAuto)"></v-select>
                </v-col>
                <v-btn
                  class="ml-4 px-3 rouge--text font-weight-bold"
                  text
                  :disabled="disabled(propositionAuto)"
                  @click="form.pointRate = $minProposeRate * 100"
                  >최소</v-btn
                >
                <v-btn
                  class="px-3 rouge--text font-weight-bold"
                  text
                  :disabled="disabled(propositionAuto)"
                  @click="form.pointRate = $maxProposeRate * 100"
                  >최대</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12" class="mt-3" style="font-size: 14px; color: #3a3a3a">인플루언서 선정 조건</v-col>
            <div class="line my-2 mb-4"></div>
            <v-col cols="12" class="mt-4">
              <v-row v-for="(filter, idx) in filters" :key="idx">
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
                  class="mt-3"
                  style="font-size: 14px; color: #757575"
                  >{{ filter.name }}</v-col
                >
                <v-col>
                  <v-chip
                    v-if="filter.name === '활동 지역'"
                    @click="showLocalFilterDialog = true"
                    class="ml-0"
                    :class="filter.sels.length > 0 ? 'white-two--text' : ''"
                    :color="filter.sels.length > 0 ? '#BD1328' : '#F5F5F5'"
                    :disabled="disabled(propositionAuto)">
                    {{ filters[2].sels.length > 0 ? filters[2].sels.length + '지역 선택됨' : filter.options[0].text }}
                  </v-chip>
                  <v-chip-group v-else v-model="filter.sels" column multiple active-class="active white--text">
                    <v-chip
                      v-for="(option, index) in filter.options"
                      :key="index"
                      color="#F5F5F5"
                      :disabled="disabled(propositionAuto)"
                      style="color: #484848; font-size: 13px; pointer-events: auto">
                      {{ option.text }}
                    </v-chip>
                    <v-col
                      :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
                      v-if="filter.name === '예상 광고 단가' && (filter.sels || []).indexOf(5) > -1">
                      <v-row>
                        <v-col cols="5">
                          <v-text-field
                            v-model="filter.values[0]"
                            name="adFeeMin"
                            type="text"
                            suffix="원"
                            v-validate="'required|min:2'"
                            :rules="[errors.first('adFeeMin') || !errors.has('adFeeMin')]"
                            single-line
                            outlined
                            solo
                            flat
                            required
                            hide-details
                            :disabled="disabled(propositionAuto)"></v-text-field>
                        </v-col>
                        <v-col cols="1" class="text-center mt-3"> ~ </v-col>
                        <v-col cols="5">
                          <v-text-field
                            v-model="filter.values[1]"
                            name="adFeeMax"
                            type="text"
                            suffix="원"
                            v-validate="'required|min:2'"
                            :rules="[errors.first('adFeeMax') || !errors.has('adFeeMax')]"
                            single-line
                            outlined
                            solo
                            flat
                            required
                            hide-details
                            :disabled="disabled(propositionAuto)"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-chip-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
                  class="mt-12"
                  style="font-size: 14px; color: #757575"
                  >리스트 제외 조건<br />(중복 제안 방지)</v-col
                >
                <v-col>
                  <v-radio-group v-model="excludeCampaign" :mandatory="true" :disabled="disabled(propositionAuto)">
                    <v-radio
                      :value="1"
                      :label="
                        (form.campaignId > -1 ? _.find(this.campaigns, { id: form.campaignId }).name : '') +
                        ' 캠페인 수락 리스트에서 중복 제외'
                      "></v-radio>
                    <v-radio :value="2" label="모든 캠페인 수락 리스트에서 중복 제외" class="mt-2"></v-radio>
                    <v-radio :value="0" label="없음(중복 제안 가능)" class="mt-2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-6">
            <v-btn
              width="120"
              class="rouge white-two--text"
              @click="saveFilter"
              :disabled="
                !form.name || form.campaignId < 0 || form.budget <= 0 || !form.pointRate || disabled(propositionAuto)
              "
              flat
              tile
              >저장</v-btn
            >
          </v-col>
          <v-col cols="12" class="ml-2" v-if="((propositionAuto || {}).status || 0) === 1" style="color: #c2c2c2">
            자동 제안 조건을 변경하시려면 먼저 제안을 일시 중지해주세요.<br />
            조건을 변경하면 기존의 인플루언서 리스트는 초기화되고, 새로운 인플루언서 리스트를 불러옵니다.
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels flat class="mt-4" :value="(propositions.length > 0 || openInfluencerPanel) && 0">
      <v-expansion-panel style="background-color: white">
        <v-expansion-panel-header class="text-left">
          <div>
            <v-icon :color="propositions.length > 0 ? '#15C072' : '#757575'">{{
              propositions.length > 0 ? 'mdi-check' : 'mdi-dots-horizontal'
            }}</v-icon
            ><span class="ml-2" style="font-size: 16px; color: #484848">인플루언서 리스트</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="((propositionAuto || {}).status || 0) >= 98 ? [] : _.take(propositions, 20)"
            item-key="taggableId"
            :items-per-page="20"
            disable-sort
            mobile-breakpoint="0"
            :hide-default-header="$vuetify.breakpoint.smAndDown"
            hide-default-footer
            v-sortable-data-table
            @sorted="saveOrder">
            <template v-slot:header.order="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.account="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.Influencer.visitor="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.expectedViewCnt="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.Influencer.adFee="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.Influencer.acceptAvg="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.point="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.reward="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="14" color="#4770BD" style="margin-left: 2px" v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                회원이 제안을 수락하면 구매 지원금이 회원에게 바로 전달됩니다. 구매 지원금은 세금(부가세 및 원천세)
                13.5%가 추가된 금액입니다.
              </v-tooltip>
            </template>
            <template v-slot:header.maxEditCount="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:header.remove="{ header }">
              <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
            </template>
            <template v-slot:top>
              <div class="body-2 my-4 mx-2" style="color: #808080">
                {{ propositions.length }}명 ・ {{ Math.floor((parseInt(propositions.length) - 1) / 20 + 1) }} Pages
              </div>
              <v-divider></v-divider>
            </template>
            <template v-slot:item.order>
              <v-icon class="handle">mdi-reorder-horizontal</v-icon>
            </template>
            <template v-slot:item.account="props">
              <v-row class="my-2 account" @click="influencerDetailDlg(props.item.Influencer)">
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '2'">
                  <v-avatar size="40">
                    <img v-if="props.item.Influencer.photo" :src="props.item.Influencer.photo" />
                    <img v-else src="@/assets/img/matching-placeholder.jpg" />
                  </v-avatar>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '9' : '4'">
                  <v-row class="caption" style="color: #4770bd" :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''">
                    {{ props.item.Influencer.account }}
                    <v-icon small color="#4770BD" class="ml-1 mb-1 hidden-sm-and-down"
                      >mdi-text-box-search-outline</v-icon
                    >
                  </v-row>
                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-chip
                      outlined
                      x-small
                      color="#34CD89"
                      class="ma-0 mr-1"
                      v-for="item in Object.keys(props.item.Influencer.categories)
                        .sort(function (a, b) {
                          return props.item.Influencer.categories[b] - props.item.Influencer.categories[a];
                        })
                        .slice(0, 2)"
                      :key="item">
                      {{ item + ' ' + Math.ceil(props.item.Influencer.categories[item]) + '%' }}
                    </v-chip>
                  </v-row>
                </v-col>
                <v-col class="hidden-md-and-up mt-2 text-right" cols="3" style="font-size: 12px; color: #757575">
                  {{ statusMap[props.item.status || 0].text }}
                </v-col>
              </v-row>
              <v-row class="hidden-md-and-up mt-2">
                <v-col>
                  <v-row style="color: #808080; font-size: 10px">
                    {{ { naver: '블로그 순위', youtube: '구독자', insta: '팔로워' }['naver'] }}
                  </v-row>
                  <v-row class="caption" style="color: #808080">
                    {{ parseInt(props.item.Influencer.rank) | numFormat
                    }}{{ { naver: '위', youtube: '', insta: '' }['naver'] }}
                  </v-row>
                </v-col>
                <v-col>
                  <v-row style="color: #808080; font-size: 10px"> 예상 광고 단가 </v-row>
                  <v-row class="caption" style="color: #808080">
                    ₩{{ parseInt(props.item.Influencer.adFee) | numFormat }}
                  </v-row>
                </v-col>
                <v-col>
                  <v-row style="color: #808080; font-size: 10px"> 제안 금액 </v-row>
                  <v-row class="caption" style="color: #808080"> ₩{{ props.item.point | numFormat }} </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.Influencer.visitor="{ item }">
              <v-row class="caption" style="color: #808080">
                {{ parseInt(item.Influencer.rank) | numFormat }}{{ { naver: '위', youtube: '', insta: '' }['naver'] }}
              </v-row>
              <v-row style="color: #808080; font-size: 10px">
                {{ { naver: '블로그 순위', youtube: '구독자', insta: '팔로워' }['naver'] }}
              </v-row>
            </template>
            <template v-slot:item.expectedViewCnt="{ item }">
              <v-row class="caption" style="color: #808080">
                {{ (parseInt(item.Influencer.adFee) / 50) | numFormat }}
              </v-row>
            </template>
            <template v-slot:item.Influencer.adFee="{ item }">
              <v-row class="caption" style="color: #808080"> ₩{{ parseInt(item.Influencer.adFee) | numFormat }} </v-row>
            </template>
            <template v-slot:item.Influencer.acceptAvg="{ item }">
              <v-row class="caption" style="color: #808080">
                ₩{{ parseInt(item.Influencer.acceptAvg) | numFormat }}
              </v-row>
            </template>
            <template v-slot:item.point="{ item }">
              <v-row class="caption" style="color: #4770bd"> ₩{{ item.point | numFormat }} </v-row>
            </template>
            <template v-slot:item.reward="{ item }">
              <v-row v-if="item.cprice && item.deliveryMethod === 'PR'" class="caption" style="color: #4770bd">
                ₩{{ (item.cprice + item.rewardFeeAmount) | numFormat }}
              </v-row>
              <v-row v-else class="caption" style="color: #4770bd"> - </v-row>
            </template>
            <template v-slot:item.maxEditCount="{ item }">
              <v-row class="caption" style="color: #808080"> {{ item.maxEditCount || 0 }}회 </v-row>
            </template>
            <template v-slot:item.status="{ item }">
              <div class="caption" style="color: #808080">
                {{ statusMap[item.status || 0].text }}
              </div>
            </template>
            <template v-slot:item.remove="{ item }">
              <v-icon @click="removeProposition(item)">mdi-trash-can</v-icon>
            </template>
          </v-data-table>
          <v-row class="my-6 ml-2" style="color: #4770bd">
            {{
              ((propositionAuto || {}).status || 0) < 98
                ? '거절되는 인원만큼 리스트가 다시 채워지며,'
                : '제안이 완료되었습니다.'
            }}<br />{{
              ((propositionAuto || {}).status || 0) < 98
                ? '설정된 예산이 소진될 때까지 제안이 진행됩니다.'
                : '아래의 “자동 제안 복사하기” 기능을 사용해 현재의 조건과 동일한 자동 제안을 추가할 수 있습니다.'
            }}
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row class="mt-4">
      <v-card flat class="pa-2" style="background-color: white; width: 100%; border-top: 2px solid #bd1328">
        <v-card-title>
          <v-icon :color="autoStatusMap[(propositionAuto || {}).status || 0].color">{{
            autoStatusMap[(propositionAuto || {}).status || 0].icon
          }}</v-icon
          ><span
            class="ml-2 font-weight-bold"
            :style="'font-size: 16px;color:' + autoStatusMap[(propositionAuto || {}).status || 0].color"
            >{{ autoStatusMap[(propositionAuto || {}).status || 0].text }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-6">
            <span
              style="font-size: 16px"
              :style="
                'color:' +
                (disabled(propositionAuto) ? autoStatusMap[(propositionAuto || {}).status || 0].color : '#484848')
              "
              >집행 예산</span
            >
            <span
              class="ml-2"
              :style="
                'color:' +
                (disabled(propositionAuto) ? autoStatusMap[(propositionAuto || {}).status || 0].color : '#4770BD')
              "
              >₩{{ form.budget | numFormat }}</span
            >
          </v-row>
          <v-row v-if="((propositionAuto || {}).status || 0) > 0">
            <span
              style="font-size: 16px"
              :style="
                'color:' +
                (disabled(propositionAuto) ? autoStatusMap[(propositionAuto || {}).status || 0].color : '#484848')
              "
              >소진 금액</span
            >
            <span
              class="ml-2"
              :style="
                'color:' +
                (disabled(propositionAuto) ? autoStatusMap[(propositionAuto || {}).status || 0].color : '#4770BD')
              "
              >₩{{
                _.sumBy(propositionAuto.propositions, proposition =>
                  proposition.status == 2 || proposition.status >= 10
                    ? proposition.point +
                      (proposition.rewardFeeAmount ? proposition.cprice + proposition.rewardFeeAmount : 0)
                    : 0
                ) | numFormat
              }}</span
            >
          </v-row>
          <v-row v-if="((propositionAuto || {}).status || 0) > 0 && ((propositionAuto || {}).status || 0) < 98">
            <span style="font-size: 16px; color: #484848">응답 대기중</span
            ><span class="ml-2" style="color: #4770bd"
              >₩{{
                (_.sumBy(_.filter(propositionAuto.propositions, { status: 1 }), 'point') +
                  (_.find(campaigns, { id: form.campaignId }).deliveryMethod === 'PR'
                    ? _.sumBy(_.filter(propositionAuto.propositions, { status: 1 }), 'cprice') +
                      _.sumBy(_.filter(propositionAuto.propositions, { status: 1 }), 'rewardFeeAmount')
                    : 0))
                  | numFormat
              }}</span
            >
          </v-row>
          <v-row class="mt-6">
            <span v-if="((propositionAuto || {}).status || 0) === 0" style="font-size: 16px; color: #484848"
              >위 내용으로 자동 제안을 시작하시겠습니까?</span
            >
            <span v-if="((propositionAuto || {}).status || 0) === 2" style="font-size: 16px; color: #484848"
              >제안이 일시 중지되었습니다. 아래 버튼을 눌러 제안을 계속할 수 있습니다.</span
            >
            <span
              v-if="((propositionAuto || {}).status || 0) == 98"
              :style="'font-size:16px;color:' + autoStatusMap[(propositionAuto || {}).status || 0].color"
              >설정한 집행 예산에 도달하여 제안이 종료되었습니다.</span
            >
            <span
              v-if="((propositionAuto || {}).status || 0) == 99"
              :style="'font-size:16px;color:' + autoStatusMap[(propositionAuto || {}).status || 0].color"
              >요청에 의해 제안이 종료되었습니다.</span
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="ml-2">
          <v-btn
            class="rouge white-two--text"
            flat
            tile
            v-if="((propositionAuto || {}).status || 0) === 0"
            :disabled="propositions.length === 0"
            @click="showProposeDialog = true"
            >자동 제안 시작</v-btn
          >
          <v-btn
            color="rouge"
            flat
            tile
            outlined
            v-if="((propositionAuto || {}).status || 0) === 1"
            @click="changeStatus(2)"
            >제안 일시 중지</v-btn
          >
          <v-btn color="rouge" text v-if="((propositionAuto || {}).status || 0) === 1" @click="changeStatus(99)"
            >제안 종료하기</v-btn
          >
          <v-btn
            class="rouge white-two--text"
            flat
            tile
            v-if="((propositionAuto || {}).status || 0) === 2"
            @click="changeStatus(1)"
            >제안 재개</v-btn
          >
          <v-btn color="rouge" text v-if="((propositionAuto || {}).status || 0) === 2" @click="changeStatus(99)"
            >제안 종료하기</v-btn
          >
          <v-btn
            color="rouge"
            flat
            tile
            outlined
            v-if="((propositionAuto || {}).status || 0) >= 98"
            @click="copyPropoistionAuto()"
            >자동 제안 복사하기</v-btn
          >
        </v-card-actions>
        <v-row style="line-height: 24px; font-weight: 400">
          <span class="ml-4 mb-6" v-if="((propositionAuto || {}).status || 0) === 0" style="color: #757575"
            >자동 제안 진행을 시작하면 회원에게 즉시 알림이 발송되므로, 예산과 캠페인이 제대로 설정되었는지
            확인해주세요.<br />제안을 받은 회원이 제안을 거절하거나 수락하지 않고 12시간이 지날 경우, 집행 예산 내에서
            다른 회원에게 제안이 발송됩니다.<br />자동 제안을 시작한 뒤 자동 제안을 일시 중지할 수 있습니다. 단, 이미
            제안이 발송되었거나 수락이 완료된 건에 대해서는 취소가 불가능합니다.<br />잔액이 부족해 설정된 조건에 맞는
            인플루언서에게 제안을 할 수 없을 경우 집행이 종료되며, 남은 예산은 잔액으로 환불됩니다.<br />구매 지원금을
            지급하는 캠페인의 경우, 제안 금액과 구매 지원금이 함께 결제됩니다.<br />결제된 제안 금액은 회원이 컨텐츠를
            등록하고 일정기간 후 회원에게 지급됩니다.<br />구매 지원금은 회원이 제안을 수락하면 영업일 기준 1~2일 이내에
            회원에게 즉시 지급됩니다.</span
          >
          <span
            class="ml-4 mb-6"
            v-if="((propositionAuto || {}).status || 0) === 1"
            style="color: #757575; line-height: 24px"
            >현재 자동 제안이 진행중이며, 일시 중지 또는 종료가 가능합니다.<br />자동 제안을 일시 중지하거나
            종료하더라도 이미 제안이 발송되었거나 수락이 완료된 건에 대해서는 취소가 불가능합니다.</span
          >
        </v-row>
      </v-card>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showProposeDialog" content-class="white-two" max-width="500px">
        <v-card class="pa-4">
          <v-card-text class="text-center">
            <div>캠페인 자동 제안</div>
            <div class="my-2 subheading" style="color: #4770bd">
              {{ form.campaignId > -1 ? _.find(this.campaigns, { id: form.campaignId }).name : '' }}
            </div>
            <div class="mt-4 mb-2 subheading">
              집행 예산 <span style="color: #4770bd">₩{{ form.budget }}</span>
            </div>
            <div class="mt-8 mb-4">위 내용으로 제안을 진행하시겠습니까?</div>
            <div class="mb-4" style="color: #4770bd">
              제안 후 취소는 불가능합니다.<br />
              혜택 제공 방식이 구매 지원금 지급인 경우,<br />
              제안시 구매 지원금이 함께 차감됩니다.<br />
              제안을 수락한 회원에 대해서만 포인트가 차감됩니다.<br />
              충전 금액이 부족할 경우 집행이 종료됩니다.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="rouge" tile outlined flat @click="showProposeDialog = false">취소</v-btn>
            <v-btn
              class="rouge white-two--text"
              tile
              flat
              @click="
                () => {
                  this.showProposeDialog = false;
                  this.autoPropose();
                }
              "
              >자동 제안 시작</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail" :openInfluencer="openInfluencer"></InfluencerDetail>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showLocalFilterDialog" content-class="white-two" max-width="500px">
        <v-card class="pa-4">
          <v-card-title class="pa-2">
            <div>
              <v-icon color="default" @click="showLocalFilterDialog = false">close</v-icon>
              <br />
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="font-weight-bold ml-6"> 활동 지역 선택 </v-row>
            <v-row class="ml-6">
              <v-chip @click="filters[2].sels = []"> 지역 전체 </v-chip>
            </v-row>
            <v-expansion-panels flat>
              <v-expansion-panel v-for="(subRegion, region) in locals" :key="region" style="background-color: white">
                <v-expansion-panel-header>
                  {{ region
                  }}{{
                    '(' +
                    (_.intersection(_.concat(_.keys(locals[region]), [region]), filters[2].sels).length || '') +
                    ')'
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip
                    @click="setLocalSels(region, null)"
                    :class="filters[2].sels.indexOf(region) > -1 ? 'white-two--text' : ''"
                    :color="filters[2].sels.indexOf(region) > -1 ? 'rouge' : '#F5F5F5'">
                    전체
                  </v-chip>
                  <v-chip
                    v-for="(value, key) in subRegion"
                    :key="key"
                    @click="setLocalSels(region, key)"
                    :class="filters[2].sels.indexOf(key) > -1 ? 'white-two--text' : ''"
                    :color="filters[2].sels.indexOf(key) > -1 ? 'rouge' : '#F5F5F5'"
                    style="color: #484848; font-size: 13px">
                    {{ value }}
                  </v-chip>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showInputInfoDialog" content-class="white-two" max-width="272px">
        <v-card class="pa-4">
          <v-card-subtitle style="font-size: 13px; padding: 0; color: #484848"> 캠페인 정보 등록 안내 </v-card-subtitle>
          <v-card-text class="font-size:14px;color:#484848;">
            <v-row class="mt-2">
              선택하신 캠페인의 일부 정보가 입력되지 않았습니다. 아래 버튼을 눌러 정보 입력 페이지로 이동하실 수
              있습니다.
            </v-row>
          </v-card-text>
          <v-card-actions style="padding: 16px 0 0 0">
            <v-spacer></v-spacer>
            <v-btn color="rouge" tile outlined flat @click="showInputInfoDialog = false" width="80">취소</v-btn>
            <v-btn
              tile
              class="rouge white-two--text"
              width="80"
              :to="'/campaign/detail/' + _.findIndex(campaigns, { id: form.campaignId }) + '?tab=2'"
              >정보입력</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import pMap from 'p-map';

import userMixin from '@/components/shared/userMixin';
import campaignMixin from '@/components/shared/campaignMixin';
import Vue from 'vue';
import store from '../../../store';
import InfluencerDetail from '@/components/campaign/dialog/InfluencerDetail';
import { EventBus } from '@/components/shared/event-bus';

let age20 = Vue.moment().subtract(30, 'years').year();
let age30 = Vue.moment().subtract(40, 'years').year();
let age40 = Vue.moment().subtract(50, 'years').year();

export default {
  name: 'Autoproposedetail',
  mixins: [userMixin, campaignMixin],
  props: ['propositionAuto'],
  components: {
    InfluencerDetail,
  },
  data() {
    return {
      form: {
        id: -1,
        campaignId: -1,
        name: '',
        budget: 0,
        pointRate: 100,
        ids: null,
      },
      filters: [
        {
          name: '카테고리 (최대 3개)',
          options: [
            { text: '전체', query: '' },
            { text: '건기식', query: 'categories[]=건기식' },
            { text: '게임', query: 'categories[]=게임' },
            { text: '교육', query: 'categories[]=교육' },
            { text: '금융', query: 'categories[]=금융' },
            { text: '다이어트', query: 'categories[]=다이어트' },
            { text: '도서/팬시', query: 'categories[]=도서/팬시' },
            { text: '레저', query: 'categories[]=레저' },
            { text: '반려동물', query: 'categories[]=반려동물' },
            { text: '부동산', query: 'categories[]=부동산' },
            { text: '생활/가구', query: 'categories[]=생활/가구' },
            { text: '스포츠', query: 'categories[]=스포츠' },
            { text: '식품', query: 'categories[]=식품' },
            { text: '엔터테인먼트', query: 'categories[]=엔터테인먼트' },
            { text: '여행', query: 'categories[]=여행' },
            { text: '웨딩', query: 'categories[]=웨딩' },
            { text: '음식점', query: 'categories[]=음식점' },
            { text: '일상', query: 'categories[]=일상' },
            { text: '정보', query: 'categories[]=정보' },
            { text: '주식', query: 'categories[]=주식' },
            { text: '출산/육아', query: 'categories[]=출산/육아' },
            { text: '패션/잡화', query: 'categories[]=패션/잡화' },
            { text: '헤어/네일', query: 'categories[]=헤어/네일' },
            { text: '화장품', query: 'categories[]=화장품' },
            { text: 'IT/전자기기', query: 'categories[]=IT/전자기기' },
          ],
          sels: [0],
          max: 3,
          field: ['category'],
        },
        {
          name: '예상 광고 단가',
          options: [
            { text: '전체', sql: '' },
            { text: '5만원 미만', sql: 'adFee < 50000' },
            { text: '5-10만원', sql: 'adFee >= 50000 AND adFee < 100000' },
            { text: '10-20만원', sql: 'adFee >= 100000 AND adFee < 200000' },
            { text: '20만원 이상', sql: 'adFee >= 200000' },
            { text: '직접 선택', sql: '' },
          ],
          sels: [1],
          max: 1,
          field: ['adFeeMin', 'adFeeMax'],
          values: [],
        },
        { name: '활동 지역', options: [{ text: '선택하기', sql: '' }], sels: [], max: 100, field: ['local'] },
        {
          name: '성별',
          options: [
            { text: '전체', query: '' },
            { text: '남성', query: 'gender=남자' },
            { text: '여성', query: 'gender=여자' },
          ],
          sels: [0],
          max: 1,
          field: ['gender'],
        },
        {
          name: '연령',
          options: [
            { text: '전체', sql: '' },
            { text: '20대', sql: 'birth > ' + age20 },
            { text: '30대', sql: 'birth <= ' + age20 + ' AND birth > ' + age30 },
            { text: '40대', sql: 'birth <= ' + age30 + ' AND birth > ' + age40 },
            { text: '50대 이상', sql: 'birth <= ' + age40 },
          ],
          sels: [0],
          max: 1,
          field: ['age'],
        },
      ],
      showLocalFilterDialog: false,
      locals: {},
      propositions: [],
      headers_desktop: [
        { text: '순서변경', value: 'order' },
        { text: '채널정보 정보', value: 'account', width: '280px' },
        { text: '인플루언서 지표', value: 'Influencer.visitor' },
        { text: '예상 광고 단가', value: 'Influencer.adFee' },
        { text: '평균 수락 금액', value: 'Influencer.acceptAvg' },
        { text: '제안 금액', value: 'point', width: '110px' },
        { text: '구매 지원금', value: 'reward', width: '110px' },
        { text: '수정 가능 횟수', value: 'maxEditCount' },
        { text: '제안 상태', value: 'status' },
        { text: '제거', value: 'remove' },
      ],
      headers_mobile: [
        { text: '순서변경', value: 'order', width: '20px' },
        { text: '인플루언서 정보', value: 'account' },
      ],
      openInfluencer: {},
      openInfluencerDetail: false,
      statusMap: {
        0: { text: '제안 예정' },
        1: { text: '제안' },
        2: { text: '수락' },
        3: { text: '거절' },
        4: { text: '무응답' },
        10: { text: '발송대기' },
        12: { text: '미방문' },
        20: { text: '이용완료' },
        22: { text: '원고수정요청' },
        24: { text: '원고확인중' },
        30: { text: '리뷰대기중' },
        32: { text: '리뷰완료' },
        50: { text: '이용취소' },
      },
      removedIds: [],
      openFilterPanel: undefined,
      openInfluencerPanel: undefined,
      showProposeDialog: false,
      showInputInfoDialog: false,
      autoStatusMap: {
        0: { text: '자동 제안 시작하기', icon: 'mdi-dots-horizontal', color: '#4770bd' },
        1: { text: '자동 제안이 진행중입니다.', icon: 'mdi-cog-clockwise', color: '#34cd89' },
        2: { text: '제안이 일시 중지되었습니다.', icon: 'mdi-pause-circle-outline', color: '#484848' },
        98: { text: '제안이 종료되었습니다.', icon: 'mdi-stop', color: '#c2c2c2' },
        99: { text: '제안이 종료되었습니다.', icon: 'mdi-stop', color: '#c2c2c2' },
      },
      excludeCampaign: 1,
      excludeAuto: 3,
      adFeeOptionMap: {
        0: { min: null, max: null },
        1: { min: 0, max: 50000 },
        2: { min: 50000, max: 100000 },
        3: { min: 100000, max: 200000 },
        4: { min: 200000, max: null },
      },
    };
  },
  computed: {
    headers() {
      return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
    },
  },
  watch: {
    filters: {
      handler(value) {
        value.forEach(filter => {
          let index = filter.sels.indexOf(0);
          if (filter.sels.length > 1 && index > -1) {
            if (index === 0) filter.sels.splice(index, 1);
            else filter.sels.shift();
          }
          if (filter.sels.length > 1 && filter.max === 1) {
            filter.sels.shift();
          } else if (filter.sels.length > filter.max) {
            filter.sels.pop();
          }
        });
        // this.getDataFromApi();
      },
      deep: true,
    },
    propositionAuto(value) {
      this.initValues(value);
    },
  },
  methods: {
    returnToList() {
      this.$emit('detail:open', false);
    },
    async saveName() {
      if (this.form.name && this.form.campaignId > -1) {
        if (this.form.id > -1) {
          await this.axios.put('/campaign/propositionAuto', {
            id: this.form.id,
            name: this.form.name,
            campaignId: this.form.campaignId,
          });
        } else {
          let result = await this.axios.post('/campaign/propositionAuto', {
            name: this.form.name,
            campaignId: this.form.campaignId,
          });
          this.form.id = result.data.id;
        }
        this.openFilterPanel = 0;
        this.$emit('update:list', this.form.id);
      } else {
        store.commit('setMsg', '입력 값을 확인해주세요.');
        store.commit('setShowSnackbar', true);
      }
    },
    checkProposition(campaignId, influencer) {
      if (campaignId) {
        return this._.find((this.propositionAuto || {}).propositions || [], proposition => {
          return (
            proposition.campaignId === campaignId &&
            proposition.taggableId === influencer.id &&
            proposition.taggableType === 'naver' &&
            proposition.status < 2
          );
        });
      } else {
        return (this.campaigns || []).some(campaign => {
          return this._.find((this.propositionAuto || {}).propositions || [], proposition => {
            return (
              proposition.campaignId === campaign.id &&
              proposition.taggableId === influencer.id &&
              proposition.taggableType === 'naver' &&
              proposition.status < 2
            );
          });
        });
      }
    },
    updateAllToProposition(influencers) {
      let campaign = this._.find(this.campaigns, { id: this.form.campaignId });
      this.propositions = [];
      this.propositions = influencers.map(influencer => {
        let oldProposition = this.checkProposition(this.form.campaignId, influencer);
        if (oldProposition) {
          let proposition = oldProposition;
          proposition.influencer = influencer;
          return proposition;
        } else {
          let point = (influencer.adFee * this.form.pointRate) / 100;
          let maxEditCount = this.$getMaxEditCount(point);
          let proposition = {
            campaignId: this.form.campaignId,
            taggableId: influencer.id,
            taggableType: 'naver',
            uid: influencer.uid,
            point: point,
            cname: campaign.serviceName,
            creward: campaign[influencer.channel].reward,
            cprice: campaign[influencer.channel].rewardPrice,
            category: campaign.category,
            status: 0,
            maxEditCount: maxEditCount,
            thumbnail: campaign.thumbnail,
            periodUse: this.$periodMap[influencer.channel][campaign.type][campaign.category.firstName][0],
            periodFirstCopy: this.$periodMap[influencer.channel][campaign.type][campaign.category.firstName][1],
            propositionAutoId: this.form.id,
            deliveryMethod: campaign.deliveryMethod,
          };
          proposition.Influencer = influencer;
          if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
            proposition.rewardFees = 13.5;
            proposition.rewardFeeAmount =
              campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
          }
          return proposition;
          // return await this.axios.post('/campaign/proposition', proposition);
        }
      }); //.filter((p) => p.status < 1)
    },
    async getPropositions() {
      let propositions = await this.axios.get(
        '/campaign/proposition?campaignId=' + this.form.campaignId + '&propositionAutoId=' + this.form.id
      );
      this.propositions = propositions.data.propositions;
    },
    async removeProposition(item) {
      let index = this._.findIndex(this.propositions, { taggableId: item.taggableId });
      let removed = this.propositions.splice(index, 1);
      let ids = this.propositions.map(proposition => proposition.taggableId);
      this.removedIds = (this.propositionAuto || {}).removedIds || this.removedIds || [];
      if (removed.length > 0) {
        this.removedIds.push(removed[0].taggableId);
      }
      await this.axios.put('/campaign/propositionAuto?id=' + this.form.id, { ids: ids, removedIds: this.removedIds });
      // await this.axios.delete('/campaign/proposition?id=' + item.id);
      // await Promise.all(this.propositions.map(async (proposition, idx) => {
      //   await this.axios.put('/campaign/proposition?id=' + proposition.id, {orderAuto: idx});
      // }));
    },
    async getDataFromApi() {
      let pageQuery = '&page=' + 1 + '&limit=' + 300;
      let query = '';
      let sql = 'query=';
      this.filters.forEach(filter => {
        if (filter.sels.length > 0 && filter.field[0] !== 'local') {
          filter.sels.forEach(sel => {
            if (filter.options[sel].query) {
              query += '&' + filter.options[sel].query;
            }
          });
        }
        if (filter.sels.length > 0) {
          filter.sels.forEach(sel => {
            if (filter.field.length > 1 && sel === 5) {
              filter.options[sel].sql = '';
              if (filter.values[0]) {
                filter.options[sel].sql += 'adFee >= ' + filter.values[0];
              }
              if (filter.values[0] && filter.values[1]) {
                filter.options[sel].sql += ' AND ';
              }
              if (filter.values[1]) {
                filter.options[sel].sql += 'adFee < ' + filter.values[1];
              }
            }
            if (filter.field[0] === 'local') {
              if (this.locals[sel]) {
                Object.keys(this.locals[sel]).map(key => {
                  query += '&local[]=' + key;
                });
              } else if (this._.isString(sel)) {
                query += '&local[]=' + sel;
              }
            } else {
              if (filter.options[sel].sql) {
                sql += (sql.length > 7 ? ' AND ' : '') + encodeURIComponent(filter.options[sel].sql);
              }
            }
          });
        }
      });

      if (this.excludeAuto & 1) {
        if (((this.propositionAuto || {}).removedIds || this.removedIds || []).length > 0) {
          ((this.propositionAuto || {}).removedIds || this.removedIds).forEach(id => {
            sql += (sql.length > 7 ? ' AND ' : '') + 'id <> ' + id;
          });
        }
      }
      if (this.excludeAuto & 2) {
        if ((this.propositionAuto || {}).id) {
          query += '&propositionAutoId=' + this.propositionAuto.id;
        }
      }

      if (this.excludeCampaign & 1) {
        query += '&campaignId[]=' + this.form.campaignId;
      } else if (this.excludeCampaign & 2) {
        this.campaigns.forEach(campaign => {
          query += '&campaignId[]=' + campaign.id;
        });
      }

      let influencers = await this.axios.get('/influencer?' + pageQuery + query + (sql.length > 7 ? '&' + sql : ''));
      influencers = influencers.data.influencers;
      await this.updateAllToProposition(influencers || []);
    },
    async saveFilter() {
      let totalPoint = this._.sumBy(
        this.propositionAuto.propositions.filter(p => p.status === 1 || p.status === 2 || p.status >= 10),
        'point'
      );
      console.log(this.form.budget, totalPoint);
      if (this.form.budget < totalPoint) {
        store.commit('setMsg', '이미 제안된 금액보다 집행 예산이 작습니다.');
        store.commit('setShowSnackbar', true);
      } else if (this.form.name && this.form.campaignId > -1) {
        let data = {};
        this.filters.map(filter => {
          if (filter.values) {
            console.log(filter.values, filter.sels);
            if (filter.sels[0] === 0) {
              data[filter.field[0]] = null;
              data[filter.field[1]] = null;
            } else if (filter.sels[0] === 5) {
              data[filter.field[0]] = filter.values[0];
              data[filter.field[1]] = filter.values[1];
            } else {
              data[filter.field[0]] = this.adFeeOptionMap[filter.sels[0]].min;
              data[filter.field[1]] = this.adFeeOptionMap[filter.sels[0]].max;
            }
          } else {
            if (filter.field[0] === 'category') {
              if (filter.sels.length !== 1 || filter.sels[0] !== 0) {
                data[filter.field[0]] = filter.sels.map(sel => {
                  return filter.options[sel].text;
                });
              }
            } else if (filter.field[0] === 'gender') {
              if (filter.sels.length !== 1 || filter.sels[0] !== 0) {
                data[filter.field[0]] = filter.options[filter.sels[0]].text;
              }
            } else if (filter.field[0] === 'age') {
              if (filter.sels.length !== 1 || filter.sels[0] !== 0) {
                data[filter.field[0]] = filter.sels[0] * 10 + 10;
              }
            } else {
              if (filter.field[0] !== 'local' || filter.sels.length > 0) {
                data[filter.field[0]] = filter.sels;
              } else {
                data[filter.field[0]] = null;
              }
            }
          }
        });

        data.exclude = this.excludeCampaign | (this.excludeAuto << 2);

        await this.getDataFromApi();
        let ids = this.propositions.map(proposition => proposition.taggableId);
        data.ids = ids;
        if (this.form.id > -1) {
          await this.axios.put(
            '/campaign/propositionAuto',
            Object.assign(this._.omit(this.form, ['name', 'campaignId']), data)
          );
        } else {
          let result = await this.axios.post('/campaign/propositionAuto', data);
          this.form.id = result.data.id;
        }
        this.openInfluencerPanel = 0;
        // await Promise.all(this.propositions.map(async (proposition) => {
        //   await this.axios.delete('/campaign/proposition?id=' + proposition.id);
        // }));
      } else {
        store.commit('setMsg', '자동 제안명 또는 선택된 캠페인이 없습니다.');
        store.commit('setShowSnackbar', true);
      }
    },
    setLocalSels(region, key) {
      if (key) {
        if (this.filters[2].sels.indexOf(region) > -1) {
          this.filters[2].sels = this.filters[2].sels.filter(sel => sel !== region);
        }
        if (this.filters[2].sels.indexOf(key) > -1) {
          this.filters[2].sels = this.filters[2].sels.filter(sel => sel !== key);
        } else {
          this.filters[2].sels.push(key);
        }
      } else {
        if (this.locals[region]) {
          this.filters[2].sels = this._.difference(this.filters[2].sels, Object.keys(this.locals[region]));
          this.filters[2].sels.push(region);
        }
      }
    },
    async initValues(value) {
      this.form = this._.pick(value, [
        'id',
        'campaignId',
        'name',
        'budget',
        'pointRate',
        'adFeeMin',
        'adFeeMax',
        'ids',
      ]);
      this.filters.map(filter => {
        if (filter.field.length > 1) {
          if (this.propositionAuto.adFeeMin && this.propositionAuto.adFeeMax) {
            let idx = this._.findIndex(this._.values(this.adFeeOptionMap), {
              min: this.propositionAuto.adFeeMin,
              max: this.propositionAuto.adFeeMax,
            });
            if (idx > -1) {
              filter.values[0] = this.propositionAuto.adFeeMin;
              filter.values[1] = this.propositionAuto.adFeeMax;
              filter.sels = [idx];
            } else {
              filter.values[0] = this.propositionAuto.adFeeMin;
              filter.values[1] = this.propositionAuto.adFeeMax;
              filter.sels = [5];
            }
          } else {
            filter.values[0] = null;
            filter.values[1] = null;
            filter.sels = [0];
          }
        } else {
          if (Array.isArray(this.propositionAuto[filter.field[0]])) {
            if (filter.field[0] === 'local') {
              filter.sels = this.propositionAuto[filter.field[0]];
            } else {
              filter.sels = this.propositionAuto[filter.field[0]].map(value => {
                return this._.findIndex(filter.options, { text: value });
              });
            }
          } else {
            if (this.propositionAuto[filter.field[0]]) {
              if (filter.field[0] === 'age') {
                filter.sels = [(this.propositionAuto[filter.field[0]] - 10) / 10];
              } else {
                filter.sels = [this._.findIndex(filter.options, { text: this.propositionAuto[filter.field[0]] })];
              }
            } else if (filter.field[0] !== 'local') {
              filter.sels = [0];
            }
          }
        }
      });

      if (this.form.ids && this.form.ids.length > 0) {
        let query = '';
        this.form.ids.map((id, idx) => {
          query += 'id=' + id;
          if (idx !== this.form.ids.length - 1) {
            query += ' OR ';
          }
        });
        let influencers = await this.axios.get('/influencer?query=' + encodeURIComponent(query));
        influencers = influencers.data.influencers;
        influencers = this._.sortBy(influencers, [influencer => this.form.ids.indexOf(influencer.id)]);
        await this.updateAllToProposition(influencers || []);
      }

      if (!this._.isNil(value.exclude)) {
        this.excludeCampaign = value.exclude & 3;
        this.excludeAuto = (value.exclude & 12) >> 2;
      }

      // if (this.form.id > -1) {
      //   await this.getPropositions();
      // }
    },
    async influencerDetailDlg(item, event) {
      console.log(event);
      this.openInfluencerDetail = true;
      this.openInfluencer = item;
    },
    async saveOrder(event) {
      const movedItem = this.propositions.splice(event.oldIndex, 1)[0];
      this.propositions.splice(event.newIndex, 0, movedItem);
      let ids = this.propositions.map(proposition => proposition.taggableId);
      await this.axios.put('/campaign/propositionAuto?id=' + this.form.id, { ids: ids });
      // await this.axios.put('/campaign/proposition?id=' + this.propositions[event.oldIndex].id, {orderAuto: event.oldIndex});
      // await this.axios.put('/campaign/proposition?id=' + this.propositions[event.newIndex].id, {orderAuto: event.newIndex});
    },
    async autoPropose() {
      let campaign = this._.find(this.campaigns, { id: this.form.campaignId });
      let campaignCheck = this.$requiredMap[campaign.category.firstName][campaign.type].every(field => {
        return campaign[field];
      });
      let channelCheck = this.$requiredMap.naver[campaign.type].every(field => {
        if (field === 'mission' || field === 'keywords') {
          return (campaign['naver'] || {})[field] && (campaign['naver'] || {})[field].length > 0;
        } else if (field === 'reward' || field === 'rewardPrice') {
          return (
            campaign.deliveryMethod !== 'PR' || (campaign.deliveryMethod === 'PR' && (campaign['naver'] || {})[field])
          );
        }
        return (campaign['naver'] || {})[field];
      });
      if (campaignCheck && channelCheck) {
        let pointCheck = await this.axios.get('/company/user/propose?companyId=' + this.user.id);
        if (pointCheck.data.balance >= this.form.budget) {
          let total = 0,
            count = 0;
          let propositions = this._.cloneDeep(this.propositions);
          await pMap(
            propositions,
            async proposition => {
              total += proposition.point;
              if (this.form.budget >= total) {
                this.propositions.shift();
                let fees = proposition.fees || 40;
                let settlementAmount = Math.floor((proposition.point * (1 - fees / 100)) / 1000) * 1000;
                proposition.status = 0;
                proposition.proposedAt = new Date();
                proposition.cname = campaign.serviceName;
                proposition.creward = campaign[proposition.taggableType].reward;
                proposition.cprice = campaign[proposition.taggableType].rewardPrice;
                proposition.category = campaign.category;
                proposition.thumbnail = campaign.thumbnail;
                proposition.periodUse =
                  this.$periodMap[proposition.taggableType][campaign.type][campaign.category.firstName][0];
                proposition.periodFirstCopy =
                  this.$periodMap[proposition.taggableType][campaign.type][campaign.category.firstName][1];
                proposition.fees = fees;
                proposition.feeAmount = proposition.point - settlementAmount;
                proposition.settlementAmount = settlementAmount;
                proposition.propositionAutoId = this.form.id;
                proposition.orderAuto = ((this.propositionAuto || {}).curIdx || 0) + count;
                if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
                  proposition.rewardFees = 13.5;
                  proposition.rewardFeeAmount =
                    campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
                  total += proposition.cprice + proposition.rewardFeeAmount;
                }

                let query = '';
                if (this.excludeCampaign === 1) {
                  query = 'campaignId=' + proposition.campaignId + ' AND (status=0 OR status=1 OR status>=10)';
                } else if (this.excludeCampaign === 2) {
                  query = this.campaigns
                    .map(campaign => {
                      return 'campaignId=' + campaign.id;
                    })
                    .join(' OR ');
                  query = '(' + query + ') AND (status=0 OR status=1 OR status>=10)';
                } else {
                  query = 'campaignId=' + proposition.campaignId + ' AND status=1';
                }
                let exist = await this.axios.get(
                  '/campaign/proposition?query=' +
                    query +
                    '&taggableId=' +
                    proposition.taggableId +
                    '&taggableType=' +
                    proposition.taggableType
                );
                if (exist.data.total === 0) {
                  proposition = await this.axios.post('/campaign/proposition', proposition);
                  proposition = proposition.data;
                  proposition.status = 1;
                  if (this._.isEmpty(proposition)) {
                    total -= proposition.point;
                    await this.axios.put('/campaign/propositionAuto?id=' + this.form.id, {
                      status: this.propositions.map(proposition => proposition.taggableId).length > 0 ? 1 : 98,
                      curIdx: ((this.propositionAuto || {}).curIdx || 0) + count,
                      ids: this.propositions.map(proposition => proposition.taggableId),
                    });
                  } else {
                    await this.axios.post('/company/user/propose?id=' + proposition.id, proposition);
                    await this.axios.put('/campaign/propositionAuto?id=' + this.form.id, {
                      status: this.propositions.map(proposition => proposition.taggableId).length > 0 ? 1 : 98,
                      curIdx: ((this.propositionAuto || {}).curIdx || 0) + count++,
                      ids: this.propositions.map(proposition => proposition.taggableId),
                    });
                  }
                } else {
                  await this.axios.put('/campaign/propositionAuto?id=' + this.form.id, {
                    ids: this.propositions.map(proposition => proposition.taggableId),
                  });
                }
              } else {
                return;
              }
            },
            { concurrency: 1 }
          );
          if (this.user) {
            if (count > 0) {
              // await this.axios.post('utils/sendsms', {
              //   phoneNumber: this.user.contacts.replace(/\D/g, ''),
              //   contents: "[슈퍼차트] 광고 자동제안 시작\n" +
              //       campaign.name + ' 광고 제안이 완료되었습니다.\n' +
              //       ((campaign.category.firstName === '매장' && campaign.type === 'T') ? '진행여부 확정 후 슈퍼차트 회원으로 예약문의가 시작될 예정입니다.' : '12시간 이내 진행여부가 확정됩니다.')
              // });
              await this.axios.post('utils/sendalimtalk', {
                templateId: '50070',
                phoneNumber: this.user.contacts.replace(/\D/g, ''),
                contents:
                  '[슈퍼차트] 자동제안 시작\n\n' +
                  campaign.name +
                  ' 광고 제안이 완료되었습니다.\n' +
                  '12시간 이내 진행여부가 확정됩니다.',
                variables: JSON.stringify({
                  '#{자동제안명}': campaign.name,
                }),
              });
              await this.axios.post('utils/sendslack', {
                channel: 'superchart',
                contents: '[슈퍼차트] 광고 자동 제안 시작/' + this.form.campaignId + '/' + campaign.name + '/LMS',
              });

              this.$store.commit('setMsg', count + '명에게 자동 제안되었습니다.');
              this.$store.commit('setShowSnackbar', true);
              this.returnToList();
              EventBus.$emit('update:result');
              EventBus.$emit('update:point');
            } else {
              this.$store.commit('setMsg', '집행 예산이 작아 자동 제안을 시작할 수 없습니다.');
              this.$store.commit('setShowSnackbar', true);
            }
          }
        } else {
          this.$store.commit('setMsg', '잔액이 부족합니다.');
          this.$store.commit('setShowSnackbar', true);
        }
      } else {
        this.showInputInfoDialog = true;
      }
    },
    async changeStatus(status) {
      await this.axios.put('/campaign/propositionAuto?id=' + this.form.id, { status: status });
      this.$emit('update:list', null);
    },
    async copyPropoistionAuto() {
      let propositionAuto = this._.omit(this.propositionAuto, ['id', 'status', 'ids', 'removedIds', 'curIdx']);
      propositionAuto.name += ' - 복사본';
      await this.axios.post('/campaign/propositionAuto', propositionAuto);
      this.$emit('update:list', null);
      this.returnToList();
    },
    disabled(propositionAuto) {
      return ((propositionAuto || {}).status || 0) >= 98 || ((propositionAuto || {}).status || 0) === 1;
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
          handle: '.handle',
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },
  async mounted() {
    let option = await this.axios.get('/company/option?type=local');
    this.locals = option.data;
    if (this.propositionAuto) {
      await this.initValues(this.propositionAuto);
    }
  },
};
</script>

<style scoped lang="scss">
.line {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

.active {
  background-color: #bd1328 !important;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}

.account {
  cursor: pointer !important;
}
</style>
