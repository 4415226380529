<template>
  <div>
    <v-row class="tiktok-detail__section font-weight-bold" style="font-size: 18px"> 개요 </v-row>
    <v-row class="pb-8 px-4">
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">
          <v-icon class="mr-1" small>mdi-account-supervisor-circle</v-icon>팔로워 수
        </div>
        <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
          {{ statisticsData?.outline?.followerCount }}명
        </div>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title"><v-icon class="mr-1" small>mdi-play-circle-outline</v-icon>광고영상 비중</div>
        <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
          {{ statisticsData?.outline?.adVideoRatio }}%
        </div>
        <!-- <div
                  v-else
                  class="tiktok-detail__subtitle font-weight-bold"
                  style="color: #484848"
                >
                  집계 중
                </div> -->
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">
          <v-icon class="mr-1" small>mdi-play-circle-outline</v-icon>3개월 평균 조회수
        </div>
        <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
          {{ statisticsData?.outline?.avgViewsPer3Month }}회
        </div>
        <!-- <div
                  v-else
                  class="tiktok-detail__subtitle font-weight-bold"
                  style="color: #484848"
                >
                  집계 중
                </div> -->
      </v-col>
    </v-row>
    <v-row class="pb-8 px-4">
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">
          <v-icon class="mr-1" small>mdi-play-circle-outline</v-icon>1개월 평균 조회수
        </div>
        <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
          {{ statisticsData?.outline?.avgViewsPerMonth }}회
        </div>
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">
          <v-icon class="mr-1" small>mdi-play-circle-outline</v-icon>1개월 평균 댓글 수
        </div>
        <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
          {{ statisticsData?.outline?.avgCommentsPerMonth }}개
        </div>
        <!-- <div
                  v-else
                  class="tiktok-detail__subtitle font-weight-bold"
                  style="color: #484848"
                >
                  집계 중
                </div> -->
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">
          <v-icon class="mr-1" small>mdi-play-circle-outline</v-icon>1개월 평균 공유수
        </div>
        <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
          {{ statisticsData?.outline?.avgSharesPerMonth }}회
        </div>
      </v-col>
    </v-row>

    <v-row class="pa-4" style="background-color: #fbfbfb; border-radius: 12px">
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">등록 영상 수</div>
        <div class="tiktok-detail__subtitle2" style="color: #484848">
          {{ statisticsData?.outline?.totalVideoCount }}개
        </div>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">최근 업로드</div>
        <div class="tiktok-detail__subtitle2" style="color: #484848">
          {{ statisticsData?.outline?.recentUploadAt }}일 전
        </div>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="tiktok-detail__title">영상 업로드 빈도</div>
        <div class="tiktok-detail__subtitle2" style="color: #484848">
          월 {{ statisticsData?.outline?.videoUploadCycle }}개
        </div>
      </v-col>
    </v-row>
    <v-row class="tiktok-detail__section font-weight-bold" style="font-size: 18px"> 채널 퍼포먼스 </v-row>
    <v-row class="font-weight-bold px-4" style="font-size: 16px">
      기간별 채널 성과
      <v-tooltip bottom max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="16" color="#4770BD" class="ml-1" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        해당 기간동안 틱톡커가 등록한 영상의 팔로워 대비 조회수 성과입니다. 등록된 영상이 없는 기간의 데이터는 보이지
        않습니다.
      </v-tooltip>
    </v-row>
    <v-row class="mt-2 px-4">
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="youtuber-detail__title">
          {{ `최근 1주 평균 조회수` }}
        </div>
        <!-- <div
                  v-if="(viewTable.avgViewPerSubscriber1Week || {}).rate > 0"
                > -->
        <div>
          <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
            {{ statisticsData?.performance?.recent1WeekAvgViewCount || '집계 중' }}
          </div>
        </div>
        <!-- <div v-else class="youtuber-detail__subtitle font-weight-bold">
                  집계 중
                </div> -->
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="youtuber-detail__title">
          {{ `최근 1개월 평균 조회수` }}
        </div>
        <!-- <div
                  v-if="(viewTable.avgViewPerSubscriber1Week || {}).rate > 0"
                > -->
        <div>
          <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
            {{ statisticsData?.performance?.recent1MonthAvgViewCount || '집계 중' }}
          </div>
        </div>
        <!-- <div v-else class="youtuber-detail__subtitle font-weight-bold">
                  집계 중
                </div> -->
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'"
        :class="$vuetify.breakpoint.mdAndUp ? 'pr-3' : 'pr-3 pb-4'">
        <div class="youtuber-detail__title">
          {{ `최근 3개월 평균 조회수` }}
        </div>
        <!-- <div
                  v-if="(viewTable.avgViewPerSubscriber1Week || {}).rate > 0"
                > -->
        <div>
          <div class="tiktok-detail__subtitle font-weight-bold" style="color: #484848">
            {{ statisticsData?.performance?.recent3MonthAvgViewCount || '집계 중' }}
          </div>
        </div>
        <!-- <div v-else class="youtuber-detail__subtitle font-weight-bold">
                  집계 중
                </div> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import campaignMixin from '@/components/shared/campaignMixin';

export default {
  name: 'TiktokDetailStatistics',
  mixins: [campaignMixin],
  props: ['detailTargetId'],
  data() {
    return {
      statisticsData: {},
    };
  },
  async created() {
    const {
      data: { data },
    } = await this.axios.get(`/tiktoker/statistics?id=${this.detailTargetId}`);
    this.statisticsData = data;
  },
};
</script>

<style scoped lang="scss">
.tiktok-detail__section {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 18px;
  color: #484848;
  padding: 8px 16px;
  margin: 32px 0 24px;
  border-bottom: #f5f5f5 solid 1px;
}

.tiktok-detail__title {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  padding-bottom: 4px;
  margin-bottom: 0;
}

.tiktok-detail__subtitle {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 24px;
  text-align: left;
  font-weight: 300;
  padding-top: 8px;
}
</style>
