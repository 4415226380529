<script>
import { Doughnut } from 'vue-chartjs';

export default {
  name: 'DoughnutChart',
  extends: Doughnut,
  props: ['data', 'labels'],
  watch: {
    data() {
      this.displayChart();
    },
  },
  methods: {
    displayChart() {
      this.renderChart(
        {
          datasets: this.data,
          labels: this.labels,
        },
        {
          legend: {
            display: false,
            position: 'right',
            labels: {
              boxWidth: 12,
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return data.labels[tooltipItem.index];
              },
            },
          },
          responsive: !0,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          cutoutPercentage: 70,
        }
      );
    },
  },
  mounted() {
    this.displayChart();
  },
};
</script>

<style scoped lang="scss"></style>
