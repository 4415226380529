<template>
  <div>
    <v-row
      class="youtuber-detail__subtitle font-weight-bold px-4 mb-4 d-flex align-center justify-space-between"
      style="font-size: 14px">
      유사 채널
      <v-btn color="rouge" flat outlined @click="csvDownload">
        <v-icon>download</v-icon>
        다운로드
      </v-btn>
    </v-row>
    <v-row>
      <v-data-table
        :headers="[
          { text: '채널', value: 'title', sortable: false },
          { text: '주 시청자', value: 'mainViewer', sortable: false, align: 'center' },
          { text: '주 시청자 %', value: 'viewerRate', sortable: false, align: 'center' },
          { text: '구독자', value: 'subscriberCount', sortable: false, align: 'right ' },
        ]"
        :items="videos"
        :pagination.sync="pagination"
        :page="page"
        :items-per-page="20"
        hide-default-footer
        style="
          backgroud-color: transparent;
          border-radius: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.12);
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          width: 100%;
        ">
        <template v-slot:item.title="{ item }">
          <div class="d-flex align-center pt-2 pb-2">
            <img v-if="item.thumbnail" width="40px" height="40px" :src="item.thumbnail" style="border-radius: 50%" />
            <img
              v-else
              src="@/assets/img/matching-placeholder.jpg"
              width="40px"
              height="40px"
              style="border-radius: 50%" />
            <div class="ml-4">
              <a :href="'https://www.youtube.com/channel/' + item.youtube_id" target="_blank" style="color: #000000">{{
                item.title
              }}</a>
              <div>
                <v-chip outlined x-small color="#34CD89" class="ma-0 mr-1" v-for="c in item.topCategory" :key="c">
                  {{ c }}
                </v-chip>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.viewers="{ item }">
          <div>
            {{ item.mainViewer }}
          </div>
        </template>

        <template v-slot:item.viewerRate="{ item }">
          <div>{{ item.viewerRate }}%</div>
        </template>

        <template v-slot:item.subscriberCount="{ item }">
          <div>
            {{ item.subscriberCount.toLocaleString() }}
          </div>
        </template>
      </v-data-table>
      <v-row class="text-center">
        <v-spacer></v-spacer>
        <v-pagination
          class="mt-4"
          v-model="page"
          :length="Math.floor((parseInt(total) - 1) / 20 + 1)"
          :total-visible="10"></v-pagination>
        <v-spacer></v-spacer>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'similarity-channel',
  // mixins: [userMixin],
  props: ['id', 'youtuberName'],
  data() {
    return {
      videos: [],
      total: 0,
      page: 1,
    };
  },
  methods: {
    getMaxViewers(item) {
      console.log(item);
    },
    async requestRecommendChannel() {
      try {
        const {
          data: { videos, total },
        } = await this.axios.get(`/youtuber/channel/recommend?id=${this.id}&limit=20&offset=${(this.page - 1) * 20}`);
        this.total = total;
        this.videos = videos;
      } catch (error) {
        console.log(error);
      }
    },
    async csvDownload() {
      try {
        const {
          data: { videos },
        } = await this.axios.get(`/youtuber/channel/recommend?id=${this.id}&offset=${0}`);

        const initHead = 'title,URL,카테고리1,카테고리1 %,카테고리2,카테고리2 %,주 시청자,주 시청자 %,구독자';

        const csv = videos
          .reduce(
            (acc, cur) => {
              // acc.push(`${cur.date},${cur.account},${cur.views || 0},${cur.click || 0},${cur.point || 0},${cur.cpv || 0},${cur.cpc || 0}`);
              const [categoryOne, categoryTwo] = cur.topCategory;
              acc.push(
                `${cur.title},https://www.youtube.com/channel/${cur.youtube_id},${categoryOne || ''},${
                  cur.categories[categoryOne] || ''
                },${categoryTwo || ''},${cur.categories[categoryTwo] || ''},${cur.mainViewer},${cur.viewerRate},${
                  cur.subscriberCount
                }`
              );
              return acc;
            },
            [initHead]
          )
          .join('\n');

        let csvFile;
        let downloadLink;

        const BOM = '\uFEFF';
        const csv2 = BOM + csv;

        csvFile = new Blob([csv2], { type: 'text/csv' });
        downloadLink = document.createElement('a');
        downloadLink.download = `${this.youtuberName} 유사채널`;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        alert('csv 다운로드에 실패했습니다.');
      }
    },
  },
  created() {
    this.requestRecommendChannel();
  },
  watch: {
    page: 'requestRecommendChannel',
  },
};
</script>

<style scoped>
.youtuber-detail__subtitle {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 24px;
  text-align: left;
  font-weight: 300;
  padding-top: 8px;
}
</style>
