import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './user';
import company from './company';
import campaign from './campaign';
import shared from './shared';
import ai from './ai';
import { analyticsPlugin } from '../plugins/analytics';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    company,
    campaign,
    shared,
    ai,
  },
  plugins: [
    createPersistedState({
      // 로컬 스토리지에 저장하기 전에 변환 함수 적용
      reducer: state => {
        // eslint-disable-next-line no-unused-vars
        const { ai, ...persistedState } = state; // "ai" 모듈 제외
        return persistedState;
      },
    }),
    analyticsPlugin,
  ],
});

export default store;
