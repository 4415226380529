<template>
  <v-container container--fluid>
    <v-row class="mb-12" justify="center">
      <v-col cols="10" class="mt-12">
        <h2>로그인</h2>
      </v-col>
      <v-col cols="10" class="line mt-4 pb-4">
        <p>
          슈퍼{{ isMatching() ? '차트' : '멤버스' }}에 오신 것을 환영합니다.<span class="mobile"></span>
          {{
            isMatching()
              ? '인플루언서의 영향력을 직접 확인하고 캠페인을 제안해보세요.'
              : '전국의 블로거를 포스팅 비용 없이 마음껏 유치하세요.'
          }}
        </p>
        <!--<v-divider class="mt-12"></v-divider>-->
      </v-col>
      <v-col cols="10" md="6" class="mt-12 mb-12">
        <form @submit.prevent="onSubmit" id="signUpForm">
          <v-col>
            <v-col v-if="!userIsAuthenticated">
              <v-col>
                <v-col>
                  <div class="label font-weight-bold">이메일</div>
                </v-col>
                <v-col class="mt-1">
                  <v-text-field
                    id="email"
                    v-model="form.email"
                    name="email"
                    type="text"
                    v-validate="'required|min:3'"
                    :rules="[errors.first('email') || !errors.has('email')]"
                    single-line
                    outlined
                    solo
                    flat
                    required
                    v-lowercase></v-text-field>
                </v-col>
              </v-col>
            </v-col>
            <v-col v-if="!userIsAuthenticated">
              <v-col>
                <v-col>
                  <div class="label">비밀번호</div>
                </v-col>
                <v-col class="mt-1">
                  <v-text-field
                    id="password"
                    v-model="form.password"
                    name="password"
                    type="password"
                    ref="password"
                    placeholder="영어대소문자, 숫자로 8자 이상"
                    v-validate="'required|min:8'"
                    :rules="[errors.first('password') || !errors.has('password')]"
                    single-line
                    outlined
                    solo
                    flat
                    required></v-text-field>
                </v-col>
              </v-col>
            </v-col>
            <v-col>
              <!--<input type="checkbox" name="autologin"/> 로그인 상태 유지-->
              <v-checkbox
                v-model="form.persistence"
                label="로그인 상태 유지"
                class="pa-0 ma-0"
                hide-details></v-checkbox>
            </v-col>
            <v-col class="text-center my-4">
              <v-btn depressed color="primary" type="submit">로그인</v-btn>
              <router-link :to="{ path: '/signup/', query: $route.query }">
                <v-btn color="primary" type="button" outlined>회원가입</v-btn>
              </router-link>
            </v-col>
            <v-col class="text-center mt-1 pt-0">
              <router-link to="/passwdch/"> 비밀번호를 잊으셨나요? > </router-link>
            </v-col>
            <v-col class="text-center mt-6 pt-0">
              <v-icon small color="#4770bd">mdi-information-outline</v-icon>
              <div style="font-size: 13px; color: #4770bd">
                슈퍼멤버스 가맹점 아이디가 이미 있으실 경우,<br />
                기존 아이디로 로그인 하실 수 있습니다.
              </div>
            </v-col>
          </v-col>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Signin',
  mixins: [userMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
        persistence: false,
      },
      prevRoute: {},
    };
  },
  // watch: {
  //   user(value) {
  //     if (value !== null && value !== undefined) {
  //       let interval = setInterval(() => {
  //         if(!this.$store.getters.loading && this.$route.name === this.$options.name) {
  //           this.$router.push('/company/dashboard');
  //           clearTimeout(interval);
  //         }
  //       }, 1000);
  //     }
  //   }
  // },
  methods: {
    onSubmit() {
      let returnUrl = this.$store.getters?.returnUrl;
      let logoutFunc = Promise.resolve();
      if (this.userIsAuthenticated) {
        logoutFunc = this.$store.dispatch('logout');
      }
      this.form.userId = this.$route.query.userId;
      this.form.email = this.form.email.trim();
      this.form.password = this.form.password.trim();
      logoutFunc
        .then(() =>
          this.$store.dispatch('signUserIn', {
            ...this.form,
            matching: this.isMatching(),
          })
        )
        .then(user => {
          if (user && this.userIsAuthenticated) {
            if (this.isMatching()) {
              this.axios
                .put('/company/user', {
                  id: user.id,
                  last_login_matching: new Date(),
                })
                .then(() => {
                  this.$router.push('/campaign/search');
                });
            } else {
              this.axios.put('/company/user', { id: user.id, last_login: new Date() }).then(() => {
                if (returnUrl) {
                  this.$router.push(returnUrl);
                } else {
                  this.$router.push('/company/report');
                }
              });
            }
          } else {
            this.$store.commit('setMsg', '아이디 또는 비밀번호가 잘못 입력되었습니다.');
            this.$store.commit('setShowSnackbar', true);
          }
        });
    },
    isMatching() {
      return process.env.VUE_APP_TARGET === 'campaign' || location.href.indexOf('superchart') > -1;
    },
  },
  async created() {
    if (this.$route.query.token) {
      let res = await this.$store.dispatch('signUserInWithToken', this.$route.query);
      let timerId = window.setInterval(async () => {
        if (res) {
          if (this.isMatching()) {
            await this.$router.push('/campaign/search');
          } else {
            await this.$router.push('/company/report');
          }
          clearInterval(timerId);
        }
      }, 1000);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/form.scss';
@import '../../assets/scss/custom';

.line {
  border-bottom: 3px solid #4a4a4a;
}

::v-deep .v-btn {
  margin-left: 0;
  margin-right: 0;
}
</style>
