<template>
  <v-card
    flat
    :style="
      $vuetify.breakpoint.mdOnly ? 'width: calc(100vw - 240px); overflow-x:auto; white-space: nowrap;' : 'width:1120px'
    ">
    <v-tabs v-model="tab" grow>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <component :is="item.content"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import campaignMixin from '@/components/shared/campaignMixin';

export default {
  name: 'AutoProposition',
  mixins: [userMixin, campaignMixin],
  data() {
    return {
      tab: 0,
      items: [
        { tab: '제안하기', content: () => import('@/components/campaign/autoproposition/AutoPropose') },
        { tab: '제안 결과', content: () => import('@/components/campaign/autoproposition/AutoResult') },
      ],
      propositions: [],
    };
  },
  watch: {
    tab: {
      handler() {
        this.$router.push({ path: 'autoproposition', query: { tab: this.tab } });
      },
      deep: true,
    },
  },
  methods: {},
  async created() {
    this.tab = parseInt(this.$route.query.tab || '0');
  },
};
</script>

<style scoped lang="scss"></style>
