<template>
  <v-row class="ml-1 pa-6">
    <v-col cols="12">
      <v-col cols="12">
        <h2 class="font-weight-bold headline hidden-sm-and-down">
          정산받을 계좌 및<br />회원의 결제를 관리할 수 있습니다.
        </h2>
      </v-col>
      <v-col cols="12">
        <div class="body-1 font-weight-bold mt-6 mb-2">매출기간</div>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn-toggle v-model="duration" mandatory>
              <v-btn class="ma-0" color="rouge" outlined style="width: 60px; height: 36px" @click="updateDate(0, 0)">
                전체
              </v-btn>
              <v-btn class="ma-0" color="rouge" outlined style="width: 60px; height: 36px" @click="updateDate(1, 0)">
                1개월
              </v-btn>
              <v-btn class="ma-0" color="rouge" outlined style="width: 60px; height: 36px" @click="updateDate(3, 0)">
                3개월
              </v-btn>
              <v-btn class="ma-0" color="rouge" outlined style="width: 60px; height: 36px" @click="updateDate(6, 0)">
                6개월
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="menuStart"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="startFormatted"
                    prepend-icon="event"
                    @blur="startDate = parseDate(startFormatted)"
                    style="max-width: 140px"></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  locale="ko-kr"
                  :max="endDate"
                  no-title
                  scrollable
                  @input="menuStart = false">
                </v-date-picker>
              </v-menu>
              <div class="pa-4 pt-6">-</div>
              <v-menu
                :close-on-content-click="false"
                v-model="menuEnd"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="endFormatted"
                    prepend-icon="event"
                    @blur="endDate = parseDate(endFormatted)"
                    style="max-width: 140px"></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  locale="ko-kr"
                  :min="startDate"
                  no-title
                  scrollable
                  @input="menuEnd = false">
                </v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
        </v-row>
        <div class="body-1 font-weight-bold mt-6 mb-2">매출</div>
        <v-data-table
          :headers="[
            { text: '정산일', width: '120px', sortable: false },
            { text: '총매출금액(원)', width: '104px', sortable: false },
            { text: '신용카드매출(원)', width: '104px', sortable: false },
            { text: '기타(원)', width: '104px', sortable: false },
          ]"
          :items="filtered"
          mobile-breakpoint="0"
          style="backgroud-color: transparent; border-top: 1px solid rgba(0, 0, 0, 0.12)">
          <template v-slot:item="props">
            <tr style="font-size: 13px">
              <td class="px-1">{{ $moment(props.item.date, 'YYYYMMDD').format('YYYY.MM.DD') }}</td>
              <td class="px-1 text-center">{{ props.item.total | numFormat }}원</td>
              <td class="px-1 text-center">{{ props.item.total | numFormat }}원</td>
              <td class="px-1 text-center french-blue--text">0원</td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr>
              <td v-if="filtered.length > 0" :colspan="1" class="pa-1 text-left font-weight-bold">합계</td>
              <td v-if="filtered.length > 0" class="pa-1 text-center font-weight-bold">
                {{ _.sumBy(filtered, 'total') | numFormat }}원
              </td>
              <td v-if="filtered.length > 0" class="pa-1 text-center font-weight-bold">
                {{ _.sumBy(filtered, 'total') | numFormat }}원
              </td>
              <td v-if="filtered.length > 0" class="pa-1 text-center font-weight-bold">0원</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-col>
    <v-col cols="12" class="text-right pa-0 ma-0">
      <download-excel
        :data="[...filtered, { date: 0, total: _.sumBy(filtered, 'total') }]"
        :fields="json_fields"
        :name="
          '부가가치세참고자료_' +
          user.name +
          '_' +
          startDate.replace(/\D/g, '') +
          '-' +
          endDate.replace(/\D/g, '') +
          '.xls'
        ">
        <v-btn color="#61c9a8" class="ma-0 white--text" style="min-width: 150px">엑셀파일 다운로드</v-btn>
      </download-excel>
    </v-col>
  </v-row>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

import JsonExcel from 'vue-json-excel';
import Vue from 'vue';

export default {
  name: 'VAT.vue',
  mixins: [userMixin],
  props: ['schedules'],
  components: {
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      duration: 1,
      menuStart: false,
      menuEnd: false,
      startDate: '',
      endDate: '',
      startFormatted: null,
      endFormatted: null,
      json_fields: {
        정산일: {
          callback: value => {
            if (value.date == 0) {
              return '합계';
            } else {
              return Vue.moment(value.date).tz('Asia/Seoul').format('YYYY.MM.DD');
            }
          },
        },
        '총 매출금액': 'total',
        '신용카드 매출': 'total',
        기타: {
          callback: () => {
            return 0;
          },
        },
      },
    };
  },
  computed: {
    filtered: function () {
      return (
        (this.schedules || []).filter(schedule => {
          let date = this.$moment(schedule.date, 'YYYYMMDD').tz('Asia/Seoul').format('YYYY-MM-DD');
          return (
            this.$moment(date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]') &&
            this.$moment(date).tz('Asia/Seoul').isSameOrBefore(new Date())
          );
        }) || []
      );
    },
  },
  watch: {
    startDate() {
      this.startFormatted = this.formatDate(this.startDate);
    },
    endDate() {
      this.endFormatted = this.formatDate(this.endDate);
    },
    schedules(value) {
      this.getInitData(value);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}. ${month}. ${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('. ');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    updateDate(mon, week) {
      this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      if (mon > 0 || week > 0) {
        this.startDate = this.$moment(this.endDate)
          .subtract(mon, 'months')
          .subtract(week, 'weeks')
          .format('YYYY-MM-DD');
      } else {
        this.startDate = this.$moment().subtract(10, 'years').format('YYYY-MM-DD');
      }
    },
    async getInitData(schedules) {
      this.schedules = schedules;
      // this.filtered = settlements.filter((settlement) => {
      //   return(settlement.status == 5)
      // });
      // this.filtered = this._.orderBy(this.filtered, ['cid', 'paidAt'], ['desc', 'desc']);
    },
  },
  async created() {
    this.startDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
    this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');

    this.getInitData(this.schedules);

    this.startFormatted = this.formatDate(this.startDate);
    this.endFormatted = this.formatDate(this.endDate);

    this.updateDate(1, 0);
  },
};
</script>

<style scoped></style>
