import 'babel-polyfill';
import Vue from 'vue';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import VueFire from 'vuefire'
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueMoment from 'vue-moment';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import VueOffline from 'vue-offline';
import browserDetect from 'vue-browser-detect-plugin';
import VueCookies from 'vue-cookies';
// import SocketIO from 'socket.io-client'
// import VueSocketIOExt from 'vue-socket.io-extended';
import VueDragscroll from 'vue-dragscroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import ScrollMagic from 'scrollmagic'
import VueScrollMagic from 'vue-scrollmagic';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import './filters';
import './directives';

import firebase from '@/config/firebase';
import validator from '@/config/validator';
import '@firebase/firestore';
// import './assets/scss/typo.scss'

// import Tawk from './plugins/tawkto'
// import ChannelIO from './plugins/channelio'
import interceptor from './helpers/httpInterceptor.js';
import Inipay from './plugins/inipay';
interceptor(axios);

import 'moment/locale/ko';

const moment = extendMoment(Moment);

Vue.use(VueAxios, axios);
// Vue.use(VueFire);
Vue.use(VueLodash, { name: 'custom', lodash: lodash });
Vue.use(VueMoment, {
  moment,
}); //Vue.moment(), this.$moment
Vue.use(VueOffline);
// Vue.use(Tawk, {
//   tawkSrc: 'https://embed.tawk.to/5c04fbd140105007f37ab6ff/default'
// });
// Vue.use(ChannelIO, {
//   channelSrc: 'https://cdn.channel.io/plugin/ch-plugin-web.js'
// });
Vue.use(browserDetect);
Vue.use(VueCookies);
Vue.use(Inipay);
Vue.use(VueScrollMagic, {
  verical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100,
});

// Vue.use(new VueSocketIO({
//   // debug: true,
//   connection: SocketIO(process.env.VUE_APP_API_URL, {}),
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   }
// }))

// const socket = SocketIO(process.env.VUE_APP_API_URL);
// Vue.use(VueSocketIOExt, socket);

Vue.use(VueDragscroll);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.config.productionTip = false;

validator();

Vue.prototype.$categories = {
  매장: { loc: 'store' },
  제품: { loc: 'product' },
  서비스: { loc: 'service' },
};
Vue.prototype.$channel = ['naver', 'insta', 'youtube'];
Vue.prototype.$forceCompute = function (computedName, forceUpdate /* default: true */) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run();
    if (forceUpdate || typeof forceUpdate == 'undefined') this.$forceUpdate();
  }
};
Vue.prototype.$periodMap = {
  naver: {
    T: {
      매장: [7, 10, 5, 5, 3], //방문기한, 첫원고, 수정요청기한, 수정사항 반영 업로드 기한, 업로드 요청일로부터 리뷰 등록기한
      제품: [7, 10, 5, 5, 3],
      서비스: [7, 10, 5, 5, 3],
    },
    P: {
      매장: [3, 10, 5, 5, 3],
      제품: [3, 10, 5, 5, 3],
      서비스: [3, 10, 5, 5, 3],
    },
  },
  insta: {
    T: {
      매장: [7, 10, 5, 5, 3],
      제품: [7, 10, 5, 5, 3],
      서비스: [7, 10, 5, 5, 3],
    },
    P: {
      매장: [3, 10, 5, 5, 3],
      제품: [3, 10, 5, 5, 3],
      서비스: [3, 10, 5, 5, 3],
    },
  },
  youtube: {
    T: {
      매장: [7, 21, 5, 7, 3],
      제품: [7, 21, 5, 7, 3],
      서비스: [7, 21, 5, 7, 3],
    },
    P: {
      매장: [3, 21, 5, 7, 3],
      제품: [3, 21, 5, 7, 3],
      서비스: [3, 21, 5, 7, 3],
    },
  },
};

Vue.prototype.$requiredMap = {
  naver: {
    T: ['mission', 'requiredKeywords', 'keywords'],
    P: ['mission', 'requiredKeywords', 'keywords'],
  },
  youtube: {
    T: ['mission', 'requiredKeywords', 'keywords'],
    P: ['mission', 'requiredKeywords', 'keywords'],
  },
  매장: {
    T: ['local', 'serviceName', 'address', 'contacts', 'abstraction', 'introduction', 'thumbnail', 'thumbnails'],
    P: ['local', 'serviceName', 'address', 'contacts', 'abstraction', 'introduction', 'thumbnail', 'thumbnails'],
  },
  제품: {
    T: [
      'serviceName',
      'contacts',
      'companyName',
      'abstraction',
      'introduction',
      'deliveryMethod',
      'shipping',
      'thumbnail',
      'thumbnails',
    ],
    P: ['serviceName', 'abstraction', 'introduction', 'thumbnail', 'thumbnails'],
  },
  서비스: {
    T: ['serviceName', 'abstraction', 'introduction', 'thumbnail', 'thumbnails'],
    P: ['serviceName', 'abstraction', 'introduction', 'thumbnail', 'thumbnails'],
  },
};
Vue.prototype.$getMaxEditCount = function (price, channel) {
  let maxEditCount = 3;
  if (channel === 'youtube') {
    if (price < 1000000 || price === undefined) {
      maxEditCount = 1;
    } else if (price < 3000000) {
      maxEditCount = 2;
    }
  } else {
    if (price < 50000 || price === undefined) {
      maxEditCount = 1;
    } else if (price < 1000000) {
      maxEditCount = 2;
    }
  }
  return maxEditCount;
};

Vue.prototype.$minYoutubeProposeRate = 0.2;
Vue.prototype.$minProposeRate = 0.5;
Vue.prototype.$maxProposeRate = 5;
Vue.prototype.$maxProposeRate2 = 10;

export const gEventBus = new Vue();

new Vue({
  // sockets: {
  //   connect: function () {
  //     console.log('socket connected')
  //   },
  //   login: function (data) {
  //     if (store.getters.user && (data.email === store.getters.user.email)) {
  //       console.log('logout')
  //       // message and logout
  //       store.commit('setMsg', '중복 로그인으로 로그아웃 됩니다.');
  //       store.commit('setShowSnackbar', true);
  //       store.dispatch('logout');
  //     }
  //   }
  // },
  router,
  store,
  vuetify,
  created() {
    process.argv.forEach((val, index) => {
      console.log(`${index}: ${val}`);
    });
    store.commit('setLoading', true);
    firebase.app.auth().onAuthStateChanged(async user => {
      store.commit('setLoading', false);
      if (
        user &&
        ((user.email.indexOf('@supermembers.co.kr') > 0 && (store.getters.user || {}).email !== user.email) ||
          (store.getters.user || {}).email !== (store.getters.head || {}).email)
      ) {
        await store.dispatch('autoSignIn', {
          email: (store.getters.user || {}).email || user.email,
          uid: null,
          matching: this.isMatching(),
        });
        if (this.$route.name === 'Matching') {
          router.push('/campaign/search');
        } else if (this.$route.name === undefined || this.$route.name === 'Landing') {
          router.push('/company/report');
        }
        // this.$Tawk.$hideWidget();
      } else if (user) {
        // if (this.$moment().diff(this.$moment(user.metadata.lastSignInTime), 'seconds') > 5) {
        await store.dispatch('autoSignIn', { ...user, matching: this.isMatching() });
        // }
        if (this.$route.name === 'Matching') {
          router.push('/campaign/search');
        } else if (this.$route.name === undefined || this.$route.name === 'Landing') {
          router.push('/company/report');
        }
        // this.$Tawk.$hideWidget();
      } else {
        // await store.dispatch('logout');
        let requiresAuth = this.$route.matched.some(record => record.meta.requiresAuth);
        if (requiresAuth) {
          router.push('/signin');
        }
      }

      // if ((store.getters.user || {}).email) {
      //   this.$socket.client.emit('login', {
      //     email: store.getters.user.email
      //   });
      // }
    });
  },
  render: h => h(App),
  mounted() {
    AOS.init({
      offset: 120,
      duration: 400,
      easing: 'ease-out-quad',
    });
  },
  methods: {
    isMatching() {
      return process.env.VUE_APP_TARGET === 'campaign' || location.href.indexOf('superchart') > -1;
    },
  },
}).$mount('#app');
