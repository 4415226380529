<template>
  <v-col class="ml-1 mb-2 pa-6 pb-12">
    <h2 class="font-weight-bold headline hidden-sm-and-down">최신 알림을 확인하세요.</h2>
    <div class="label mt-6 mb-2">새로운 알림</div>
    <div class="line"></div>
    <v-data-table
      :headers="headers"
      :items="notices"
      :items-per-page="10"
      mobile-breakpoint="0"
      style="backgroud-color: transparent">
      <template v-slot:item="{ item }">
        <tr @click.stop.prevent="openWindow(item)">
          <td style="padding: 0; min-width: 100px">{{ item.date | dateFormat }}</td>
          <td style="padding: 0; margin: 0; min-width: 170px">
            <insta-icon class="table_icon mr-2" v-if="item.icon === 'insta'" />
            <naver-icon class="table_icon mr-2" v-else />
            {{ item.company }}
          </td>
          <td class="row_content" style="padding: 0; min-width: 360px">{{ item.content }}</td>
        </tr>
      </template>
      <template v-slot:footer.page-text="props">
        <span>전체 {{ props.itemsLength }}건 중 {{ props.pageStart }} - {{ props.pageStop }} 건</span>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';

export default {
  name: 'Dashboard',
  mixins: [companyMixin],
  components: {
    naverIcon: () => import('@/assets/img/dashboard/naver-tableicon.svg'),
    instaIcon: () => import('@/assets/img/dashboard/instagram-tableicon.svg'),
  },
  data() {
    return {
      headers: [
        { text: '일자', align: 'left', value: 'date', sortable: false, width: '100' },
        { text: '광고명', value: 'company', sortable: false, width: '170' },
        { text: '내용', value: 'content', sortable: false },
      ],
      notices: [],
      loading: false,
    };
  },
  watch: {
    companies() {
      if (this.notices.length === 0) {
        this.loadNotices();
      }
    },
  },
  methods: {
    loadNotices() {
      if (!this.loading) {
        this.loading = true;
        this.notices.splice();
        Promise.all(
          this.companies.map((company, idx) => {
            return this.axios
              .get('/contract?company=' + company.id + '&orderBy=DATE(date0)&direction=desc')
              .then(contracts => {
                contracts.data.contracts.map(contract => {
                  if (contract.date2) {
                    //
                  } else if (contract.date1 && contract.username && contract.level) {
                    this.notices.push({
                      date: contract.date1,
                      icon: contract.type || 'naver',
                      company: company.name,
                      content:
                        this._.capitalize(contract.level) +
                        '등급 ' +
                        contract.username +
                        (contract.type === 'insta' ? ' 인스타그래머' : ' 블로거') +
                        ' 후기가 등록되었으니 확인 후 평가부탁드립니다.',
                      link: contract.link
                        ? contract.type === 'insta'
                          ? contract.link
                          : 'https://blog.naver.com/' +
                            String(contract.userEmail || '').split('@')[0] +
                            '/' +
                            contract.link
                        : null,
                    });
                  } else if (
                    (contract.deliveryHistory &&
                      contract.deliveryHistory[contract.deliveryHistory.length - 1].state === -1) ||
                    contract.deliveryState === -1
                  ) {
                    this.notices.push({
                      date: contract.deliveryHistory
                        ? (contract.deliveryHistory || [])[(contract.deliveryHistory || []).length - 1].updatedAt
                        : contract.deliveryDate,
                      icon: contract.type || 'naver',
                      company: company.name,
                      content:
                        this._.capitalize(contract.level) +
                        '등급 ' +
                        contract.username +
                        (contract.type === 'insta' ? ' 인스타그래머' : ' 블로거') +
                        '의 정보수정이 완료되었습니다.',
                      link: '/company/contract',
                      idx: idx,
                    });
                  } else if (contract.username && contract.level && company.category) {
                    this.notices.push({
                      date: contract.date0,
                      icon: contract.type || 'naver',
                      company: company.name,
                      content:
                        this._.capitalize(contract.level) +
                        '등급 ' +
                        contract.username +
                        (company.category.firstName === '매장'
                          ? (contract.type === 'insta' ? ' 인스타그래머' : ' 블로거') + '의 매장 방문이 완료되었습니다.'
                          : (contract.type === 'insta' ? ' 인스타그래머' : ' 블로거') + ' 제품 신청이 완료되었습니다.'),
                      link: '/company/contract',
                      idx: idx,
                    });
                  }
                });
                return null;
              });
          })
        )
          .then(() => {
            this.notices.sort((a, b) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    openWindow(item) {
      if (item.link) {
        if (item.idx) {
          this.$store.commit('setCompanyIdx', item.idx);
        }
        window.open(item.link, '_blank');
      }
    },
  },
  created() {
    if (!this._.isEmpty(this.companies)) {
      this.loadNotices();
    }
  },
  filters: {
    dateFormat: value => {
      if (value) {
        let date = String(value).split('T')[0];
        const [year, month, day] = date.split('-');
        return `${year}.${month}.${day}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-datatable.v-table {
  background-color: transparent;
  ::v-deep .v-datatable__actions {
    background-color: transparent;
  }
}

.table_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  vertical-align: middle;
}

.row_content {
  color: #bd1328;
  color: var(--rouge-color);
  letter-spacing: -0.9px;
  text-decoration: underline;
}

::v-deep .line {
  border-bottom: 1px solid #757575;
  border-bottom: 1px solid var(--warm-grey-color);
  width: 100%;
}
</style>
