<template>
  <div class="VideoInfo">
    <div>
      <v-data-table
        :headers="headers"
        :items="propositionsFiltered"
        item-key="id"
        :items-per-page.sync="options.itemsPerPage"
        :options.sync="options"
        disable-sort
        show-expand
        :expanded.sync="expanded"
        mobile-breakpoint="0"
        :footer-props="{
          itemsPerPageOptions: [],
        }"
        hide-default-footer
        style="backgroud-color: transparent">
        <template v-slot:header.channel="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.Youtuber.account="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.responsedAt="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.manuStatus="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.maxEditCount="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.request="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.reviewStatus="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.reviewRegistedAt="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:header.reviewRate="{ header }">
          <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
        </template>
        <template v-slot:top>
          <v-row>
            <div class="pa-4" style="font-size: 16px; color: #484848">
              기획안 / 등록 영상 목록
              <span class="body-2 ml-2" style="color: #808080"
                >{{ propositionsFiltered.length }}개 ・
                {{ Math.floor((parseInt(propositionsFiltered.length) - 1) / options.itemsPerPage + 1) }} Pages</span
              >
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color: #484848; font-size: 13px">
                    {{ options.itemsPerPage }}명씩 보기
                    <v-icon small>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in [20, 50, 100]" :key="index" @click="options.itemsPerPage = item">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
          <v-divider></v-divider>
        </template>
        <template v-slot:item.channel="props">
          <naverIcon v-if="props.item.taggableType === 'naver'" />
          <instaIcon v-if="props.item.taggableType === 'insta'" />
          <youtubeIcon v-if="props.item.taggableType === 'youtube'" />
        </template>
        <template v-slot:item.Youtuber.account="{ item }">
          <v-row @click="youtuberDetailDlg(item.Youtuber)">
            <v-col cols="3">
              <v-avatar size="40">
                <img v-if="item.Youtuber.thumbnail" :src="item.Youtuber.thumbnail" />
                <img v-else src="@/assets/img/matching-placeholder.jpg" />
              </v-avatar>
            </v-col>
            <v-col cols="8" class="ml-1">
              <v-row class="caption mt-3" style="color: #4770bd">
                {{ item.Youtuber.title }}
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.responsedAt="props">
          <div v-if="props.item.responsedAt" class="caption" style="color: #808080">
            {{ $moment(props.item.responsedAt).tz('Asia/Seoul').format('YYYY.MM.DD') }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:item.manuStatus="{ item }">
          <div class="caption" style="color: #808080">
            <div v-if="((item.status || 0) === 12 || (item.status || 0) === 20) && item.planningGuide">
              <v-icon small>mdi-numeric-1-circle-outline</v-icon>
              기획안 대기중
            </div>
            <div v-else-if="((item.status || 0) === 12 || (item.status || 0) === 20) && !item.planningGuide">
              <v-icon small>mdi-numeric-1-circle-outline</v-icon>
              <a href="#" @click="planningGuideDialog(item, manuscript)" style="text-decoration: underline">
                가이드 발송 필요
              </a>
            </div>
            <div v-else-if="(item.status || 0) > 21 && (item.status || 0) < 33 && manuscript[item.id]">
              <div v-if="manuscript[item.id] && manuscript[item.id][0].status === 3">
                <v-icon v-if="manuscript[item.id][0].type === 0" small>mdi-numeric-2-circle-outline</v-icon>
                <v-icon v-else small>mdi-numeric-4-circle-outline</v-icon>
                <a
                  @click="
                    manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)
                  "
                  style="text-decoration: underline">
                  {{ manuscript[item.id][0].type === 0 ? '기획안 등록' : '평가 대기중' }}
                </a>
              </div>
              <div v-else-if="manuscript[item.id] && manuscript[item.id][0].status === 1">
                <v-icon v-if="manuscript[item.id][0].type === 0" small>mdi-numeric-2-circle-outline</v-icon>
                <v-icon v-else small>mdi-numeric-3-circle-outline</v-icon>
                <a
                  @click="
                    manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)
                  "
                  style="text-decoration: underline">
                  {{ manuscript[item.id][0].type === 0 ? '기획안 수정 중' : '가편집본 수정 중' }}
                </a>
              </div>
              <div v-else-if="manuscript[item.id] && manuscript[item.id][0].status === 2">
                <v-icon v-if="manuscript[item.id][0].type === 0" small>mdi-numeric-2-circle-outline</v-icon>
                <v-icon v-else small>mdi-numeric-4-circle-outline</v-icon>
                <a
                  @click="
                    manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)
                  "
                  style="text-decoration: underline">
                  {{ manuscript[item.id][0].type === 0 ? '가편집 등록' : '유튜브 업로드 대기중' }}
                </a>
              </div>
              <div v-else-if="manuscript[item.id]">
                <v-icon v-if="manuscript[item.id][0].type === 0" small>mdi-numeric-2-circle-outline</v-icon>
                <v-icon v-else small>mdi-numeric-3-circle-outline</v-icon>
                <a
                  @click="
                    manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)
                  "
                  style="text-decoration: underline">
                  {{
                    manuscript[item.id][0].step
                      ? (manuscript[item.id][0].type === 0 ? '기획안 ' : '가편집 ') +
                        manuscript[item.id][0].step +
                        '차 수정본 등록'
                      : manuscript[item.id][0].type === 0
                      ? '기획안 확인 필요'
                      : '가편집 확인 필요'
                  }}
                </a>
              </div>
            </div>
            <div v-else>-</div>
          </div>
        </template>
        <template v-slot:item.maxEditCount="{ item }">
          <div class="caption" style="color: #808080">
            <div v-if="item.maxEditCount && (manuscript[item.id] || [{ status: 0 }])[0].status !== 3">
              {{
                (manuscript[item.id] || [{ step: 0 }])[0].step +
                ((manuscript[item.id] || [{ step: 0 }])[0].status == 2 ? 1 : 0)
              }}{{ (manuscript[item.id] || [{ status: 0 }])[0].type === 1 ? '/' + item.maxEditCount : '' }}회
            </div>
            <div v-else>-</div>
          </div>
        </template>
        <template v-slot:item.request="{ item }">
          <v-btn
            v-if="
              manuscript[item.id] && _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].status === 0
            "
            rounded
            depressed
            small
            color="rouge"
            class="white--text"
            @click="manuscriptReqDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)"
            >요청</v-btn
          >
          <v-btn
            v-else-if="
              manuscript[item.id] && _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].status === 1
            "
            rounded
            depressed
            small
            outlined
            color="rouge"
            @click="manuscriptReqDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)"
            >보기</v-btn
          >
          <v-btn v-else rounded depressed disabled small>요청</v-btn>
        </template>
        <template v-slot:item.reviewStatus="{ item }">
          <div class="caption" style="color: #808080">
            <div v-if="(item.status || 0) === 30">대기</div>
            <div v-else-if="(item.status || 0) === 32 || (item.status || 0) === 40">
              <a :href="item.review" target="_blank" style="text-decoration: underline"> 등록 </a>
            </div>
            <div v-else>-</div>
          </div>
        </template>
        <template v-slot:item.reviewRegisteredAt="props">
          <div v-if="props.item.reviewRegisteredAt" class="caption" style="color: #808080">
            {{ $moment(props.item.reviewRegisteredAt).tz('Asia/Seoul').format('YYYY.MM.DD') }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:item.reviewRate="{ item }">
          <div v-if="item.rating" class="caption" style="color: #808080">
            {{ item.rating + '/' + 5 }}
          </div>
          <div
            v-else-if="item.review"
            @click="
              openRateDialog = true;
              rateProposition = item;
            "
            class="caption rouge--text"
            style="text-decoration: underline">
            평가하기
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td colspan="10">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="10">
                <v-stepper
                  v-if="manuscript[item.id]"
                  :value="getStep(item, _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0])"
                  alt-labels
                  flat
                  tile>
                  <v-stepper-header>
                    <v-stepper-step step="1"> 캠페인 수락 </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2"> 기획안확인/수정요청 </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3"> 가편집영상확인/수정 </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4"> 영상 업로드 </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <div style="border-top: 1px solid #c2c2c2; border-top: 1px solid var(--pinkish-grey-color)">
              <v-row
                v-for="(history, idx) in manuscriptHistory(item.id)"
                :key="idx"
                class="py-4"
                :style="
                  idx !== manuscriptHistory(item.id).length - 1
                    ? 'border-bottom: 1px solid #c2c2c2; border-bottom: 1px solid var(--pinkish-grey-color)'
                    : ''
                ">
                <v-col cols="1" class="text-center">
                  <v-avatar>
                    <v-icon
                      v-if="history.status === 0 && history.type === 0"
                      color="white"
                      class="circle"
                      size="24"
                      style="background-color: #34cd89">
                      mdi-file-document
                    </v-icon>
                    <v-icon
                      v-else-if="history.status === 1 || history.status === 2"
                      color="white"
                      class="circle warm-grey"
                      size="24">
                      mdi-send
                    </v-icon>
                    <v-icon
                      v-else-if="history.status === 0 && history.type === 1"
                      color="white"
                      class="circle"
                      size="24"
                      style="background-color: #34cd89">
                      mdi-file-video
                    </v-icon>
                    <v-icon
                      v-else-if="history.status === 3 && history.type === 0"
                      color="white"
                      class="circle warm-grey"
                      size="24">
                      mdi-send
                    </v-icon>
                    <v-icon v-else-if="history.status === 3" color="white" class="circle warm-grey" size="24">
                      mdi-video
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col :cols="history.status > 0 && history.status < 3 ? '11' : '9'">
                  <span v-if="history.status === 0 && history.type === 0">
                    {{ history.step ? '기획안 수정본 도착(' + history.step + '차)' : '기획안 도착' }}
                    <span class="french-blue--text">{{
                      $moment(history.manuRegistedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <span v-else-if="history.status === 1 && history.type === 0">
                    {{ '기획안 수정 요청 발송됨(' + (history.step + 1) + '차)' }}
                    <span class="french-blue--text">{{
                      $moment(history.editRequestedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <span v-else-if="history.status === 3 && history.type === 0">
                    가편집 영상 제작 요청 발송됨
                    <span class="french-blue--text">{{
                      $moment(history.editRequestedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <span v-else-if="history.status === 0 && history.type === 1">
                    {{ history.step ? '가편집 영상 수정본 도착(' + history.step + '차)' : '가편집 영상 도착' }}
                    <span class="french-blue--text">{{
                      $moment(history.manuRegistedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <span v-else-if="history.status === 1 && history.type === 1">
                    {{ '가편집 영상 수정 요청 발송됨(' + (history.step + 1) + '차)' }}
                    <span class="french-blue--text">{{
                      $moment(history.editRequestedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <span v-else-if="history.status === 2 && history.type === 1">
                    영상 업로드 요청 발송됨
                    <span class="french-blue--text">{{
                      $moment(history.editRequestedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <span v-else-if="history.status === 3 && history.type === 1">
                    영상 업로드 완료
                    <span class="french-blue--text">{{
                      $moment(history.editRequestedAt).format('YYYY.MM.DD. HH:MM')
                    }}</span></span
                  >
                  <br />
                  <v-row style="color: #757575">
                    <span v-if="history.status === 0 && history.type === 0"></span>
                    <span v-else-if="history.status === 1 && history.type === 0" style="white-space: normal">{{
                      history.request
                    }}</span>
                    <span v-else-if="history.status === 3 && history.type === 0" style="white-space: normal">{{
                      history.request
                    }}</span>
                    <span v-else-if="history.status === 0 && history.type === 1"
                      >가편집 영상 수정 가능 횟수 {{ item.maxEditCount - history.step }}회 남음</span
                    >
                    <span v-else-if="history.status === 1 && history.type === 1" style="white-space: normal">{{
                      history.request
                    }}</span>
                    <span v-else-if="history.status === 2 && history.type === 1" style="white-space: normal">{{
                      history.request
                    }}</span>
                    <span v-else-if="history.status === 3 && history.type === 1"
                      >광고 성과를 마케팅 보고서에서 확인하실수 있습니다.</span
                    >
                    <span v-if="history.status === 2 && history.type === 1"
                      >업로드 예정일: {{ $moment(item.uploadRequestDate).format('YYYY.MM.DD.dddd HH:MM') }}</span
                    >
                  </v-row>
                  <v-row>
                    <a v-if="history.status === 0 && history.type === 0" :href="history.manuscript" target="_blank">{{
                      history.step ? '기획안 수정본 보기>' : '기획안 보기>'
                    }}</a>
                    <a
                      v-else-if="history.status === 0 && history.type === 1"
                      :href="history.manuscript"
                      target="_blank"
                      >{{ history.step ? '영상 수정본 보기>' : '영상 보기>' }}</a
                    >
                    <a v-else-if="history.status === 3 && history.type === 1" :href="history.manuscript" target="_blank"
                      >등록된 영상 보기></a
                    >
                  </v-row>
                  <br />
                </v-col>
                <v-col :cols="history.status > 0 && history.status < 3 ? '0' : '2'" class="px-2">
                  <v-btn
                    v-if="history.type === 0 && history.status === 0"
                    href="#"
                    @click="
                      manuscriptReqDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)
                    "
                    :disabled="
                      manuscript[item.id] &&
                      (_.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].status !== 0 ||
                        _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].step !== history.step)
                    "
                    flat
                    tile
                    outlined
                    color="rouge"
                    style="width: 100%"
                    >{{
                      manuscript[item.id] &&
                      (_.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].status !== 0 ||
                        _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].step !== history.step)
                        ? '요청 발송됨'
                        : '기획안수정/영상제작요청'
                    }}</v-btn
                  >
                  <v-btn
                    v-if="history.type === 1 && history.status === 0"
                    href="#"
                    @click="
                      manuscriptReqDialog(manuscript[item.id], manuscript[item.id][0].step, manuscript[item.id][0].type)
                    "
                    :disabled="
                      manuscript[item.id] &&
                      (_.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].status !== 0 ||
                        _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].step !== history.step)
                    "
                    flat
                    tile
                    outlined
                    color="rouge"
                    style="width: 100%"
                    >{{
                      manuscript[item.id] &&
                      (_.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].status !== 0 ||
                        _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0].step !== history.step)
                        ? '요청 발송됨'
                        : '영상수정/영상업로드요청'
                    }}</v-btn
                  >
                  <v-btn
                    v-if="history.status === 3 && history.type === 1"
                    href="#"
                    @click="
                      openRateDialog = true;
                      rateProposition = item;
                    "
                    flat
                    tile
                    outlined
                    color="rouge"
                    style="width: 100%"
                    >평가하기</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="item.status === 2 || item.status >= 10"
                class="py-4"
                style="border-top: 1px solid #c2c2c2; border-top: 1px solid var(--pinkish-grey-color)">
                <v-col cols="1" class="text-center">
                  <v-avatar>
                    <v-icon color="white" class="circle warm-grey" size="24"> mdi-account-check </v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="9">
                  <span>
                    캠페인 수락 완료
                    <span class="french-blue--text">{{ new Date(item.responsedAt).toLocaleDateString() }}</span></span
                  >
                  <br />
                  <span style="color: #757575">{{ campaign.address }}/{{ campaign.contacts }}</span>
                </v-col>
                <v-col cols="2" class="px-2">
                  <v-btn
                    href="#"
                    v-if="
                      !item.planningGuide &&
                      getStep(item, _.orderBy(manuscript[item.id], ['type', 'step'], ['desc', 'desc'])[0]) === 1
                    "
                    flat
                    tile
                    outlined
                    color="rouge"
                    @click="planningGuideDialog(item, manuscript)"
                    style="width: 100%"
                    >기획안 가이드 보내기</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </td>
        </template>
      </v-data-table>
      <div class="text-center" style="background-color: white">
        <v-pagination
          v-model="options.page"
          :length="Math.floor((parseInt(propositionsFiltered.length) - 1) / options.itemsPerPage + 1)"
          :total-visible="10"></v-pagination>
      </div>
    </div>
    <v-row justify="center" align="center">
      <v-dialog v-model="openManuscriptReqDialog" content-class="white-two" max-width="500px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openManuscriptReqDialog = false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="pb-2">
            <v-row class="subheading" style="color: #484848">
              {{ (currentManuscript || {}).type === 0 ? '기획안 열람하기 (초안)' : '가편집 영상 보기 (초안)' }}
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="body-1 mb-2">
              <v-col cols="12" class="my-2">
                <v-btn
                  rounded
                  flat
                  color="rouge white--text"
                  style="font-size: 13px"
                  :href="currentManuscript.manuscript"
                  target="_blank"
                  >{{ (currentManuscript || {}).type === 0 ? '기획안 보기' : '영상 보기' }}</v-btn
                >
              </v-col>
              <v-col
                class="text-decoration-underline rouge--text caption"
                @click="
                  openManuscriptReqDialog = false;
                  openManuscriptReReqDialog = true;
                "
                >잘못된 링크가 등록되었나요?</v-col
              >
            </v-row>
            <v-row class="subheading mt-4" style="color: #484848">
              {{ currentManuscriptStatus == 0 ? '요청 보내기' : '보낸 요청' }}
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              {{
                currentManuscriptStatus == 0
                  ? (currentManuscript || {}).type === 0
                    ? '기획안 수정 요청사항을 적어주세요.'
                    : '영상 수정 요청사항을 적어주세요.'
                  : '유튜버가 요청을 확인중입니다.'
              }}
              {{
                (currentManuscript || {}).type === 1
                  ? '(수정 요청 ' +
                    ((this._.find(this.propositions, ['id', currentManuscript.propositionId]) || {}).maxEditCount -
                      currentManuscript.step) +
                    '회 남음)'
                  : ''
              }}
            </v-row>
            <v-row class="my-2">
              <v-radio-group
                v-if="currentManuscriptStatus === 0"
                v-model="(currentManuscript || {}).status"
                row
                :mandatory="true"
                class="radio_group"
                :disabled="currentManuscriptStatus != 0"
                hide-details>
                <v-radio
                  v-if="enableRequest"
                  :label="
                    (currentManuscript || {}).type === 0 ? '기획안 수정을 요청합니다.' : '영상 수정을 요청합니다.'
                  "
                  value="1"
                  class="radio pl-1"
                  color="rouge"></v-radio>
                <v-radio
                  :label="(currentManuscript || {}).type === 0 ? '기획안을 승인합니다.' : '영상 업로드를 승인합니다.'"
                  :value="(currentManuscript || {}).type === 0 ? 3 : 2"
                  class="radio pl-1"
                  color="rouge"></v-radio>
              </v-radio-group>
              <span v-else style="color: #757575; font-size: 14px; font-weight: 700">{{
                currentManuscriptStatus === 1 ? '수정 요청 내용' : '업로드 요청 내용'
              }}</span>
            </v-row>
            <v-row>
              <v-textarea
                v-if="(currentManuscript || {}).status == 1"
                v-model="(currentManuscript || {}).request"
                :readonly="currentManuscriptStatus != 0"
                name="request"
                type="request"
                v-validate="'required|min:10'"
                :rules="[errors.first('request') || !errors.has('request')]"
                :hint="
                  (currentManuscript || {}).status === 1
                    ? '요청사항을 입력해주세요.'
                    : '전달할 메시지가 있으실 경우 함께 입력해주세요.'
                "
                outlined
                hide-details></v-textarea>
              <v-textarea
                v-else
                v-model="(currentManuscript || {}).request"
                :readonly="currentManuscriptStatus != 0"
                outlined></v-textarea>
            </v-row>
            <v-row v-if="(currentManuscript || {}).status === 2 && (currentManuscript || {}).type === 1">
              <v-checkbox
                v-model="setUploadRequestDate"
                label="업로드 날짜 및 시간 지정"
                @change="initUploadRequestDate"></v-checkbox>
            </v-row>
            <v-row
              v-if="
                (currentManuscript || {}).status === 2 && (currentManuscript || {}).type === 1 && setUploadRequestDate
              ">
              <v-menu
                ref="uploadRequestDateMenu"
                v-model="uploadRequestDateMenu"
                :close-on-content-click="false"
                :return-value.sync="uploadRequestDate"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="$moment(uploadRequestDate || new Date()).format('YYYY. MM. DD. dddd')"
                    class="mr-2"
                    outlined
                    dense
                    append-icon="mdi-menu-down"
                    v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="uploadRequestDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="uploadRequestDateMenu = false"> 취소 </v-btn>
                  <v-btn text color="primary" @click="$refs.uploadRequestDateMenu.save(uploadRequestDate)">
                    확인
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="uploadRequestTimeMenu"
                v-model="uploadRequestTimeMenu"
                :close-on-content-click="false"
                :return-value.sync="uploadRequestTime"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      $moment(
                        (uploadRequestDate || $moment().format('YYYY-MM-DD')) + ' ' + (uploadRequestTime || '')
                      ).format('LT')
                    "
                    outlined
                    dense
                    append-icon="mdi-menu-down"
                    v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-time-picker
                  v-if="uploadRequestTimeMenu"
                  v-model="uploadRequestTime"
                  @click:minute="$refs.uploadRequestTimeMenu.save(uploadRequestTime)">
                </v-time-picker>
              </v-menu>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openManuscriptReqDialog = false">닫기</v-btn>
            <v-btn
              tile
              class="rouge white-two--text"
              :disabled="currentManuscriptStatus != 0"
              @click="manuscriptRequest()"
              >요청 발송</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openManuscriptReReqDialog" content-class="white-two" max-width="500px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openManuscriptReReqDialog = false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="pb-2">
            <v-row class="subheading" style="color: #484848"> 링크 재등록 요청 </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="subheading mt-4" style="color: #484848"> 요청 사유 </v-row>
            <v-row>
              <v-select
                v-model="(currentManuscript || {}).reRequest"
                :items="[
                  '링크 열람 권한이 없습니다.',
                  '내용이 비어있는 링크가 등록되었습니다.',
                  '다른 업체의 링크가 등록되었습니다.',
                  '존재하지 않는 페이지입니다.',
                ]"
                class="pa-0 my-2"
                hide-details
                outlined
                flat></v-select>
            </v-row>
            <v-row style="color: #757575">
              링크가 잘못 등록된 경우에만 재등록 요청이 가능합니다. 사유가 위 목록에 없는 경우 고객센터로 연락해주세요.
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openManuscriptReReqDialog = false">닫기</v-btn>
            <v-btn tile class="rouge white-two--text" @click="manuscriptReRequest()">요청 발송</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openPlanningGuideDialog" content-class="white-two" max-width="500px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openPlanningGuideDialog = false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="pb-2">
            <v-row class="subheading" style="color: #484848"> 기확안 가이드 보내기 </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="mt-4" style="color: #484848"> 기획안 작성시 참고할 가이드 문서의 URL을 입력해주세요. </v-row>
            <v-row>
              <v-text-field
                v-model="(currentProposition || {}).planningGuide"
                flat
                outlined
                v-validate="'required'"></v-text-field>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openPlanningGuideDialog = false">닫기</v-btn>
            <v-btn tile class="rouge white-two--text" @click="sendPlanningGuide()">보내기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openRateDialog" content-class="white-two" max-width="400px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openRateDialog = false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="subheading" style="color: #484848"> 리뷰 평가하기 </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col class="review-stars">
                <v-icon v-for="(val, idx) in isActive" @mouseover="mouseOver(idx)" :key="idx">{{
                  val ? 'mdi-star' : 'mdi-star-outline'
                }}</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openRateDialog = false">취소</v-btn>
            <v-btn tile class="rouge white-two--text" @click="rate()">평가하기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <YoutuberDetail v-model="openYoutuberDetail" :openYoutuber="openYoutuber"></YoutuberDetail>
    </v-row>
  </div>
</template>

<script>
import YoutuberDetail from '@/components/campaign/dialog/YoutuberDetail';

export default {
  name: 'Videoinfo',
  props: ['campaign', 'propositions'],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver.svg'),
    instaIcon: () => import('@/assets/img/campaign/insta.svg'),
    youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
    YoutuberDetail,
  },
  data() {
    return {
      headers_desktop: [
        { text: '채널', value: 'channel' },
        { text: '채널 정보', value: 'Youtuber.account', width: '200px' },
        { text: '수락 일자', value: 'responsedAt' },
        { text: '진행 단계', value: 'manuStatus' },
        { text: '수정 요청 횟수', value: 'maxEditCount' },
        { text: '수정∙업로드 요청', value: 'request' },
        { text: '영상 등록 상태', value: 'reviewStatus' },
        { text: '영상 등록 일자', value: 'reviewRegisteredAt' },
        { text: '영상 평가', value: 'reviewRate' },
        { text: '', value: 'data-table-expand' },
      ],
      headers_mobile: [
        { text: '채널', value: 'channel' },
        { text: '채널 정보', value: 'Youtuber.account', width: '200px' },
        { text: '수락 일자', value: 'responsedAt' },
      ],
      options: { page: 1, itemsPerPage: 20 },
      expanded: [],
      manuscript: {},
      openManuscriptReqDialog: false,
      openManuscriptReReqDialog: false,
      currentManuscript: {},
      enableRequest: true,
      openRateDialog: false,
      isActive: [false, false, false, false, false],
      rateProposition: {},
      openYoutuber: {},
      openYoutuberDetail: false,
      currentManuscriptStatus: 0,
      setUploadRequestDate: false,
      uploadRequestDateMenu: false,
      uploadRequestDate: null,
      uploadRequestTimeMenu: false,
      uploadRequestTime: null,
      openPlanningGuideDialog: false,
      currentProposition: {},
    };
  },
  computed: {
    headers() {
      // return this.headers_desktop;
      return this.headers_desktop;
    },
    propositionsFiltered() {
      let filtered = this.propositions.filter(item => item.status === 2 || (item.status >= 10 && item.status < 50));
      return this._.orderBy(filtered, ['responsedAt'], ['desc']);
    },
  },
  watch: {
    propositions(value) {
      this.getManuscripts(value);
    },
  },
  methods: {
    manuscriptHistory(id) {
      let histories = this._.orderBy(this.manuscript[id], ['type', 'step'], ['desc', 'desc']);
      histories.forEach(history => {
        if (history.status > 0) {
          let newHistory = this._.cloneDeep(history);
          newHistory.status = 0;
          histories.push(newHistory);
        }
        if (history.status === 3 && history.type === 1) {
          let newHistory = this._.cloneDeep(history);
          newHistory.status = 2;
          histories.push(newHistory);
        }
      });
      histories = this._.orderBy(histories, ['type', 'step', 'status'], ['desc', 'desc', 'desc']);
      return histories;
    },
    manuscriptDialog(manuscripts, step, type) {
      // this.openManuscriptReqDialog=false;
      this.openManuscriptReqDialog = true;
      this.currentManuscript = JSON.parse(
        JSON.stringify(manuscripts.filter(manuscript => manuscript.step === step)[0])
      );
      this.currentManuscriptStatus = this.currentManuscript.status;
      this.enableRequest =
        type === 0 || step < this._.find(this.propositions, ['id', this.currentManuscript.propositionId]).maxEditCount;
    },
    manuscriptReqDialog(manuscripts, step, type) {
      this.openManuscriptReqDialog = true;
      this.currentManuscript = JSON.parse(
        JSON.stringify(manuscripts.filter(manuscript => manuscript.step === step)[0])
      );
      this.currentManuscriptStatus = this.currentManuscript.status;
      this.enableRequest =
        type === 0 || step < this._.find(this.propositions, ['id', this.currentManuscript.propositionId]).maxEditCount;
    },
    async manuscriptRequest() {
      let success = await this.$validator.validateAll();
      if (success) {
        this.openManuscriptReqDialog = false;
        await this.axios.put('/proposition/manuscript', {
          id: this.currentManuscript.id,
          step: this.currentManuscript.step,
          status: this.currentManuscript.status,
          request: this.currentManuscript.request,
          editRequestedAt: new Date(),
        });

        let proposition = {};
        if (this.currentManuscript.status === 2 && this.currentManuscript.type === 1) {
          proposition.status = 30;
        } else if (
          this.campaign.category.firstName === '매장' &&
          this.currentManuscript.type === 0 &&
          this.currentManuscript.status === 3
        ) {
          proposition.status = 12;
        } else {
          proposition.status = 22;
        }

        if (this.uploadRequestDate || this.uploadRequestTime) {
          proposition.uploadRequestDate = this.$moment(
            (this.uploadRequestDate || this.$moment().format('YYYY-MM-DD')) + ' ' + (this.uploadRequestTime || '')
          ).toDate();
        }
        console.log(proposition.uploadRequestDate, this.uploadRequestDate, this.uploadRequestTime);
        await this.axios.put('/campaign/proposition?id=' + this.currentManuscript.propositionId, proposition);
      }
    },
    async manuscriptReRequest() {
      this.openManuscriptReReqDialog = false;
      await this.axios.put('/proposition/manuscript', {
        id: this.currentManuscript.id,
        status: 4,
        reRequest: this.currentManuscript.reRequest,
        reRequestedAt: new Date(),
      });

      let proposition = {};
      proposition.status = 22;

      if (this.uploadRequestDate || this.uploadRequestTime) {
        proposition.uploadRequestDate = this.$moment(
          (this.uploadRequestDate || this.$moment().format('YYYY-MM-DD')) + ' ' + (this.uploadRequestTime || '')
        ).toDate();
      }

      await this.axios.put('/campaign/proposition?id=' + this.currentManuscript.propositionId, proposition);
    },
    mouseOver(idx) {
      this.isActive.forEach((value, n) => {
        if (n > idx) {
          this.$set(this.isActive, n, false);
        } else {
          this.$set(this.isActive, n, true);
        }
      });
    },
    rate() {
      let star = 0;
      this.openRateDialog = false;
      this.isActive.forEach((value, n) => {
        if (value) star++;
        this.$set(this.isActive, n, false);
      });
      this.axios.put('/campaign/proposition?id=' + this.rateProposition.id, {
        status: 40,
        rating: star,
        ratedAt: new Date(),
      });
    },
    getManuscripts(value) {
      let query = '';
      value.map((proposition, idx) => {
        query += 'propositionId=' + proposition.id;
        if (idx !== value.length - 1) {
          query += ' OR ';
        }
      });

      this.axios
        .get('/proposition/manuscript?orderBy=step&direction=desc&query=' + encodeURIComponent(query))
        .then(scripts => {
          this.manuscript = this._.groupBy(
            this._.orderBy(scripts.data.manuscripts, ['type', 'step'], ['desc', 'desc']),
            'propositionId'
          );
        })
        .catch(err => {
          console.log(err);
        });
    },
    async youtuberDetailDlg(item) {
      this.openYoutuberDetail = true;
      this.openYoutuber = item;
    },
    getStep(proposition, manuscript) {
      if ((proposition.status || 0) > 21 && (proposition.status || 0) < 33 && manuscript) {
        if (manuscript.type == 0) {
          return 2;
        } else {
          if (manuscript.status == 2 || manuscript.status == 3) {
            return 4;
          } else {
            return 3;
          }
        }
      } else {
        return 1;
      }
    },
    initUploadRequestDate(checked) {
      if (checked) {
        this.uploadRequestDate = this.$moment().format('YYYY-MM-DD');
      } else {
        this.uploadRequestDate = null;
        this.uploadRequestTime = null;
      }
    },
    planningGuideDialog(item, manuscript) {
      this.openPlanningGuideDialog = true;
      this.currentProposition = item;
      this.currentManuscript = manuscript;
    },
    async sendPlanningGuide() {
      this.openPlanningGuideDialog = false;
      await this.axios.put('/campaign/proposition?id=' + this.currentProposition.id, {
        planningGuide: this.currentProposition.planningGuide,
      });
    },
  },
  async mounted() {
    this.getManuscripts(this.propositions);
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}

::v-deep .v-stepper.v-stepper--alt-labels.theme--light {
  box-shadow: none;
}

.radio_group {
  margin-top: 0px;
}

.radio {
  border: 1px solid;
  border-radius: 8px;
  max-width: 214px;
  height: 40px;
  margin-top: 0px;
  width: 214px;
}

.radio.v-item--active {
  border-color: var(--rouge-color);
}
</style>
