import Vue from 'vue';

Vue.directive('lowercase', {
  inserted: function (el, _, vnode) {
    el.addEventListener('input', async function (e) {
      e.target.value = e.target.value.toLowerCase();
      vnode.componentInstance.$emit('input', e.target.value.toLowerCase());
    });
  },
});
