<template>
  <div>
    <v-container container--fluid grid-list-lg>
      <v-col>
        <v-col class="pa-2">
          <v-row>
            <v-col class="hidden-sm-and-down" md="3"> </v-col>
            <v-col md="9" :class="$vuetify.breakpoint.smAndDown ? 'px-4' : ''">
              <v-row>
                <v-col cols="12" md="8">
                  <Header></Header>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  v-if="
                    $route.path !== '/company/dashboard' &&
                    $route.path !== '/company/editinfo' &&
                    $route.path !== '/company/pay' &&
                    $route.path !== '/company/settlement' &&
                    $route.path !== '/company/charge'
                  ">
                  <div class="align-select">
                    <v-col>
                      <v-col class="text-right caption mb-0 pb-0">
                        <v-btn text class="ma-0 pa-0" @click="changeCompany(-1)">
                          <v-icon small class="px-1">add_circle_outline</v-icon
                          >{{ companyTemp.step > 0 ? '광고 이어서 등록하기' : '새 광고 추가' }}
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-select
                          :items="items"
                          item-text="name"
                          item-value="id"
                          :value="((items || [])[$store.getters.companyIdx] || {}).id"
                          height="40px"
                          :hide-details="true"
                          outlined
                          solo
                          flat
                          single-line
                          @change="changeCompany">
                          <template slot="selection" slot-scope="data">
                            <v-row align="center">
                              <span v-if="data.item.id === -1" class="body-1"
                                ><v-icon>$vuetify.icons.plus</v-icon>&nbsp;&nbsp;</span
                              >
                              <span class="body-1">{{ data.item.name }}</span>
                            </v-row>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-row align="center" style="min-height: 48px">
                              <span v-if="data.item.id === -1" class="body-1"
                                ><v-icon>$vuetify.icons.plus</v-icon>&nbsp;&nbsp;</span
                              >
                              <span class="body-1">{{ data.item.name }}</span>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col md="3" class="pa-2 hidden-sm-and-down">
              <v-navigation-drawer
                mobile-breakpoint="960"
                width="238"
                permanent
                floating
                style="background-color: transparent">
                <v-list class="pa-0">
                  <v-list-item
                    v-for="item in list1"
                    active-class="rouge--text"
                    :class="item.class"
                    :key="item.title"
                    :to="item.path">
                    <v-list-item-content class="font-weight-bold body-1">
                      <v-list-item-title class="text-center">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list class="pa-0 mt-1">
                  <v-list-item
                    v-for="item in list2"
                    active-class="rouge--text"
                    :class="item.class"
                    :key="item.title"
                    :to="item.path">
                    <v-list-item-content class="font-weight-bold body-1">
                      <v-list-item-title class="text-center">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div class="matchingLink mt-12 pa-1">
                  <a href="http://www.superchart.com" target="_blank"
                    ><img class="mt-4" style="width: 100%" src="@/assets/img/members_to_chart.png"
                  /></a>
                </div>
              </v-navigation-drawer>
            </v-col>
            <v-col class="pa-2" md="9">
              <router-view
                :class="
                  $route.path !== '/company/modads' &&
                  $route.path !== '/company/report' &&
                  $route.path !== '/company/settlement' &&
                  $route.path !== '/company/pay' &&
                  $route.path !== '/company/charge' &&
                  $route.path !== '/company/contract'
                    ? 'content-page'
                    : ''
                "></router-view>
              <v-row class="mt-4 body-2" style="color: #c2c2c2">
                <v-spacer></v-spacer>
                {{ appVersion }}
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-container>
  </div>
</template>

<script>
// import firebase from '@/config/firebase';
import '@firebase/firestore';

import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';
import Header from '@/components/company/Header';
import { lowerCase } from 'lodash';

export default {
  name: 'Company',
  mixins: [userMixin, companyMixin],
  components: { Header },
  data() {
    return {
      list1: [
        // {title: '알림현황', path: '/company/dashboard', class: 'item', hide: false},
        {
          title: '마케팅 보고서',
          path: '/company/report',
          class: 'item',
          hide: false,
        },
        {
          title: '이용 관리',
          path: '/company/contract',
          class: 'item',
          hide: false,
        },
        {
          title: '광고 관리',
          path: '/company/modads',
          class: 'item',
          hide: false,
        },
      ],
      list2: [
        {
          title: '결제 관리',
          path: '/company/pay',
          class: 'item',
          hide: false,
        },
        {
          title: '정산 관리',
          path: '/company/settlement',
          class: 'item',
          hide: false,
        },
        // {title: '충전금액 관리', path: '/company/charge', class: 'item', hide: true},
        {
          title: '담당자 정보수정',
          path: '/company/editinfo',
          class: 'item',
          hide: false,
        },
        { title: '로그아웃', path: '/logout', class: 'item', hide: false },
      ],
    };
  },
  computed: {
    appVersion() {
      return 'build ' + process.env.VUE_APP_VERSION;
    },
    items() {
      const items = JSON.parse(JSON.stringify(this.$store.getters.companies)) || [];
      // const label = this.companyTemp.step > 0 ? '광고 이어서 등록하기' : '광고 등록하기';

      // items.push({
      //   name: label,
      //   id: -1
      // });
      return items;
    },
  },
  watch: {
    $route(to) {
      window.dataLayer.push({
        event: 'ceo_category_info',
        ceoCategory: lowerCase(to.name),
      });

      this.setListClass(to.path);
      // this.generateNavigationLinks()
    },
    companies() {
      if (this._.isEmpty(this.companies)) {
        this.$router.push({ name: 'Regads' });
      }

      if (!this._.isEmpty(this.companies) && !this._.isEmpty(this.user)) {
        // this.companies.forEach((company) => {
        //   firebase.app
        //     .firestore()
        //     .collection('company3')
        //     .doc(company.id + '')
        //     .onSnapshot((snapshot) => {
        //       let company = snapshot.data();
        //       if (
        //         this.$moment
        //           .tz('Asia/Seoul')
        //           .diff(
        //             company.updatedAt ? company.updatedAt.toDate() : null,
        //             'seconds'
        //           ) < 5
        //       ) {
        //         location.reload();
        //       }
        //     });
        // });
      }
    },
  },
  created() {
    const hasProductItem = this.items.some(v => v.category.firstName === '제품');
    this.list2[2].hide = !hasProductItem;
  },
  methods: {
    changeCompany(id) {
      if (id === -1) {
        this.$router.push({ name: 'Regads' });
        // this.$router.go('/regads')
        // this.$router.replace('/regads');
      } else {
        this.companies.some((company, idx) => {
          if (company.id === id) {
            this.$store.commit('setCompanyIdx', idx);
            return true;
          }
          return false;
        });
        this.$store.dispatch('getCompanyInfo', { email: this.user.email });
      }
    },
    setListClass(path) {
      // if (path === '/company/bankaccount') {
      // }
      this.list1.forEach((item, idx) => {
        if (item.path === path) {
          if (idx < this.list1.length - 1) {
            this.list1[idx + 1].class = 'item item_active';
          }
        } else {
          if (idx < this.list1.length - 1) {
            this.list1[idx + 1].class = 'item';
          }
        }
      });
      this.list2.forEach((item, idx) => {
        if (item.path === path) {
          if (idx < this.list2.length - 1) {
            this.list2[idx + 1].class = 'item item_active';
          }
        } else {
          if (idx < this.list2.length - 1) {
            this.list2[idx + 1].class = 'item';
          }
        }
      });
    },
    isMatching() {
      return process.env.VUE_APP_TARGET === 'campaign' || location.href.indexOf('superchart') > -1;
    },
    // onLogout() {
    //   this.$store.dispatch('logout');
    //   this.$router.push('/')
    // }
  },
  async mounted() {
    this.companies.some(company => {
      if (company.category.firstName === '제품') {
        this.list2[1].hide = false;
        return true;
      }
      this.list2[1].hide = true;
      return false;
    });

    this.list1 = this.list1.filter(item => {
      return !item.hide;
    });

    this.list2 = this.list2.filter(item => {
      return !item.hide;
    });

    this.setListClass(this.$route.path);

    if (this._.isEmpty(this.companies) && !this.isMatching() && this.userIsAuthenticated) {
      await this.$store.dispatch('getCompanyInfo', { email: this.user.email });
      window.setTimeout(async () => {
        if (this._.isEmpty(this.companies) && !this.isMatching()) {
          this.$router.push({ name: 'Regads' });
        }
      }, 3000);
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/custom';

::v-deep .item.v-list-item--active:before {
  background-color: #ffffff;
}

::v-deep .item.v-list-item {
  border: 1px solid #9b9b9b;
  border: 1px solid var(--default-light-color);
  background-color: #f7f7f6;
  border-bottom: none;
  box-sizing: border-box;
  color: #000000;
  color: var(--default-color);
}

::v-deep .item:last-child.v-list-item {
  border: 1px solid #9b9b9b;
  border: 1px solid var(--default-light-color);
  background-color: #f7f7f6;
  box-sizing: border-box;
  color: #000000;
  color: var(--default-color);
}

::v-deep .item.item_active.v-list-item {
  border-top: none;
}

::v-deep .item.v-list-item--active,
::v-deep .item:last-child.v-list-item--active {
  border: 1px solid #bd1328;
  border: 1px solid var(--rouge-color);
  background-color: #fff;
}

::v-deep .item .v-list-item__title {
  font-size: 14px;
}

::v-deep .content-page {
  border: solid 1px #979797;
  border: solid 1px var(--border-color);
}

::v-deep .v-text-field--outlined.v-text-field--single-line .v-input__slot {
  min-height: 44px;
}

::v-deep .v-select__selections span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 170px;
}

.align-select {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.matchingLink {
  width: 225px;
  height: 233px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
</style>
