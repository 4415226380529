<script>
// import Vue from 'vue'
import { Line } from 'vue-chartjs';

import companyMixin from '../../shared/companyMixin';

export default {
  name: 'KeywordChart',
  extends: Line,
  mixins: [companyMixin],
  props: ['data', 'labels'],
  watch: {
    labels() {
      this.renderChart(
        {
          labels: this.labels.map(label => {
            return label.replace(/-/g, '/').substr(5, 5);
          }),
          datasets: this.data,
        },
        {
          responsive: !0,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: !0,
                  maintainAspectRatio: !0,
                  reverse: true,
                },
              },
            ],
          },
          elements: {
            line: {
              tension: 0,
            },
          },
        }
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
