export default {
  computed: {
    campaign() {
      return this.$store.getters.campaign;
    },
    campaigns() {
      return this.$store.getters.campaigns;
    },
    naverCampaigns() {
      return this.$store.getters.campaigns.filter(
        campaign => campaign.naver || (campaign.channel || 'naver') === 'naver'
      );
    },
    youtubeCampaigns() {
      return this.$store.getters.campaigns.filter(campaign => campaign.youtube || campaign.channel === 'youtube');
    },
    campaignIdx() {
      return this.$store.getters.campaignIdx;
    },
    campaignTemp() {
      return this.$store.getters.campaignTemp;
    },
    campaignRegStep() {
      return this.$store.getters.campaignRegStep;
    },
  },
  methods: {
    toIsoString(date) {
      let tzo = -date.getTimezoneOffset();
      let dif = tzo >= 0 ? '+' : '-';
      let pad = function (num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
      return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ':' +
        pad(tzo % 60)
      );
    },
  },
};
