import Vue from 'vue';

export default {
  state: {
    companies: [],
    companyIdx: 0,
    companyTemp: {},
    companyRegStep: 1,
  },
  mutations: {
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setCompanyIdx(state, payload) {
      state.companyIdx = payload;
    },
    setCompanyTemp(state, payload) {
      state.companyTemp = payload;
    },
    clearCompanyTemp(state) {
      state.companyTemp = {};
    },
    setCompanyRegStep(state, payload) {
      state.companyRegStep = payload;
    },
  },
  actions: {
    async getCompanyInfo({ commit, rootState }, payload) {
      commit('clearError');
      if (!((rootState.user || {}).email || (payload || {}).email)) {
        return null;
      }
      try {
        let data = await Vue.axios.get(
          '/company3?email=' + ((rootState.user || {}).email || payload.email) + '&orderBy=id&direction=desc'
        );
        commit('clearCompanyTemp');
        let companies = data.data.companies.filter(company => {
          company.id *= 1;
          if (company.is_verified === 99) {
            // company.step
            commit('setCompanyTemp', company);
            return false;
          } else {
            return true;
          }
        });
        companies = Vue._.sortBy(companies, [
          function (com) {
            return ((com.naver || {}).available || 0.1) + ((com.insta || {}).available || 0.1);
          },
        ]);

        commit('setCompanies', companies || []);
        if (rootState.companyIdx >= companies.length || !rootState.companyIdx === undefined) {
          commit('setCompanyIdx', 0);
        }
        return payload;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    },
    async signCompanyUp({ commit, rootState, dispatch }, payload) {
      commit('clearError');
      try {
        await Vue.axios.post('/company3', payload);
        dispatch('getCompanyInfo', payload);
        // commit('setCompanyIdx', rootState.companies.length - 1)

        Vue.axios.post('/utils/sendmail', {
          from: '슈퍼멤버스 <cs@supermembers.co.kr>',
          to: (rootState.user || {}).email || payload.email,
          subject: '슈퍼멤버스에 오신 것을 환영합니다.',
          template: 'companyWelcome',
          username: payload.name,
        });
        return payload;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    },
  },
  getters: {
    company(state) {
      return state.companies[state.companyIdx || 0];
    },
    companies(state) {
      return state.companies;
    },
    companyIdx(state) {
      return state.companyIdx;
    },
    companyTemp(state) {
      return state.companyTemp;
    },
    companyRegStep(state) {
      return state.companyRegStep;
    },
  },
};
