<template>
  <div class="whiteBg">
    <div class="container row2">
      <div class="text">
        <h2 class="font-weight-bold">블로그 리뷰 + 추가 미션 설정</h2>
        <p class="description">
          매장 마케팅, 제품 마케팅 모두 블로그 리뷰에 추가로<br />
          인지도를 위한 리뷰를 요청할 수 있습니다.
        </p>
      </div>
      <div style="display: flex" class="tap">
        <div
          style="
            width: 60px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            cursor: pointer;
            margin-right: 20px;
            padding-bottom: 4px;
          "
          :style="selectedTab === 0 ? 'color: #EA2A3A; box-shadow: 0 4px 2px -2px #EA2A3A;' : 'color: #212121;'"
          @click="onChangeTab(0)">
          매장
        </div>
        <div
          style="
            width: 60px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: center;
            cursor: pointer;
            padding-bottom: 4px;
          "
          :style="selectedTab === 1 ? 'color: #EA2A3A; box-shadow: 0 4px 2px -2px #EA2A3A;' : 'color: #212121;'"
          @click="onChangeTab(1)">
          제품
        </div>
      </div>
      <div class="guide" v-html="guideContent"></div>
      <img :src="selectedTab === 0 ? storeImage : productImage" class="row-image mx-2" />
    </div>
  </div>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Row3',
  mixins: [userMixin],
  data() {
    return {
      storeImage:
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/recieptReview.png')
          : require('@/assets/img/landing/ceo-landing/recieptReview-mobile.png'),
      productImage:
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/storeReview.png')
          : require('@/assets/img/landing/ceo-landing/storeReview-mobile.png'),
      selectedTab: 0,
      guideContent: '블로거에게 블로거 리뷰에 추가로 <span class="mobile"></span> 구글 리뷰를 요청할 수 있어요.',
    };
  },
  methods: {
    onChangeTab(index) {
      this.selectedTab = index;
      this.guideContent =
        index === 0
          ? '블로거에게 블로거 리뷰에 추가로 <span class="mobile"></span> 구글 리뷰를 요청할 수 있어요.'
          : '블로거에게 블로거 리뷰에 추가로 <span class="mobile"></span> 쇼핑몰 리뷰를 요청할 수 있어요.';
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.storeImage =
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/recieptReview.png')
          : require('@/assets/img/landing/ceo-landing/recieptReview-mobile.png');

      this.productImage =
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/storeReview.png')
          : require('@/assets/img/landing/ceo-landing/storeReview-mobile.png');
    });
  },
};
</script>

<style scoped lang="scss">
.row2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 72px 0;
  @media (max-width: 960px) {
    padding: 80px 0px 56px;
    overflow: hidden;
  }
}

.tap {
  margin-top: 48px;
  margin-bottom: 32px;

  @media (max-width: 960px) {
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

.description {
  color: #484848;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;

  @media (max-width: 960px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.row2 .row-image {
  width: 707px;
  margin: 12px 0 0 0;
  @media (max-width: 960px) {
    max-width: 400px;
    margin: 16px auto;
    width: 100%;
    text-align: center;
  }
}
.text {
  .eyebrow {
    color: #757575;
    margin-bottom: 4px;
    margin-left: 1px;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 12px;
    color: #212121;
  }
  p {
    color: #484848;
    line-height: 1.8;
  }
  @media (max-width: 960px) {
    // padding-top: 0;
    // margin-bottom: auto;
    .eyebrow {
      margin-bottom: 6px;
      font-size: 13px;
    }
    h2 {
      margin-bottom: 12px;
    }
    p {
      color: #484848;
      line-height: 1.94;
    }
  }
}

.rank-button {
  background: #ffffff;
  border: 2px solid #bd1328;
  border-radius: 16px;
  color: #bd1328;
  font-size: 14px;
}

.whiteBg {
  background-color: #fbfbfb;
}

.guide {
  color: #484848;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;

  @media (max-width: 960px) {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
