<template>
  <v-col class="mt-1">
    <v-col class="shades transparent pa-6">
      <v-row class="px-0">
        <v-col cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">
            서비스를 한 줄로 소개해주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-text-field
            v-model="form.abstraction"
            name="abstraction"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('abstraction') || !errors.has('abstraction')]"
            placeholder="서비스 한 줄 소개"
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">
            서비스의 특징과 자랑을 적어주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-textarea
            v-model="form.introduction"
            name="introduction"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('introduction') || !errors.has('introduction')]"
            placeholder="서비스 상세 소개"
            @input="checkDone"
            outlined
            solo
            flat></v-textarea>
        </v-col>
        <v-col cols="12" md="9" v-if="camData.type === 'T'">
          <span class="label mb-2">혜택 제공 방식을 선택해주세요.</span>
          <v-menu v-model="menuDeliveryMethod" :close-on-content-click="false" :max-width="400" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="#4770BD" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <v-card>
              <v-card-title>
                <div>
                  <v-icon color="default" @click="menuDeliveryMethod = false">close</v-icon><br />
                  <h2 class="mt-2 font-weight-bold subheading">혜택 제공 방식 안내</h2>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="body-2 font-weight-bold">무료 제공</div>
                <div>회원이 매장에 방문하여 제공 혜택을 무료로 이용합니다.</div>
                <div class="mt-4 body-2 font-weight-bold">구매 지원금 지급</div>
                <div>
                  회원이 매장을 방문하기 전 네이버 예약등을 통해 온라인에서 직접 결제해야 하는 경우 선택해주세요. 제안을
                  보낼 때 제안금액과 구매 지원금을 합한 금액이 결제됩니다.
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="rouge white-two--text" tile @click="menuDeliveryMethod = false"> 닫기 </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-radio-group
            v-model="form.deliveryMethod"
            row
            :mandatory="true"
            class="radio_group"
            @change="checkDone"
            :hint="
              form.deliveryMethod == 'F'
                ? '서비스 혜택을 직접 발송합니다. 회원에게 제품을 보낸 후 발송 완료 처리를 해주시면 원고/리뷰 작성이 시작됩니다.'
                : '회원이 자사몰에서 서비스 혜택을 직접 구매할 수 있도록 구매 지원금을 지급합니다. 회원에게 광고 제안을 보낼 때 광고 제안 금액과 구매 지원금이 함께 결제됩니다.'
            "
            persistent-hint
            disabled>
            <v-radio label="무료 제공" value="F" class="radio pl-2" color="rouge"></v-radio>
            <v-radio label="구매 지원금 지급" value="PR" class="radio pl-2" color="rouge"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label mt-6 mb-2">
            회원이 쇼핑몰 혜택을 구매할 쇼핑몰 페이지 주소를 입력해주세요.<span class="caption rouge--text">
              *필수</span
            >
          </div>
          <v-text-field
            v-model="form.storeUrl"
            name="storeUrl"
            type="text"
            v-validate="'min:2'"
            :rules="[errors.first('storeUrl') || !errors.has('storeUrl')]"
            placeholder="https//"
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            hide-details></v-text-field>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label mt-6 mb-2">자사몰에서 직접 구매시 필수 요구사항을 정해주세요.</div>
          <v-row>
            <v-checkbox
              v-model="form.requirement"
              @change="checkDone"
              label="텍스트 리뷰"
              color="rouge"
              value="text"
              hide-details
              class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
            <v-checkbox
              v-model="form.requirement"
              @change="checkDone"
              label="포토 리뷰"
              color="rouge"
              value="photo"
              hide-details
              class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
            <v-checkbox
              v-model="form.requirement"
              @change="checkDone"
              label="동영상 리뷰"
              color="rouge"
              value="video"
              hide-details
              class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
            <v-checkbox
              v-model="form.requirement"
              @change="checkDone"
              label="스토어 찜(스마트스토어)"
              color="rouge"
              value="zzimStore"
              hide-details
              class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
            <v-checkbox
              v-model="form.requirement"
              @change="checkDone"
              label="상품 찜(스마트스토어)"
              color="rouge"
              value="zzimProduct"
              hide-details
              class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
          </v-row>
        </v-col>
        <v-col class="mt-6" cols="12" md="10" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label mb-2">자사몰 리뷰에 공정위 문구 필수 삽입 여부를 선택해주세요.</div>
          <v-btn-toggle v-model="form.ftcRequired" @change="checkDone" mandatory>
            <v-btn
              :value="1"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              >예</v-btn
            >
            <v-btn
              :value="0"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              >아니오</v-btn
            >
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.deliveryMethod == 'PR' && form.ftcRequired && camData.type === 'T'">
          <div class="label mt-6 mb-2">쇼핑몰 리뷰에 들어가는 공정위 문구를 입력해주세요.</div>
          <v-text-field
            v-model="form.ftcText"
            name="ftcText"
            type="text"
            v-validate="'min:2'"
            :rules="[errors.first('ftcText') || !errors.has('ftcText')]"
            placeholder="제품을 무상으로 지원받아 작성한 솔직한 후기입니다."
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            hide-details></v-text-field>
        </v-col>
        <v-col cols="12" class="my-4" v-if="camData.type === 'T'">
          <div class="label">혜택 제공을 위해 슈퍼차트 회원에게 입력받아야 하는 정보를 선택해주세요.</div>
          <v-row>
            <v-col class="mt-2" cols="12" md="9">
              <v-row>
                <v-checkbox
                  v-if="form.deliveryMethod !== 'PR'"
                  v-model="form.delivery"
                  @change="checkDone"
                  label="주소"
                  color="rouge"
                  value="address"
                  hide-details
                  class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
                <v-checkbox
                  v-model="form.delivery"
                  @change="checkDone"
                  label="실명"
                  color="rouge"
                  value="name"
                  hide-details
                  class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
                <v-checkbox
                  v-model="form.delivery"
                  @change="checkDone"
                  label="연락처"
                  color="rouge"
                  value="contact"
                  hide-details
                  class="checkbox pl-2 pt-2"></v-checkbox>
              </v-row>
              <v-row class="mt-2">
                <v-checkbox
                  v-if="form.deliveryMethod !== 'PR'"
                  v-model="form.delivery"
                  @change="checkDone"
                  label="쇼핑몰아이디"
                  color="rouge"
                  value="id"
                  hide-details
                  class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
                <v-checkbox
                  v-model="form.delivery"
                  @change="checkDone"
                  label="이메일"
                  color="rouge"
                  value="email"
                  hide-details
                  class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="10">
          <div class="body-1 mb-2" style="color: #484848">리뷰 작성시 참고할 수 있는 사이트가 있다면 입력해주세요.</div>
          <v-textarea
            v-model="form.serviceInfo"
            name="serviceInfo"
            type="text"
            :rules="[errors.first('serviceInfo') || !errors.has('serviceInfo')]"
            placeholder=""
            @input="checkDone"
            outlined
            solo
            flat></v-textarea>
        </v-col>
        <v-col class="my-2" cols="12" md="10">
          <div class="label mb-2" style="color: #484848">
            추가로 받아야 하는 정보가 있으실 경우 질문 형식으로 입력해주세요.
          </div>
          <v-row v-for="(m, index) in form.additionalInfo || []" :key="index">
            <v-text-field
              v-model="form.additionalInfo[index]"
              name="additionalInfo"
              type="text"
              class="mt-2"
              single-line
              outlined
              solo
              flat
              hide-details></v-text-field>
            <v-btn color="rouge" @click="removeAdditionalInfo(index)" icon text class="plus_btn"
              ><v-icon style="color: #757575">mdi-trash-can-outline</v-icon></v-btn
            >
          </v-row>
          <v-row>
            <v-text-field
              v-model="additionalInfo"
              name="mission"
              type="text"
              class="mt-2"
              @keyup.enter="addAdditionalInfo"
              single-line
              outlined
              solo
              flat
              hide-details></v-text-field>
            <v-btn color="rouge" @click="addAdditionalInfo" tile class="plus_btn">추가</v-btn>
          </v-row>
        </v-col>
        <!--        <v-col cols="12" md="10" v-if="camData.type === 'P'">-->
        <!--          <div class="body-1 mb-2" style="color: #484848;">리뷰 가이드 파일의 링크를 입력해주세요.</div>-->
        <!--          <v-textarea v-model="form.guide" name="guide" type="text"-->
        <!--                      :rules="[errors.first('guide') || !errors.has('guide')]"-->
        <!--                      placeholder=""-->
        <!--                      @input="checkDone"-->
        <!--                      outlined solo flat></v-textarea>-->
        <!--        </v-col>-->
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
export default {
  name: 'Page3',
  props: ['camData', 'option'],
  data: function () {
    return {
      form: {
        abstraction: this.camData.abstraction || '',
        introduction: this.camData.introduction || '',
        serviceInfo: this.camData.serviceInfo || '',
        guide: this.camData.guide || '',
        delivery: this.camData.delivery || [],
        additionalInfo: this.camData.additionalInfo || [],
        deliveryMethod: this.camData.deliveryMethod || 'F',
        storeUrl: this.camData.storeUrl || '',
        requirement: this.camData.requirement || [],
        ftcRequired: this.camData.ftcRequired,
        ftcText: this.camData.ftcText || '제품을 무상으로 지원받아 작성한 솔직한 후기입니다.',
      },
      additionalInfo: '',
      menuDeliveryMethod: false,
    };
  },
  watch: {
    camData(value) {
      this.form = {
        abstraction: value.abstraction || '',
        introduction: value.introduction || '',
        serviceInfo: value.serviceInfo || '',
        guide: value.guide || '',
        additionalInfo: value.additionalInfo || [],
        deliveryMethod: value.deliveryMethod || 'F',
        storeUrl: value.storeUrl || '',
        requirement: value.requirement || [],
        ftcRequired: value.ftcRequired,
        ftcText: value.ftcText || '제품을 무상으로 지원받아 작성한 솔직한 후기입니다.',
      };
    },
    'form.requirement': function (value) {
      if (
        value.length > 0 &&
        (value[value.length - 1] == 'text' || value[value.length - 1] == 'photo' || value[value.length - 1] == 'video')
      ) {
        this._.remove(this.form.requirement, item => {
          return ['text', 'photo', 'video'].indexOf(item) > -1 && item !== value[value.length - 1];
        });
      }
    },
  },
  methods: {
    addAdditionalInfo: function () {
      this.form.additionalInfo = this.form.additionalInfo || [];
      if (this.additionalInfo.length > 0 && this.form.additionalInfo.indexOf(this.additionalInfo) === -1) {
        this.form.additionalInfo.push(this.additionalInfo);
        this.additionalInfo = '';
        this.checkDone();
      }
    },
    removeAdditionalInfo: function (idx) {
      console.log('remove' + idx);
      this.form.additionalInfo.splice(idx, 1);
      this.checkDone();
    },
    checkDone() {
      // this.$validator.validateAll().then(success => {
      //   if (success) {
      // if(!this._.isEmpty(this.form.keywords)) {
      // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
      if (this.camData.type === 'P' && this.form.deliveryMethod) {
        this.form.deliveryMethod = null;
      }
      this.$emit('updateEvent', this.form);
      // this.$store.commit('setDirty', true);
      // }
      // }
      // });
    },
  },
};
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/custom';

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 230px;
  min-width: 120px;
  height: 40px;
  margin-top: 0px;
  flex: 1 1 auto;
}

.radio_group {
  margin-top: 0px;
}

.radio {
  border: 1px solid;
  border-radius: 8px;
  max-width: 130px;
  height: 40px;
  margin-top: 0px;
  width: 130px;
}

.radio.v-item--active {
  border-color: var(--rouge-color);
}

.plus_btn {
  width: 56px;
  height: 40px !important;
  min-width: 40px !important;
  color: white;
  padding: 0 !important;
}
</style>
