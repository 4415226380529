<template>
  <div class="whiteBg">
    <div class="tab-page container downloads">
      <div class="text">
        <div class="mt-8"></div>
        <v-row justify="center" align="center">
          <v-col class="text-center pt-16">
            <v-row
              style="
                display: block;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: #212121;
              ">
              슈퍼멤버스 도입 문의
            </v-row>
            <v-row
              class="body--matching mt-3"
              style="
                display: block;
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 27px;
                /* identical to box height, or 180% */

                text-align: center;

                color: #484848;
              ">
              딱 맞는 블로그 마케팅을 제안받아 보세요.
            </v-row>
            <v-row class="mb-16 mt-6" style="display: block">
              <v-col justify="center" align="center">
                <div
                  class="pipedrive-request pa-8 ma-4"
                  :style="$vuetify.breakpoint.mdAndUp ? 'width: 460px' : 'min-width: 320px'">
                  <div
                    class="pipedriveWebForms"
                    data-pd-webforms="https://webforms.pipedrive.com/f/72xuCfjBVeGQ7to6dHviHoGe95yxd1OGCLFpRjEb6E7udU9ivMJGLWKOuc1zjpiGwH"></div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Downloads',
  mounted() {
    let pipedriveScript = document.createElement('script');
    pipedriveScript.setAttribute('src', 'https://webforms.pipedrive.com/f/loader');
    document.head.appendChild(pipedriveScript);
  },
  data() {
    return {
      showConsultDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
.downloads {
  display: flex;
  flex-direction: row;
  align-content: center;
  .text {
    text-align: center;
    margin: 120px auto;
    .eyebrow {
      color: #757575;
      margin-bottom: 4px;
      margin-left: 1px;
    }
    h2 {
      color: #212121;
    }
    p {
      color: #484848;
      line-height: 1.8;
    }
    button {
      background-color: #ea2a3a;
      height: 48px;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}

@media (max-width: 960px) {
  .downloads {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-content: center;
    overflow: hidden;
    margin: 0 auto 0;
    .row-image {
      width: 375px;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      margin: 112px auto;
      padding-top: 0;
      .eyebrow {
        margin-bottom: 6px;
      }
      h2 {
      }
      p {
        color: #484848;
        line-height: 1.94;
      }
    }
    button {
      background-color: #fff;
      height: 48px;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}

.signup {
  width: 220px;
  height: 48px;
  color: #fff;
  border-radius: 24px;
  box-shadow: 0 12px 24px 0 rgba(75, 75, 75, 0.1);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.whiteBg {
  background-color: #fbfbfb;
}

.pipedrive-request {
  background-color: white;
  border-radius: 24px;
  width: 200px;
  filter: drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.1));
}
</style>
