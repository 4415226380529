<template>
  <div>
    <v-row class="youtuber-detail__subtitle font-weight-bold px-4 mb-4" style="font-size: 14px"> 최근 영상 </v-row>
    <v-row>
      <v-data-table
        height="400"
        fixed-header
        :headers="[
          { text: '영상', value: 'thumbnail', sortable: false },
          { text: '제목', value: 'title', sortable: false },
          { text: '조회수', value: 'viewCount', sortable: true, width: '88px' },
          { text: '좋아요', value: 'likeCount', sortable: true, width: '88px' },
          {
            text: '댓글수',
            value: 'commentCount',
            sortable: true,
            width: '88px',
          },
        ]"
        :items="videos"
        :items-per-page="30"
        hide-default-footer
        style="
          backgroud-color: transparent;
          border-radius: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.12);
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          width: 100%;
        ">
        <template v-slot:[`item.thumbnail`]="{ item }">
          <a :href="item.videoURL" target="_blank">
            <div class="mx-1" style="maxwidth: 128px">
              <img v-if="!!item?.thumbnail" :src="item.thumbnail" style="width: 60px; height: 108px" />
              <img v-else src="@/assets/img/matching-placeholder.jpg" style="width: 60px; height: 108px" />
            </div>
          </a>
        </template>
        <template v-slot:[`item.viewCount`]="{ item }">
          {{ item?.viewCount }}
        </template>
        <template v-slot:[`item.likeCount`]="{ item }">
          <div>
            {{ item?.likeCount }}
          </div>
        </template>
        <template v-slot:[`item.commentCount`]="{ item }">
          <div>
            {{ item?.commentCount }}
          </div>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import campaignMixin from '@/components/shared/campaignMixin';

export default {
  name: 'TiktokDetailLatestVideo',
  mixins: [campaignMixin],
  props: ['detailTargetId'],
  data() {
    return {
      videos: [],
    };
  },
  async created() {
    const {
      data: { data },
    } = await this.axios.get(`/tiktoker/videos?id=${this.detailTargetId}`);
    this.videos = data;
  },
};
</script>

<style scoped lang="scss">
.tiktok-detail__section {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 18px;
  color: #484848;
  padding: 8px 16px;
  margin: 32px 0 24px;
  border-bottom: #f5f5f5 solid 1px;
}

.tiktok-detail__title {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  padding-bottom: 4px;
  margin-bottom: 0;
}

.tiktok-detail__subtitle {
  font-family: 'Spoqa Han Sans Neo';
  font-size: 24px;
  text-align: left;
  font-weight: 300;
  padding-top: 8px;
}
</style>
