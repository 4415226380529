<template>
  <v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pt-0 pb-2">
      <v-btn-toggle v-model="tap" class="transparent" mandatory>
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="0"
          text
          outlined
          style="width: 235px; height: 50px"
          >네이버</v-btn
        >
        <!--        <v-btn class="ma-0" active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" :value="1" text outlined style="width: 235px; height: 50px">인스타</v-btn>-->
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="2"
          text
          outlined
          style="width: 234px; height: 50px"
          >한줄평</v-btn
        >
      </v-btn-toggle>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.smAndDown" class="pt-0 px-4">
      <v-btn-toggle v-model="tap" class="transparent" mandatory style="width: 100%">
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="0"
          text
          outlined
          style="width: 33%; height: 50px"
          >네이버</v-btn
        >
        <!--        <v-btn class="ma-0" active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" :value="1" text outlined style="width: 33%; height: 50px">인스타</v-btn>-->
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="2"
          text
          outlined
          style="width: 33%; height: 50px"
          >한줄평</v-btn
        >
      </v-btn-toggle>
    </v-col>
    <v-col>
      <CardRegistrationReminder />
    </v-col>
    <v-col class="content-page mt-2 pt-0">
      <Naver :contracts="contracts" v-if="tap === 0"></Naver>
      <!--      <Insta :contracts="contracts" v-if="tap === 1"></Insta>-->
      <Comment :contracts="contracts" v-if="tap === 2"></Comment>
    </v-col>
  </v-col>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';

import Naver from '@/components/company/report/Naver';
import CardRegistrationReminder from '@/components/company/report/CardRegistrationReminder';
// import Insta from '@/components/company/report/Insta'

export default {
  name: 'Report',
  mixins: [companyMixin],
  components: {
    Naver,
    CardRegistrationReminder,
    // Insta,
    Comment: () => import('@/components/company/report/Comment'),
  },
  data() {
    return {
      tap: 0,
      contracts: [],
    };
  },
  computed: {},
  watch: {
    company(value) {
      this.getInitData(value);
    },
  },
  methods: {
    async getInitData(company) {
      this.axios
        .get(
          '/contract?company=' +
            company.id +
            '&orderBy=DATE(date0)&direction=desc&withInfluencer=' +
            (this.company.type === 'A')
        )
        .then(contracts => {
          this.contracts = contracts.data.contracts;
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.company) {
      this.getInitData(this.company);
    }
  },
};
</script>

<style scoped lang="scss"></style>
