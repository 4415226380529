<template>
  <span class="button" :style="isFull ? 'width : 100%;' : ''" @click="handleClick">
    <span class="icon">{{ icon }}</span>
    <span class="label">{{ label }}</span>
  </span>
</template>

<script>
export default {
  props: ['icon', 'label', 'isFull'],
  methods: {
    handleClick() {
      this.$emit('click'); // 클릭 이벤트를 부모 컴포넌트로 전달
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  padding: 0px 10px;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  height: 34px;
}

.icon {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-right: 6px;
}

.label {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
</style>
