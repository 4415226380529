export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
    head() {
      return this.$store.getters.head;
    },
    branches() {
      return this.$store.getters.branches;
    },
    points() {
      return this.$store.getters.points;
    },
    availPoints() {
      return this.$store.getters.availPoints;
    },
    waitPoints() {
      return this.$store.getters.waitPoints;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    userIsAuthenticated() {
      return !this._.isEmpty(this.$store.getters.user);
    },
    payState() {
      return this.$store.getters.payState;
    },
    adminMode() {
      return this.$store.getters.adminMode;
    },
  },
};
