import { render, staticRenderFns } from "./Page4.vue?vue&type=template&id=2d285d03&scoped=true"
import script from "./Page4.vue?vue&type=script&lang=js"
export * from "./Page4.vue?vue&type=script&lang=js"
import style0 from "./Page4.vue?vue&type=style&index=0&id=2d285d03&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d285d03",
  null
  
)

export default component.exports