<template>
  <v-col class="mt-1">
    <v-card flat>
      <v-col class="mt-2" cols="12" v-if="form[channel]">
        <v-col class="shades white pa-6">
          <v-row>
            <v-col class="mt-6" cols="12" md="12">
              <v-row v-if="camData.type !== 'P'">
                <v-col cols="12" md="10" class="mb-4" v-if="camData.deliveryMethod !== 'PR'">
                  <div class="label mb-2">광고비 외 회원에게 추가로 제공되는 혜택이 있나요?</div>
                  <v-btn-toggle v-model="isReward[channel]" mandatory dense>
                    <v-btn
                      :value="true"
                      active-class="rouge--text text--rouge"
                      class="ma-0"
                      style="width: 130px"
                      outlined
                      >예</v-btn
                    >
                    <v-btn
                      :value="false"
                      active-class="rouge--text text--rouge"
                      class="ma-0"
                      style="width: 130px"
                      outlined
                      @click="
                        () => {
                          form[channel].reward = null;
                          form[channel].rewardPrice = null;
                        }
                      "
                      >아니오</v-btn
                    >
                  </v-btn-toggle>
                  <div class="mt-2" style="color: #4770bd; font-size: 13px">
                    {{ rewardHint[(camData.category || {}).firstName][isReward[channel]] }}
                  </div>
                </v-col>
                <v-col cols="8" md="8" v-if="isReward[channel]">
                  <div class="label mb-2" style="color: #484848">
                    회원 제공 혜택<span class="caption ml-1 rouge--text">*필수</span>
                  </div>
                  <v-text-field
                    v-model="form[channel].reward"
                    name="reward"
                    type="text"
                    class="text-right"
                    :rules="[errors.first('reward') || !errors.has('reward')]"
                    placeholder="A세트 무료제공"
                    @input="checkDone"
                    single-line
                    outlined
                    solo
                    flat
                    hide-details></v-text-field>
                </v-col>
                <v-col cols="3" v-if="$vuetify.breakpoint.smAndDown"> </v-col>
                <v-col class="ml-1" cols="4" md="2" v-if="isReward[channel]">
                  <div class="label mb-2">정가<span class="caption ml-1 rouge--text">*필수</span></div>
                  <v-text-field
                    v-model.number="form[channel].rewardPrice"
                    name="rewardPrice"
                    type="number"
                    suffix="원"
                    class="text-right"
                    :rules="[errors.first('rewardPrice') || !errors.has('rewardPrice')]"
                    @input="checkDone"
                    single-line
                    outlined
                    solo
                    flat
                    hide-details></v-text-field>
                </v-col>
                <div
                  class="my-2"
                  style="color: #4770bd; font-size: 13px"
                  v-if="camData.category.firstName === '제품' && camData.deliveryMethod === 'PR'">
                  회원이 구매 또는 예약해야 하는 품목을 입력해주세요. 입력하신 정가가 구매 지원금으로 회원에게
                  지급됩니다.
                </div>
              </v-row>
              <v-row>
                <v-col cols="11" md="11">
                  <div class="label mb-2" style="color: #484848">
                    필수미션을 입력해주세요.<a
                      href="https://www.notion.so/mayacrew/7c67b704a9e64004aaa8a5ead1c0ef3a"
                      class="caption text-decoration-underline"
                      target="_blank">
                      필수미션 작성 예시 보기></a
                    ><span class="caption rouge--text"> *필수</span>
                  </div>
                  <v-row v-for="(m, index) in form[channel].mission || []" :key="index">
                    <v-textarea
                      v-model="form[channel].mission[index]"
                      name="mission"
                      type="text"
                      class="mt-2"
                      outlined
                      solo
                      flat
                      hide-details></v-textarea>
                    <v-btn color="rouge" @click="removeMission(index)" icon text class="plus_btn"
                      ><v-icon style="color: #757575">mdi-trash-can-outline</v-icon></v-btn
                    >
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="mission"
                      name="mission"
                      type="text"
                      class="mt-2"
                      @keyup.enter="addMission"
                      outlined
                      solo
                      flat
                      hide-details></v-textarea>
                    <v-btn color="rouge" @click="addMission" tile class="plus_btn">추가</v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="10" class="mt-6">
                  <div class="label mb-2" style="color: #484848">
                    {{ channelName(idx) }}
                    {{ channel === 'insta' ? '포스트' : channel === 'youtube' ? '영상' : '리뷰' }}에 들어가야 하는 필수
                    {{ channel === 'insta' ? '해시태그' : '키워드' }}를 설정해주세요. (1개)<span
                      class="caption rouge--text">
                      *필수</span
                    >
                  </div>
                  <v-row>
                    <v-chip
                      v-for="(requiredKeyword, index) in form[channel].requiredKeywords || []"
                      :key="index"
                      style="height: 40px"
                      class="mt-2"
                      @click:close="removeArray('requiredKeyword', index)"
                      close
                      color="rouge"
                      label
                      outlined
                      >{{ requiredKeyword }}</v-chip
                    >
                    <v-text-field
                      v-model="requiredKeyword"
                      name="keyword"
                      type="text"
                      placeholder="예) 상수동 맛집"
                      class="mt-2"
                      @keyup.enter="addArray('requiredKeyword')"
                      single-line
                      outlined
                      solo
                      flat
                      hide-details></v-text-field>
                    <v-btn color="rouge" @click="addArray('requiredKeyword')" class="plus_btn"
                      ><v-icon>add</v-icon></v-btn
                    >
                  </v-row>
                  <div class="label py-3" style="color: #757575">
                    <v-icon class="mr-1 mb-1" small>mdi-help-circle-outline</v-icon>키워드 설정에 어려움이 있으신가요?
                    <a class="link--secondary" href="https://pf.kakao.com/_Jgyxid" target="_blank">카카오톡 채널</a>로
                    말씀해주시면 키워드 최적화를 도와드릴게요.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="10" class="mt-6">
                  <div class="label mb-2" style="color: #484848">
                    {{ channelName(idx) }}
                    {{ channel === 'insta' ? '포스트' : channel === 'youtube' ? '영상' : '리뷰' }}에 추가로 들어가는
                    선택 {{ channel === 'insta' ? '해시태그' : '키워드' }}를 설정해주세요.<span
                      class="caption rouge--text">
                      *필수</span
                    >
                  </div>
                  <v-row>
                    <v-chip
                      v-for="(keyword, index) in form[channel].keywords || []"
                      :key="index"
                      style="height: 40px"
                      class="mt-2"
                      @click:close="removeArray('keyword', index)"
                      close
                      color="rouge"
                      label
                      outlined
                      >{{ keyword }}</v-chip
                    >
                    <v-text-field
                      v-model="keyword"
                      name="keyword"
                      type="text"
                      placeholder="예) 상수동 맛집"
                      class="mt-2"
                      @keyup.enter="addArray('keyword')"
                      single-line
                      outlined
                      solo
                      flat
                      hide-details></v-text-field>
                    <v-btn color="rouge" @click="addArray('keyword')" class="plus_btn"><v-icon>add</v-icon></v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="10" class="mt-6">
                  <div v-if="camData.category.firstName === '매장'" class="body-1 mb-2" style="color: #484848">
                    매장의 소개 또는 예약 사이트가 있으실 경우 링크를 입력해주세요.
                  </div>
                  <div v-else-if="camData.category.firstName === '제품'" class="body-1 mb-2" style="color: #484848">
                    제품을 구매할 수 있는 링크를 입력해주세요.
                  </div>
                  <div v-else-if="camData.category.firstName === '서비스'" class="body-1 mb-2" style="color: #484848">
                    서비스 웹사이트 또는 다운로드 경로를 알려주세요.
                  </div>
                  <v-text-field
                    v-model="form[channel].purchaseLink"
                    name="purchaseLink"
                    type="text"
                    v-validate="'tightURLVerify'"
                    @paste="onPaste"
                    ref="purchaseLinkInput"
                    :rules="[errors.first('purchaseLink') || !errors.has('purchaseLink')]"
                    hint="입력해주신 링크가 블로그 리뷰에 삽입되며, 추후 마케팅 보고서에서 클릭 수를 확인할 수 있습니다."
                    persistent-hint
                    @input="checkDone"
                    outlined
                    solo
                    flat></v-text-field>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-checkbox class="ml-2" v-model="checkUTM" label="(선택) UTM을 설정합니다."></v-checkbox>
                        <v-tooltip bottom max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on"
                              >mdi-help-circle-outline</v-icon
                            >
                          </template>
                          별도의 웹 트래픽 분석 서비스를 이용하시지 않는 경우 UTM 설정을 하지 않으셔도 됩니다.
                        </v-tooltip>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-if="checkUTM">
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">utm_source</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].utm.source"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">utm_medium</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].utm.medium"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">utm_campaign</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].utm.campaign"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-checkbox
                          class="ml-2"
                          v-model="checkNT"
                          label="(선택) 스마트스토어 NT 파라미터를 설정합니다."></v-checkbox>
                        <v-tooltip bottom max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small color="#4770BD" class="mb-1" v-bind="attrs" v-on="on"
                              >mdi-help-circle-outline</v-icon
                            >
                          </template>
                          구매링크를 스마트스토어로 연결하여 NT파라미터 마케팅 분석을 이용하는 경우 이 값을
                          설정해주세요.
                        </v-tooltip>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-if="checkNT">
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">nt_source</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].nt.source"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">nt_medium</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].nt.medium"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">nt_detail</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].nt.detail"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="text-center">
                        <v-col class="mt-3" cols="2" style="color: #484848">nt_keyword</v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="form[channel].nt.keyword"
                            type="text"
                            @input="checkDone"
                            outlined
                            solo
                            flat>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="10">
                  <div class="label mb-2" style="color: #484848">링크 클릭 유도문구</div>
                  <v-select
                    v-model="callToActionList[channel]"
                    :items="callToActionItems"
                    height="40px"
                    :hide-details="true"
                    @change="checkDone"
                    outlined
                    solo
                    flat></v-select>
                  <v-text-field
                    v-if="callToActionList[channel] === '직접 입력'"
                    v-model="form[channel].callToAction"
                    name="callToAction"
                    type="text"
                    :rules="[errors.first('callToAction') || !errors.has('callToAction')]"
                    @input="checkDone"
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="shades white pa-6 mt-2">
          <v-row>
            <v-col cols="12" md="12">
              <h3>편집 조건을 설정해주세요.</h3>
            </v-col>
            <v-col cols="12" md="12" class="mt-6">
              <div class="label my-2" style="color: #484848">편집 조건</div>
              <v-radio-group
                v-model="editCondition"
                :mandatory="true"
                class="radio_group"
                hide-details
                @change="changeEditCondition(channel)">
                <v-row class="mb-2">
                  <v-radio
                    label="추천 조건"
                    color="rouge"
                    value="R"
                    hide-details
                    class="radio pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>
                  <v-radio
                    label="직접 설정"
                    color="rouge"
                    value="M"
                    hide-details
                    class="radio pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" class="mt-6">
              <v-row v-for="(item, idx) in editOptions[channel]" :key="idx">
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'"
                  v-if="!item.condition || form[channel][item.condition.field] === item.condition.value">
                  <div class="mt-6">{{ item.content.label }}</div>
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '7' : '12'"
                  v-if="
                    item.type === 'radio' &&
                    (!item.condition || form[channel][item.condition.field] === item.condition.value)
                  ">
                  <v-radio-group
                    v-model="form[channel][item.content.name]"
                    :mandatory="true"
                    class="radio_group"
                    :disabled="editCondition === 'R'"
                    @change="item.change ? item.change() : checkDone()"
                    hide-details>
                    <v-row class="mb-2">
                      <v-radio
                        v-for="(option, idx) in item.content.options"
                        :key="idx"
                        :label="option.label"
                        color="rouge"
                        :value="option.value"
                        hide-details
                        class="pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>
                    </v-row>
                  </v-radio-group>
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '5' : '9'"
                  v-if="
                    item.type === 'slider' &&
                    (!item.condition || form[channel][item.condition.field] === item.condition.value)
                  ">
                  <v-slider
                    v-model="form[channel][item.content.name]"
                    always-dirty
                    :min="item.content.min"
                    :max="item.content.max"
                    :tick-labels="item.content.options"
                    tick-size="0"
                    hide-details></v-slider>
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? '2' : '3'"
                  class="pl-6"
                  v-if="
                    item.type === 'slider' &&
                    (!item.condition || form[channel][item.condition.field] === item.condition.value)
                  ">
                  <v-text-field
                    v-model.number="form[channel][item.content.name]"
                    class="mt-0"
                    type="number"
                    :suffix="item.content.suffix"
                    readonly></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col v-else>
        <v-row class="my-8 ml-6">
          <v-col cols="12">{{ channelName(idx) }} 채널 정보 추가하기</v-col>
          <v-col cols="12">
            <v-btn :disabled="channel === 'insta' || channel === 'youtube'" @click="addChannel(channel)"> 추가 </v-btn>
          </v-col>
          <v-col cols="12" v-if="channel === 'insta' || channel === 'youtube'" style="color: #4770bd; font-size: 13px"
            >{{ channelName(idx) }} 채널은 11월경 추가될 예정입니다.</v-col
          >
        </v-row>
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'Page4',
  props: ['camData', 'option'],
  data: function () {
    return {
      channel: 'naver',
      form: {},
      mission: '',
      requiredKeyword: '',
      keyword: '',
      hashtag: '',
      editCondition: 'R',
      isReward: {
        naver: true,
        insta: true,
        youtube: true,
      },
      rewardHint: {
        매장: {
          true: '음식점, 헤어샵 등 회원에게 제공되는 제품/서비스가 있습니다.',
          false: '매장에 직접 방문해 체험하지만 별도의 제공 혜택이 없는 경우에만 사용해주세요.',
        },
        제품: {
          true: '도서, 화장품 등 회원에게 제공되는 제품/서비스가 있습니다.',
          false: '광고비 외 별도의 제공 혜택이 없는 경우에만 사용해주세요.',
        },
        서비스: {
          true: '앱 유료기능, 쿠폰 등 회원에게 제공되는 제품/서비스가 있습니다.',
          false: '제품/서비스가 무료 혹은 유료로 제공되는 혜택이 없는 경우에만 사용해주세요.',
        },
      },
      checkUTM: false,
      checkNT: false,
      callToActionItems: [
        '매장 예약은 이곳에서 하실 수 있어요.',
        '매장의 자세한 정보는 여기서 확인하실 수 있어요.',
        '제품을 구매하려면 여기를 눌러주세요.',
        '서비스의 자세한 정보는 여기서 확인하실 수 있어요.',
        '직접 입력',
      ],
      callToActionList: {
        naver: '직접 입력',
        insta: '직접 입력',
        youtube: '직접 입력',
      },
    };
  },
  computed: {
    editOptions() {
      let naverTickLabels = Array(16)
        .fill()
        .map((v, i) => {
          if ([0, 5, 10, 15].indexOf(i) >= 0) {
            return i + 5 + '장';
          } else return '';
        });
      let instaTickLabels = Array(5)
        .fill()
        .map((v, i) => {
          if ([0, 2, 4].indexOf(i) >= 0) {
            return i + 1 + '장';
          } else return '';
        });
      let youtubeVideoTickLabels = Array(10)
        .fill()
        .map((v, i) => {
          if ([0, 2, 4, 9].indexOf(i) >= 0) {
            return i + 1 + '분';
          } else return '';
        });
      let youtubeExposureTickLabels = Array(121)
        .fill()
        .map((v, i) => {
          let tick = i + 59;
          if ([59, 89, 119, 179].indexOf(tick) >= 0) {
            return tick + 1 + '초';
          } else return '';
        });
      let youtubeExposureRateTickLabels = Array(61)
        .fill()
        .map((v, i) => {
          let tick = i + 39;
          if ([39, 59, 79, 99].indexOf(tick) >= 0) {
            return tick + 1 + '%';
          } else return '';
        });
      return {
        naver: [
          {
            type: 'radio',
            content: {
              name: 'device',
              label: '스마트폰 사용 여부',
              options: [
                { label: '무관', value: 'N' },
                { label: '스마트폰 촬영 불가', value: 'X' },
              ],
              default: 'N',
            },
          },
          {
            type: 'slider',
            content: {
              name: 'photo',
              label: '사진 분량',
              min: 5,
              max: 20,
              options: naverTickLabels,
              suffix: '장 이상',
              default: 5,
            },
          },
          {
            type: 'radio',
            content: {
              name: 'video',
              label: '영상 포함 여부',
              options: [
                { label: '무관', value: 'N' },
                { label: '영상 필수', value: 'O' },
              ],
              default: 'N',
            },
          },
          {
            type: 'radio',
            content: {
              name: 'face',
              label: '얼굴 포함 여부',
              options: [
                { label: '무관', value: 'N' },
                { label: '필수', value: 'O' },
              ],
              default: 'N',
            },
          },
        ],
        insta: [
          {
            type: 'radio',
            content: {
              name: 'device',
              label: '스마트폰 사용 여부',
              options: [
                { label: '무관', value: 'N' },
                { label: '스마트폰 촬영 불가', value: 'X' },
              ],
              default: 'N',
            },
          },
          {
            type: 'radio',
            content: {
              name: 'video',
              label: '미디어 형태',
              options: [
                { label: '무관', value: 'N' },
                { label: '사진', value: 'P' },
                { label: '영상', value: 'V' },
                { label: '영상+사진', value: 'VP' },
              ],
              default: 'N',
            },
          },
          {
            type: 'radio',
            content: {
              name: 'face',
              label: '얼굴 포함 여부',
              options: [
                { label: '무관', value: 'N' },
                { label: '필수', value: 'O' },
              ],
              default: 'N',
            },
          },
          {
            type: 'slider',
            content: {
              name: 'photo',
              label: '사진 분량',
              min: 1,
              max: 5,
              options: instaTickLabels,
              suffix: '장 이상',
              default: 1,
            },
          },
        ],
        youtube: [
          {
            type: 'radio',
            content: {
              name: 'adsType',
              label: '광고 형태',
              options: [
                { label: '브랜디드 컨텐츠', value: 'B' },
                { label: 'PPL', value: 'P' },
              ],
              default: 'B',
            },
            change: this.changeType,
          },
          {
            type: 'radio',
            condition: { field: 'adsType', value: 'P' },
            content: {
              name: 'exclusive',
              label: '단독영상여부',
              options: [
                { label: '다른 브랜드 광고 포함 불가', value: 'X' },
                { label: '다른 브랜드 광고 포함 허용', value: 'O' },
              ],
              default: 'X',
            },
          },
          {
            type: 'radio',
            content: {
              name: 'plan',
              label: '기획안 가이드 여부',
              options: [
                {
                  label: '자유롭게 작성한 기획안을 받고 싶습니다.',
                  value: 'F',
                },
                {
                  label: '기획안 작성을 위한 가이드 문서를 제공합니다.',
                  value: 'G',
                },
              ],
              default: 'F',
            },
          },
          {
            type: 'slider',
            condition: { field: 'adsType', value: 'P' },
            content: {
              name: 'exposure',
              label: '노출 분량',
              min: 60,
              max: 180,
              options: youtubeExposureTickLabels,
              suffix: '초',
              default: 90,
            },
          },
          {
            type: 'slider',
            content: {
              name: 'photo',
              label: '영상 최소 분량',
              min: 1,
              max: 10,
              options: youtubeVideoTickLabels,
              suffix: '분',
              default: 3,
            },
          },
          {
            type: 'slider',
            condition: { field: 'adsType', value: 'B' },
            content: {
              name: 'exposure',
              label: '노출 비중',
              min: 40,
              max: 100,
              options: youtubeExposureRateTickLabels,
              suffix: '%',
              default: 60,
            },
          },
          {
            type: 'radio',
            content: {
              name: 'video',
              label: '화질',
              options: [
                { label: '1080p 이상', value: '1080' },
                { label: '4k', value: '4k' },
                { label: '무관', value: 'N' },
              ],
              default: '1080',
            },
          },
          {
            type: 'radio',
            content: {
              name: 'device',
              label: '스마트폰 사용 여부',
              options: [
                { label: '무관', value: 'N' },
                { label: '스마트폰 촬영 불가', value: 'X' },
              ],
              default: 'N',
            },
          },
        ],
      };
    },
  },
  watch: {
    camData: {
      handler: function (value) {
        this.form = {
          keywords: value.keywords || [],
          hashtags: value.hashtags || [],
        };
        this.channel = {
          limitation: (value.naver || {}).limitation || (value.insta || {}).limitation,
        };
        this.insta_number = ((value.insta || {}).number === 0 ? 20 : (value.insta || {}).number) || 20;
        this.naver_number = ((value.naver || {}).number === 0 ? 20 : (value.naver || {}).number) || 20;
        this.insta_limit = (value.insta || {}).number === 0;
        this.naver_limit = (value.naver || {}).number === 0;
        this.naver_stopRequest = (value.naver || {}).stopRequest || false;
        this.insta_stopRequest = (value.insta || {}).stopRequest || false;

        this.initData();
      },
      // immediate: true,
      deep: true,
    },
    isReward: {
      handler(value) {
        this.$channel.forEach(channel => {
          if (!value[channel]) {
            this.form[channel].reward = null;
            this.checkDone();
          }
        });
      },
      deep: true,
    },
    checkUTM(value) {
      if (value) {
        this.checkNT = false;
        if (!this.form[this.channel].utm) {
          this.form[this.channel].utm = this.form[this.channel].utm || {
            source: 'naver',
            medium: 'blog',
            campaign: this.camData.name,
          };
        }
      } else {
        this.form[this.channel].utm = null;
      }
    },
    checkNT(value) {
      if (value) {
        this.checkUTM = false;
        if (!this.form[this.channel].nt) {
          this.form[this.channel].nt = this.form[this.channel].nt || {
            source: 'naver',
            medium: 'blog',
            detail: this.camData.name,
            keyword: this.camData.name,
          };
        }
      } else {
        this.form[this.channel].nt = null;
      }
    },
  },
  methods: {
    onPaste() {
      this.$nextTick(function () {
        this.$refs.purchaseLinkInput.$validator.validate();
      });
    },
    channelName(idx) {
      let name = {
        0: '네이버 블로그',
        1: '인스타그램',
        2: '유튜브',
      };
      return name[idx];
    },
    addArray: function (category) {
      this.form[this.channel][category + 's'] = this.form[this.channel][category + 's'] || [];
      if (category !== 'requiredKeyword' || this.form[this.channel][category + 's'].length === 0) {
        if (this[category].length > 0 && this.form[this.channel][category + 's'].indexOf(this[category]) === -1) {
          this.form[this.channel][category + 's'].push(this[category]);
          this[category] = '';
          this.checkDone();
        }
      }
    },
    removeArray: function (category, idx) {
      console.log('remove' + idx);
      this.form[this.channel][category + 's'].splice(idx, 1);
      this.checkDone();
    },
    addMission: function () {
      this.form[this.channel].mission = this.form[this.channel].mission || [];
      if (this.mission.length > 0 && this.form[this.channel].mission.indexOf(this.mission) === -1) {
        this.form[this.channel].mission.push(this.mission);
        this.mission = '';
        this.checkDone();
      }
    },
    removeMission: function (idx) {
      console.log('remove' + idx);
      this.form[this.channel].mission.splice(idx, 1);
      this.checkDone();
    },
    changeEditCondition: function (channel) {
      if (this.editCondition === 'R') {
        this.editOptions[channel].forEach(option => {
          this.form[channel][option.content.name] = option.content.default;
        });
      }
      this.checkDone();
    },
    async checkDone() {
      if (this.callToActionList[this.channel] !== '직접 입력') {
        this.$set(this.form[this.channel], 'callToAction', this.callToActionList[this.channel]);
      }
      let success = await this.$validator.validateAll();
      if (success) {
        // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
        this.$emit('updateEvent', this.form);
        // this.$store.commit('setDirty', true);
      }
      return null;
    },
    addChannel(channel) {
      this.form[channel] = {};
      this.$forceUpdate();
    },
    initData() {
      this.$channel.forEach(channel => {
        if (this.camData && this.camData[channel]) {
          this.form[channel] = this.camData[channel];
          this.channel = channel;
        }

        if (!this._.isEmpty(this.form[channel])) {
          this.checkUTM = !!this.form[this.channel].utm;
          this.checkNT = !!this.form[this.channel].nt;

          if (
            this.editOptions[channel].some(option => {
              return (
                this.form[channel][option.content.name] !== null &&
                this.form[channel][option.content.name] != option.content.default
              );
            })
          ) {
            this.editCondition = 'M';
          }

          this.isReward[channel] =
            !this._.isEmpty(this.form[channel].reward) || !this._.isEmpty(this.form[channel].rewardPrice);

          if (
            this.callToActionItems.indexOf(this.form[channel].callToAction) > -1 &&
            this.callToActionList[channel] !== this.form[channel].callToAction
          ) {
            this.callToActionList[channel] = this.form[channel].callToAction;
            this.form[channel].callToAction = '';
          }
        }
      });
    },
    changeType() {
      this.editOptions[this.channel].map(item => {
        if (item.content.default !== undefined && item.content.name !== 'adsType') {
          if (!item.condition || this.form[this.channel][item.condition.field] === item.condition.value) {
            console.log(item.content.name);
            this.form[this.channel][item.content.name] = item.content.default;
          }
        }
      });
      this.checkDone();
    },
  },
  created() {
    this.initData();
  },
};
</script>
<style scoped lang="scss">
/*@import '../../../../assets/scss/custom';*/

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 170px;
  height: 40px;
  margin-top: 0px;
}

.line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

.label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

::v-deep .check {
  ::v-deep .v-label {
    font-size: 14px;
  }
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

.plus_btn {
  width: 56px;
  height: 40px !important;
  min-width: 40px !important;
  color: white;
  padding: 0 !important;
}

.link--secondary {
  display: inline-block;
  color: var(--secondary-color);
  font-weight: bold;
  text-decoration: underline;
}
</style>
