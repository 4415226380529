export default {
  computed: {
    cssProps() {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary,
        '--secondary-color': this.$vuetify.theme.themes.light.secondary,
        '--default-color': this.$vuetify.theme.themes.light.default,
        '--rouge-color': this.$vuetify.theme.themes.light.rouge,
        '--default-semilight-color': this.$vuetify.theme.themes.light['default-semilight'],
        '--default-light-color': this.$vuetify.theme.themes.light['default-light'],
        '--border-light-color': this.$vuetify.theme.themes.light['border-light'],
        '--border-color': this.$vuetify.theme.themes.light.border,
        '--card-background-color': this.$vuetify.theme.themes.light['card-background'],
        '--golden-color': this.$vuetify.theme.themes.light.golden,
        '--crimson-color': this.$vuetify.theme.themes.light.crimson,
        '--french-blue-color': this.$vuetify.theme.themes.light['french-blue'],
        '--dark-slate-blue-color': this.$vuetify.theme.themes.light['dark-slate-blue'],
        '--warm-grey-color': this.$vuetify.theme.themes.light['warm-grey'],
        '--white-color': this.$vuetify.theme.white,
        '--pinkish-grey-color': this.$vuetify.theme.themes.light['pinkish-grey'],
        '--white-two-color': this.$vuetify.theme.themes.light['white-two'],
        '--light-pink': this.$vuetify.theme.themes.light['light-pink'],
      };
    },
  },
};
