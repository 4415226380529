<template>
  <v-col class="cardreg">
    <v-row class="ml-1 pa-6">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h2 class="font-weight-bold headline hidden-sm-and-down">
              결제 내역을 확인하고<br />결제수단을 변경할 수 있습니다.
            </h2>
          </v-col>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">결제수단</div>
            <v-data-table
              :headers="[
                { text: '주결제 수단', sortable: false },
                { text: '카드명', width: '104px', sortable: false },
                { text: '카드번호', width: '88px', sortable: false },
                { text: '상태', width: '64px', sortable: false },
                { text: '등록/삭제', width: '96px', sortable: false },
              ]"
              :items="local_cards"
              hide-default-footer
              class="card"
              mobile-breakpoint="0"
              no-data-text="등록된 결제수단이 없습니다. 하단의 카드등록 버튼을 눌러주세요."
              style="
                backgroud-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              ">
              <template v-slot:item="props">
                <tr>
                  <td>
                    <v-radio-group v-model="mainCard" :mandatory="false" @change="save">
                      <v-radio :value="props.index"></v-radio>
                    </v-radio-group>
                  </td>
                  <td>{{ props.item.card_name }}</td>
                  <td>{{ props.item.last4 }}</td>
                  <td>{{ props.item.status || '활성' }}</td>
                  <td class="text-right pa-0">
                    <v-btn
                      color="rouge"
                      class="ma-0 ml-6 mr-2"
                      flat
                      outlined
                      @click="delCard(props.item)"
                      style="max-width: 80px">
                      <!-- :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'"> -->
                      <v-icon>close</v-icon>
                      <span class="ml-2 hidden-sm-and-down">삭제</span>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-row class="justify-end py-4 pr-2">
              <v-btn class="rouge white-two--text ma-0 ml-4" flat @click="showAddCard = true" style="max-width: 120px">
                <!--                <v-icon class="hidden-dm-and-up">add</v-icon>-->
                카드 등록
              </v-btn>
            </v-row>
          </v-col>
          <!--<v-btn block color="primary" class="ma-0" @click="save">-->
          <!--저장-->
          <!--</v-btn>-->
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="showAddCard" content-class="white-two" persistent max-width="370px">
            <v-card class="pa-4">
              <v-card-title>
                <div>
                  <v-icon color="default" @click="showAddCard = false">close</v-icon>
                  <br />
                  <h2 class="mt-2 font-weight-bold headline">결제 수단 추가</h2>
                </div>
              </v-card-title>
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <!--Cleave.js-->
                    <card v-model="cardDetail" :invert-card.sync="invertedCard"></card>
                  </v-col>
                  <v-col cols="12" class="mt-12">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="cardDetail.name"
                          name="name"
                          :rules="[errors.first('name') || !errors.has('name')]"
                          placeholder="소유주 성함"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="cardDetail.birth"
                          name="birth"
                          v-validate="'required|min:6'"
                          :rules="[errors.first('birth') || !errors.has('birth')]"
                          maxlength="10"
                          placeholder="생년월일 6자리 또는 사업자번호 10자리"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="hidden-md-and-up">
                        <v-text-field
                          v-model="cardDetail.number"
                          name="number"
                          :rules="[errors.first('number') || !errors.has('number')]"
                          placeholder="카드번호"
                          v-mask="'####-####-####-####'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="hidden-sm-and-down pr-1">
                        <v-text-field
                          v-model="cardDetail.numberArray[0]"
                          name="number0"
                          :rules="[errors.first('number0') || !errors.has('number0')]"
                          @input="mergeNumber"
                          placeholder="xxxx"
                          v-mask="'####'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="hidden-sm-and-down pr-1">
                        <v-text-field
                          v-model="cardDetail.numberArray[1]"
                          name="number1"
                          :rules="[errors.first('number1') || !errors.has('number1')]"
                          @input="mergeNumber"
                          placeholder="xxxx"
                          v-mask="'####'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="hidden-sm-and-down pr-1">
                        <v-text-field
                          v-model="cardDetail.numberArray[2]"
                          name="number2"
                          :rules="[errors.first('number2') || !errors.has('number2')]"
                          @input="mergeNumber"
                          placeholder="xxxx"
                          v-mask="'####'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="hidden-sm-and-down">
                        <v-text-field
                          v-model="cardDetail.numberArray[3]"
                          name="number3"
                          :rules="[errors.first('number3') || !errors.has('number3')]"
                          @input="mergeNumber"
                          placeholder="xxxx"
                          v-mask="'####'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pr-2" cols="12" md="6">
                        <v-text-field
                          v-model="cardDetail.expiry"
                          name="expiry"
                          :rules="[errors.first('expiry') || !errors.has('expiry')]"
                          placeholder="유효기간"
                          v-mask="'##/##'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="cardDetail.pwd_2digit"
                          name="pwd_2digit"
                          :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                          :rules="[errors.first('pwd_2digit') || !errors.has('pwd_2digit')]"
                          :type="showPassword ? 'text' : 'password'"
                          placeholder="비밀번호 앞 두자리(법인카드 생략가능)"
                          v-mask="'##'"
                          hide-details
                          single-line
                          outlined
                          solo
                          flat
                          @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="rouge"
                  outlined
                  flat
                  @click.native="
                    showAddCard = false;
                    resetCardData();
                  "
                  >취소</v-btn
                >
                <v-btn
                  class="rouge white-two--text"
                  flat
                  @click="
                    showAddCard = false;
                    addCard();
                  "
                  >저장</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Card from '@/components/company/pay/card/components/Card';
import companyMixin from '@/components/shared/companyMixin';
import userMixin from '@/components/shared/userMixin';
import { mask } from 'vue-the-mask';

const defaultCreditCardData = {
  customer_uid: '',
  card_number: '',
  customer_name: '',
  number: '',
  numberArray: [],
  name: '',
  expiry: '',
  birth: '',
  pwd_2digit: '',
};

export default {
  name: 'cardreg',
  components: {
    Card,
  },
  mixins: [userMixin, companyMixin],
  directives: {
    mask,
  },
  props: ['option', 'cards'],
  data() {
    return {
      local_cards: [],
      newWindow: null,
      intervalID: null,
      showDetail: false,
      mainCard: undefined,
      showAddCard: false,
      cardDetail: defaultCreditCardData,
      invertedCard: false,
      showPassword: false,
      showChangeCard: false,
    };
  },
  watch: {
    cards(value) {
      this.local_cards = value;
      this.local_cards.forEach((card, idx) => {
        if (card.main) {
          this.mainCard = idx;
        }
      });
    },
  },
  methods: {
    async mergeNumber() {
      this.cardDetail.number = this.cardDetail.numberArray.join('-');
    },
    async save() {
      this.local_cards.forEach((card, idx) => {
        card.main = this.mainCard === idx;
        this.axios.put('/company/user/card?userId=' + this.user.id + '&id=' + card.id, card);
      });
    },
    async addCard() {
      console.log(this.cardDetail.number);
      try {
        this.cardDetail.card_number = this.cardDetail.number.replace(/[' ']/gi, '-');
        this.cardDetail.customer_name = this.cardDetail.name;
        let cardData = JSON.parse(JSON.stringify(this.cardDetail));
        let tempExpiry = cardData.expiry.split('/');
        cardData.expiry = '20' + tempExpiry[1].trim() + '-' + tempExpiry[0].trim();

        let result = await this.axios.post('/company/user/card?userId=' + this.user.id, cardData);
        if (result.data.message) {
          this.$store.commit('setMsg', result.data.message);
          this.$store.commit('setShowSnackbar', true);
        }
        let cards = await this.axios.get('/company/user/card?userId=' + this.user.id);
        this.local_cards = cards.data.cards;
        this.local_cards.forEach((card, idx) => {
          if (card.main) {
            this.mainCard = idx;
          }
        });
        this.resetCardData();
      } catch (e) {
        this.$store.commit('setMsg', '카드 정보를 확인해 주세요.');
        this.$store.commit('setShowSnackbar', true);
        console.log(e);
        this.resetCardData();
      }
    },
    async delCard(card) {
      try {
        await this.axios.delete('/company/user/card?userId=' + this.user.id + '&id=' + card.id);
        let cards = await this.axios.get('/company/user/card?userId=' + this.user.id);
        this.local_cards = cards.data.cards;
        this.local_cards.forEach((card, idx) => {
          if (card.main) {
            this.mainCard = idx;
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    resetCardData() {
      this.cardDetail.customer_uid = '';
      this.cardDetail.card_number = '';
      this.cardDetail.customer_name = '';
      this.cardDetail.number = '';
      this.cardDetail.numberArray = [];
      this.cardDetail.name = '';
      this.cardDetail.expiry = '';
      this.cardDetail.birth = '';
      this.cardDetail.pwd_2digit = '';
    },
  },
  async created() {
    this.$store.commit('setPayState', 0);
    this.local_cards = this.cards;
    this.local_cards.forEach((card, idx) => {
      if (card.main) {
        this.mainCard = idx;
      }
    });
  },
};
</script>
