<script>
import { Line } from 'vue-chartjs';

export default {
  name: 'NaverChart',
  extends: Line,
  props: ['data'],
  watch: {
    data() {
      this.displayChart();
    },
  },
  methods: {
    displayChart() {
      this.renderChart(
        {
          datasets: this.data,
        },
        {
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          elements: {
            line: {
              cubicInterpolationMode: 'monotone',
            },
          },
          responsive: !0,
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'YYYY-MM-DD',
                  displayFormats: {
                    day: 'MM.DD',
                  },
                },
                gridLines: {
                  display: false,
                },
                distribution: 'series',
                ticks: {
                  source: 'auto', //'data'
                },
              },
            ],
            yAxes: [
              {
                // stacked: true,
                ticks: {
                  // beginAtZero: !0,
                  maintainAspectRatio: 0,
                  reverse: this.data[0] ? this.data[0].reverseY : false,
                },
                scaleLabel: {
                  display: false,
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    this.displayChart();
  },
};
</script>

<style scoped lang="scss"></style>
