<template>
  <v-col class="mt-1">
    <v-col class="shades transparent pa-6">
      <v-row class="px-0">
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 my-2" style="color: #484848">캠페인 명</div>
              <v-text-field
                v-model="form.name"
                name="name"
                type="text"
                :rules="[errors.first('name') || !errors.has('name')]"
                hide-details
                disabled
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                color="rouge"
                class="mt-11"
                flat
                tile
                outlined
                style="width: 140px"
                @click="showChangeCampaignName = true"
                >캠페인명 변경</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 my-2" style="color: #484848">가맹점코드</div>
              <v-text-field
                :value="camData.code"
                name="code"
                type="text"
                :rules="[errors.first('code') || !errors.has('code')]"
                hide-details
                disabled
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
            <v-col>
              <v-btn color="rouge" class="mt-11" flat tile outlined style="width: 140px" @click="showDialog = true"
                >코드변경</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 mt-6 mb-2" style="color: #484848">광고 범위</div>
              <v-text-field
                :value="(camData.category || {}).firstName"
                name="firstName"
                type="text"
                :rules="[errors.first('firstName') || !errors.has('firstName')]"
                hide-details
                disabled
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 mt-6 mb-2" style="color: #484848">광고 형태</div>
              <v-text-field
                :value="camData.type === 'P' ? '기자단' : '체험단'"
                name="type"
                type="text"
                :rules="[errors.first('type') || !errors.has('type')]"
                hide-details
                disabled
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 mt-6 mb-2" style="color: #484848">분야</div>
              <v-text-field
                :value="(camData.category || {}).secondName"
                name="secondName"
                type="text"
                :rules="[errors.first('secondName') || !errors.has('secondName')]"
                hide-details
                disabled
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 mt-6 mb-2" style="color: #484848">세부 유형</div>
              <v-text-field
                :value="(camData.category || {}).thirdName"
                name="thirdName"
                type="text"
                :rules="[errors.first('thirdName') || !errors.has('thirdName')]"
                hide-details
                disabled
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="showDialog" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showDialog = false">close</v-icon><br />
                <h2 class="mt-2 font-weight-bold headline">가맹점 코드 변경</h2>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="12">
                  <div class="body-1 mt-0 mb-6">
                    매장 이용확인시 필요한 가맹점 코드를<br />
                    숫자 두 자리 형식으로 입력해주세요.
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="form.code"
                    name="coded"
                    type="text"
                    v-validate="'required'"
                    :rules="[errors.first('coded') || !errors.has('coded')]"
                    placeholder="가맹점 코드"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat
                    required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="rouge"
                outlined
                flat
                @click.native="
                  showDialog = false;
                  form.code = camData.code;
                "
                >취소</v-btn
              >
              <v-btn
                class="rouge white-two--text"
                flat
                @click="
                  showDialog = false;
                  save();
                "
                >저장</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="showChangeCampaignName" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showChangeCampaignName = false">close</v-icon><br />
                <h2 class="mt-2 font-weight-bold headline">캠페인 명 변경</h2>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="12">
                  <div class="body-1 mt-0 mb-6">새 캠페인명을 입력해주세요.</div>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="form.name"
                    name="newName"
                    type="text"
                    v-validate="'required'"
                    :rules="[errors.first('newName') || !errors.has('newName')]"
                    placeholder="새 캠페인 명"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat
                    required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="rouge"
                outlined
                flat
                @click.native="
                  showChangeCampaignName = false;
                  form.name = camData.name;
                "
                >취소</v-btn
              >
              <v-btn
                class="rouge white-two--text"
                flat
                @click="
                  showChangeCampaignName = false;
                  save();
                "
                >저장</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Page1',
  mixins: [userMixin],
  props: ['camData'],
  data: function () {
    return {
      form: {
        name: this.camData.name,
        code: this.camData.code,
      },
      showDialog: false,
      showChangeCampaignName: false,
    };
  },
  watch: {
    camData(value) {
      this.form = {
        name: value.name || '',
        code: value.code || '',
      };
    },
  },
  methods: {
    async save() {
      let success = await this.$validator.validateAll();
      if (success) {
        await this.axios.put('/campaign?id=' + (this.camData.id || this.camData.rKey), { ...this.form, delay: true });
        await this.$store.dispatch('getCampaignInfo', { email: this.user.email });
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-text-field--outlined.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outlined.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
}
</style>
