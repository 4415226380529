<template>
  <div class="whiteBg">
    <div class="row3 text">
      <h2 class="font-weight-bold">
        1만 명의 사장님이 경험한<br />슈퍼멤버스 성공 사례를<span class="mobile" />
        확인해보세요.
      </h2>
      <p>붐비는 가게의 사장님들은 이미 슈퍼멤버스를 사용하고 있습니다.</p>
    </div>
    <v-row class="mt-6 mb-4">
      <v-spacer></v-spacer>
      <v-chip-group
        v-model="selected"
        column
        mandatory
        class="px-4"
        active-class="white--text"
        style="color: #484848; font-size: 13px; pointer-events: auto; justify-content: center">
        <v-chip
          v-for="(filter, index) in ['전체', '매장', '제품']"
          :key="index"
          :color="selected === index ? '#EA2A3A' : '#f5f5f5'"
          style="color: #484848; font-size: 13px; pointer-events: auto">
          {{ filter }}
        </v-chip>
      </v-chip-group>
      <v-spacer></v-spacer>
    </v-row>
    <v-sheet class="mx-auto" style="background-color: #faf8f9"> </v-sheet>
    <slick ref="slick" :options="slickOptions" v-if="images.length > 0" style="padding-bottom: 72px">
      <div class="item" v-for="data in images" :key="`${data.keyword}-${data.title}-${data.img}`">
        <v-card flat style="cursor: pointer; background-color: transparent">
          <div style="display: flex; justify-content: center">
            <span
              style="
                padding: 4px 8px;
                border-radius: 8px;
                border: 1px solid;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              "
              :style="
                data.title === '매장'
                  ? 'border-color : #EA2A3A; color : #EA2A3A;'
                  : 'border-color : #4463BB; color : #4463BB;'
              ">
              {{ data.title }}
            </span>
          </div>
          <div
            class="mt-2"
            style="
              padding: 8px 14px;
              border-radius: 28px;
              border: 1px solid #5bc567;
              background: #fff;
              box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <img
              :src="require('@/assets/img/landing/ceo-landing/example/simple-icons_naver.png')"
              style="width: 16px; height: 16px" />
            <span style="color: #1e1e22; font-size: 14px; font-style: normal; font-weight: 700; line-height: normal">{{
              data.keyword
            }}</span>
            <img :src="require('@/assets/img/landing/ceo-landing/example/mic.png')" style="width: 16px; height: 20px" />
          </div>
          <img
            class="mt-2"
            :src="data.img"
            :width="$vuetify.breakpoint.mdAndUp ? 210 : 160"
            style="box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1); border-radius: 12px 12px 0px 0px" />
        </v-card>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from 'vue-slick';

const 매장 = [
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/1.png'),
    keyword: '코엑스 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/2.png'),
    keyword: '이태원 스테이크',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/3.png'),
    keyword: '삼성동 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/4.png'),
    keyword: '신논현역 피자',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/5.png'),
    keyword: '홍대 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/6.png'),
    keyword: '연남동 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/7.png'),
    keyword: '홍대 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/8.png'),
    keyword: '송리단길 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/9.png'),
    keyword: '연남동 피자',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/10.png'),
    keyword: '도산공원 술집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/11.png'),
    keyword: '동대입구역 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/12.png'),
    keyword: '경리단길 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/13.png'),
    keyword: '제주도 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/14.png'),
    keyword: '합정 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/15.png'),
    keyword: '종로 피자',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/16.png'),
    keyword: '상수 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/17.png'),
    keyword: '익선동 피자',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/18.png'),
    keyword: '서울대입구역 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/19.png'),
    keyword: '당산 피자',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/20.png'),
    keyword: '제주 스테이크',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/21.png'),
    keyword: '도산공원 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/22.png'),
    keyword: '속초 물회 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/23.png'),
    keyword: '당산 피자',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/24.png'),
    keyword: '신용산 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/25.png'),
    keyword: '사당역 술집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/26.png'),
    keyword: '영등포 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/27.png'),
    keyword: '잠실역 카페',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/28.png'),
    keyword: '양양 물회',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/29.png'),
    keyword: '용리단길 맛집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/30.png'),
    keyword: '용산역 와인바',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/31.png'),
    keyword: '청담 술집',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/32.png'),
    keyword: '구로디지털단지 삼겹살',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/33.png'),
    keyword: '상암동 샐러드',
  },
  {
    title: '매장',
    img: require('@/assets/img/landing/ceo-landing/example/매장/34.png'),
    keyword: '의정부역 파스타',
  },
];

const 제품 = [
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/1.png'),
    keyword: '20대 여성 가방',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/2.png'),
    keyword: '가죽 반지갑',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/3.png'),
    keyword: '현관 도어락',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/4.png'),
    keyword: '강아지샴푸',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/5.png'),
    keyword: '원피스수영복',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/6.png'),
    keyword: '프로바이오틱스유산균',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/7.png'),
    keyword: '헤드폰 추천',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/8.png'),
    keyword: '아이스티',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/9.png'),
    keyword: '여성수영복',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/10.png'),
    keyword: '탈모샴푸 1위',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/11.png'),
    keyword: '아샷추',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/12.png'),
    keyword: '아이스티',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/13.png'),
    keyword: '대평폐기물스티커',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/14.png'),
    keyword: '아기유산균추천',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/15.png'),
    keyword: '오버핏 청자켓',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/16.png'),
    keyword: '가죽 부츠',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/17.png'),
    keyword: '곤약밥',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/18.png'),
    keyword: '국산콩 두유',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/19.png'),
    keyword: '기능성 브라',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/20.png'),
    keyword: '등산 마스크',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/21.png'),
    keyword: '라면포트',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/22.png'),
    keyword: '보정 브라',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/23.png'),
    keyword: '브라런닝',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/24.png'),
    keyword: '비건 두유',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/25.png'),
    keyword: '에멀전',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/26.png'),
    keyword: '여성 가방 추천',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/27.png'),
    keyword: '여자 캡모자',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/28.png'),
    keyword: '오버핏 후드티',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/29.png'),
    keyword: '운동 마스크',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/30.png'),
    keyword: '자동차 점프스타터',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/31.png'),
    keyword: '전기 냄비',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/32.png'),
    keyword: '전동킥보드',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/33.png'),
    keyword: '핸드폰 침대 거치대',
  },
  {
    title: '제품',
    img: require('@/assets/img/landing/ceo-landing/example/제품/34.png'),
    keyword: '헤드폰',
  },
];

export default {
  name: 'Example',
  components: {
    Slick,
  },
  data() {
    return {
      images: [],
      selected: 0,
      items: [],
      slickOptions: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 16000,
        variableWidth: true,
        centerMode: true,
        centerPadding: '1px',
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              autoplay: true,
              autoplaySpeed: 1300,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
  },
  watch: {
    selected(value) {
      switch (value) {
        case 0:
          this.images = [...매장, ...제품].sort(() => Math.random() - 0.5);
          break;
        case 1:
          this.images = [...매장].sort(() => Math.random() - 0.5);
          break;
        case 2:
          this.images = [...제품].sort(() => Math.random() - 0.5);
          break;
      }
    },
  },
  mounted() {
    // this.items = this._.flatten(this.filters.map((filter) => {
    //   return filter.datas;
    // }));
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
      }
    });
  },
  created() {
    this.images = [...매장, ...제품].sort(() => Math.random() - 0.5);
  },
};
</script>

<style scoped lang="scss">
::v-deep .slick-slide {
  padding-bottom: 0;
}

::v-deep .slick-list {
  overflow: inherit;
}

::v-deep .v-slide-group__content {
  justify-content: center;
}

.row3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 72px 0 12px;
  @media (max-width: 960px) {
    padding: 80px 0px 12px;
    overflow: hidden;
  }
}
.text {
  .eyebrow {
    color: #757575;
    margin-bottom: 4px;
    margin-left: 1px;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 12px;
    color: #212121;
  }
  p {
    color: #484848;
    line-height: 1.8;
    font-weight: 700;
  }
  @media (max-width: 960px) {
    // padding-top: 0;
    // margin-bottom: auto;
    .eyebrow {
      margin-bottom: 6px;
      font-size: 13px;
    }
    h2 {
      margin-bottom: 12px;
    }
    p {
      color: #484848;
      line-height: 1.94;
      padding: 0 24px;
    }
  }
}

.white--text {
  color: white;
  background-color: #ea2a3a;
}
.whiteBg {
  background-color: #fbfbfb;
}
</style>
