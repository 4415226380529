<template>
  <div class="container contents help-contents">
    <h2 class="title-left font-weight-bold">개인정보처리방침</h2>
    <br />

    <h4 class="title-left title">1. 개인정보의 처리 목적</h4>
    <br />
    <ol class="help">
      <(주)마야크루>(‘슈퍼멤버스(www.supermembers.co.kr)’, ‘슈퍼차트(www.superchart.com)’) (이하 ‘회사’) 는 다음의
      목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.<br />
      - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에
      따른 금액 결제, 물품 또는 서비스의 공급.배송 등
    </ol>
    <br />

    <h4 class="title-left title">2. 개인정보의 처리 및 보유 기간</h4>
    <br />
    <ol class="help">
      ① 회사는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보
      보유․이용기간 내에서 개인정보를 처리․보유합니다.<br />
      <br />
      ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.<br />
      - 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계 잔존시에는 해당 채권․채무관계
      정산시까지<br />
      - 전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년
    </ol>
    <br />

    <h4 class="title-left title">3. 개인정보의 제3자 제공</h4>
    <br />
    <ol class="help">
      회사는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우 외에는 개인정보를
      제3자에게 제공하지 않습니다.
    </ol>

    <h4 class="title-left title">4. 개인정보처리 위탁</h4>
    <br />
    <ol class="help">
      ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br />
      1. <사이트 및 앱 내의 신용카드 결제><br />
      - 위탁받는 자 (수탁자) : 주식회사 나이스페이먼츠<br />
      - 위탁하는 업무의 내용 : 구매 및 요금 결제<br />
      - 위탁기간 : 5년<br />
      <br />
      2. <사이트 및 앱 내의 신용카드 결제><br />
      - 위탁받는 자 (수탁자) : 주식회사 케이지 이니시스<br />
      - 위탁하는 업무의 내용 : 구매 및 요금 결제<br />
      - 위탁기간 : 5년<br />
      <br />
      ② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
      보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
      개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br />
      <br />
      ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br />
    </ol>
    <br />

    <h4 class="title-left title">
      5. 정보주체의 권리, 의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
    </h4>
    <br />
    <ol class="help">
      ① 정보주체는 회사에 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br />
      1. 개인정보 열람요구<br />
      2. 오류 등이 있을 경우 정정 요구<br />
      3. 삭제요구<br />
      4. 처리정지 요구<br />
    </ol>
    <br />

    <h4 class="title-left title">6. 처리하는 개인정보의 항목 작성</h4>
    <br />
    <ol class="help">
      ① 회사는 다음의 개인정보 항목을 처리하고 있습니다.<br />
      <회원가입><br />
      - 필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키,
      접속 IP 정보, 결제기록<br />
      - 선택항목 : 회사전화번호, 회사명, 신용카드정보<br />
    </ol>
    <br />

    <h4 class="title-left title">
      7. 개인정보의 파기 - 회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.
      파기의 절차, 기한 및 방법은 다음과 같습니다.
    </h4>
    <br />
    <ol class="help">
      -파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련
      법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는
      다른 목적으로 이용되지 않습니다.<br />
      -파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의
      처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가
      불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br />
    </ol>
    <br />

    <h4 class="title-left title">
      8. 개인정보의 안전성 확보 조치 회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적
      및 물리적 조치를 하고 있습니다.
    </h4>
    <br />
    <ol class="help">
      1. 내부관리계획의 수립 및 시행<br />
      개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br />
      <br />
      2. 접속기록의 보관 및 위변조 방지<br />
      개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지
      않도록 보안기능 사용하고 있습니다.<br />
      <br />
      3. 개인정보에 대한 접근 제한<br />
      개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여
      필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br />
    </ol>
    <br />

    <h4 class="title-left title">9. 개인정보 보호책임자 작성</h4>
    <br />
    <ol class="help">
      ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
      등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br />
      <br />
      ▶ 개인정보 보호책임자<br />
      성명 :오준호<br />
      직책 :총괄관리자<br />
      직급 :대표<br />
      연락처 :02-876-0119, company@supermembers.co.kr,<br />
      ※ 개인정보 보호 담당부서로 연결됩니다.<br />
      <br />
      ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
      등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)마야크루(‘www.supermembers.co.kr’이하
      ‘슈퍼멤버스) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br />
    </ol>
    <br />

    <h4 class="title-left title">10. 개인정보 처리방침 변경</h4>
    <br />
    <ol class="help">
      ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는
      변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
    </ol>
    <br />

    <h4 class="title-left title">11. 기타사항</h4>
    <br />
    <ol class="help">
      이용자는 회사의 서비스를 이용하기 위해 YouTube 채널을 인증할 수 있습니다. 이 때 회사의 서비스는 YouTube API
      서비스를 활용하게 되고, 이용자가 회사 웹사이트를 통해 Youtube 콘텐츠 및 서비스를 이용하게 될 때, 이용자는
      <a target="_blank" href="https://www.youtube.com/t/terms">YouTube 서비스 약관</a
      >,
      <a target="_blank" href="https://policies.google.com/privacy">개인정보 보호정책</a
      >을 적용 받는 것에 동의합니다. 이용자는
      <a target="_blank" href="https://myaccount.google.com/permissions?pli=1">Google 계정 설정 사이트</a
      >를 통해 회사의 서비스에서 YouTube 채널 인증을 철회할 수 있습니다. 회사는 제3자 서비스의 콘텐츠 또는 개인정보
      처리방침에 대해 책임을 지지 않습니다.
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
};
</script>

<style scoped lang="scss">
.help-contents {
  padding: 80px 0;
  font-size: 14px;
}

.help {
  line-height: 1.8em;
  padding-bottom: 20px;
}
</style>
