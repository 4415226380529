import colors from 'vuetify/lib/util/colors';

export default {
  // primary: {
  //   base: colors.purple.base,
  //   darken1: colors.purple.darken2,
  // },
  // primary: '#ee44aa',
  // secondary: '#424242',
  // accent: '#82B1FF',
  // error: '#FF5252',
  // info: '#2196F3',
  // success: '#4CAF50',
  // warning: '#FFC107',
  primary: '#b00016',
  secondary: '#395a97',
  default: colors.shades.black,
  rouge: '#bd1328',
  'default-semilight': '#1b1b1b',
  'default-light': '#9b9b9b',
  'border-light': '#c7c7c7',
  border: '#979797',
  'card-background': '#fbfbfb',
  golden: '#ecb800',
  crimson: '#7d0010',
  'french-blue': '#4770bd',
  'dark-slate-blue': '#263c64',
  'warm-grey': colors.grey.darken1,
  white: colors.grey.lighten4,
  'pinkish-grey': '#c2c2c2',
  'white-two': colors.shades.white,
  'light-pink': '#faf8f9',
};
