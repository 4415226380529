import { render, staticRenderFns } from "./Regcam.vue?vue&type=template&id=03d90892&scoped=true"
import script from "./Regcam.vue?vue&type=script&lang=js"
export * from "./Regcam.vue?vue&type=script&lang=js"
import style0 from "./Regcam.vue?vue&type=style&index=0&id=03d90892&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d90892",
  null
  
)

export default component.exports