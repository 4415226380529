<template>
  <div class="pb-2 fab-wrapper d-flex flex-column align-center" v-if="!isTop">
    <button class="fab" style="line-height: 26px" @click="$emit('update:showConsultDialog', true)">
      지금 시작하면 20% 할인!
      <v-icon style="color: #fff; margin: 0 0 0 4px">chevron_right</v-icon>
    </button>

    <img src="@/assets/img/landing/ceo-landing/pointer.png" alt="fab-pointer" width="40px" height="61px" />
  </div>
</template>

<script>
export default {
  name: 'Fab',
  props: {
    showConsultDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTop: true,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isTop = window.scrollY <= 500;
    },
  },
};
</script>

<style scoped lang="scss">
.fab-wrapper {
  position: fixed;
  bottom: 5%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, 0);
}

.fab {
  width: 220px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 12px 24px 0 rgba(16, 16, 16, 0.32);
  color: #fff;

  background-color: #212121;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

@keyframes updown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

img {
  margin-top: -15px;
  animation: updown 2s ease-in-out infinite;
  pointer-events: none;
}
</style>
