<template>
  <v-col>
    <v-row>
      <v-card v-if="!$route.query.id" flat style="width: 100%; background-color: #f2f2f2">
        <row v-for="(data, idx) in datas" :key="idx">
          <v-data-table
            :headers="headers"
            :items="data"
            item-key="name"
            class="mt-3"
            hide-default-footer
            disable-sort
            mobile-breakpoint="0"
            :hide-default-header="$vuetify.breakpoint.smAndDown"
            @click:row="detailResult(data[0])"
            style="backgroud-color: transparent">
            <template v-slot:header.name="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:header.total="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:header.accept="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:header.wait="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:header.refuse="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:header.noRes="{ header }">
              <span style="font-size: 13px; color: #484848">{{ header.text }}</span>
            </template>
            <template v-slot:item.name="{ item }">
              <v-row class="body-1" style="color: #484848">
                {{ item.name }}
              </v-row>
              <v-row class="hidden-md-and-up">
                <div v-if="(item.wait || 0) > 0" class="" style="color: #c2c2c2">
                  <v-icon style="color: #c2c2c2">mdi-dots-horizontal-circle</v-icon>
                  수락 대기중
                </div>
                <div v-else style="color: #34cd89">
                  <v-icon style="color: #34cd89">mdi-check-underline-circle</v-icon>
                  제안 완료
                </div>
              </v-row>
              <v-row class="hidden-md-and-up">
                <v-col>
                  <v-row style="color: #808080; font-size: 10px"> 총 인원 </v-row>
                  <v-row class="body-1" style="color: #484848">
                    {{ item.total }}
                  </v-row>
                </v-col>
                <v-col>
                  <v-row style="color: #808080; font-size: 10px"> 수락 인원 </v-row>
                  <v-row class="body-1" style="color: #484848">
                    {{ item.accept }}
                  </v-row>
                </v-col>
                <v-col>
                  <v-row style="color: #808080; font-size: 10px"> 대기중 인원 </v-row>
                  <v-row class="body-1" style="color: #484848">
                    {{ item.wait }}
                  </v-row>
                </v-col>
                <v-col>
                  <v-row style="color: #808080; font-size: 10px"> 거절 </v-row>
                  <v-row class="body-1" style="color: #bd1328">
                    {{ item.refuse }}
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.status="{ item }">
              <div style="font-size: 12px">
                <div v-if="(item.wait || 0) > 0" class="" style="color: #c2c2c2">
                  <v-icon style="color: #c2c2c2">mdi-dots-horizontal-circle</v-icon>
                  수락 대기중
                </div>
                <div v-else style="color: #34cd89">
                  <v-icon style="color: #34cd89">mdi-check-underline-circle</v-icon>
                  제안 완료
                </div>
              </div>
            </template>
            <template v-slot:item.total="{ item }">
              <v-row class="body-1" style="color: #484848"> {{ item.total }}명 </v-row>
            </template>
            <template v-slot:item.accept="{ item }">
              <v-row class="body-1" style="color: #4770bd"> {{ item.accept }}명 </v-row>
            </template>
            <template v-slot:item.wait="{ item }">
              <v-row class="body-1" style="color: #484848"> {{ item.wait }}명 </v-row>
            </template>
            <template v-slot:item.refuse="{ item }">
              <v-row class="body-1" style="color: #757575"> {{ item.refuse }}명 </v-row>
            </template>
            <template v-slot:item.noRes="{ item }">
              <v-row class="body-1" style="color: #757575"> {{ item.noRes }}명 </v-row>
            </template>
          </v-data-table>
        </row>
      </v-card>
      <v-card v-else flat style="width: 100%; background-color: #f2f2f2">
        <div>
          <v-toolbar
            flat
            class="pl-4 my-4"
            @click="
              detail = null;
              $router.push({ path: 'propositionyoutube', query: { tab: 1 } });
            "
            style="cursor: pointer">
            <v-icon>chevron_left</v-icon> <span class="ml-5">목록보기</span>
          </v-toolbar>
        </div>
        <v-row class="pa-4 mb-4" style="background-color: white">
          <v-col md="5" style="font-size: 13px; color: #484848">
            <v-row> 캠페인 </v-row>
            <v-row class="mt-2">
              <v-select
                :items="datas1"
                item-text="name"
                item-value="id"
                :value="((datas1 || [])[selectedCampaign] || {}).id"
                height="40px"
                hide-details
                outlined
                solo
                flat
                single-line
                @change="changeCampaign">
              </v-select>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headersDetail"
          :items="detail.propositions"
          item-key="id"
          :items-per-page.sync="options.itemsPerPage"
          :options.sync="options"
          :search="filter"
          :custom-filter="filterStatus"
          disable-sort
          mobile-breakpoint="0"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
          hide-default-footer
          :footer-props="{
            itemsPerPageOptions: [],
          }">
          <template v-slot:header.taggableType="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.Youtuber.account="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.status="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.Youtuber.adFee="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.point="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.maxEditCount="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.message="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.proposedAt="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.deadline="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:header.rePropose="{ header }">
            <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
          </template>
          <template v-slot:item.taggableType="props">
            <naverIcon v-if="props.item.taggableType === 'naver'" />
            <instaIcon v-if="props.item.taggableType === 'insta'" />
            <youtubeIcon v-if="props.item.taggableType === 'youtube'" />
          </template>
          <template v-slot:top>
            <v-row>
              <div class="body-2 my-4 mx-4" style="color: #808080">
                {{ detail.propositions.length }}개 ・
                {{ Math.floor((parseInt(detail.propositions.length) - 1) / options.itemsPerPage + 1) }} Pages
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color: #484848; font-size: 13px">
                      {{ options.itemsPerPage }}명씩 보기
                      <v-icon small>expand_more</v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in [30, 50, 100]"
                      :key="index"
                      @click="options.itemsPerPage = item">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color: #484848; font-size: 13px">
                      {{ filter }}
                      <v-icon small>expand_more</v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in [
                        '상태 전체',
                        '확인중',
                        '제안 수락',
                        '제안 거절',
                        '무응답',
                        '수락 후 취소',
                      ]"
                      :key="index"
                      @click="filter = item">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.Youtuber.account="{ item }">
            <v-row class="py-2" @click="youtuberDetailDlg(item.Youtuber)">
              <v-col cols="3">
                <v-avatar size="40">
                  <img v-if="item.Youtuber.thumbnail" :src="item.Youtuber.thumbnail" />
                  <img v-else src="@/assets/img/matching-placeholder.jpg" />
                </v-avatar>
              </v-col>
              <v-col cols="8" class="ml-2">
                <v-row class="caption mt-3" style="color: #4770bd">
                  {{ item.Youtuber.title }}
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up">
              <v-col>
                <v-row style="color: #808080; font-size: 10px"> 제안금액 </v-row>
                <v-row class="caption" style="color: #808080"> ₩{{ (item.point || 0) | numFormat }} </v-row>
              </v-col>
              <v-col>
                <v-row style="color: #808080; font-size: 10px"> 제안일 </v-row>
                <v-row class="caption" style="color: #808080">
                  <div class="caption" style="color: #808080" v-if="item.proposedAt">
                    {{ $moment(item.proposedAt).tz('Asia/Seoul').format('YYYY.MM.DD') }}
                  </div>
                  <div v-else>-</div>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="caption" style="color: #808080">
              <div v-if="(item.status || 0) <= 1" style="color: #c2c2c2">
                <v-icon style="color: #c2c2c2">mdi-dots-horizontal-circle</v-icon>
                확인중
              </div>
              <div v-else-if="(item.status || 0) === 3" style="color: #bd1328">
                <v-row>
                  <v-icon :class="item.reasonRefuse ? 'mt-2' : ''" style="color: #bd1328">mdi-minus-circle</v-icon>
                  <v-col class="ml-1">
                    <a
                      @click="
                        () => {
                          showReasonRefuseDialog = true;
                          reasonRefuseEditProposition = item;
                        }
                      "
                      href="#">
                      제안 거절<br /><span style="font-size: 12px; text-decoration: underline">{{
                        item.reasonRefuse
                      }}</span>
                    </a>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="(item.status || 0) === 4" style="color: #bd1328">
                <v-icon style="color: #bd1328">mdi-dots-horizontal-circle</v-icon>
                무응답
              </div>
              <div v-else-if="(item.status || 0) === 50" style="color: #c2c2c2">
                <v-icon style="color: #c2c2c2">mdi-dots-horizontal-circle</v-icon>
                수락 후 취소
              </div>
              <div v-else style="color: #34cd89">
                <v-icon style="color: #34cd89">mdi-check-underline-circle</v-icon>
                제안 수락
              </div>
            </div>
            <v-row class="hidden-md-and-up pt-3">
              <v-col>
                <v-row style="color: #808080; font-size: 10px"> 제안 남은 시간 </v-row>
                <v-row class="caption" style="color: #808080"> 0 </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Youtuber.adFee="{ item }">
            <v-row class="caption" style="color: #808080"> ₩{{ (item.Youtuber.adFee || 0) | numFormat }} </v-row>
          </template>
          <template v-slot:item.point="{ item }">
            <v-row class="caption" style="color: #808080"> ₩{{ (item.point || 0) | numFormat }} </v-row>
          </template>
          <template v-slot:item.maxEditCount="{ item }">
            <v-row class="caption" style="color: #808080"> {{ (item.maxEditCount || 0) | numFormat }}회 </v-row>
          </template>
          <template v-slot:item.message="{ item }">
            <v-row v-if="item.message" class="caption">
              <a
                style="color: #4770bd"
                @click="
                  () => {
                    showMessageDialog = true;
                    messageEditProposition = item;
                  }
                ">
                <v-icon color="#4770BD" class="mr-2">mdi-file-document-outline</v-icon>
                보기
              </a>
            </v-row>
            <v-row v-else> - </v-row>
          </template>
          <template v-slot:item.proposedAt="props">
            <div class="caption" style="color: #808080" v-if="props.item.proposedAt">
              {{ $moment(props.item.proposedAt).tz('Asia/Seoul').format('YYYY.MM.DD HH:MM') }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.deadline="props">
            <div class="caption" style="color: #808080" v-if="props.item.proposedAt">
              {{ getTimeRemaining(props.item) }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:item.rePropose="{ item }">
            <v-menu v-model="item.campaignAddMenu" :close-on-content-click="false" :nudge-width="200" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon color="#4770BD" class="mr-2">mdi-playlist-plus</v-icon>
                </div>
              </template>
              <v-card>
                <v-list>
                  <v-subheader style="color: #484848; font-size: 13px">다음 캠페인에 추가</v-subheader>
                  <v-list-item v-if="youtubeCampaigns.length === 0">
                    <v-list-item-title style="font-size: 14px">
                      <router-link to="/regcam/" style="color: #484848"
                        ><v-icon>mdi-plus-circle-outline</v-icon> 새 캠페인 추가</router-link
                      >
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(campaign, index) in youtubeCampaigns"
                    @click="updateToProposition(campaign, item)"
                    :key="index">
                    <v-list-item-title
                      style="font-size: 14px"
                      :style="checkProposition(campaign, item.Youtuber) === 2 ? 'color:#c2c2c2' : 'color:#484848'"
                      >{{ campaign.name }}
                      <v-chip outlined small color="#757575" v-if="checkProposition(campaign, item.Youtuber) === 2"
                        >제안 중</v-chip
                      >
                      <v-chip outlined small color="#BD1328" v-else-if="checkProposition(campaign, item.Youtuber)"
                        >삭제</v-chip
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
            v-model="options.page"
            :length="Math.floor((parseInt(detail.propositions.length) - 1) / options.itemsPerPage + 1)"
            :total-visible="10"></v-pagination>
        </div>
      </v-card>
    </v-row>
    <v-row justify="center">
      <YoutuberDetail v-model="openYoutuberDetail" :openYoutuber="openYoutuber"></YoutuberDetail>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showMessageDialog" content-class="white-two" persistent max-width="500px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showMessageDialog = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold subheading" style="color: #484848">제안 메시지</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12">
                <h2 class="font-weight-bold subheading mt-0 mb-2">발송된 제안 메시지</h2>
              </v-col>
              <v-col>
                <v-textarea
                  v-model="messageEditProposition.message"
                  name="message"
                  type="text"
                  v-validate="'required'"
                  :rules="[errors.first('message') || !errors.has('message')]"
                  placeholder="’유튜버’님의 쾌활한 분위기가 저희 브랜드와 잘 맞아 협업 제안을 드리게 되었습니다."
                  hide-details
                  outlined
                  solo
                  flat
                  required
                  disabled></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="rouge" outlined flat @click.native="showMessageDialog = false">닫기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="showReasonRefuseDialog" content-class="white-two" persistent max-width="500px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showReasonRefuseDialog = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold subheading" style="color: #484848">답변 메시지</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12">
                <h2 class="font-weight-bold subheading mt-0 mb-2">인플루언서가 보낸 답변 메시지</h2>
              </v-col>
              <v-col>
                <v-textarea
                  v-model="reasonRefuseEditProposition.reasonRefuse"
                  name="reasonRefuse"
                  type="text"
                  v-validate="'required'"
                  :rules="[errors.first('reasonRefuse') || !errors.has('reasonRefuse')]"
                  hide-details
                  outlined
                  solo
                  flat
                  required
                  disabled></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="rouge" outlined flat @click.native="showReasonRefuseDialog = false">닫기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import campaignMixin from '@/components/shared/campaignMixin';
import YoutuberDetail from '@/components/campaign/dialog/YoutuberDetail';

export default {
  name: 'Result',
  mixins: [userMixin, campaignMixin],
  props: ['propositions'],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver.svg'),
    instaIcon: () => import('@/assets/img/campaign/insta.svg'),
    youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
    YoutuberDetail,
  },
  data() {
    return {
      headers_desktop: [
        { text: '캠페인 명', value: 'name', width: '280px' },
        { text: '진행 단계', value: 'status' },
        { text: '총 인원', value: 'total' },
        { text: '수락 인원', value: 'accept' },
        { text: '대기중 인원', value: 'wait' },
        { text: '거절', value: 'refuse' },
        { text: '무응답', value: 'noRes' },
      ],
      headers_mobile: [{ text: '캠페인 명', value: 'name' }],
      headersDetail_desktop: [
        { text: '채널', value: 'taggableType', width: '52px' },
        { text: '채널 정보', value: 'Youtuber.account', width: '200px' },
        { text: '수락 여부', value: 'status' },
        { text: '예상 광고 단가', value: 'Youtuber.adFee' },
        { text: '제안 금액', value: 'point' },
        { text: '수정 가능 횟수', value: 'maxEditCount' },
        { text: '제안 메시지', value: 'message', width: '140px' },
        { text: '제안 일시', value: 'proposedAt', width: '140px' },
        { text: '남은시간', value: 'deadline' },
        { text: '다시 제안', value: 'rePropose' },
      ],
      headersDetail_mobile: [
        { text: '채널', value: 'taggableType', width: '52px' },
        { text: '채널 정보', value: 'Youtuber.account', width: '200px' },
        { text: '수락 여부', value: 'status' },
      ],
      datas: [],
      datas1: [],
      detail: null,
      options: { page: 1, itemsPerPage: 30 },
      selectedCampaign: 0,
      filter: '상태 전체',
      openYoutuber: {},
      openYoutuberDetail: false,
      showMessageDialog: false,
      messageEditProposition: {},
      showReasonRefuseDialog: false,
      reasonRefuseEditProposition: {},
    };
  },
  computed: {
    headers() {
      return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
    },
    headersDetail() {
      return this.$vuetify.breakpoint.mdAndUp ? this.headersDetail_desktop : this.headersDetail_mobile;
    },
  },
  watch: {},
  methods: {
    changeCampaign(id) {
      this.datas1.some((campaign, idx) => {
        if (campaign.id === id) {
          this.selectedCampaign = idx;
          this.detail = this.datas1[idx];
          return true;
        }
        return false;
      });
    },
    getTimeRemaining(item) {
      // this.$moment.duration(this.$moment(item.proposedAt).startOf('hour').add(25, 'h').diff(this.$moment())).humanize()
      let diff = this.$moment(item.proposedAt).startOf('hour').add(13, 'h').diff(this.$moment());
      let duration = this.$moment.duration(diff);
      return duration > 0 ? Math.floor(duration.asHours()) + this.$moment.utc(diff).format(':mm:ss') : 0;
    },
    filterStatus(value, search, item) {
      return (
        value != null &&
        search != null &&
        (search === '상태 전체' ||
          ((item.status || 0) <= 1 && search === '확인중') ||
          ((item.status || 0) === 3 && search === '제안 거절') ||
          ((item.status || 0) === 4 && search === '무응답') ||
          ((item.status || 0) === 50 && search === '수락 후 취소') ||
          (((item.status || 0) === 2 || ((item.status || 0) >= 10 && (item.status || 0) < 50)) &&
            search === '제안 수락'))
      );
    },
    checkProposition(campaign, youtuber) {
      if (campaign) {
        if (
          this._.find(this.propositions, proposition => {
            return (
              proposition.campaignId === campaign.id &&
              proposition.taggableId === youtuber.id &&
              proposition.taggableType === 'youtube' &&
              proposition.status === 1
            );
          })
        ) {
          return 2;
        } else {
          return this._.find(this.propositions, proposition => {
            return (
              proposition.campaignId === campaign.id &&
              proposition.taggableId === youtuber.id &&
              proposition.taggableType === 'youtube' &&
              proposition.status < 2
            );
          });
        }
      } else {
        return this.youtubeCampaigns.some(campaign => {
          return this._.find(this.propositions, proposition => {
            return (
              proposition.campaignId === campaign.id &&
              proposition.taggableId === youtuber.id &&
              proposition.taggableType === 'youtube' &&
              proposition.status < 2
            );
          });
        });
      }
    },
    async updateToProposition(campaign, orgProposition) {
      let youtuber = orgProposition.Youtuber;
      if (youtuber.uid) {
        this.completeDialogTxt1 = campaign.name;
        let oldProposition = this.checkProposition(campaign, youtuber);
        if (!oldProposition) {
          let maxEditCount = this.$getMaxEditCount(orgProposition.point);
          let proposition = {
            campaignId: campaign.id,
            taggableId: youtuber.id,
            taggableType: 'youtube',
            uid: youtuber.uid,
            point: orgProposition.point,
            cname: campaign.serviceName,
            creward: campaign[orgProposition.taggableType].reward,
            cprice: campaign[orgProposition.taggableType].rewardPrice,
            category: campaign.category,
            status: 0,
            maxEditCount: maxEditCount,
            thumbnail: campaign.thumbnail,
            periodUse: this.$periodMap[orgProposition.taggableType][campaign.type][campaign.category.firstName][0],
            periodFirstCopy:
              this.$periodMap[orgProposition.taggableType][campaign.type][campaign.category.firstName][1],
            fees: 30,
          };
          if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
            proposition.rewardFees = 13.5;
            proposition.rewardFeeAmount =
              campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
          }
          await this.axios.post('/campaign/proposition', proposition);
          let propositions = await this.axios.get('/campaign/proposition');
          this.propositions = propositions.data.propositions;
          this.completeDialogTitle = '추가 완료';
          this.completeDialogTxt2 = '제안목록에 추가되었습니다.';
          this.showCompleteDialog = true;
        } else if (oldProposition !== 2) {
          await this.axios.delete('/campaign/proposition?id=' + oldProposition.id);
          let propositions = await this.axios.get('/campaign/proposition');
          this.propositions = propositions.data.propositions;
          this.completeDialogTitle = '삭제 완료';
          this.completeDialogTxt2 = '제안목록에서 삭제되었습니다.';
          this.showCompleteDialog = true;
        }
        this.$emit('update:propositions', this.propositions);
      } else {
        this.$store.commit('setMsg', '현재 제안이 불가능한 유저입니다.');
        this.$store.commit('setShowSnackbar', true);
      }
    },
    async youtuberDetailDlg(item, event) {
      console.log(event);
      this.openYoutuberDetail = true;
      this.openYoutuber = item;
    },
    async detailResult(row) {
      this.detail = row;
      this.$router.push({ path: 'propositionyoutube', query: { tab: 1, id: row.id } });

      this.datas1.some((campaign, idx) => {
        if (campaign.id === row.id) {
          this.selectedCampaign = idx;
          return true;
        }
        return false;
      });
    },
  },
  async created() {
    let propositions = this.propositions.filter(
      proposition => !proposition.propositionAutoId && proposition.taggableType === 'youtube'
    );
    let grouped = this._.groupBy(propositions, 'campaignId');
    Object.keys(grouped).map(campaignId => {
      let row = {
        id: campaignId,
        name: this._.find(this.youtubeCampaigns, { id: grouped[campaignId][0].campaignId }).name,
        total: grouped[campaignId].length,
      };
      let count = this._.countBy(grouped[campaignId], proposition => {
        return proposition.status;
      });
      row.total -= count['0'] || 0;
      row.wait = count['1'] || 0;
      row.refuse = count['3'] || 0;
      row.noRes = count['4'] || 0;
      row.accept = row.total - row.wait - row.refuse - row.noRes; //count['2'];
      row.propositions = grouped[campaignId].filter(proposition => proposition.status > 0);
      row.propositions = this._.orderBy(row.propositions, ['proposedAt'], ['desc']);
      row.campaign = this._.find(this.youtubeCampaigns, { id: parseInt(campaignId) });
      this.datas.push([row]);
      this.datas1.push(row);
    });
    this.datas = this._.sortBy(this.datas, [
      function (data) {
        return data[0].campaign.createdAt;
      },
    ]);
    this.datas = this._.reverse(this.datas);
    this.detail = null;
  },
};
</script>

<style scoped lang="scss">
.edit-button {
  pointer-events: auto;
}

::v-deep .v-expansion-panel__header {
  padding: 0px;
}
</style>
