var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container contents help-contents"},[_c('v-row',{staticClass:"mt-12"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"11","md":"9"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('h1',{staticClass:"display-1 font-weight-bold"},[_vm._v("자주묻는 질문")])]),_c('v-row',{attrs:{"justify":"center mt-4"}},[_c('div',[_vm._v("사장님들께서 자주 문의하시는 질문과 답변을 모았습니다.")])]),_c('v-row',{staticClass:"mt-12"},_vm._l((['광고주', '블로거']),function(top){return _c('v-col',{key:top,style:({
            height: '48px',
            color: _vm.selectedTopCategory === top ? '#BD1328' : '#757575',
            textAlign: 'center',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            borderBottom: _vm.selectedTopCategory === top ? '1px solid #BD1328' : '1px solid white',
            lineHeight: '32px',
            cursor: 'pointer',
          }),on:{"click":function($event){_vm.selectedTopCategory = top}}},[_vm._v(_vm._s(top))])}),1),_c('v-row',{staticClass:"mt-12"},_vm._l((_vm.types[_vm.selectedTopCategory]),function(t){return _c('v-col',{key:t,attrs:{"cols":"4","md":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('v-row',{staticClass:"circle",style:({
                  backgroundColor: _vm.type === t ? '#BD1328' : 'white',
                  borderColor: _vm.type === t ? '#BD1328' : '#dbdbdb',
                }),attrs:{"justify":"center","align":"center"}},[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.type = t}}},[_c('v-icon',{attrs:{"color":_vm.type === t ? 'white' : '#dbdbdb'}},[_vm._v("mdi-store")])],1)],1)],1),_c('v-spacer')],1),_c('v-row',{staticClass:"mt-3",style:({
              color: _vm.type === t ? '#BD1328' : '#757575',
            }),attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(t)+" ")])],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-list',[_c('v-divider',{staticClass:"mt-8",attrs:{"color":"red"}}),_vm._l((_vm.filtered),function(item,idx){return _c('div',{key:item.title},[_c('v-list-group',{attrs:{"two-line":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.q + item.question)}}),_c('v-divider')],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[(idx + 1 < _vm.filtered.length)?_c('v-divider',{key:idx}):_vm._e(),_c('v-list-item',{staticClass:"pl-10"},[_c('v-list-item-content',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.answer)+" ")])],1)],1),(idx + 1 < _vm.items.length)?_c('v-divider',{key:idx}):_vm._e()],1)})],2)],1)],1)],1)],1),_c('v-spacer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }