<template>
  <v-dialog
    v-model="show"
    content-class="white-two"
    class="rounded-xl"
    max-width="400px"
    :eager="true"
    @input="$emit('update:show', $event)">
    <v-card class="pa-8">
      <v-card-text>
        <div style="min-height: 640px">
          <div class="pipedriveWebForms" :data-pd-webforms="webformUrl"></div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConsultDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    webformUrl: {
      type: String,
      default: 'https://webforms.pipedrive.com/f/6Oz0bFL2C8bh9YhBXX0ALJw6binYn0UmhxNWJQ9iOfKje2UW9kB4rAgqADABrONdAf',
    },
  },
  mounted() {
    let pipedriveScript = document.createElement('script');
    pipedriveScript.setAttribute('src', 'https://webforms.pipedrive.com/f/loader');
    document.head.appendChild(pipedriveScript);
  },
};
</script>
