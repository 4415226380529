export const analyticsPlugin = store => {
  const pushAnalytics = state => {
    if (state.user.user && Object.keys(state.user.user).length > 0) {
      const {
        charger: userName,
        createdAt: signupAt,
        email,
        contacts,
        is_superuser: isSuperUser,
        route,
        companyId,
        name: companyName,
      } = state.user.user;

      window.dataLayer.push({
        event: 'company_info',
        userName: userName || undefined,
        signupAt: signupAt || undefined,
        email: email || undefined,
        contacts: contacts || undefined,
        isSuperUser: isSuperUser || undefined,
        route: route || undefined,
        companyId: companyId || undefined,
        companyName: companyName || undefined,
      });
    }

    if (state.company.companies?.length > 0 && state.company.companyIdx != undefined) {
      const {
        id: adId,
        type: campaignType,
        category,
        local,
        naver,
      } = state.company.companies[state.company.companyIdx];

      window.dataLayer.push({
        event: 'ad_info',
        adId: adId || undefined,
        campaignType: campaignType || undefined,
        topCategory: category ? category.firstName : undefined,
        midCategory: category ? category.secondName : undefined,
        bottomCategory: category ? category.thirdName : undefined,
        regionCompany: local ? local.region : undefined,
        localCompany: local ? local.local : undefined,
        adState: naver?.available,
      });
    }
  };

  // localStorage에서 값을 가져와 초기화하는 경우 감지
  pushAnalytics(store.state);

  store.subscribe((mutation, state) => {
    if (mutation.type === 'setCompanyIdx' || mutation.type === 'setCompanies' || mutation.type === 'setUser') {
      pushAnalytics(state);
    }
  });
};
