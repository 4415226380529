var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"ml-1 pa-6"},[_c('v-row',[_c('v-select',{staticClass:"mr-2",attrs:{"items":[
        { text: '게시', value: true },
        { text: '게시중단', value: false },
      ],"item-text":"text","item-value":"value","label":"가맹점 인증 상태","height":"40px","hide-details":"","outlined":"","solo":"","flat":""},model:{value:(_vm.verified),callback:function ($$v) {_vm.verified=$$v},expression:"verified"}}),_c('v-select',{staticClass:"mr-2",attrs:{"items":[
        { text: '결제내역없음', value: -2 },
        { text: '광고게시예정', value: -1 },
        { text: '게시중', value: 0 },
        { text: '일시중지', value: 1 },
        { text: '광고 종료', value: 2 },
      ],"item-text":"text","item-value":"value","label":"네이버 게시 상태","height":"40px","hide-details":"","outlined":"","solo":"","flat":""},model:{value:(_vm.naver),callback:function ($$v) {_vm.naver=$$v},expression:"naver"}}),_c('v-select',{attrs:{"items":[
        { text: '결제내역없음', value: -2 },
        { text: '광고게시예정', value: -1 },
        { text: '게시중', value: 0 },
        { text: '일시중지', value: 1 },
        { text: '광고 종료', value: 2 },
      ],"item-text":"text","item-value":"value","label":"인스타그램 게시 상태","height":"40px","hide-details":"","outlined":"","solo":"","flat":""},model:{value:(_vm.insta),callback:function ($$v) {_vm.insta=$$v},expression:"insta"}})],1),_c('v-btn',{staticClass:"rouge white-two--text",on:{"click":_vm.save}},[_vm._v("저장")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }