<template>
  <div style="max-width: 960px; margin: 0 auto">
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_1.jpg')" style="width: 100%" />
    <a href="https://me2.do/5iTgw7T8">
      <img :src="require('@/assets/img/partner/detail/맥형아카데미_2_도입버튼.jpg')" style="width: 100%" />
    </a>
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_3.jpg')" style="width: 100%" />
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_4.jpg')" style="width: 100%" />
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_5.jpg')" style="width: 100%" />
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_6.jpg')" style="width: 100%" />
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_7.jpg')" style="width: 100%" />
    <img :src="require('@/assets/img/partner/detail/맥형아카데미_8.jpg')" style="width: 100%" />
  </div>
</template>

<script>
export default {
  name: 'PartnerDetail',
};
</script>

<style scoped lang="scss"></style>
