import Vue from 'vue';

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));

Vue.filter('formatDate', value => {
  if (value) {
    let date = String(value).split('T')[0];
    const [year, month, day] = date.split('-');
    return `${year}.${month}.${day}`;
  }
});

Vue.filter('formatLevel', value => {
  if (value === 'black') {
    return '블랙';
  } else if (value === 'red') {
    return '레드';
  } else if (value === 'yellow') {
    return '옐로우';
  }
});

Vue.filter('phone', function (phone) {
  return phone
    .replace(/[^0-9]/g, '')
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
    .replace('--', '-');
});
