var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero"},[_c('div',{staticClass:"landing"},[_c('div',{staticClass:"guide"},[_c('div',{staticClass:"d-flex align-center justify-center w-112 h-24 white--text mb-4",staticStyle:{"background-color":"#3a3a3a","line-height":"26px","border-radius":"30px","padding":"4px 12px 4px 12px"},attrs:{"id":"signupMain1"}},[_vm._v(" 지인 추천율 80% ")]),_vm._m(0),_vm._m(1),(_vm.userIsAuthenticated)?_c('router-link',{attrs:{"to":"/company/modads"}},[_c('button',{staticClass:"signup newPrimaryBg pl-4 py-0",staticStyle:{"line-height":"26px"},attrs:{"id":"signupMain1"}},[_vm._v(" 무료 상담하기 "),_c('v-icon',{staticStyle:{"color":"#fff","margin":"0 0 2px 4px"}},[_vm._v("chevron_right")])],1)]):_c('button',{staticClass:"signup newPrimaryBg pl-4 py-0",staticStyle:{"line-height":"26px"},attrs:{"id":"signupMain2"},on:{"click":function($event){_vm.showConsultDialog = true}}},[_vm._v(" 무료 상담하기 "),_c('v-icon',{staticStyle:{"color":"#fff","margin":"0 0 2px 4px"},attrs:{"icon":"chevron_right"}},[_vm._v("chevron_right")])],1),_c('v-row',{staticStyle:{"max-height":"0px"},attrs:{"justify":"center","align":"center"}},[_c('ConsultDialog',{attrs:{"show":_vm.showConsultDialog},on:{"update:show":function($event){_vm.showConsultDialog=$event}}})],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{style:({
        width: '596px',
        marginLeft: '70px',
      }),attrs:{"src":require(`@/assets/img/landing/ceo-landing/landing-logo.png`)}}):_c('img',{style:({
        width: _vm.$vuetify.breakpoint.smAndUp ? '400px' : '100%',
        maxWidth: '400px',
        objectFit: 'contain',
      }),attrs:{"src":require(`@/assets/img/landing/ceo-landing/landing-logo-mobile.png`)}})]),_c('Fab',{attrs:{"showConsultDialog":_vm.showConsultDialog},on:{"update:showConsultDialog":function($event){_vm.showConsultDialog=$event},"update:show-consult-dialog":function($event){_vm.showConsultDialog=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('b',{staticClass:"desktop-text"},[_vm._v("검증된 블로그 리뷰로"),_c('br'),_vm._v("찾아오는 손님을 늘려보세요.")]),_c('b',{staticClass:"mobile-text"},[_vm._v("검증된 블로그 리뷰로"),_c('br'),_vm._v("방문 손님을 늘려보세요.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"30px"}},[_vm._v(" 영향력이 검증된 상위 블로거만 선별했습니다."),_c('br'),_vm._v(" 슈퍼멤버스 월별 구독으로 바빠지는 매장을 경험하세요! ")])
}]

export { render, staticRenderFns }