<template>
  <v-col class="mt-2">
    <v-col class="shades white pa-6">
      <v-row class="px-4">
        <v-col cols="12" md="12">
          <h3>등록하실 광고의 종류는 무엇인가요?</h3>
        </v-col>
        <v-col>
          <v-row>
            <v-col md="5">
              <div class="label mt-6 mb-2">캠페인을 만들 채널을 선택해주세요.</div>
              <v-select
                v-model="form.channel"
                :items="[
                  { text: '네이버 블로그', value: 'naver' },
                  { text: '유튜브', value: 'youtube' },
                ]"
                item-text="text"
                item-value="value"
                height="40px"
                :hide-details="true"
                outlined
                solo
                flat
                @change="changeChannel"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5">
              <div class="label mt-6 mb-2">우선 범위를 좁혀볼까요?</div>
              <v-select
                v-model="form.category.firstName"
                :items="Object.entries(option.category || {}).map(([k, v]) => ({ text: k, value: k })) || []"
                item-text="text"
                item-value="value"
                height="40px"
                :hide-details="true"
                outlined
                solo
                flat
                @change="changeScope"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--      <v-row class="px-4">-->
      <!--        <v-col md="8">-->
      <!--          <div class="label mt-6 mb-2">광고의 형태를 선택해주세요.</div>-->
      <!--          <v-radio-group v-model="form.type" :mandatory="true" class="radio_group" @change="checkDone" hide-details>-->
      <!--            <v-row class="mb-2">-->
      <!--              <v-radio-->
      <!--                  label="체험단" color="rouge" value="T" hide-details-->
      <!--                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>-->
      <!--              <v-radio-->
      <!--                  label="기자단" color="rouge" value="P" hide-details-->
      <!--                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>-->
      <!--            </v-row>-->
      <!--          </v-radio-group>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-col>
    <v-col class="shades white pa-6 mt-2">
      <v-row class="px-4">
        <v-col cols="12" md="12" class="mb-4">
          <v-row>
            <v-col md="7">
              <div class="body-1 mb-2" style="color: #484848">캠페인 명을 입력해주세요.</div>
              <v-text-field
                v-model="form.name"
                name="name"
                type="text"
                v-validate="'required|min:2'"
                :rules="[errors.first('name') || !errors.has('name')]"
                hint="예) 2023년 11월 기자단 - 로제떡볶이 매장"
                persistent-hint
                @input="checkDone"
                single-line
                outlined
                solo
                flat
                required></v-text-field>
            </v-col>
            <v-col md="4" class="mt-10 ml-4">
              <a @click="showLoadCampaignDataDlg = true" style="font-size: 13px; font-weight: 700"
                >기존 캠페인 불러오기</a
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="label mb-2">어떤 분야인가요?</div>
              <v-select
                v-model="form.category.secondName"
                :items="
                  Object.entries((option.category[form.category.firstName] || {}).sub || {}).map(([k, v]) => ({
                    text: k,
                    value: k,
                  })) || []
                "
                item-text="text"
                item-value="value"
                height="40px"
                :hide-details="true"
                outlined
                solo
                flat
                @change="changeField"></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" class="mt-4">
          <v-row>
            <v-col md="5">
              <div class="label mb-2">세부 유형을 선택해주세요.</div>
              <v-select
                v-model="form.category.thirdName"
                :items="
                  Object.entries(
                    (((option.category[form.category.firstName] || {}).sub || {})[form.category.secondName] || {})
                      .sub || {}
                  ).map(([k, v]) => ({ value: k, text: k })) || []
                "
                item-text="text"
                item-value="value"
                height="40px"
                :hide-details="true"
                outlined
                solo
                flat
                @change="changeDetail"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="px-4" v-if="form.category.firstName == '매장'">
        <v-col>
          <div class="label mt-6 mb-2">네이버 지도에서 내 가게 정보 불러오기</div>
          <v-btn
            class="mx-0"
            color="rouge"
            outlined
            tile
            flat
            @click="showNaverMapInfo = true"
            :disabled="!form.category.thirdName">
            불러오기
          </v-btn>
          <div v-if="form.category.thirdName" style="font-size: 12px; color: #484848">
            네이버 지도에 등록된 업체 정보를 불러올 수 있습니다.
          </div>
          <div v-else style="font-size: 12px; color: #4770bd">매장의 분야와 세부 유형을 먼저 선택해주세요.</div>
        </v-col>
      </v-row>
    </v-col>
    <v-row justify="center">
      <v-dialog v-model="showLoadCampaignDataDlg" content-class="white-two" persistent max-width="400px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showLoadCampaignDataDlg = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold headline">기존 캠페인 내용 불러오기</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12">
                <div class="body-1 mt-0 mb-6">내용을 불러오실 캠페인을 선택해주세요.</div>
              </v-col>
              <v-col>
                <v-row class="mt-2">
                  <v-select
                    :items="campaigns"
                    item-text="name"
                    item-value="id"
                    :value="((campaigns || [])[selectedCampaign] || {}).id"
                    height="40px"
                    hide-details
                    outlined
                    solo
                    flat
                    single-line
                    @change="changeCampaign">
                  </v-select>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="rouge" outlined flat @click.native="showLoadCampaignDataDlg = false">취소</v-btn>
            <v-btn
              class="rouge white-two--text"
              flat
              @click="
                showLoadCampaignDataDlg = false;
                loadCampaign();
              "
              >내용 불러오기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showNaverMapInfo" content-class="white-two" persistent max-width="400px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showNaverMapInfo = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold subheading">네이버 지도에서 내 가게 정보 불러오기</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 0">
            <v-row>
              1. 네이버 지도에서 내 가게의 정보 페이지로 이동합니다.<br />
              2. 공유 버튼을 눌러 URL을 복사 후 아래에 붙여넣어주세요.
            </v-row>
            <v-row class="my-2">
              <a
                @click="
                  showNaverMapInfo = false;
                  showGuide = true;
                "
                style="text-decoration: underline"
                >URL을 찾기 어려우신가요?</a
              >
            </v-row>
            <v-row class="mt-6">
              <v-text-field
                v-model="naverUrl"
                name="naverUrl"
                type="text"
                :rules="[
                  v => (v || '').indexOf('naver.me/') > -1 || '유효한 URL이 아닙니다.',
                  v => (v || '').indexOf('http') > -1 || '유효한 URL이 아닙니다.',
                ]"
                outlined
                solo
                flat
                error-messages></v-text-field>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 1">
            <v-row class="mt-2" justify="center" align="center">
              <div>
                <v-progress-circular indeterminate :size="30" :width="5" color="red accent-3"></v-progress-circular>
              </div>
              <div>내 가게 정보를 가져오고 있습니다.</div>
              <div>잠시만 기다려주세요.</div>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 2">
            <v-row class="mt-2">
              <div>불러온 정보가 정확한지 꼭 확인해주세요.</div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="naverMapInfoStep === 2"
              class="rouge white-two--text"
              flat
              @click="
                showNaverMapInfo = false;
                getNaverMapClose();
              "
              >닫기</v-btn
            >
            <v-btn v-if="naverMapInfoStep === 0" color="rouge" outlined flat @click.native="showNaverMapInfo = false"
              >취소</v-btn
            >
            <v-btn v-if="naverMapInfoStep === 0" class="rouge white-two--text" flat @click="getNaverMapInfo()"
              >불러오기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showGuide"
        content-class="white-two"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '790px' : '400px'">
        <v-card class="pa-4">
          <v-card-title>
            <div><v-icon color="default" @click="showGuide = false">close</v-icon><br /></div>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-carousel v-model="guideIdx" hide-delimiters>
              <v-carousel-item v-for="(guide, i) in guides" :key="i" :src="guide"> </v-carousel-item>
            </v-carousel>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="rouge"
              outlined
              flat
              :href="$vuetify.breakpoint.mdAndUp ? 'http://map.naver.com' : 'http://www.naver.com'"
              target="_blank"
              >네이버 지도 바로가기</v-btn
            >
            <v-btn class="rouge white-two--text" flat @click="showGuide = false">확인</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import campaignMixin from '@/components/shared/campaignMixin';
import { EventBus } from '@/components/shared/event-bus';

export default {
  name: 'Page1',
  mixins: [campaignMixin],
  props: ['camData', 'option'],
  data: function () {
    return {
      form: {
        name: this.camData.name || '',
        category: {
          firstName: this.camData.category.firstName || '',
          first: this.camData.category.first || '',
          secondName: this.camData.category.secondName || '',
          second: this.camData.category.second || '',
          thirdName: this.camData.category.thirdName || '',
          third: this.camData.category.third || '',
        },
        type: this.camData.type || 'T',
        channel: this.camData.channel || 'naver',
        step: 1,
      },
      showLoadCampaignDataDlg: false,
      selectedCampaign: 0,
      showNaverMapInfo: false,
      naverMapInfoStep: 0,
      naverUrl: '',
      naverMapInfo: {
        name: '',
        abstraction: '',
        address: '',
        contacts: '',
        introduction: '',
      },
      showGuide: false,
      guidesD: [
        require('@/assets/img/campaign/naverPlaceD_1.png'),
        require('@/assets/img/campaign/naverPlaceD_2.png'),
        require('@/assets/img/campaign/naverPlaceD_3.png'),
      ],
      guidesM: [
        require('@/assets/img/campaign/naverPlaceM_1.png'),
        require('@/assets/img/campaign/naverPlaceM_2.png'),
        require('@/assets/img/campaign/naverPlaceM_3.png'),
        require('@/assets/img/campaign/naverPlaceM_4.png'),
      ],
      guideIdx: 0,
    };
  },
  computed: {
    guides() {
      return this.$vuetify.breakpoint.mdAndUp ? this.guidesD : this.guidesM;
    },
  },
  watch: {
    'camData.name': function (value) {
      this.form.name = value;
    },
    naverUrl: function (value) {
      if (value.length === 0) {
        this.naverUrl = null;
      }
      if (value.indexOf('http') > 0) {
        const regex = /http[s]?:\/\/[^\s]+/g;
        const links = value.match(regex);
        if (links) {
          this.naverUrl = links[0];
        }
      }
    },
  },
  methods: {
    changeChannel(value) {
      this.form.channel = value;
      this.checkDone();
    },
    changeScope(value) {
      this.form.category.first = this.option.category[value].id;
      this.form.category.secondName = '';
      this.form.category.second = '';
      this.form.category.thirdName = '';
      this.form.category.third = '';
      this.checkDone();
    },
    changeField(value) {
      this.form.category.second = this.option.category[this.form.category.firstName].sub[value].id;
      this.form.category.thirdName = '';
      this.form.category.third = '';
      this.checkDone();
    },
    changeDetail(value) {
      this.form.category.third =
        this.option.category[this.form.category.firstName].sub[this.form.category.secondName].sub[value].id;
      this.checkDone();
    },
    checkDone() {
      this.form.type = 'T';
      if (this.form.category.first && this.form.category.second && this.form.category.third) {
        // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
        this.$emit('updateEvent', this.form);
      }
      if (this.form.name) {
        this.$emit('updateEvent', { name: this.form.name, step: this.form.step });
      }
      if (this.form.channel) {
        this.$emit('updateChannel', this.form.channel);
      }
    },
    changeCampaign(id) {
      this.campaigns.some((campaign, idx) => {
        if (campaign.id === id) {
          this.selectedCampaign = idx;
          return true;
        }
        return false;
      });
    },
    async loadCampaign() {
      let newCampaign = this._.cloneDeep(this.campaigns[this.selectedCampaign]);
      delete newCampaign.id;
      delete newCampaign.status;
      delete newCampaign.createdAt;
      delete newCampaign.updatedAt;
      ['naver', 'insta', 'youtube'].map(channel => {
        if (newCampaign[channel]) {
          delete newCampaign[channel].id;
          delete newCampaign[channel].createdAt;
          delete newCampaign[channel].updatedAt;
        }
      });
      console.log(newCampaign);
      newCampaign.step = 1;
      this.form.name = newCampaign.name;
      this.form.category = newCampaign.category;
      this.form.type = newCampaign.type;
      this.form.step = newCampaign.step;
      this.form.channel =
        newCampaign.channel || (newCampaign.youtube || {}).channel || (newCampaign.naver || {}).channel || 'naver';
      newCampaign.channel = newCampaign.channel || this.form.channel;
      this.$emit('updateEvent', newCampaign);
      EventBus.$emit('update:campaign');
    },
    async getNaverMapInfo() {
      // this.naverMapInfoStep = 1;
      let isValid =
        this.naverUrl.length > 0 &&
        this.naverUrl.indexOf('naver') > -1 &&
        this.naverUrl.indexOf('blog') === -1 &&
        this.naverUrl.indexOf('search') === -1;
      if (isValid) {
        EventBus.$emit('save:campaign');
        let timerId = setInterval(async () => {
          if (this.camData.id > 0) {
            clearInterval(timerId);
            let naverMapInfo = await this.axios.get(
              '/utils/naverme?naverUrl=' + this.naverUrl + '&id=' + this.camData.id
            );
            if (naverMapInfo.status === 200 && naverMapInfo.data.storeId) {
              delete naverMapInfo.data.menu;
              naverMapInfo.data.serviceName = naverMapInfo.data.name;
              delete naverMapInfo.data.name;
              this.naverMapInfo = naverMapInfo.data;
              this.naverMapInfo.step = 2;
              console.log(this.naverMapInfo);
              this.$emit('updateEvent', this.naverMapInfo);
              this.naverMapInfoStep = 2;
              EventBus.$emit('update:campaign');
            } else {
              this.naverUrl = '';
              alert('불러오기 실패');
            }
          }
        }, 2000);
        setTimeout(() => {
          clearInterval(timerId);
          if (this.camData.id === undefined) alert('불러오기 실패');
        }, 10000);
      } else {
        alert('유효한 URL이 아닙니다.');
      }
    },
    getNaverMapClose() {
      this.naverMapInfoStep = 0;
    },
  },
  created() {
    this.checkDone();
  },
};
</script>
<style scoped lang="scss">
.strikethrough {
  text-decoration: line-through;
}

.radio_group {
  margin-top: 0px;
}

.radio {
  border: 1px solid;
  border-radius: 8px;
  max-width: 170px;
  height: 40px;
  margin-top: 0px;
  width: 170px;
}

.radio.v-item--active {
  border-color: var(--rouge-color);
}
</style>
