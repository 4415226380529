<template>
  <v-col class="regads mt-2">
    <v-col class="shades white pa-6">
      <v-row class="px-4">
        <v-col cols="12">
          <h3 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 14px' : 'font-size: 18px'">
            매장에서 판매하는 품목에 대해 소개해주세요.
          </h3>
        </v-col>
        <v-col cols="12" class="mt-4">
          <div class="label mb-2">품목 목록</div>
          <span class="caption warm-grey--text">품목 목록은 광고등록 후에도 수정이 가능합니다.</span>
          <v-list>
            <div class="line2"></div>
            <v-list-item class="pa-2">
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 50%">품목명</v-list-item-content>
              <v-list-item-content class="mx-0 mt-2 px-1 align-end" style="width: 30%">가격</v-list-item-content>
              <v-list-item-action style="width: 20%">등록/삭제</v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(menu, index) in form.menu" :key="index" class="py-2">
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 50%">{{ menu.name }}</v-list-item-content>
              <v-list-item-content class="mx-0 mt-2 px-1 align-end" style="width: 30%">{{
                menu.price
              }}</v-list-item-content>
              <v-list-item-action style="width: 20%">
                <v-btn
                  color="rouge"
                  class="ma-0 ml-4"
                  flat
                  outlined
                  @click="delMenu(index)"
                  :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">
                  <v-icon>close</v-icon>
                  <span class="ml-2 hidden-sm-and-down">삭제</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <div class="line2"></div>
            <v-list-item class="py-2">
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 50%">
                <v-text-field
                  v-model="menu.name"
                  name="menuname"
                  type="text"
                  :rules="[errors.first('menuname') || !errors.has('menuname')]"
                  placeholder="품명"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-list-item-content>
              <v-list-item-content class="mx-0 mt-2 px-1" style="width: 30%">
                <v-text-field
                  v-model="menu.price"
                  name="menuprice"
                  type="text"
                  :rules="[errors.first('menuprice') || !errors.has('menuprice')]"
                  placeholder="가격"
                  hide-details
                  single-line
                  outlined
                  solo
                  flat></v-text-field>
              </v-list-item-content>
              <v-list-item-action style="width: 20%">
                <v-btn
                  class="rouge white-two--text ma-0 ml-4"
                  flat
                  @click="addMenu(menu)"
                  :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">
                  <v-icon>fas fa-plus</v-icon>
                  <span class="ml-2 hidden-sm-and-down">추가</span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <!--        <v-col cols="12" md="12" my-2>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" md="6" class="estimate">-->
        <!--              <bulbIcon class="light_bulb"/>-->
        <!--              <div>-->
        <!--                품목 목록은 광고등록 후에도<br>-->
        <!--                수정이 가능합니다.-->
        <!--              </div>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-col>-->
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
// import companyMixin from '@/components/shared/companyMixin';
import { EventBus } from '@/components/shared/event-bus';

export default {
  name: 'Page5',
  // mixins: [companyMixin],
  props: ['adsData', 'option'],
  components: {
    // bulbIcon: () => import('@/assets/img/regads/light-bulb.svg')
  },
  data: function () {
    return {
      form: {
        menu: this.adsData.menu || [],
      },
      menu: {},
    };
  },
  watch: {},
  methods: {
    addMenu(menu) {
      this.form.menu.push({ name: menu.name, price: menu.price });
      // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
      this.form.step = 5;
      this.$emit('updateEvent', this.form);
    },
    delMenu(index) {
      this.form.menu.splice(index, 1);
      // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
      this.form.step = 5;
      this.$emit('updateEvent', this.form);
    },
  },
  created() {
    // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
    // this.form.step = 5;
    // this.$emit('updateEvent', this.form);
    EventBus.$on('update:ads', async () => {
      this._.merge(this.form, this._.pick(this.adsData, ['menu']));
    });
  },
};
</script>

<style scoped>
::v-deep .v-list-item {
  padding: 0;
  font-size: 14px;
}

::v-deep .fas.fa-plus {
  font-size: 14px;
}
</style>
