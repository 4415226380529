<template>
  <div class="container contents help-contents">
    <v-row class="mt-12">
      <v-spacer></v-spacer>
      <v-col cols="11" md="9">
        <v-row justify="center">
          <h1 class="display-1 font-weight-bold">자주묻는 질문</h1>
        </v-row>
        <v-row justify="center mt-4">
          <div>사장님들께서 자주 문의하시는 질문과 답변을 모았습니다.</div>
        </v-row>
        <v-row class="mt-12">
          <v-col
            v-for="top in ['광고주', '블로거']"
            :key="top"
            @click="selectedTopCategory = top"
            :style="{
              height: '48px',
              color: selectedTopCategory === top ? '#BD1328' : '#757575',
              textAlign: 'center',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              borderBottom: selectedTopCategory === top ? '1px solid #BD1328' : '1px solid white',
              lineHeight: '32px',
              cursor: 'pointer',
            }"
            >{{ top }}</v-col
          >
        </v-row>

        <v-row class="mt-12">
          <v-col cols="4" md="" :key="t" v-for="t in types[selectedTopCategory]">
            <v-row justify="center">
              <v-spacer />
              <v-col justify="center" align="center">
                <v-row
                  justify="center"
                  align="center"
                  class="circle"
                  :style="{
                    backgroundColor: type === t ? '#BD1328' : 'white',
                    borderColor: type === t ? '#BD1328' : '#dbdbdb',
                  }">
                  <v-btn text icon @click="type = t">
                    <v-icon :color="type === t ? 'white' : '#dbdbdb'">mdi-store</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row
              justify="center"
              class="mt-3"
              :style="{
                color: type === t ? '#BD1328' : '#757575',
              }">
              {{ t }}
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="">
            <v-card flat>
              <v-list>
                <v-divider color="red" class="mt-8" />
                <div v-for="(item, idx) in filtered" :key="item.title">
                  <v-list-group v-model="item.active" two-line no-action>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-html="q + item.question" style="white-space: pre-wrap"></v-list-item-title>
                        <v-divider></v-divider>
                      </v-list-item-content>
                    </template>
                    <v-divider v-if="idx + 1 < filtered.length" :key="idx"></v-divider>
                    <v-list-item class="pl-10">
                      <v-list-item-content style="white-space: pre-line">
                        {{ item.answer }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                  <v-divider v-if="idx + 1 < items.length" :key="idx"></v-divider>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data: () => ({
    q: '<span style="color:#bd1328;">Q.</span>  ',
    selectedTopCategory: '광고주',
    types: {},
    items: {},
    type: '',
  }),
  watch: {
    selectedTopCategory() {
      this.type = '';
    },
  },
  computed: {
    filtered: function () {
      if (this.type.length > 0) {
        return this.items[this.selectedTopCategory].filter(item => {
          return item.type.indexOf(this.type) > -1;
        });
      }
      return this.items[this.selectedTopCategory];
    },
  },
  async created() {
    try {
      const request = await fetch('https://s3.ap-northeast-2.amazonaws.com/supermembers.upload/etc/qna.json');
      const response = await request.json();
      console.log(response);

      const getTypes = array => {
        let types = [];

        array.forEach(item => {
          if (item.type.includes(',')) {
            types.push(...item.type.split(','));
          } else {
            types.push(item.type);
          }
        });

        return [...new Set(types)];
      };

      this.types = {
        광고주: getTypes(response['광고주']),
        블로거: getTypes(response['블로거']),
      };

      this.items = {
        ...response,
      };
    } catch (error) {
      console.log(error);
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.help-contents {
  padding: 80px 0;
  font-size: 14px;
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: solid 2px #dbdbdb;
}
</style>
