<template>
  <div class="mt-4">
    <v-data-table
      :headers="campaign.type === 'P' ? _.tail(headers) : headers"
      :items="propositionsWithIndex"
      disable-sort
      show-expand
      item-key="id"
      :items-per-page.sync="options.itemsPerPage"
      :options.sync="options"
      mobile-breakpoint="0"
      :expanded.sync="expanded"
      hide-default-footer
      :footer-props="{
        itemsPerPageOptions: [],
      }"
      style="backgroud-color: transparent">
      <template v-slot:header.check="{}">
        <span style="font-size: 10px; font-weight: normal">
          <v-icon small class="mb-1" :color="getCheckboxState() ? 'rouge' : ''" @click="onCheckboxClickHandler">{{
            getCheckboxState() ? 'mdi-check-circle' : 'mdi-check-circle-outline'
          }}</v-icon>
        </span>
      </template>
      <template v-slot:header.channel="{ header }">
        <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
      </template>
      <template v-slot:header.account="{ header }">
        <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
      </template>
      <template v-slot:header.delivery="{ header }">
        <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
      </template>
      <template v-slot:header.deliveryState="{ header }">
        <span style="font-size: 10px; font-weight: normal">{{ header.text }}</span>
      </template>
      <template v-slot:top>
        <div class="subheading pl-4 py-4" style="background-color: white">
          {{ campaign.type === 'P' || campaign.deliveryMethod === 'PR' ? '신청 정보' : '배송 정보'
          }}<span class="body-2 ml-2" style="color: #808080"
            >{{ propositionsWithIndex.length }}개 ・
            {{ Math.floor((parseInt(propositions.length) - 1) / options.itemsPerPage + 1) }} Pages</span
          >
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color: #484848; font-size: 13px">
                {{ options.itemsPerPage }}명씩 보기
                <v-icon small>expand_more</v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in [20, 50, 100]" :key="index" @click="options.itemsPerPage = item">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            @click="downloadDeliverys"
            color="rouge"
            flat
            outlined
            style="float: right"
            v-if="!(campaign.type === 'P' || campaign.deliveryMethod === 'PR')">
            <v-icon>download</v-icon>
            배송정보 다운받기
          </v-btn>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.check="{ item }">
        <v-icon small class="mb-1" :color="propositionColor(item)" @click="addToProposeList(item)">{{
          selected[item.index] ? 'mdi-check-circle' : 'mdi-check-circle-outline'
        }}</v-icon>
      </template>
      <!--        <template v-slot:item.level="props">-->
      <!--          <v-icon size="20" :color="props.item.level" class="mr-2"-->
      <!--                  v-if="props.item.deliveryHistory && props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 0 &&-->
      <!--                    ($moment().diff($moment(props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt).tz('Asia/Seoul').format('YYYY-MM-DD'), 'days') > 2)">-->
      <!--            mdi-clock-alert-->
      <!--          </v-icon>-->
      <!--          <v-icon size="20" :color="props.item.level" class="mr-2"-->
      <!--                  v-else-if="props.item.deliveryState === 0 &&-->
      <!--                    ($moment().diff($moment(props.item.date0).tz('Asia/Seoul').format('YYYY-MM-DD'), 'days') > 2)">-->
      <!--            mdi-clock-alert-->
      <!--          </v-icon>-->
      <!--          <v-icon size="20" :color="props.item.level" class="mr-2" v-else>mdi-hexagon</v-icon>-->
      <!--          <span>{{ props.item.username }}</span><br>-->
      <!--          <span class="ml-6" :class="props.item.type === 'insta' ? 'pink&#45;&#45;text text&#45;&#45;darken-2': 'green&#45;&#45;text'">{{ props.item.type === 'insta' ? '인스타' : '네이버'}}</span>-->
      <!--        </template>-->
      <template v-slot:item.channel="props">
        <naverIcon v-if="props.item.taggableType === 'naver'" />
        <instaIcon v-if="props.item.taggableType === 'insta'" />
        <youtubeIcon v-if="props.item.taggableType === 'youtube'" />
      </template>
      <template v-slot:item.account="{ item }">
        <v-row @click="influencerDetailDlg(item.Influencer)">
          <v-col cols="3">
            <v-avatar size="40">
              <img v-if="item.Influencer.photo" :src="item.Influencer.photo" @error="item.Influencer.photo = null" />
              <img v-else src="@/assets/img/matching-placeholder.jpg" />
            </v-avatar>
          </v-col>
          <v-col cols="8" class="ml-1">
            <v-row class="caption mt-3" style="color: #4770bd">
              {{ item.Influencer.account }}
            </v-row>
          </v-col>
        </v-row>
        <!--        <v-row class="hidden-md-and-up">-->
        <!--          <v-col>-->
        <!--            <v-row v-if="item.deliveryHistory">-->
        <!--              <span v-if="item.deliveryHistory[item.deliveryHistory.length - 1].state === 1" class="french-blue&#45;&#45;text"> 발송완료 </span>-->
        <!--              <span v-else-if="item.deliveryHistory[item.deliveryHistory.length - 1].state === -2"> 정보수정요청 </span>-->
        <!--              <span v-else-if="item.deliveryHistory[item.deliveryHistory.length - 1].state === -1" class="primary&#45;&#45;text"> 정보수정완료 </span>-->
        <!--              <span v-else-if="item.deliveryHistory[item.deliveryHistory.length - 1].state === 10" class="primary&#45;&#45;text"> 이용취소 </span>-->
        <!--              <span v-else class="primary&#45;&#45;text"> 신청완료 </span><br>-->
        <!--            </v-row>-->
        <!--            <v-row style="color:#808080;font-size:10px">-->
        <!--              배송 상태-->
        <!--            </v-row>-->
        <!--          </v-col>-->
        <!--          <v-col>-->
        <!--            <v-row v-if="item.deliveryHistory">-->
        <!--              <span :class="$moment().diff($moment(item.deliveryHistory[item.deliveryHistory.length - 1].updatedAt).tz('Asia/Seoul').format('YYYY-MM-DD'), 'days') > 2 ? 'primary&#45;&#45;text' : 'grey&#45;&#45;text text&#45;&#45;darken-1'">{{item.deliveryHistory[item.deliveryHistory.length - 1].updatedAt ? new Date(item.deliveryHistory[item.deliveryHistory.length - 1].updatedAt).toLocaleDateString() : ''}}</span>-->
        <!--            </v-row>-->
        <!--            <v-row v-else>-->
        <!--              - -->
        <!--            </v-row>-->
        <!--            <v-row style="color:#808080;font-size:10px">-->
        <!--              변경 일자-->
        <!--            </v-row>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </template>
      <template v-slot:item.delivery="props">
        <v-col>
          <v-col
            v-for="(key, idx) in [
              'name',
              'contact',
              'id',
              'email',
              'address',
              'addressContract',
              'addressName',
              'request',
              'additionalInfo',
              'courier',
              'waybill',
              'waybill2',
            ]"
            :key="idx"
            class="pa-0 ma-0">
            <v-row
              v-if="
                key === 'waybill2' ||
                (key !== 'additionalInfo' && (props.item.delivery || {})[key]) ||
                (key === 'additionalInfo' &&
                  (props.item.delivery || {})[key] &&
                  !_.isEmpty(JSON.parse((props.item.delivery || {})[key])))
              "
              class="my-1">
              <v-col
                v-if="key === 'name'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >실명</v-col
              >
              <v-col
                v-if="key === 'contact'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >연락처</v-col
              >
              <v-col
                v-if="key === 'email'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >이메일</v-col
              >
              <v-col v-if="key === 'id'" class="caption font-weight-bold pa-0" style="max-width: 60px; min-width: 60px"
                >자사몰 ID</v-col
              >
              <v-col
                v-if="key === 'address'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >배송주소</v-col
              >
              <v-col
                v-if="key === 'addressContract'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >연락처</v-col
              >
              <v-col
                v-if="key === 'addressName'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >수신자명</v-col
              >
              <v-col
                v-if="key === 'request'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >요청사항</v-col
              >
              <v-col
                v-if="key === 'additionalInfo'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >추가질문</v-col
              >
              <v-col
                v-if="key === 'courier'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >배송사</v-col
              >
              <v-col
                v-if="key === 'waybill'"
                class="caption font-weight-bold pa-0"
                style="max-width: 60px; min-width: 60px"
                >운송장</v-col
              >
              <v-col
                v-if="key === 'waybill2' && props.item.delivery['address']"
                class="caption rouge--text text-left pa-0"
                @click="waybillDlg(props.item)"
                >운송장 등록 ></v-col
              >
              <v-col v-else-if="key === 'additionalInfo'" class="caption text-left pa-0">
                <v-row v-for="(value, name) in JSON.parse(props.item.delivery[key])" :key="name">
                  <v-col>
                    <v-row style="font-size: 12px; color: #3a3a3a; font-weight: 700">
                      {{ name }}
                    </v-row>
                    <v-row style="font-size: 12px; color: #3a3a3a">
                      {{ value }}
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else class="caption text-left pa-0">{{ props.item.delivery[key] }}</v-col>
            </v-row>
          </v-col>
          <v-col v-if="campaign.type === 'P'">
            <v-row>
              <v-col class="caption font-weight-bold pa-0" style="max-width: 60px; min-width: 60px">
                리뷰 가이드
              </v-col>
              <v-col>
                <v-row>
                  <a
                    v-if="props.item.guide"
                    class="caption rouge--text text-left pa-0"
                    :href="props.item.guide"
                    target="_blank"
                    >등록된 리뷰 가이드 보기 ></a
                  >
                </v-row>
                <v-row
                  class="caption rouge--text text-left pa-0 font-weight-bold"
                  @click="reviewGuideDlg(props.item)"
                  style="cursor: pointer">
                  리뷰 가이드 링크 등록 >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </template>
      <template v-slot:item.deliveryState="props">
        <td class="pa-0" style="min-width: 80px" v-if="campaign.deliveryMethod === 'PR'">
          <div v-if="props.item.rewardStatus === 0" class="grey--text">구매 지원금 지급 대기</div>
          <span :class="'grey--text text--darken-1'" v-if="props.item.rewardStatus === 0">{{
            new Date(props.item.responsedAt).toLocaleDateString()
          }}</span>
          <div v-if="props.item.rewardStatus === 1" class="french-blue--text">구매 지원금 지급 완료</div>
          <span :class="'grey--text text--darken-1'" v-if="props.item.rewardStatus > 0">{{
            new Date(props.item.rewardCompletedAt).toLocaleDateString()
          }}</span>
        </td>
        <td class="pa-0" style="min-width: 80px" v-else-if="props.item.deliveryHistory">
          <span
            v-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 1"
            class="french-blue--text">
            발송완료
          </span>
          <span v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === -2">
            정보수정요청
          </span>
          <span
            v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === -1"
            class="primary--text">
            정보수정완료
          </span>
          <span
            v-else-if="props.item.deliveryHistory[props.item.deliveryHistory.length - 1].state === 10"
            class="primary--text">
            이용취소
          </span>
          <span v-else class="primary--text"> 신청완료 </span><br />
          <span
            :class="
              $moment().diff(
                $moment(props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt)
                  .tz('Asia/Seoul')
                  .format('YYYY-MM-DD'),
                'days'
              ) > 2
                ? 'primary--text'
                : 'grey--text text--darken-1'
            "
            >{{
              props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt
                ? new Date(
                    props.item.deliveryHistory[props.item.deliveryHistory.length - 1].updatedAt
                  ).toLocaleDateString()
                : ''
            }}</span
          >
          <br />
        </td>
      </template>
      <template v-slot:expanded-item="props">
        <td colspan="6">
          <div style="border-top: 1px solid #c2c2c2; border-top: 1px solid var(--pinkish-grey-color)">
            <v-row
              v-for="(history, idx) in props.item.deliveryHistory"
              :key="idx"
              class="py-4"
              :style="
                idx !== props.item.deliveryHistory.length - 1
                  ? 'border-bottom: 1px solid #c2c2c2; border-bottom: 1px solid var(--pinkish-grey-color)'
                  : ''
              ">
              <v-col cols="2" class="text-center">
                <v-avatar>
                  <v-icon v-if="history.state === 1" color="white" class="circle french-blue" size="24">
                    mdi-cube-send
                  </v-icon>
                  <v-icon v-else-if="history.state === -2" color="white" class="circle warm-grey" size="24">
                    mdi-message-alert
                  </v-icon>
                  <v-icon v-else-if="history.state === -1" color="white" class="circle rouge" size="24">
                    mdi-file-check
                  </v-icon>
                  <v-icon v-else-if="history.state === 10" color="white" class="circle warm-grey" size="24">
                    mdi-clock-alert
                  </v-icon>
                  <v-icon v-else color="white" class="circle rouge" size="24"> mdi-account-check </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="10">
                <span v-if="history.state === 1">
                  발송완료
                  <span class="french-blue--text">{{ new Date(history.updatedAt).toLocaleDateString() }}</span></span
                >
                <span v-else-if="history.state === -2">
                  정보수정요청
                  <span class="french-blue--text">{{ new Date(history.updatedAt).toLocaleDateString() }}</span></span
                >
                <span v-else-if="history.state === -1">
                  정보수정완료
                  <span class="french-blue--text">{{ new Date(history.updatedAt).toLocaleDateString() }}</span></span
                >
                <span v-else-if="history.state === 10">
                  이용취소
                  <span class="french-blue--text">{{ new Date(history.updatedAt).toLocaleDateString() }}</span></span
                >
                <span v-else>
                  신청완료
                  <span class="french-blue--text">{{ new Date(history.updatedAt).toLocaleDateString() }}</span></span
                >
                <br /><br />
                <span v-if="history.state === 1">배송 상태가 발송 완료로 변경되었습니다.</span>
                <span v-else-if="history.state === -2">정보 수정 요청이 회원에게 발송되었습니다.</span>
                <span v-else-if="history.state === -1">정보 수정이 완료되었습니다.</span>
                <span v-else-if="history.state === 10">회원의 이용이 취소되었습니다.</span>
                <span v-else>{{ '' }} 회원님의 신청이 완료되었습니다.</span>
                <br />
                <v-row>
                  <v-col cols="9">
                    <span class="warm-grey--text">{{ history.message }}</span>
                  </v-col>
                  <v-col cols="2" v-if="idx === props.item.deliveryHistory.length - 1 && campaign.type !== 'P'">
                    <v-btn
                      :color="history.state === -1 ? 'pinkish-grey' : 'rouge'"
                      flat
                      outlined
                      @click="changeDeliveryInfo(props.item)"
                      :disabled="history.state == -1"
                      style="min-width: 130px"
                      >정보 수정 요청</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </td>
      </template>
      <template v-slot:footer>
        <v-row
          class="mx-0 pa-1 text-right"
          v-if="campaign.type !== 'P' && campaign.category.firstName !== '매장' && campaign.deliveryMethod !== 'PR'">
          <v-col class="mt-4 text-left" cols="3">
            <span class="rouge--text font-weight-bold">총 {{ selected.filter(select => select).length }}건 선택</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? '5' : '7'">
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="deliveryState"
                  :items="[
                    { value: 0, text: '신청 완료' },
                    { text: campaign.deliveryMethod === 'P' ? '적립 완료' : '발송 완료', value: 1 },
                  ]"
                  item-text="text"
                  item-value="value"
                  class="pa-0 mt-1"
                  outlined
                  dense
                  hide-details
                  flat></v-select>
              </v-col>
              <v-btn
                color="primary"
                @click="changDelivery"
                :disabled="selected.filter(select => select).length === 0"
                style="width: 120px">
                배송정보 변경
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center" style="background-color: white">
      <v-pagination
        v-model="options.page"
        :length="Math.floor((parseInt(propositionsWithIndex.length) - 1) / options.itemsPerPage + 1)"
        :total-visible="10"></v-pagination>
    </div>
    <v-row justify="center" align="center">
      <v-dialog v-model="openWaybill" content-class="white-two" max-width="400px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openWaybill = false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="subheading" style="color: #484848"> 운송장 등록 </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row>
              <v-col>
                <div class="font-weight-bold" style="color: #484848">
                  회원명
                  <span class="font-weight-normal" style="color: #757575">{{
                    propositoionDelivery.delivery.name
                  }}</span>
                </div>
                <v-row>
                  <v-col cols="1">
                    <div class="font-weight-bold" style="color: #484848">주소</div>
                  </v-col>
                  <v-col>
                    <span class="font-weight-normal" style="color: #757575">{{
                      propositoionDelivery.delivery.address
                    }}</span>
                  </v-col>
                </v-row>
                <div class="font-weight-bold" style="color: #484848">배송회사</div>
                <v-select
                  v-model="propositoionDelivery.delivery.courier"
                  :items="Object.entries(option.courier).map(([k, v]) => ({ value: k, text: v })) || []"
                  item-text="text"
                  item-value="value"
                  outlined
                  solo
                  flat></v-select>
                <div class="font-weight-bold" style="color: #484848">송장번호</div>
                <v-text-field
                  v-model="propositoionDelivery.delivery.waybill"
                  outlined
                  hide-details
                  class="my-4"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openWaybill = false">닫기</v-btn>
            <v-btn tile class="rouge white-two--text" @click="setWaybill()">운송장 등록</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openReviewGuide" content-class="white-two" max-width="400px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openReviewGuide = false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="subheading mb-2" style="color: #484848"> 리뷰 가이드 등록 </v-row>
            <v-row>
              <v-col>
                <v-row v-for="(key, idx) in ['name', 'email', 'contact']" :key="idx">
                  <v-col cols="2">
                    <div
                      v-if="key === 'name' && propositoionReviewGuide.delivery[key]"
                      class="font-weight-bold"
                      style="color: #484848">
                      회원명
                    </div>
                    <div
                      v-if="key === 'contact' && propositoionReviewGuide.delivery[key]"
                      class="font-weight-bold"
                      style="color: #484848">
                      이메일
                    </div>
                    <div
                      v-if="key === 'email' && propositoionReviewGuide.delivery[key]"
                      class="font-weight-bold"
                      style="color: #484848">
                      연락처
                    </div>
                  </v-col>
                  <v-col>
                    <span class="font-weight-normal" style="color: #757575">{{
                      propositoionReviewGuide.delivery[key]
                    }}</span>
                  </v-col>
                </v-row>
                <div class="font-weight-bold" style="color: #484848">리뷰 가이드</div>
                <v-text-field
                  v-model="propositoionReviewGuide.guide"
                  outlined
                  hide-details
                  class="my-4"
                  placeholder="리뷰가이드 링크 입력"></v-text-field>
                <div>회원이 리뷰에 등록할 사진등이 포함된 문서 또는 파일의 링크를 입력해주세요.</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openReviewGuide = false">닫기</v-btn>
            <v-btn tile class="rouge white-two--text" @click="setReviewGuide()">링크 등록</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showChangeInfo" content-class="white-two" persistent max-width="500px">
        <v-card class="px-6 pt-2 pb-4">
          <v-card-title class="pb-1">
            <div>
              <v-icon color="default" @click="showChangeInfo = false">close</v-icon>
              <h2 class="mt-4 font-weight-bold headline">정보 수정 요청</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="body-1">
              정보 수정 요청시 회원에게 보여질 메시지를 선택해주세요.
              <v-radio-group v-model="predefinedMessage" hide-details>
                <v-radio
                  color="primary"
                  label="연락처가 잘못 입력되었습니다."
                  value="연락처가 잘못 입력되었습니다."></v-radio>
                <v-radio color="primary" label="직접 입력" value="직접 입력"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="deliveryMessage"
                name="deliveryMessage"
                type="text"
                class="pl-6 ml-2 mt-2"
                single-line
                outlined
                solo
                flat
                hide-details
                v-if="predefinedMessage === '직접 입력'"></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="rouge" flat outlined @click.native="showChangeInfo = false">취소</v-btn>
            <v-btn
              class="rouge white-two--text ma-0 ml-4"
              flat
              @click="
                showChangeInfo = false;
                sendRequest();
              "
              >요청 보내기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail" :openInfluencer="openInfluencer"></InfluencerDetail>
    </v-row>
  </div>
</template>

<script>
import InfluencerDetail from '@/components/campaign/dialog/InfluencerDetail';

export default {
  name: 'DeliveryInfo',
  props: ['campaign', 'propositions'],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver.svg'),
    instaIcon: () => import('@/assets/img/campaign/insta.svg'),
    youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
    InfluencerDetail,
  },
  data() {
    return {
      headers_desktop: [
        { text: ' ', value: 'check', width: '40px' },
        { text: '채널', value: 'channel', width: '52px' },
        { text: '채널 정보', value: 'account', width: '200px' },
        { text: '배송 정보', value: 'delivery' },
        { text: '배송상태', value: 'deliveryState' },
        { text: '', value: 'data-table-expand' },
      ],
      headers_mobile: [
        { text: ' ', value: 'check', width: '40px' },
        { text: '채널 정보', value: 'account', width: '200px' },
        { text: '', value: 'data-table-expand' },
      ],
      options: { page: 1, itemsPerPage: 20 },
      selected: [],
      expanded: [],
      openWaybill: false,
      openReviewGuide: false,
      propositoionDelivery: { delivery: {} },
      propositoionReviewGuide: { delivery: {} },
      option: { courier: {} },
      deliveryState: 0,
      showChangeInfo: false,
      predefinedMessage: '자사몰 아이디를 조회할 수 없습니다. 홈페이지 가입여부를 확인 후 다시 입력해주세요.',
      openInfluencer: {},
      openInfluencerDetail: false,
    };
  },
  computed: {
    propositionsWithIndex() {
      let propositions = this.propositions
        .map((items, index) => ({
          ...items,
          index: index,
        }))
        .filter(item => item.delivery && (item.status == 2 || (item.status >= 10 && item.status < 50)));
      return this._.orderBy(
        propositions,
        proposition => {
          if (proposition.deliveryHistory && proposition.deliveryHistory.length > 0) {
            return proposition.deliveryHistory[proposition.deliveryHistory.length - 1].updatedAt;
          } else {
            return null;
          }
        },
        ['desc']
      );
    },
    headers() {
      return this.headers_desktop;
      // return this.$vuetify.breakpoint.mdAndUp ? this.headers_desktop : this.headers_mobile;
    },
  },
  methods: {
    propositionColor(item) {
      return this.selected[item.index] ? 'rouge' : '';
    },
    addToProposeList(item) {
      this.selected[item.index] = !this.selected[item.index];
      this.$forceUpdate();
    },
    waybillDlg(item) {
      this.openWaybill = true;
      this.propositoionDelivery = item;
    },
    async setWaybill() {
      this.openWaybill = false;

      if (!this.propositoionDelivery.deliveryHistory) {
        this.propositoionDelivery.deliveryHistory = [];
        let date = this.propositoionDelivery.deliveryDate
          ? new Date(this.propositoionDelivery.deliveryDate)
          : new Date();

        this.propositoionDelivery.deliveryHistory.push({
          state: this.propositoionDelivery.deliveryState,
          updatedAt: date,
          message: '',
        });
      }
      // if (1 != (this.propositoionDelivery.deliveryHistory[this.propositoionDelivery.deliveryHistory.length - 1].state)) {
      this.propositoionDelivery.deliveryHistory.push({
        state: 1,
        updatedAt: new Date(),
        message: '',
      });
      return this.axios.put('/campaign/proposition?id=' + this.propositoionDelivery.id, {
        deliveryState: 1,
        deliveryHistory: this.propositoionDelivery.deliveryHistory,
        status: 20,
        delivery: this.propositoionDelivery.delivery,
      });
      // } else {
      //   return null;
      // }
    },
    async setReviewGuide() {
      this.openReviewGuide = false;
      await this.axios.put('/campaign/proposition?id=' + this.propositoionReviewGuide.id, {
        guide: this.propositoionReviewGuide.guide,
      });
    },
    reviewGuideDlg(item) {
      this.openReviewGuide = true;
      this.propositoionReviewGuide = item;
    },
    async changDelivery() {
      await Promise.all(
        this.selected.map((select, idx) => {
          if (select) {
            console.log(idx);
            let proposition = this.propositions[idx];
            if (!proposition.deliveryHistory) {
              proposition.deliveryHistory = [];
              let date = new Date(proposition.date0);
              if (proposition.deliveryState === 1) {
                date = proposition.deliveryDate ? new Date(proposition.deliveryDate) : new Date();
              }
              proposition.deliveryHistory.push({
                state: proposition.deliveryState,
                updatedAt: date,
                message: '',
              });
            }
            if (this.deliveryState != proposition.deliveryHistory[proposition.deliveryHistory.length - 1].state) {
              proposition.deliveryHistory.push({
                state: parseInt(this.deliveryState),
                updatedAt: new Date(),
                message: '',
              });
              return this.axios.put('/campaign/proposition?id=' + proposition.id, {
                deliveryState: parseInt(this.deliveryState),
                deliveryHistory: proposition.deliveryHistory,
                status: this.deliveryState == 1 ? 20 : proposition.status,
              });
            } else {
              return null;
            }
          }
        })
      );
      location.reload();
    },
    changeDeliveryInfo(proposition) {
      this.showChangeInfo = true;
      this.proposition = proposition;
    },
    sendRequest() {
      if (!this.proposition.deliveryHistory) {
        this.proposition.deliveryHistory = [];
        let date = new Date(this.proposition.date0);
        if (this.proposition.deliveryState === 1) {
          date = this.proposition.deliveryDate ? new Date(this.proposition.deliveryDate) : new Date();
        }
        this.proposition.deliveryHistory.push({
          state: this.proposition.deliveryState,
          updatedAt: date,
          message: '',
        });
      }
      this.proposition.deliveryHistory.push({
        state: -2,
        updatedAt: new Date(),
        message: this.predefinedMessage === '직접 입력' ? this.deliveryMessage : this.predefinedMessage,
      });
      this.axios.put('/campaign/proposition?id=' + this.proposition.id, {
        deliveryHistory: this.proposition.deliveryHistory,
      });
    },
    async influencerDetailDlg(item, event) {
      console.log(event);
      this.openInfluencerDetail = true;
      this.openInfluencer = item;
    },
    downloadDeliverys() {
      const selectedIndex = Object.entries({ ...this.selected })
        .filter(([, v]) => !!v)
        .map(([k]) => k);

      if (!selectedIndex.length) {
        return;
      }

      const clonePropositionsWithIndexToObject = [...this.propositionsWithIndex].reduce((acc, cur) => {
        acc[cur.index] = cur;
        return acc;
      }, {});

      const initHead = '실명,연락처,이메일,배송주소,배송지 연락처,수신자명,요청사항';

      const csv = selectedIndex
        .reduce(
          (acc, index) => {
            const delivery = clonePropositionsWithIndexToObject[index].delivery || {};
            acc.push(
              ['name', 'contact', 'email', 'address', 'addressContract', 'addressName', 'request']
                .map(key => delivery[key] || '')
                .join(',')
            );
            return acc;
          },
          [initHead]
        )
        .join('\n');

      let csvFile;
      let downloadLink;

      const BOM = '\uFEFF';
      const csv2 = BOM + csv;

      csvFile = new Blob([csv2], { type: 'text/csv' });
      downloadLink = document.createElement('a');
      downloadLink.download = `${this.campaign.name} 배송 정보.csv`;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    getCheckboxState() {
      return [...this.propositionsWithIndex].every(item => !!this.selected[item.index]);
    },
    onCheckboxClickHandler() {
      const isChecked = !this.getCheckboxState();
      [...this.propositionsWithIndex].forEach(item => {
        this.selected[item.index] = isChecked;
      });

      this.$forceUpdate();
    },
  },
  created() {
    this.axios
      .get('/company/option')
      .then(option => {
        this.option = option.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0 4px 3px -5px, inset 0 -4px 3px -5px;
}
</style>
