<template>
  <v-row>
    <v-col class="mt-2">
      <v-col class="shades white pb-6 pa-2">
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <h3>슈퍼멤버스 회원에게 제공할 혜택 정보를 설정해주세요.</h3>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shades white pt-6 pa-2 mt-2" v-if="_.includes(type, 'naver_n') || _.includes(type, 'naver_s')">
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <h3>네이버 블로그 채널의 정보를 설정해주세요.</h3>
          </v-col>
          <v-col cols="12" class="mt-6">
            <div class="label mb-2">
              네이버 블로그 후기에 포함되었으면 하는 키워드가 있으신가요?<span class="caption ml-1 rouge--text"
                >*필수</span
              >
            </div>
            <v-row>
              <v-chip
                v-for="(keyword, index) in form.requiredKeywords"
                :key="index"
                style="height: 40px"
                class="mt-2"
                @click:close="removeRequiredKeyword(index)"
                close
                color="rouge"
                label
                outlined
                >{{ keyword }}</v-chip
              >
              <v-text-field
                v-model="requiredKeyword"
                name="requiredKeyword"
                type="text"
                placeholder="예) 다이어트 샐러드"
                class="mt-2"
                @keyup.enter="addRequiredKeyword"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
              <v-btn color="rouge" @click="addRequiredKeyword" class="plus_btn"><v-icon dark>add</v-icon></v-btn>
              <v-col class="my-0" cols="12" md="12">
                <span class="caption rouge--text">*필수 키워드는 최대 3개까지 설정할 수 있습니다.</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-6">
            <div class="label mb-2">네이버 블로그 후기에 포함되었으면 하는 선택키워드가 있으신가요?</div>
            <v-row>
              <v-chip
                v-for="(keyword, index) in form.keywords"
                :key="index"
                style="height: 40px"
                class="mt-2"
                @click:close="removeKeyword(index)"
                close
                color="rouge"
                label
                outlined
                >{{ keyword }}</v-chip
              >
              <v-text-field
                v-model="keyword"
                name="keyword"
                type="text"
                placeholder="예) 다이어트 샐러드"
                class="mt-2"
                @keyup.enter="addKeyword"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
              <v-btn color="rouge" @click="addKeyword" class="plus_btn"><v-icon dark>add</v-icon></v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" v-if="adsData.type === 'S'">
            <v-col cols="12" md="12" class="mt-6">
              <div class="label my-2">등급별 제공 품목<span class="caption ml-1 rouge--text">*필수</span></div>
            </v-col>
            <v-col cols="12" md="12" class="mt-4">
              <v-row>
                <v-col cols="12">
                  <v-row style="align-items: flex-end">
                    <v-col class="ml-1" cols="12" md="6">
                      <div class="mb-2">블랙 등급 제공 품목</div>
                      <v-row>
                        <v-col cols="1">
                          <v-icon size="20" color="black" class="mt-3">mdi-hexagon</v-icon>
                        </v-col>
                        <v-col cols="11">
                          <v-text-field
                            v-model="form.naver.black"
                            name="naverBlack"
                            type="text"
                            class="text-right"
                            :rules="[errors.first('naverBlack') || !errors.has('naverBlack')]"
                            :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                            @input="checkDone"
                            single-line
                            outlined
                            solo
                            flat></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="mb-2 ml-1">
                        <div class="mb-2">
                          <p v-if="adsData.deliveryMethod === 'PR'" style="font-size: 12px; color: #bd1328">
                            *택배비 포함
                          </p>
                          <p>일반 소비자가</p>
                        </div>
                      </div>
                      <v-text-field
                        v-model.number="form.naver.blackFullPrice"
                        name="naverBlackFullPrice"
                        type="number"
                        suffix="원"
                        class="text-right ml-1"
                        :rules="[errors.first('naverBlackFullPrice') || !errors.has('naverBlackFullPrice')]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="mb-2 ml-1">
                        {{ adsData.deliveryMethod !== 'PR' ? '슈퍼멤버스 특별가' : '구매 후 환급금' }}
                      </div>
                      <v-text-field
                        v-model.number="form.naver.blackPayment"
                        name="naverBlackPayment"
                        type="number"
                        suffix="원"
                        class="text-right ml-1"
                        :rules="[errors.first('naverBlackPayment') || !errors.has('naverBlackPayment')]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row style="align-items: flex-end">
                    <v-col class="ml-1" cols="12" md="6">
                      <div class="mb-2">레드 등급 제공 품목</div>
                      <v-row>
                        <v-col cols="1">
                          <v-icon size="20" color="red" class="mt-3">mdi-hexagon</v-icon>
                        </v-col>
                        <v-col cols="11">
                          <v-text-field
                            v-model="form.naver.red"
                            name="naverRed"
                            type="text"
                            class="text-right"
                            :rules="[errors.first('naverRed') || !errors.has('naverRed')]"
                            :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                            @input="checkDone"
                            single-line
                            outlined
                            solo
                            flat></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" md="2" style="align-items: flex-end">
                      <div class="mb-2 ml-1">
                        <div class="mb-2">
                          <p v-if="adsData.deliveryMethod === 'PR'" style="font-size: 12px; color: #bd1328">
                            *택배비 포함
                          </p>
                          <p>일반 소비자가</p>
                        </div>
                      </div>
                      <v-text-field
                        v-model.number="form.naver.redFullPrice"
                        name="naverRedFullPrice"
                        type="number"
                        suffix="원"
                        class="text-right ml-1"
                        :rules="[errors.first('naverRedFullPrice') || !errors.has('naverRedFullPrice')]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="mb-2 ml-1">
                        {{ adsData.deliveryMethod !== 'PR' ? '슈퍼멤버스 특별가' : '구매 후 환급금' }}
                      </div>
                      <v-text-field
                        v-model.number="form.naver.redPayment"
                        name="naverRedPayment"
                        type="number"
                        suffix="원"
                        class="text-right ml-1"
                        :rules="[errors.first('naverRedPayment') || !errors.has('naverRedPayment')]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row style="align-items: flex-end">
                    <v-col class="ml-1" cols="12" md="6">
                      <div class="mb-2">옐로우 등급 제공 품목</div>
                      <v-row>
                        <v-col cols="1">
                          <v-icon size="20" color="yellow" class="mt-3">mdi-hexagon</v-icon>
                        </v-col>
                        <v-col cols="11">
                          <v-text-field
                            v-model="form.naver.yellow"
                            name="naverYellow"
                            type="text"
                            class="text-right"
                            :rules="[errors.first('naverYellow') || !errors.has('naverYellow')]"
                            :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                            @input="checkDone"
                            single-line
                            outlined
                            solo
                            flat
                            hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="mb-2 ml-1">
                        <div class="mb-2">
                          <p v-if="adsData.deliveryMethod === 'PR'" style="font-size: 12px; color: #bd1328">
                            *택배비 포함
                          </p>
                          <p>일반 소비자가</p>
                        </div>
                      </div>
                      <v-text-field
                        v-model.number="form.naver.yellowFullPrice"
                        name="naverYellowFullPrice"
                        type="number"
                        suffix="원"
                        class="text-right ml-1"
                        :rules="[errors.first('naverYellowFullPrice') || !errors.has('naverYellowFullPrice')]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <div class="mb-2 ml-1">
                        {{ adsData.deliveryMethod !== 'PR' ? '슈퍼멤버스 특별가' : '구매 후 환급금' }}
                      </div>
                      <v-text-field
                        v-model.number="form.naver.yellowPayment"
                        name="naverYellowPayment"
                        type="number"
                        suffix="원"
                        class="text-right ml-1"
                        :rules="[errors.first('naverYellowPayment') || !errors.has('naverYellowPayment')]"
                        @input="checkDone"
                        single-line
                        outlined
                        solo
                        flat
                        hide-details></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mb-6">
                <v-col v-if="adsData.deliveryMethod === 'PR'" class="caption primary--text ml-8" cols="12"
                  >*광고등록이 완료된 후 결제관리 메뉴에서 "구매 후 환급금"을 충전해주세요. "구매 후 환급금"은 5%의
                  수수료가 부과됩니다.</v-col
                >
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="12" md="12" v-else-if="adsData.type === 'A'">
            <v-col cols="11" md="11">
              <div class="label my-2">
                필수미션을 입력해주세요.<a
                  href="https://www.notion.so/mayacrew/7c67b704a9e64004aaa8a5ead1c0ef3a"
                  class="caption text-decoration-underline"
                  target="_blank"
                  >필수미션 작성 예시 보기></a
                >
              </div>
              <v-row v-for="(m, index) in form.naver.mission || []" :key="index">
                <v-textarea
                  v-model="form.naver.mission[index]"
                  name="mission"
                  type="text"
                  class="mt-2"
                  outlined
                  solo
                  flat
                  hide-details></v-textarea>
                <v-btn color="rouge" @click="removeMission(index)" icon text class="plus_btn">
                  <v-icon style="color: #757575">mdi-trash-can-outline</v-icon></v-btn
                >
              </v-row>
              <v-row>
                <v-textarea
                  v-model="mission"
                  name="mission"
                  type="text"
                  class="mt-2"
                  @keyup.enter="addMission"
                  outlined
                  solo
                  flat
                  hide-details></v-textarea>
                <v-btn color="rouge" @click="addMission" tile class="plus_btn">추가</v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="6">
                  <div class="label my-2 mt-10">제공 혜택<span class="caption ml-1 rouge--text">*필수</span></div>
                </v-col>
                <v-col cols="3" v-if="adsData.type === 'A' && adsData.deliveryMethod === 'PR'">
                  <div class="rouge--text mt-12">*택배비 포함</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="label mb-2">제공 혜택</div>
                  <v-text-field
                    v-model="form.naver.diamond"
                    name="naverDiamond"
                    type="text"
                    class="text-right"
                    :rules="[errors.first('naverDiamond') || !errors.has('naverDiamond')]"
                    placeholder="제공 혜택"
                    @input="checkDone"
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="3">
                  <div class="label mb-2">일반 소비자가</div>
                  <v-text-field
                    v-model.number="form.naver.diamondFullPrice"
                    name="naverDiamondFullPrice"
                    type="number"
                    suffix="원"
                    class="text-right ml-1"
                    :rules="[errors.first('naverDiamondFullPrice') || !errors.has('naverDiamondFullPrice')]"
                    @input="checkDone"
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <div class="label my-2">원고료<span class="caption ml-1 rouge--text">*필수</span></div>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  class="mt-2 text-right"
                  v-model.number="form.diamondPoint"
                  @input="checkDone"
                  suffix="원"
                  single-line
                  outlined
                  solo
                  flat
                  hide-details
                  readonly
                  disabled></v-text-field>
              </v-col>
              <!--              <v-col>-->
              <!--                <span class="caption primary&#45;&#45;text">적정 원고료는 20,000원~50,000원입니다.</span>-->
              <!--              </v-col>-->
            </v-col>
          </v-col>
          <div class="line my-6"></div>
          <v-col cols="12" v-if="_.includes(type, 'naver_n') || _.includes(type, 'naver_s')">
            <div class="label mb-2">모집 희망인원<span class="caption ml-1 rouge--text">*필수</span></div>
            <v-checkbox
              v-if="_.includes(type, 'naver_s') && adsData.type !== 'A'"
              v-model="naver_limit"
              label="제한 없음"
              color="rouge"
              class="check ma-0"
              height="20px"></v-checkbox>
            <v-row v-if="!naver_limit">
              <v-col class="pa-1" cols="8" md="6">
                <v-slider
                  v-if="_.includes(type, 'naver_n')"
                  v-model="naver_number"
                  @change="checkDone"
                  always-dirty
                  :min="adsData.type === 'A' ? 5 : 10"
                  :max="adsData.type === 'A' ? 50 : 100"
                  :tick-labels="ticknLabels"
                  tick-size="0"
                  :step="adsData.type === 'A' ? 5 : 10"
                  show-ticks="always"
                  hide-details></v-slider>
                <v-slider
                  v-else-if="_.includes(type, 'naver_s')"
                  v-model="naver_number"
                  @change="checkDone"
                  always-dirty
                  :min="adsData.type === 'A' ? 5 : 10"
                  :max="adsData.type === 'A' ? 50 : 100"
                  :tick-labels="ticksLabels"
                  tick-size="0"
                  :step="adsData.type === 'A' ? 5 : 10"
                  show-ticks="always"
                  hide-details></v-slider>
              </v-col>
              <v-col cols="4" class="pl-6">
                <v-text-field
                  v-if="_.includes(type, 'naver_n') || _.includes(type, 'naver_s')"
                  v-model.number="naver_number"
                  class="mt-0"
                  type="number"
                  :suffix="adsData.type === 'A' ? '명' : '명/월'"
                  readonly></v-text-field>
                <v-row class="mt-2 rouge--text" justify="center" align="center" v-if="adsData.type !== 'A'">
                  결제 금액:
                  {{ option.price.naver.product.N * (naver_number / 10) }}원
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shades white pt-6 pa-2 mt-2" v-if="_.includes(type, 'insta_n') || _.includes(type, 'insta_s')">
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <h3>인스타그램 채널의 정보를 설정해주세요.</h3>
          </v-col>
          <v-col cols="12" class="mt-6">
            <div class="label mb-2">인스타그램 후기에 포함되었으면 하는 해시태그가 있으신가요?</div>
            <v-row>
              <v-chip
                v-for="(hashtag, index) in form.hashtags"
                :key="index"
                style="height: 40px"
                class="mt-2"
                @click:close="removeHashtag(index)"
                close
                color="rouge"
                label
                outlined
                >{{ '#' + hashtag }}</v-chip
              >
              <span class="warm-grey--text display-1 mx-2 mt-2">#</span>
              <v-text-field
                v-model="hashtag"
                name="hashtag"
                type="text"
                placeholder="예) 다이어트 샐러드"
                class="mt-2"
                @keyup.enter="addHashtag"
                single-line
                outlined
                solo
                flat
                hide-details></v-text-field>
              <v-btn color="rouge" @click="addHashtag" class="plus_btn"><v-icon dark>add</v-icon></v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" class="mt-6">
            <div class="label my-2">등급별 제공 품목</div>
          </v-col>
          <v-col cols="12" md="12" class="mt-4">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col class="ml-1" cols="12" md="6">
                    <div class="mb-2">블랙 등급 제공 품목</div>
                    <v-row>
                      <v-col cols="1">
                        <v-icon size="20" color="black" class="mt-3">mdi-hexagon</v-icon>
                      </v-col>
                      <v-col cols="11">
                        <v-text-field
                          v-model="form.insta.black"
                          name="instaBlack"
                          type="text"
                          class="text-right"
                          :rules="[errors.first('instaBlack') || !errors.has('instaBlack')]"
                          :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                          @input="checkDone"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="mb-2 ml-1">
                      <div class="mb-2">일반 소비자가</div>
                    </div>
                    <v-text-field
                      v-model.number="form.insta.blackFullPrice"
                      name="instaBlackFullPrice"
                      type="number"
                      suffix="원"
                      class="text-right ml-1"
                      :rules="[errors.first('instaBlackFullPrice') || !errors.has('instaBlackFullPrice')]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="mb-2 ml-1">슈퍼멤버스 특별가</div>
                    <v-text-field
                      v-model.number="form.insta.blackPayment"
                      name="instaBlackPayment"
                      type="number"
                      suffix="원"
                      class="text-right ml-1"
                      :rules="[errors.first('instaBlackPayment') || !errors.has('instaBlackPayment')]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col class="ml-1" cols="12" md="6">
                    <div class="mb-2">레드 제공 품목</div>
                    <v-row>
                      <v-col cols="1">
                        <v-icon size="20" color="red" class="mt-3">mdi-hexagon</v-icon>
                      </v-col>
                      <v-col cols="11">
                        <v-text-field
                          v-model="form.insta.red"
                          name="instaRed"
                          type="text"
                          class="text-right"
                          :rules="[errors.first('instaRed') || !errors.has('instaRed')]"
                          :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                          @input="checkDone"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="mb-2 ml-1">일반 소비자가</div>
                    <v-text-field
                      v-model.number="form.insta.redFullPrice"
                      name="instaRedFullPrice"
                      type="number"
                      suffix="원"
                      class="text-right ml-1"
                      :rules="[errors.first('instaRedFullPrice') || !errors.has('instaRedFullPrice')]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="mb-2 ml-1">슈퍼멤버스 특별가</div>
                    <v-text-field
                      v-model.number="form.insta.redPayment"
                      name="instaRedPayment"
                      type="number"
                      suffix="원"
                      class="text-right ml-1"
                      :rules="[errors.first('instaRedPayment') || !errors.has('instaRedPayment')]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col class="ml-1" cols="12" md="6">
                    <div class="mb-2">옐로우 등급 제공 품목</div>
                    <v-row>
                      <v-col cols="1">
                        <v-icon size="20" color="yellow" class="mt-3">mdi-hexagon</v-icon>
                      </v-col>
                      <v-col cols="11">
                        <v-text-field
                          v-model="form.insta.yellow"
                          name="instaYellow"
                          type="text"
                          class="text-right"
                          :rules="[errors.first('instaYellow') || !errors.has('instaYellow')]"
                          :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                          @input="checkDone"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="mb-2 ml-1">일반 소비자가</div>
                    <v-text-field
                      v-model.number="form.insta.yellowFullPrice"
                      name="instaYellowFullPrice"
                      type="number"
                      suffix="원"
                      class="text-right ml-1"
                      :rules="[errors.first('instaYellowFullPrice') || !errors.has('instaYellowFullPrice')]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <div class="mb-2 ml-1">슈퍼멤버스 특별가</div>
                    <v-text-field
                      v-model.number="form.insta.yellowPayment"
                      name="yellowPayment"
                      type="number"
                      suffix="원"
                      class="text-right ml-1"
                      :rules="[errors.first('yellowPayment') || !errors.has('yellowPayment')]"
                      @input="checkDone"
                      single-line
                      outlined
                      solo
                      flat></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <div class="line mb-6"></div>
          <v-col cols="12" v-if="_.includes(type, 'insta_n') || _.includes(type, 'insta_s')">
            <div class="label mb-2">인스타그램 월별 모집 희망인원</div>
            <!--            <v-checkbox v-if="_.includes(type, 'insta_s')" v-model="insta_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px"></v-checkbox>-->
            <v-row v-if="!insta_limit">
              <v-col class="pa-1" cols="8" md="6">
                <v-slider
                  v-if="_.includes(type, 'insta_n')"
                  v-model="insta_number"
                  @change="checkDone"
                  always-dirty
                  min="1"
                  max="20"
                  :tick-labels="ticknLabels"
                  tick-size="0"
                  :step="10"
                  show-ticks="always"
                  hide-details></v-slider>
                <v-slider
                  v-else-if="_.includes(type, 'insta_s')"
                  v-model="insta_number"
                  @change="checkDone"
                  always-dirty
                  min="20"
                  max="200"
                  :tick-labels="ticksLabels"
                  tick-size="0"
                  :step="10"
                  show-ticks="always"
                  hide-details></v-slider>
              </v-col>
              <v-col cols="4" class="pl-6">
                <v-text-field
                  v-if="_.includes(type, 'insta_n') || _.includes(type, 'insta_s')"
                  v-model.number="insta_number"
                  class="mt-0"
                  type="number"
                  suffix="명/월"
                  readonly></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shades white pa-6 mt-2" v-if="type.length > 0">
        <v-col class="px-4">
          <v-col cols="12" md="10">
            <div class="label mb-2">슈퍼멤버스 회원에게 제품을 제공할 때, 품목 제한이 필요하신가요?</div>
            <v-btn-toggle v-model="isLimitation" @change="checkDone" mandatory>
              <v-btn
                :value="false"
                active-class="rouge--text text--rouge v-btn--active"
                class="ma-0"
                style="width: 130px"
                outlined
                >아니오</v-btn
              >
              <v-btn
                :value="true"
                active-class="rouge--text text--rouge v-btn--active"
                class="ma-0"
                style="width: 130px"
                outlined
                >예</v-btn
              >
            </v-btn-toggle>
          </v-col>
          <v-col class="my-0" v-if="isLimitation" cols="12" md="12">
            <span class="caption rouge--text">이용품목에 제한이 있는 경우 회원의 만족도가 떨어질 수 있습니다.</span>
          </v-col>
          <v-col v-if="isLimitation" cols="12" md="10">
            <div class="label mt-6 mb-2">품목 제한</div>
            <v-text-field
              v-if="isLimitation"
              v-model="channel.limitation"
              name="limitation"
              type="text"
              :rules="[errors.first('limitation') || !errors.has('limitation')]"
              hint="예) A, B, C 제품 중 C 제품은 할인이 적용되지 않습니다."
              persistent-hint
              @input="checkDone"
              single-line
              outlined
              solo
              flat></v-text-field>
          </v-col>
        </v-col>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Page4',
  props: ['adsData', 'option'],
  data: function () {
    return {
      MAX_REQUIRED_KEYWORD_COUNT: 3,
      form: {
        keywords: this.adsData.keywords || [],
        hashtags: this.adsData.hashtags || [],
        requiredKeywords: this.adsData.requiredKeywords || [],
        diamondPoint: this.adsData.diamondPoint || 20000,
        blackPoint: this.adsData.blackPoint || 20000,
        redPoint: this.adsData.redPoint || 20000,
        naver: {},
        insta: {},
      },
      channel: {
        startDate:
          (this.adsData.naver || {}).startDate ||
          (this.adsData.insta || {}).startDate ||
          this.$moment.tz('Asia/Seoul').add(1, 'day').format('YYYY-MM-DD'),
        limitation: (this.adsData.naver || {}).limitation || (this.adsData.insta || {}).limitation || '',
      },
      menu: false,
      // dateFormatted: this.formatDate((this.adsData.naver || {}).startDate) || this.formatDate((this.adsData.insta || {}).startDate) || null,
      isLimitation: !!(this.adsData.naver || {}).limitation || !!(this.adsData.insta || {}).limitation || false,
      type: ['naver_n'],
      insta_number: (this.adsData.insta || {}).number || 20,
      naver_number: (this.adsData.naver || {}).number || 20,
      insta_limit: (this.adsData.insta || {}).number === 0 || false,
      naver_limit: (this.adsData.naver || {}).number === 0 || false,
      keyword: '',
      requiredKeyword: '',
      hashtag: '',
      mission: '',
    };
  },
  computed: {
    ticknLabels() {
      if (this.adsData.type === 'A') {
        return Array(10)
          .fill()
          .map((v, i) => {
            if ([5, 25, 50].indexOf((i + 1) * 5) >= 0) {
              return (i + 1) * 5;
            } else return '';
          });
      } else {
        return Array(10)
          .fill()
          .map((v, i) => {
            if ([10, 50, 100].indexOf((i + 1) * 10) >= 0) {
              return (i + 1) * 10;
            } else return '';
          });
      }
    },
    ticksLabels() {
      if (this.adsData.type === 'A') {
        return Array(10)
          .fill()
          .map((v, i) => {
            if ([5, 25, 50].indexOf((i + 1) * 5) >= 0) {
              return (i + 1) * 5;
            } else return '';
          });
      } else {
        return Array(10)
          .fill()
          .map((v, i) => {
            if ([10, 50, 100].indexOf((i + 1) * 10) >= 0) {
              return (i + 1) * 10;
            } else return '';
          });
      }
    },
  },
  // watch: {
  //   'channel.startDate': function () {
  //     this.dateFormatted = this.formatDate(this.channel.startDate)
  //   }
  // },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}. ${month}. ${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('. ');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    addRequiredKeyword: function () {
      if (
        this.requiredKeyword.length > 0 &&
        this.form.requiredKeywords.indexOf(this.requiredKeyword) === -1 &&
        this.MAX_REQUIRED_KEYWORD_COUNT > this.form.requiredKeywords.length
      ) {
        this.form.requiredKeywords.push(this.requiredKeyword);
        this.requiredKeyword = '';
        this.checkDone();
      }
    },
    removeRequiredKeyword: function (idx) {
      console.log('remove' + idx);
      this.form.requiredKeywords.splice(idx, 1);
      this.checkDone();
    },
    addKeyword: function () {
      if (this.keyword.length > 0 && this.form.keywords.indexOf(this.keyword) === -1) {
        this.form.keywords.push(this.keyword);
        this.keyword = '';
        this.checkDone();
      }
    },
    removeKeyword: function (idx) {
      console.log('remove' + idx);
      this.form.keywords.splice(idx, 1);
      this.checkDone();
    },
    addHashtag: function () {
      if (this.hashtag.length > 0 && this.form.hashtags.indexOf(this.hashtag) === -1) {
        this.form.hashtags.push(this.hashtag);
        this.hashtag = '';
        this.checkDone();
      }
    },
    removeHashtag: function (idx) {
      console.log('remove' + idx);
      this.form.hashtags.splice(idx, 1);
      this.checkDone();
    },
    addMission: function () {
      this.form.naver.mission = this.form.naver.mission || [];
      if (this.mission.length > 0 && this.form.naver.mission.indexOf(this.mission) === -1) {
        this.form.naver.mission.push(this.mission);
        this.mission = '';
        this.checkDone();
      }
    },
    removeMission: function (idx) {
      console.log('remove' + idx);
      this.form.naver.mission.splice(idx, 1);
      this.checkDone();
    },
    async checkDone(value) {
      console.log('checkDone');
      if (this._.includes(value, 'insta_n') && this._.includes(value, 'insta_s')) {
        this.type.splice(Math.min(value.indexOf('insta_n'), value.indexOf('insta_s')), 1);
      }
      if (this._.includes(value, 'naver_n') && this._.includes(value, 'naver_s')) {
        this.type.splice(Math.min(value.indexOf('naver_n'), value.indexOf('naver_s')), 1);
      }
      let success = await this.$validator.validateAll();
      if (success) {
        // console.log(123);
        // console.log(
        //   this.requiredKeyword.length > 0 ? [this.requiredKeyword] : null
        // );
        // this.form.requiredKeywords =
        //   this.requiredKeyword.length > 0 ? [this.requiredKeyword] : null;
        ['naver', 'insta'].forEach(channel => {
          if (this._.includes(this.type, channel + '_n') || this._.includes(this.type, channel + '_s')) {
            this.form[channel] = this.form[channel] || {};
            this.form[channel].startDate = this.channel.startDate;
            this.form[channel].limitation = this.channel.limitation;

            this.form[channel].type = this._.includes(this.type, channel + '_n') ? 'N' : 'S';
            if (!this.isLimitation) {
              delete this.form[channel].limitation;
            }
            this.form[channel].number = this[channel + '_limit'] ? 0 : this[channel + '_number'];

            ['diamond', 'black', 'red', 'yellow'].forEach(level => {
              if (level === 'diamond' && this.form[channel][level + 'FullPrice']) {
                this.form[channel][level + 'Payment'] = this.form[channel][level + 'FullPrice'];
                this.form[channel][level + 'Price'] = 0;
              }
              if (
                typeof this.form[channel][level + 'Price'] === 'string' &&
                this.form[channel][level + 'Price'].length === 0
              ) {
                this.form[channel][level + 'Price'] = 0;
              }
              if (
                typeof this.form[channel][level + 'FullPrice'] === 'string' &&
                this.form[channel][level + 'FullPrice'].length === 0
              ) {
                this.form[channel][level + 'FullPrice'] = 0;
              }
              if (
                typeof this.form[channel][level + 'Payment'] === 'string' &&
                this.form[channel][level + 'Payment'].length === 0
              ) {
                this.form[channel][level + 'Payment'] = 0;
              }
            });

            ['black', 'red', 'yellow', 'diamond'].forEach(level => {
              if (
                this.form[channel][level + 'FullPrice'] !== undefined &&
                this.form[channel][level + 'Payment'] !== undefined
              ) {
                this.form[channel][level + 'Price'] =
                  this.form[channel][level + 'FullPrice'] - this.form[channel][level + 'Payment'];
              }
            });
          } else {
            // delete this.form[channel];
            this.form[channel] = undefined;
          }
        });

        // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
        this.form.step = 4;
        if (this.adsData.step > 2) {
          this.$emit('updateEvent', this.form);
        }
      }
    },
    initData() {
      // this.channel.startDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      // this.dateFormatted = this.formatDate(this.channel.startDate);
      if (this.adsData.naver) {
        if (this.adsData.naver.type === 'N') this.type.push('naver_n');
        else this.type.push('naver_s');
        this.form.naver = this.adsData.naver;
      }
      if (this.adsData.insta) {
        if (this.adsData.insta.type === 'N') this.type.push('insta_n');
        else this.type.push('insta_s');
        this.form.insta = this.adsData.insta;
      }
      if ((this.adsData['requiredKeywords'] || []).length > 0) {
        this.requiredKeyword = this.adsData.requiredKeywords[0];
      }
    },
  },
  created() {
    this.initData();
  },
};
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/custom';

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 170px;
  height: 40px;
  margin-top: 0px;
  min-width: 140px;
}

.line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

.label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

::v-deep .check {
  ::v-deep .v-label {
    font-size: 14px;
  }
}

.plus_btn {
  width: 40px;
  height: 40px !important;
  min-width: 40px !important;
  max-width: 40px;
  color: white;
  padding: 0 !important;
}
</style>
