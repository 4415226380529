<template>
  <a v-if="!$route.path.includes('/ai')" class="kakaoButton" href="http://pf.kakao.com/_Jgyxid/chat" target="_blank">
    <p class="kakaoDesktop">카톡 상담하기</p>
    <p class="kakaoMobile">상담하기</p>
    <div class="kakaoCircle">
      <img
        class="kakaoImg"
        src="https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/ceo_kakao_ch.svg?alt=media&token=e1da5e5c-f7eb-4020-b64b-10914f9a7ab9" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'KakaoButton',
};
</script>

<style scoped lang="scss">
.kakaoButton {
  display: absolute;
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 216px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 8px 20px 0 rgba(72, 72, 72, 0.1);
  background-color: #fff;
  border: none;
  text-decoration: none;
}

.kakaoButton p {
  padding: 18px 24px;
  text-align: left;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.kakaoMobile {
  display: none;
}

.kakaoDesktop {
  display: inline-block;
}

@media (max-width: 960px) {
  .kakaoDesktop {
    display: none;
  }

  .kakaoMobile {
    display: inline-block;
  }

  .kakaoButton {
    width: 152px;
  }
}

.kakaoCircle {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 30.5px;
  box-shadow: 0 8px 16px 0 rgba(72, 72, 72, 0.1);
  background-color: #fae100;
}

.kakaoImg {
  padding: 15px;
}
</style>
