<template>
  <v-col class="modads mt-1">
    <v-col class="shades white pa-6">
      <v-row class="px-0">
        <v-col cols="12" md="10">
          <div class="body-1 mt-6 mb-2" style="color: #484848">
            서비스 이름을 입력해주세요.<span class="caption rouge--text"> *필수</span>
          </div>
          <v-text-field
            v-model="form.serviceName"
            name="serviceName"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('serviceName') || !errors.has('serviceName')]"
            @input="checkDone"
            single-line
            outlined
            solo
            flat
            required></v-text-field>
        </v-col>
        <v-col cols="12" md="10" v-if="camData.type !== 'P'">
          <div class="body-1 mb-2" style="color: #484848">
            서비스 이용과 관련하여 문의를 받으실 수 있는 연락처를 입력해주세요.
          </div>
          <v-text-field
            v-model="form.contacts"
            name="contacts"
            type="text"
            v-validate="'required|min:2'"
            :rules="[errors.first('contacts') || !errors.has('contacts')]"
            @input="checkDone"
            outlined
            solo
            flat></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mb-2" style="color: #484848">
            공식 계정이 있는 경우 인스타그램 아이디만 입력해주세요. (@제외)
          </div>
          <v-text-field
            v-model="form.instaOfficial"
            name="instaOfficial"
            type="text"
            :rules="[errors.first('instaOfficial') || !errors.has('instaOfficial')]"
            @input="checkDone"
            outlined
            solo
            flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
export default {
  name: 'Page2',
  props: ['camData', 'option'],
  data: function () {
    return {
      form: {
        serviceName: this.camData.serviceName || '',
        contacts: this.camData.contacts || '',
        instaOfficial: this.camData.instaOfficial || '',
      },
      checkUTM: false,
      checkNT: false,
      utm: {
        source: 'naver',
        medium: 'blog',
        campaign: this.camData.name,
      },
      nt: {
        source: 'naver',
        medium: 'blog',
        detail: this.camData.name,
        keyword: this.camData.name,
      },
    };
  },
  watch: {
    camData(value) {
      this.form = {
        serviceName: value.serviceName || '',
        contacts: value.contacts || '',
        instaOfficial: value.instaOfficial || '',
      };
    },
    checkUTM(value) {
      if (value) {
        this.checkNT = false;
      }
    },
    checkNT(value) {
      if (value) {
        this.checkUTM = false;
      }
    },
  },
  methods: {
    checkDone() {
      // this.$validator.validateAll().then(success => {
      //   if (success) {
      // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
      this.$emit('updateEvent', this.form);
      // this.$store.commit('setDirty', true);
      // }
      // });
    },
  },
  created() {},
};
</script>
<style scoped lang="scss">
h3 {
  height: 24px;
}
</style>
