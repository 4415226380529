<template>
  <v-col class="ma-0 pa-0">
    <v-data-table
      :headers="headers"
      :items="adsPayments"
      show-expand
      :items-per-page="5"
      item-key="id"
      mobile-breakpoint="0"
      style="background-color: transparent">
      <!-- ------------------- -->
      <template v-slot:item.order="props">
        <v-row class="mt-2">
          <span>{{ props.item.order }}차</span>
        </v-row>
      </template>
      <!-- ------------------- -->
      <template v-slot:item.startEnd="props">
        <div>
          <span class="span-title">시작일</span> <span>{{ props.item.adsStart }}</span>
        </div>
        <div>
          <span class="span-title">종료일</span> <span>{{ props.item.nextPaidAt || '-' }}</span>
        </div>
      </template>
      <!-- ------------------- -->
      <template v-slot:item.info="props">
        <div style="padding: 8px 0px">
          <div class="wrapper">
            <span class="span-title">모집기간</span> <span>{{ getPeriodApplyDate(props.item) }}</span>
          </div>
          <div class="wrapper">
            <span class="span-title">선정기간</span> <span>{{ getPeriodAccpetDate(props.item) }}</span>
          </div>
          <div class="wrapper">
            <span class="span-title">등록기간</span> <span>{{ getPeriodReviewDate(props.item) }}</span>
          </div>
        </div>
      </template>
      <!-- ------------------- -->
      <template v-slot:expanded-item="props">
        <td colspan="4">
          <div style="padding: 32px; display: flex; justify-content: space-between">
            <div class="statistics-box">
              <div class="statistics-title">선정 대기</div>
              <div class="statistics-content">{{ getAdsPaymentsStatistics[props.item.order]?.applied ?? '-' }}</div>
            </div>
            <div class="statistics-box">
              <div class="statistics-title">선정 완료</div>
              <div class="statistics-content">{{ getAdsPaymentsStatistics[props.item.order]?.accepted ?? '-' }}</div>
            </div>
            <div class="statistics-box">
              <div class="statistics-title">이용 완료</div>
              <div class="statistics-content">{{ getAdsPaymentsStatistics[props.item.order]?.used ?? '-' }}</div>
            </div>
            <div class="statistics-box">
              <div class="statistics-title">등록 완료</div>
              <div class="statistics-content">{{ getAdsPaymentsStatistics[props.item.order]?.reviewed ?? '-' }}</div>
            </div>
            <div class="statistics-box">
              <div class="statistics-title">모집인원</div>
              <div class="statistics-content">
                {{ getAdsPaymentsStatistics[props.item.order]?.total ?? '-' }} /
                {{ getAdsPaymentsStatistics[props.item.order]?.max ?? '-' }}
              </div>
            </div>
          </div>
        </td>
      </template>
      <!-- ------------------- -->
    </v-data-table>
  </v-col>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';
import moment from 'moment';
import Vue from 'vue';

export default {
  name: 'Product',
  mixins: [companyMixin],
  props: ['contracts', 'adsPayments'],
  components: {},
  data() {
    return {
      headers: [
        { text: '차수', value: 'order', sortable: false, width: 70 },
        {
          text: '기간',
          align: 'left',
          value: 'startEnd',
          sortable: false,
          width: 150,
        },
        {
          text: '정보',
          align: 'left',
          value: 'info',
          sortable: false,
          width: 300,
        },
        {
          text: '상세',
          width: 80,
          value: 'data-table-expand',
        },
      ],
      rowsPerPage: 5,
    };
  },
  computed: {
    getAdsPaymentsStatistics() {
      const statistics = {};
      this.adsPayments.forEach(payment => {
        statistics[payment.order] = this.contracts.reduce(
          (acc, contract) => {
            const { adsStart, lastPaidAt } = payment;
            if (moment(contract.appliedAt).isBetween(moment(adsStart), moment(lastPaidAt), 'days', '[)')) {
              if (contract.currentState === -2) acc.applied += 1;
              else {
                if (contract.currentState === -3) acc.accepted += 1;
                if (contract.currentState === 0) acc.used += 1;
                if (contract.currentState > 0) acc.reviewed += 1;
                if (contract.currentState !== -1) acc.total += 1;
              }
            }
            return acc;
          },
          { applied: 0, accepted: 0, used: 0, reviewed: 0, total: 0, max: payment.number }
        );
      });
      return statistics;
    },
  },
  methods: {
    getPeriodApplyDate(adsPayment) {
      const { adsStart, periodApply } = adsPayment;
      if (!periodApply) return '-';
      const baseDate = Vue.moment(adsStart);
      const applyStartDate = baseDate.format('YYYY. MM. DD');
      const applyEndDate = baseDate.add(periodApply, 'days').format('YYYY. MM. DD');
      return `${applyStartDate} ~ ${applyEndDate}`;
    },
    getPeriodAccpetDate(adsPayment) {
      const { adsStart, periodApply, periodAccept } = adsPayment;
      if (!periodApply || !periodAccept) return '-';

      const baseDate = Vue.moment(adsStart).add(periodApply, 'days');
      const acceptStartDate = baseDate.add(1, 'd').format('YYYY. MM. DD');
      const acceptEndDate = baseDate.add(periodAccept - 1, 'days').format('YYYY. MM. DD');
      return `${acceptStartDate} ~ ${acceptEndDate}`;
    },
    getPeriodReviewDate(adsPayment) {
      const { adsStart, periodApply, periodAccept, periodReview } = adsPayment;
      if (!periodApply || !periodAccept || !periodReview) return '-';

      const baseDate = Vue.moment(adsStart).add(periodApply + periodAccept, 'days');
      const reviewStartDate = baseDate.add(1, 'd').format('YYYY. MM. DD');
      const reviewEndDate = baseDate.add(periodReview, 'days').format('YYYY. MM. DD');
      return `${reviewStartDate} ~ ${reviewEndDate}`;
    },
    getContractAdsPayment(adsPayments, contract) {
      let adsPayment;
      for (const payment of adsPayments) {
        const { startDate, lastPaidAt } = payment;
        if (moment(contract.date0).isBetween(moment(startDate), moment(lastPaidAt))) {
          adsPayment = payment;
          break;
        }
      }
      return adsPayment;
    },
  },
};
</script>

<style scoped>
::v-deep .v-expansion-panel__header {
  padding: 0px;
}

::v-deep .v-text-field--outline.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outline.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #c2c2c2;
  border: solid 1px var(--pinkish-grey-color);
}

::v-deep .v-label {
  font-size: 14px;
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.span-title {
  font-weight: bold;
  margin-right: 4px;
}

.wrapper {
  padding: 1px 0px;
}

.statistics-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.statistics-title {
  font-size: 16px;
}

.statistics-content {
  font-size: 24px;
  font-weight: 400;
}
</style>
