import Vue from 'vue';
import ko from 'vee-validate/dist/locale/ko';
import VeeValidate, { Validator } from 'vee-validate';
import validator from 'validator';
import { isValidURL } from '../utils/validation/url';
import { isValidPhoneNumber } from '../utils/validation/phone';

const dict = {
  ko: {
    messages: {
      email: () => `올바른 이메일 형식이 아닙니다.`,
      required: () => `필수 입력란입니다.`,
      confirmed: (field, confirmedField) => `${confirmedField}와 일치하지 않습니다.`,
      url: () => `올바른 URL 형식이 아닙니다.`,
      min: (field, [length]) => `최소 ${length}글자이어야 합니다.`,
      tightURLVerify: () => 'http:// 또는 https://를 포함한 전체 URL을 입력해주세요.',
      phoneNumber: () => '휴대전화 번호를 확인해 주세요.',
    },
    attributes: {
      username: '아이디',
      email: '이메일',
      password: '비밀번호',
      password_confirmed: '비밀번호 확인',
      contacts: '담당자 연락처',
      root: '방문경로',
      recommended: '추천인',
    },
    custom: {
      email: {
        required: '이메일 주소가 없습니다.',
      },
      name: {
        required: () => '이름이 없습니다.',
      },
    },
  },
};

export default function () {
  Validator.localize('ko', ko);
  Validator.localize('ko', dict.ko);
  Validator.localize(dict);

  Validator.extend('tightURLVerify', {
    validate: value => {
      return validator.isURL(value) && isValidURL(value);
    },
  });

  Validator.extend('phoneNumber', {
    validate: value => {
      return isValidPhoneNumber(value);
    },
  });

  Vue.use(VeeValidate);
  Vue.use(VeeValidate, {
    locale: 'ko',
    dictionary: { ko: ko },
  });

  Validator.localize('ko');
}
