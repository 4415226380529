<template>
  <div>
    <div></div>
    <div v-if="$route.path === '/company/dashboard'">
      <h2 class="headline">알림 현황</h2>
      <p>
        <br />회원의 이용 현황, 후기 등록 및<br />
        결제와 관련한 알림을 확인할 수 있습니다.
      </p>
    </div>
    <div v-if="$route.path === '/company/mypage'">
      <h2 class="headline">{{ user.name }}</h2>
      <p v-if="user.rejected_reason">
        {{ user.rejected_reason }}
      </p>
      <p v-else>새 알림이 없습니다.</p>
    </div>
    <div v-else-if="$route.path === '/company/modads'">
      <div>
        <h2 class="headline">광고정보 수정</h2>
        <!--<div id="area-state">-->
        <!--<span v-if="user.is_verified == 0" class="judge-state">회원가입심사중</span>-->
        <!--<button v-else-if="user.is_verified < 3" class="secondary" style="width:140px" id="btn-entrance">승인신청</button>-->
        <!--<span v-else-if="user.is_verified == 3" class="judge-state">광고 심사중</span>-->
        <!--<span v-else class="judge-state">심사 완료</span>-->
        <!--</div>-->
      </div>
      <div>
        <p v-if="company && company.is_verified == 0">
          회원 가입 심사가 완료되면 광고 승인을 요청할 수 있습니다.<br />
          심사 완료 전에도 광고 내용은 수정할 수 있습니다.
        </p>
        <p v-else-if="company && company.is_verified == 1">
          수정정보가 게시되기 까지는 1-3일이 소요되며, 광고 내용이 적절하지 않으면 게시가 거절될 수 있습니다.<br />
          수정이 완료된 후, 승인신청 버튼을 눌러주세요.
        </p>
        <p v-else-if="company && company.is_verified == 2">
          {{ company.reject_rejected_reason }}
        </p>
        <p v-else-if="company && company.is_verified == 3 && !company.verified">
          <a href="http://pf.kakao.com/_Jgyxid/chat" target="_blank">여기를 눌러</a> 카카오톡채널에 상호명과 함께<br />
          광고등록이 완료되었다고 알려주세요.<br />
          광고등록 완료를 알려주셔야 광고 진행이 가능합니다.
        </p>
        <p v-else-if="company.category.firstName === '매장'">
          수정하신 후, 저장 버튼을 눌러주세요.<br />
          수정사항은 실시간 반영됩니다.<br />
          기존 예약한 회원들에게는 할인혜택 변동에 대한 사전 안내를 해주시기 바랍니다.
        </p>
        <p v-else>
          수정하신 후, 저장 버튼을 눌러주세요.<br />
          수정사항은 실시간 반영되오니 내용 수정을 신중히 부탁드립니다.
        </p>
      </div>
    </div>
    <div v-else-if="$route.path === '/company/contract'">
      <h2 class="headline">이용회원 관리</h2>
      <p>
        <br />회원의 이용확인 및 관리, <br />
        후기확인을 할 수 있습니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/company/report'">
      <h2 class="headline">마케팅 보고서</h2>
      <p>
        내 광고의 이용 현황, 결과물을 확인할 수<br />
        있는 페이지입니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/company/pay' && payState === 1">
      <h2 class="headline">결제 신청</h2>
      <p>합리적인 가격에 마케팅 서비스를 바로 이용해보세요.</p>
    </div>
    <div v-else-if="$route.path === '/company/pay' && payState === 2">
      <h2 class="headline">결제 신청 완료</h2>
      <p>결제 신청이 완료되었습니다.</p>
    </div>
    <div v-else-if="$route.path === '/company/pay' && payState === 3">
      <h2 class="headline">결제 신청 실패</h2>
      <p>결제 신청에 실패했습니다..</p>
    </div>
    <div v-else-if="$route.path === '/company/pay'">
      <h2 class="headline">결제 관리</h2>
      <p v-if="user.email === 'test@supermembers.co.kr'">1개월 단위로 결제되며 이용기간이 지난 후 자동결제됩니다.</p>
      <p>서비스 만료일이 지나면, 광고 업로드가 중지됩니다.</p>
    </div>
    <div v-else-if="$route.path === '/company/settlement'">
      <h2 class="headline">정산 관리</h2>
      <p>
        슈퍼멤버스 회원의 결제내역을 확인하실 수 있습니다.<br />
        정산된 금액은 정산 예정일에 일괄처리됩니다.
      </p>
    </div>
    <div v-else-if="$route.path === '/company/charge'">
      <h2 class="headline">충전금액 관리</h2>
      <p>슈퍼멤버스 충전금을 관리할 페이지 입니다.</p>
    </div>
    <div v-else-if="$route.path === '/company/editinfo'">
      <h2 class="headline">담당자 정보 수정</h2>
      <p>
        광고 및 이용과 관련하여 슈퍼멤버스에서<br />
        연락을 취할 담당자분의 정보를 수정하는 페이지입니다.
      </p>
    </div>
  </div>
</template>

<script>
import userMixin from '../shared/userMixin';
import companyMixin from '../shared/companyMixin';

export default {
  name: 'Header',
  mixins: [userMixin, companyMixin],
};
</script>

<style scoped lang="scss"></style>
