<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <!--@input="$store.dispatch('setStep', adsData.step)"-->
        <v-stepper v-model="adsData.step" :value="adsData.step" alt-labels>
          <v-stepper-header>
            <template v-for="(page, index) in pages">
              <v-stepper-step :complete="adsData.step > page.step" :step="page.step" :key="`${index}-step`">
                <span v-if="adsData.step === page.step" class="rouge--text">{{ page.title }}</span>
              </v-stepper-step>
              <v-divider v-if="index !== pages.length - 1" :key="index"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="(page, index) in pages"
              :key="`${index}-content`"
              :step="page.step"
              class="ma-0 pa-0 grey lighten-5">
              <v-card color="grey lighten-5" flat>
                <keep-alive>
                  <component :is="page.component" v-bind="curProps" v-dynamic-events="customEvents"></component>
                  <!--<component :is="page.component" :="curProps" v-dynamic-events="saveEvents"></component>-->
                  <!--<component :is="page.component" v-model="adsData"></component>-->
                  <!--<component :is="page.component" :value="adsData" @:input="adsData = arguments[0]"></component>-->
                  <!--<component :is="page.component" :value="adsData" @:input="adsData = $event.target.value"></component>-->
                </keep-alive>

                <div class="shades white pa-6 pb-12">
                  <v-btn
                    v-if="index !== 0"
                    color="rouge"
                    class="ma-0 ml-4"
                    text
                    outlined
                    @click="adsData.step--"
                    style="width: 120px"
                    >뒤로가기</v-btn
                  >
                  <v-btn
                    v-if="index !== pages.length - 1"
                    class="rouge white-two--text ma-0 ml-4"
                    @click="checkRequiredInfo(page.step)"
                    :style="'opacity:' + (page.done ? '1.0' : '0.2') + '; width:120px'">
                    다음
                  </v-btn>

                  <v-btn
                    v-else
                    color="primary"
                    class="ma-0 mr-4"
                    @click="adsData.category.firstName === '매장' ? (showComplete = true) : registerAds()"
                    style="float: right; width: 120px">
                    광고등록
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showStop" content-class="white-two" persistent max-width="500px">
        <v-card class="px-6 pt-2 pb-4">
          <v-card-title class="pb-1">
            <div>
              <v-icon color="default" @click="showStop = false">close</v-icon>
              <h2 class="mt-4 font-weight-bold headline">광고 등록에 어려움이 있으신가요?</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="body-1">
              광고 등록에 관해 궁금한 점이 있으시다면,
              <span class="rouge--text">오른쪽 하단 채팅버튼</span>을<span class="desktop" /> 클릭하여 도움을 받으실 수
              있습니다.<span class="desktop" /> 현재 작성중인 광고를 임시 저장하고, 언제든지 현재 페이지로<span
                class="desktop" />
              돌아오실 수 있습니다.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn class="rouge white-two--text" text @click.native="showStop = false">뒤로</v-btn>
            <v-btn
              color="rouge"
              class="ma-0 ml-4"
              text
              outlined
              @click="
                showStop = false;
                save();
                $router.push('/company/report');
              "
              >임시저장 후 종료</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showComplete" content-class="white-two" persistent max-width="500px">
        <v-card class="px-8 pt-2 pb-2">
          <v-card-title class="pb-1 pl-">
            <div>
              <h2 class="mt-4 font-weight-bold headline">카톡채널 '슈퍼멤버스'로<br />꼭 업체명을 알려주세요.</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <span class="body-1">
              슈퍼멤버스는 광고진행 알림을 카톡으로 드리고 있습니다.<br />
              하단 카톡채널 버튼을 누르신 후 업체명을 보내주셔야 광고진행이 가능합니다.<br />
              <span class="french-blue--text">(하단 버튼 클릭 혹은 카카오톡 > 검색 슈퍼멤버스'채널)<br /></span><br />
            </span>
          </v-card-text>
          <v-card-actions class="px-0 pt-2 pb-6">
            <!--            <v-btn class="rouge white-two&#45;&#45;text ma-0" text @click="showComplete=false;registerAds()" href="https://pf.kakao.com/_Jgyxid" targe="_blank">카톡채널 열기</v-btn>-->
            <v-btn
              color="rouge white-two--text"
              class="ma-0 ml-4"
              text
              @click="
                showComplete = false;
                registerAds();
              "
              >완료</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showRequiredInfoDlg" content-class="white-two" persistent max-width="500px">
        <v-card class="px-8 pt-2 pb-2">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showRequiredInfoDlg = false">close</v-icon><br />
              <h2 class="mt-2 font-weight-bold headline">누락된 필수 정보가 있습니다. 계속 진행하시겠습니까?</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <span class="body-1">
              필수 정보를 모두 입력해주셔야 광고를 개시할 수 있습니다.<br />
              미입력된 정보는 광고 등록 완료 후 광고 관리 페이지에서도 입력하실 수 있습니다.
            </span>
          </v-card-text>
          <v-card-actions class="px-0 pt-2 pb-6">
            <v-spacer></v-spacer>
            <v-btn color="rouge" outlined flat @click="showRequiredInfoDlg = false">취소</v-btn>
            <v-btn
              color="rouge white-two--text"
              class="ma-0 ml-4"
              @click="
                showRequiredInfoDlg = false;
                nextStep(adsData.step);
              "
              >넘어가기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from '@/components/shared/event-bus';
import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';
import Page1 from '../../components/company/regads/Page1';
import Page6 from '../../components/company/regads/Page6';

export default {
  name: 'Regads',
  mixins: [userMixin, companyMixin],
  components: {},
  computed: {
    curProps: function () {
      // if (this.pages[this.adsData.step - 1].component.name === '') {
      return {
        adsData: this.adsData,
        option: this.option,
      };
      // }
    },
    showStop: {
      set(showDialog) {
        this.$store.commit('setShowDialog', showDialog);
      },
      get() {
        return this.$store.getters.showDialog;
      },
    },
  },
  data() {
    return {
      adsData: {
        category: {
          firstName: '매장',
          first: '1cbTA11dQev5s531ukil',
          secondName: '',
          thirdName: '',
        },
        is_verified: 99,
        step: 1,
      },
      option: { category: {} },
      pages: [
        {
          step: 1,
          title: '기본 정보 입력',
          // component: 'Page1'
          component: Page1,
          done: false,
        },
        {
          step: 2,
          done: true,
        },
        {
          step: 3,
          done: true,
        },
        {
          step: 4,
          done: true,
        },
        {
          step: 5,
          done: true,
        },
        {
          step: 6,
          title: '사진 업로드',
          component: Page6,
          done: true,
        },
      ],
      showComplete: false,
      customEvents: {
        updateEvent: e => {
          let page = this.pages[e.step - 1];
          page.done = true;
          this.$set(this.pages, e.step - 1, page);
          if (e.category && e.category.firstName !== this.adsData.category.firstName) {
            console.log('updateEvent');
            this.adsData = {
              is_verified: 99,
              step: 1,
              id: this.adsData.id,
            };
          }
          // delete e.step;
          // this._.merge(this.adsData, e);
          Object.keys(e).forEach(key => {
            this.adsData[key] = e[key];
          });
          // this.adsData = this._.omitBy(this.adsData, this._.isUndefined(this.adsData));
          // this.adsData = this._.defaults(e, this.adsData)
        },
      },
      timer: null,
      showRequiredInfoDlg: false,
      requiredMap: {
        매장: {
          2: ['local.region', 'local.code', 'name', 'address', 'contacts', 'time'],
          3: ['abstraction', 'introduction'],
          4: {
            A: ['keywords'],
            S: [
              'naver.blackPrice',
              'naver.redPrice',
              'naver.yellowPrice',
              'naver.black',
              'naver.red',
              'naver.yellow',
              'keywords',
            ],
          },
          6: ['thumbnails'],
        },
        제품: {
          2: ['name', 'site', 'companyName', 'csNumber'],
          3: ['abstraction', 'introduction'],
          4: {
            A: ['naver.number', 'naver.diamond', 'keywords'],
            S: ['naver.number', 'naver.black', 'naver.red', 'naver.yellow', 'keywords'],
          },
          6: ['thumbnails'],
        },
      },
    };
  },
  watch: {
    companyTemp(value) {
      if (value.is_verified === 99) {
        let data = JSON.parse(JSON.stringify(value));
        let step = data.step;
        data.step = 1;
        console.log(data);
        this.adsData = data;
        this.changeComponent();
        this.adsData.step = step;
      }
    },
    'adsData.step': function (value) {
      window.dataLayer.push({
        event: 'ad_step_info',

        adRegisterStep: value,
      });

      this.$store.commit('setCompanyRegStep', value);
    },
  },
  directives: {
    DynamicEvents: {
      bind: function (el, binding, vnode) {
        let allEvents = binding.value;
        if (typeof allEvents !== 'undefined') {
          let allEventsName = Object.keys(binding.value);
          allEventsName.forEach(function (event) {
            vnode.componentInstance.$on(event, eventData => {
              allEvents[event](eventData);
            });
          });
        }
      },
      unbind: function (el, binding, vnode) {
        vnode.componentInstance.$off();
      },
    },
  },
  methods: {
    async checkRequiredInfo(step) {
      if (this.requiredMap[this.adsData.category.firstName][step]) {
        if (
          (
            this.requiredMap[this.adsData.category.firstName][step][this.adsData.type] ||
            this.requiredMap[this.adsData.category.firstName][step]
          ).every(field => {
            if (field.indexOf('.') > 0) {
              return !this._.isNil(this.adsData[field.split('.')[0]]?.[field.split('.')[1]]);
            } else {
              return !this._.isNil(this.adsData[field]);
            }
          })
        ) {
          await this.nextStep(step);
        } else {
          this.showRequiredInfoDlg = true;
        }
      } else {
        await this.nextStep(step);
      }
    },
    async nextStep(step) {
      if (step) {
        this.changeComponent();
      }

      if (step === this.pages.length) {
        this.adsData.step = 1;
      } else if (this.pages[step - 1].done) {
        this.adsData.step = step + 1;
      }
      await this.save();
    },
    async save() {
      this.adsData.email = this.adsData.email || this.user.email;
      this.adsData.user_id = this.adsData.user_id || this.user.id;
      // if(this.companyTemp.id) {
      //   await this.axios.put('/company3?id=' + this.companyTemp.id, this.adsData);
      //   await this.$store.dispatch('getCompanyInfo', {email: this.user.email});
      // }
      // else {
      if (this.adsData.step === 6) {
        this.$store.commit('clearCompanyTemp');
      } else {
        this.$store.commit('setCompanyTemp', this.adsData);
      }

      let res = await this.axios.post('/company3', this.adsData);
      if (!this.adsData.id && res?.data?.id) {
        this.adsData.id = res?.data?.id;
      }
      if ((this.adsData.category.firstName === '제품' && this.adsData.step === 2) || this.adsData.step === 6) {
        if (this.adsData.step === 6) {
          this.$store.commit('setLoading', true);
          let delay = ms => new Promise(resolve => setTimeout(resolve, ms));
          await delay(3000);
          this.$store.commit('setLoading', false);
        }
        await this.$store.dispatch('getCompanyInfo', {
          email: this.user.email,
        });
      }
      if (this.adsData.category.firstName === '매장' && this.adsData.step === 6 && this.adsData.is_verified !== 3) {
        location.reload();
      }
      // }
    },
    changeComponent() {
      for (let i = 1; i < 5; i++) {
        let page = this.pages[i];
        page.component = () =>
          import(
            '../../components/company/regads/' +
              (this.adsData.category.firstName === '매장' ? 'store' : 'product') +
              '/Page' +
              (i + 1)
          );
        if (i === 1) {
          page.title = this.adsData.category.firstName === '매장' ? '가맹점 정보 입력' : '제품 정보 입력';
        } else if (i === 2) {
          page.title = '광고 정보 입력';
        } else if (i === 3) {
          page.title = '채널 정보 입력';
        } else if (i === 4) {
          page.title = this.adsData.category.firstName === '매장' ? '품목 입력' : '제품 종류 입력';
        }
        this.$set(this.pages, i, page);
      }
      for (let i = 0; i < this.adsData.step - 1; i++) {
        let page = this.pages[i];
        page.done = true;
        this.$set(this.pages, i, page);
      }
    },
    async registerAds() {
      this.adsData.is_verified = 3;

      if (!this.$store.getters.loading) {
        this.$store.commit('setLoading', true);
        try {
          this.adsData.lastContractDate = new Date();
          this.adsData.lastContractDateInsta = new Date(this.$moment().subtract(30, 'years').format('YYYY-MM-DD'));
          this.adsData.deadline = this.adsData.category.firstName === '매장' && this.adsData.type === 'S' ? 7 : 14;
          this.adsData.blackPoint = this.adsData.redPoint = this.adsData.diamondPoint;
          ['naver', 'insta'].forEach(channel => {
            if (this.adsData[channel]) {
              this.adsData[channel].revisitPeriod = this.adsData.category.firstName === '매장' ? 60 : 60;
              if (this.adsData[channel].number > 0) {
                this.adsData[channel].current = 0;
              }
              this.adsData[channel].blackPayment = this.adsData[channel].blackPayment || 0;
              this.adsData[channel].redPayment = this.adsData[channel].redPayment || 0;
              this.adsData[channel].yellowPayment = this.adsData[channel].yellowPayment || 0;
              this.adsData[channel].whitePayment = this.adsData[channel].whitePayment || 0;
              if (this.adsData.category.firstName === '제품') {
                this.adsData[channel].deliveryCharge = 0;
              }

              if (
                this.adsData[channel].black !== undefined &&
                (this.adsData[channel].diamond === null || this.adsData[channel].diamond === undefined)
              ) {
                this.adsData[channel].diamond = this.adsData[channel].black;
              }
              if (
                this.adsData[channel].blackFullPrice !== undefined &&
                (this.adsData[channel].diamondFullPrice === null ||
                  this.adsData[channel].diamondFullPrice === undefined)
              ) {
                this.adsData[channel].diamondFullPrice = this.adsData[channel].blackFullPrice;
              }
              if (
                this.adsData[channel].blackPrice !== undefined &&
                (this.adsData[channel].diamondPrice === null || this.adsData[channel].diamondPrice === undefined)
              ) {
                this.adsData[channel].diamondPrice = this.adsData[channel].blackPrice;
              }
              if (
                (this.adsData[channel].blackPayment !== undefined &&
                  (this.adsData[channel].diamondPayment === null ||
                    this.adsData[channel].diamondPayment === undefined)) ||
                (!!this.adsData[channel].blackPayment && this.adsData[channel].diamondPayment === 0)
              ) {
                this.adsData[channel].diamondPayment = this.adsData[channel].blackPayment;
              }

              if (this.adsData.type === 'A' && this.adsData.deliveryMethod === 'PR') {
                this.adsData[channel].diamondPayment = this.adsData[channel].diamondFullPrice;
              }
            }
          });

          this.adsData.code = '55';
          const body = { ...this.adsData };

          /*
          rereceiptReviewRequired => false 라는 것은 영수증 리뷰가 필수가 아닌 것으로 화이트 등급이 참여가 불가능
          enableWhite => false 라는 것은 화이트 등급을 활성화 하지 않겠다는 뜻
          */
          if (!body.receiptReviewRequired || !body?.naver?.enableWhite) {
            ['white', 'whiteFullPrice', 'whitePayment', 'whitePrice'].forEach(key => {
              if (body['naver']) {
                delete body['naver'][key];
              }
            });
          }

          //프리미엄 체험단 생성시 다른 등급 금액 초기화
          if (body.type === 'A') {
            ['black', 'red', 'yellow'].forEach(level => {
              body.naver[`${level}`] = null;
              body.naver[`${level}FullPrice`] = null;
              body.naver[`${level}Price`] = null;
              body.naver[`${level}Payment`] = null;
            });
          }

          this.adsData = body;

          await this.save();
          this.$router.push('/company/report');

          this.$store.commit('setMsg', '저장이 완료되었습니다.');
          this.$store.commit('setShowSnackbar', true);
        } catch (err) {
          console.log(err);
        }
        this.$store.commit('setLoading', false);
      }
    },
  },
  beforeCreate() {
    this.axios
      .get('/company/option')
      .then(option => {
        this.option = option.data;
        delete this.option.category['서비스'];
      })
      .catch(err => {
        console.log(err);
      });
  },
  created() {
    // if(this._.isEmpty(this.companyTemp)) {
    //   this.$store.commit('setCompanyTemp', this.adsData);
    // }
    if (this.companyTemp.is_verified === 99) {
      this.adsData = JSON.parse(JSON.stringify(this.companyTemp));
      this.changeComponent();
    }
    EventBus.$on('save:ads', async () => {
      await this.save();
      await this.$store.dispatch('getCompanyInfo', {
        id: this.adsData.companyId,
      });
    });
    // this.timer = setInterval(async () => {
    //   if (this.$route.path === "/regads") {
    //     await this.save();
    //   } else {
    //     clearInterval(this.timer);
    //   }
    // }, 3 * 60000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },
  beforeRouteLeave(to, from, next) {
    if ((this.adsData.step === 2 || this.adsData.step === 3) && this.adsData?.category?.third) {
      this.axios
        .post('utils/sendalimtalk', {
          templateId: '50035',
          phoneNumber: this.user.contacts.replace(/\D/g, ''),
          contents:
            '[슈퍼멤버스] 다음단계 진행 안내\n\n' +
            '안녕하세요, ' +
            this.user.name +
            ' 담당자님.\n' +
            '광고 정보 등록이 완료되지 않아 알림 드립니다.\n\n' +
            '등록 중 어려운 점이나,\n' +
            '문의사항이 있으시면 편하게 채팅 남겨주세요(카톡)',
          urls: ['https://bit.ly/3JeD6kT'],
          reserve_time: this.$moment().add(10, 'm').format('YYYY-MM-DD HH:mm:ss'),
          variables: JSON.stringify({
            '#{가맹점명}': this.user.name,
          }),
        })
        .then(() => {
          return this.axios.post('utils/sendalimtalk', {
            templateId: '50048',
            phoneNumber: this.user.contacts.replace(/\D/g, ''),
            contents:
              '[슈퍼멤버스] 다음단계 진행 안내\n\n' +
              '광고 등록에 어려움이 있으신가요?\n\n' +
              '🙋 슈퍼멤버스 매니저가 등록을 대신 도와드릴게요!\n\n' +
              "'도움 요청'이라고 채팅을 남겨주세요.",
            reserve_time: this.$moment().add(24, 'h').format('YYYY-MM-DD HH:mm:ss'),
          });
        })
        .then(() => {
          next();
        })
        .catch(error => {
          console.error(error);
          next();
        });
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/custom';

::v-deep h3 {
  height: 24px;
  font-size: 18px;
}

::v-deep .v-btn-toggle .v-btn:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

::v-deep .v-btn-toggle .v-btn:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

::v-deep .light_bulb {
  width: 14px;
  height: 20px;
  object-fit: contain;
  background-color: #00000000;
}

::v-deep .line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

::v-deep .label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

::v-deep .level_icon {
  width: 56px;
  height: 67px;
  object-fit: contain;
}

::v-deep .text-right input {
  text-align: right;
}
</style>
