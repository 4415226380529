<!--<template>-->
<!--<div class="container">-->
<!--<canvas ref="canvas" width="240" height="200"></canvas>-->
<!--</div>-->
<!--</template>-->
<script>
import { HorizontalBar } from 'vue-chartjs';

import companyMixin from '../../shared/companyMixin';

export default {
  name: 'DashboardChart',
  extends: HorizontalBar,
  mixins: [companyMixin],
  props: ['datas'],
  data() {
    return {
      questions: [],
    };
  },
  watch: {
    datas() {
      // this.datas.splice(4, 1)
      this.renderChart(
        {
          labels: this.datas.map(data => {
            return data.question;
          }),
          datasets: [
            {
              label: '내 광고 만족지수',
              backgroundColor: 'rgba(189, 19, 40, 0.7)',
              borderColor: 'rgba(82, 82, 82, 0.7)',
              borderWidth: 1,
              data: this.datas.map(data => {
                if (data.myAnswerInfo) {
                  return data.myAnswerInfo.avg;
                } else {
                  return 0;
                }
              }),
            },
            {
              label: '전체가맹점 평균',
              backgroundColor: '(117, 117, 117, 0.2)',
              borderColor: 'rgba(245, 203, 198, 0.7)',
              borderWidth: 1,
              data: this.datas.map(data => {
                return data.avg;
              }),
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  maintainAspectRatio: true,
                  max: 5,
                },
              },
            ],
          },
        }
      );
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss"></style>
