<template>
  <div class="sidelayout-content">
    <div class="flex-box mypage-box">
      <div class="box">
        <div class="flex-box padding-small box-title">가맹점 코드</div>
        <div class="flex-box padding-small">
          <h1>{{ code }}</h1>
        </div>
      </div>
      <div class="box">
        <div class="flex-box padding-small box-title">담당자 정보</div>
        <div class="padding-small">
          <h5><span class="font-weight-bold">담당자</span>&emsp;{{ (company || {}).charger }}</h5>
          <h5><span class="font-weight-bold">연락처</span>&emsp;{{ (company || {}).contacts }}</h5>
          <h5><span class="font-weight-bold">이메일</span>&emsp;{{ (company || {}).email }}</h5>
        </div>
      </div>
    </div>
    <div class="mobile" style="margin-top: 10px">
      <router-link to="/editinfo/">
        <button class="primary">담당자 정보 수정하기</button>
      </router-link>
    </div>
    <div class="box margin">
      <div class="flex-box padding-small box-title">
        <span>누적 이용 통계</span>
        <div class="dropdown">
          <router-link to="/report/"><i class="material-icons">more_horiz</i></router-link>
          <ul>
            <li>
              <router-link to="/report/">보고서 더보기</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="used-box">
        <div class="stacked-box">
          <div>
            <h4 class="light-grey">BLACK</h4>
            <h2 class="font-weight-bold">{{ used.black }}</h2>
          </div>
          <div>
            <h4 class="light-grey">RED</h4>
            <h2 class="font-weight-bold red">{{ used.red }}</h2>
          </div>
        </div>
        <div class="stacked-box">
          <div>
            <h4 class="light-grey">YELLOW</h4>
            <h2 class="font-weight-bold yellow">{{ used.yellow }}</h2>
          </div>
          <div>
            <h4 class="light-grey">관심</h4>
            <h2 class="font-weight-bold color-primary">{{ used.like }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile" style="margin-top: 10px">
      <router-link to="/report/">
        <button class="primary">마케팅 보고서 더보기</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import userMixin from '@/components/shared/userMixin';
import companyMixin from '@/components/shared/companyMixin';

let getInfo = function () {
  this.axios
    .get('/company/adsmodel?incharge_id=' + this.company.id)
    .then(model => {
      if (model.data.total > 0) {
        this.code = model.data.adsmodels[0].code;
      }
    })
    .catch(err => {
      console.log(err);
    });

  this.axios
    .get('/contract?company=' + this.company.id)
    .then(contracts => {
      contracts.data.contracts.forEach(contract => {
        this.used[contract.level]++;
      });
    })
    .catch(err => {
      console.log(err);
    });

  this.axios
    .get('/company/like?id=' + this.company.id)
    .then(likes => {
      this.used['like'] = likes.data.total;
    })
    .catch(err => {
      console.log(err);
    });
};

export default {
  name: 'Mypage',
  mixins: [userMixin, companyMixin],
  data() {
    return {
      code: '55',
      used: {
        black: 0,
        red: 0,
        yellow: 0,
        like: 0,
      },
    };
  },
  watch: {
    company(value) {
      if (value !== null && value !== undefined) {
        getInfo.call(this);
      }
    },
  },
  created() {
    if (!this._.isEmpty(this.company)) {
      getInfo.call(this);
    }
  },
};
</script>

<style scoped lang="scss">
/*@import '../../assets/css/headerfooter.css';*/
.mypage-box {
  align-items: stretch;
}

.mypage-box .box {
  flex: 1;
  margin-right: 10px;
}

.mypage-box .box:last-child {
  margin-right: 0;
}

.used-box,
.used-box .stacked-box {
  display: flex;
}

.used-box .stacked-box,
.used-box .stacked-box > div {
  flex: 1;
  border-right: 1px solid #d0d0d0;
}

.used-box .stacked-box > div {
  text-align: center;
  padding: 20px 0;
}

.used-box .stacked-box:last-child,
.used-box .stacked-box > div:last-child {
  border: none;
}

@media (max-width: 960px) {
  .mypage-box {
    flex-direction: column;
  }
  .mypage-box .box {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .mypage-box .box:last-child {
    margin-bottom: 0;
  }
  .used-box {
    flex-direction: column;
  }
  .used-box .stacked-box {
    border-right: none;
    border-bottom: 1px solid #d0d0d0;
  }
}
</style>
