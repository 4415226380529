<template>
  <div v-if="showGuide">
    <v-dialog
      v-model="showGuide"
      @click:outside="closeDialog"
      @keyup.esc="closeDialog()"
      content-class="white-two"
      persistent
      :max-width="$vuetify.breakpoint.mdAndUp ? '790px' : '400px'">
      <v-card class="pa-4">
        <v-card-title>
          <div><v-icon color="default" @click="showGuide = false">close</v-icon><br /></div>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-carousel v-model="guideIdx" hide-delimiters>
            <v-carousel-item v-for="(guide, i) in guides" :key="i" :src="guide"> </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="rouge"
            outlined
            flat
            :href="$vuetify.breakpoint.mdAndUp ? 'http://map.naver.com' : 'http://www.naver.com'"
            target="_blank"
            >네이버 지도 바로가기</v-btn
          >
          <v-btn class="rouge white-two--text" flat @click="showGuide = false">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Naverplaceguide',
  props: ['value'],
  data: function () {
    return {
      showGuide: this.value,
      guidesD: [
        require('@/assets/img/campaign/naverPlaceD_1.png'),
        require('@/assets/img/campaign/naverPlaceD_2.png'),
        require('@/assets/img/campaign/naverPlaceD_3.png'),
      ],
      guidesM: [
        require('@/assets/img/campaign/naverPlaceM_1.png'),
        require('@/assets/img/campaign/naverPlaceM_2.png'),
        require('@/assets/img/campaign/naverPlaceM_3.png'),
        require('@/assets/img/campaign/naverPlaceM_4.png'),
      ],
      guideIdx: 0,
    };
  },
  computed: {
    guides() {
      return this.$vuetify.breakpoint.mdAndUp ? this.guidesD : this.guidesM;
    },
  },
  watch: {
    value(value) {
      console.log(value);
      this.showGuide = value;
    },
    showGuide(value) {
      console.log(value);
      this.$emit('input', value);
    },
  },
  methods: {
    closeDialog() {
      this.showGuide = false;
    },
  },
};
</script>
